import React, { useEffect, useMemo, useState, useContext } from "react";
import withDefaultDashBoardLayout from "../../../../components/layouts/withDefaultDashBoardLayout";
import styles from "./index.module.scss";
import BreadCrumb from "../../../../components/BreadCrumb";
import { useParams } from "react-router-dom";
import { revenueDirectPayFilters } from "../utils";
import { Pagination } from "react-bootstrap";
import {
  formatToRupees,
  getPaginationList,
} from "../../../../helpers/constants/func";
import { getDirectPayLedgerEntriesApi } from "../../../../helpers/services/revenueservices";
import moment from "moment";
import StatusBadge from "../../../../components/StatusBadge";
import { statusMapper } from "../../revenuePDCPage/utils";
import LedgerStatusModal from "./modal/LedgerStatusModal";
import GlobalStateContext from "../../../../GlobalStateContext";
import {  getFiscalYearForListing } from "../../../../helpers/common/common.function";

function RevenueDirectPayDetailsPage() {
  const params = useParams();
  const { roles } = useContext(GlobalStateContext);
  const [selected, setSelected] = useState({
    show: false,
    info: null,
  });
  const [filters, setFilters] = useState({
    status: "",
    page: 1,
  });
  const [listInfo, setListInfo] = useState({
    items: [],
    meta: {
      current_page: 1,
      total_data_count: 0,
      total_pages: 0,
    },
  });

  const onHandleFilter = (value) => {
    setFilters((prev) => {
      let st = { ...prev };
      st.status = value;
      st.page = 1;
      return st;
    });
  };

  const onHandlePagination = (value) => {
    setFilters((prev) => {
      let st = { ...prev };
      st.page = value;
      return st;
    });
  };

  const getEntries = async (status, page) => {
    try {
      const { data, meta } = await getDirectPayLedgerEntriesApi(
        params.id,
        status,
        page,
      );
      setListInfo({
        items: data.map((_i) => {
          let mDT = moment(_i?.transaction_date);
          _i.transaction_date =
            _i?.transaction_date && mDT.isValid()
              ? mDT.format("DD MMM YYYY")
              : "";
          mDT = moment(_i?.approved_at);
          _i.approved_at = _i?.approved_at && mDT.isValid()
            ? mDT.format("DD MMM YYYY")
            : "";
          _i.amount = formatToRupees(_i.amount);
          return _i;
        }),
        meta,
      });
    } catch (e) {
      setListInfo({
        items: [],
        meta: {
          current_page: 1,
          total_data_count: 0,
          total_pages: 0,
        },
      });
    }
  };

  const onSelect = (view = false, value = null, callApi = false) => {
    const result = {
      show: false,
      info: null,
    };
    if (view) {
      result.show = true;
      result.info = value;
    } else {
      if (callApi) {
        getEntries(filters.status, filters.page);
      }
    }
    setSelected(result);
  };

  const paginateItems = useMemo(() => {
    if (listInfo.meta.total_pages) {
      return getPaginationList(filters.page, listInfo.meta.total_pages);
    }
    return [];
  }, [listInfo, filters.page]);

  useEffect(() => {
    getEntries(filters.status, filters.page);
  }, [filters]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <LedgerStatusModal
        info={selected.info}
        show={selected.show}
        close={(value, callAPi) => onSelect(false, value, callAPi)}
      />
      <div className={styles["revenue-directpaydetails-page"]}>
        <div className={styles["heading-container"]}>
          <div className={styles["heading-left"]}>
            <h1 className={"mb-0 " + styles["heading"]}>
              <span className={"font-zkga fw-800 text-gray-900"}>
                Direct Pay -{" "}
              </span>
              <span className={"font-manrope fw-700 text-gray-600"}>
                {getFiscalYearForListing()}
              </span>
            </h1>
            <div>
              <BreadCrumb
                seperator="/"
                list={[
                  {
                    label: "Revenue",
                    link: "/revenue/direct-pay",
                    active: false,
                  },
                  {
                    label: "Direct Pay",
                    link: "/revenue/direct-pay",
                    active: false,
                  },
                  {
                    label: params?.empName || "",
                    link: "",
                    active: true,
                  },
                ]}
              />
            </div>
          </div>
        </div>

        <div className={styles["content-container"]}>
          <div className={styles["content-table"] + " table-responsive"}>
            <div className={styles["content-filter"]}>
              <div className={styles["content-left"]}>
                {revenueDirectPayFilters.map((revenueFilter) => {
                  return (
                    <button
                      onClick={() => {
                        onHandleFilter(revenueFilter.value);
                      }}
                      key={"revenueFilter_" + revenueFilter.value}
                      className={
                        "btn " +
                        (revenueFilter.value === filters.status
                          ? styles["active"]
                          : "")
                      }
                    >
                      {revenueFilter.label}
                    </button>
                  );
                })}
              </div>
              <div className={styles["content-right"]}></div>
            </div>

            <table className="table align-middle">
              <thead>
                <tr>
                  <th>Property Name</th>
                  <th>Property Code</th>
                  <th>Resident ID</th>
                  <th>Resident Name</th>
                  <th>Transaction ID</th>
                  <th>Amount</th>
                  <th>Transaction Date</th>
                  <th>{`${filters?.status === "rejected" ? 'Rejected' : 'Approved'}`} By</th>
                  {
                    (filters.status === "approved") ? (<th>Approved At</th>) : (<></>)
                  }
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                {listInfo.items.map((item) => {
                  const statusBadge =
                    statusMapper[item.status] ?? statusMapper.collected;
                  return (
                    <tr key={"ledger_" + item.id}>
                      <td>{item.property_details?.property_name}</td>
                      <td>{item.property_details?.property_code}</td>
                      <td>{item.lead_details?.resident_id}</td>
                      <td>{item.lead_details?.resident_name}</td>
                      <td>{item?.payment_transaction_id}</td>
                      <td>{item?.amount}</td>
                      <td>{item?.transaction_date}</td>
                      {
                        filters?.status === "rejected" ?
                        <td>{`${item?.rejected_by_details?.employee_first_name ?? ""} ${item?.rejected_by_details?.employee_last_name ?? ""}`}</td>
                        :
                          <td>{`${item?.approved_by_details?.employee_first_name ?? ""} ${item?.approved_by_details?.employee_last_name ?? ""}`}</td>
                      }
                      {
                        (filters.status === "approved") ? (<td>{item?.approved_at}</td>) : (<></>)
                      }
                      <td>
                        <StatusBadge
                          onClick={() =>
                            item.status !== "deposited"
                              ? onSelect(true, item)
                              : item.status === "deposited" &&
                                (roles?.super_admin ||
                                  roles?.finance_manager ||
                                  roles?.finance_head || roles?.cfo)
                                ? onSelect(true, item)
                                : null
                          }
                          style={{ ...statusBadge.style, cursor: "pointer" }}
                          label={statusBadge.label}
                        />
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
            {paginateItems.length > 1 ? (
              <div className="d-flex justify-content-end">
                <Pagination>
                  <Pagination.Item
                    disabled={filters.page === 1}
                    onClick={() => {
                      if (filters.page > 1) {
                        onHandlePagination(filters.page - 1);
                      }
                    }}
                  >
                    <svg
                      className="rotate-270deg"
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M4 10.5L8 6.5L12 10.5"
                        stroke="#FF5700"
                        stroke-width="1.25"
                        stroke-linecap="round"
                      />
                    </svg>
                  </Pagination.Item>
                  {paginateItems.map((item) => {
                    return (
                      <Pagination.Item
                        onClick={() => {
                          onHandlePagination(item);
                        }}
                        key={"PaginateItem_" + item}
                        active={item === filters.page}
                      >
                        {item}
                      </Pagination.Item>
                    );
                  })}
                  <Pagination.Item
                    disabled={!(filters.page < listInfo.meta.total_pages)}
                    onClick={() => {
                      if (filters.page < listInfo.meta.total_pages) {
                        onHandlePagination(filters.page + 1);
                      }
                    }}
                  >
                    <svg
                      className="rotate-90deg"
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M4 10.5L8 6.5L12 10.5"
                        stroke="#FF5700"
                        stroke-width="1.25"
                        stroke-linecap="round"
                      />
                    </svg>
                  </Pagination.Item>
                </Pagination>
              </div>
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default withDefaultDashBoardLayout(RevenueDirectPayDetailsPage);
