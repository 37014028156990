import { useEffect ,useState, useContext } from 'react';
import '../../../css/resident-details-tab.css';
import { Modal } from "react-bootstrap";
import { Form } from "react-bootstrap";
import Select from "react-dropdown-select";
// import Select from "react-select";
import { useFormik } from 'formik';
import * as Yup from 'yup';
import toast from "react-hot-toast";
import { updateProperty } from '../../../helpers/services/api.services';
import GlobalStateContext from '../../../GlobalStateContext';


function DetailsTab({ propDetail,clusters,states,cities,loadPropertyDetails }) {

    const {roles} = useContext(GlobalStateContext);
    const [showUpdatePropertyModal, setShowUpdatePropertyModal] = useState(false);
    const handleCloseConfirmNocModal = () => {
        loadValues();
        setShowUpdatePropertyModal(false)
    };
    const handleCreatePropertyModal = () => setShowUpdatePropertyModal(true);

    useEffect(()=>{
        // console.log("propDetail -- ",propDetail)
        loadValues();
    },[propDetail,clusters,states,cities])

    const loadValues=()=>{
        formik.setFieldValue('id', propDetail?.id)
        formik.setFieldValue('property_name', propDetail?.property_name)
        formik.setFieldValue('property_code', propDetail?.property_code)
        formik.setFieldValue('capacity_no_of_beds', propDetail?.capacity_no_of_beds ? propDetail.capacity_no_of_beds : "")
        formik.setFieldValue('postal_address_1', propDetail?.postal_address_1)
        formik.setFieldValue('postal_address_2', propDetail?.postal_address_2)
        formik.setFieldValue('pincode', propDetail?.pincode)
        // formik.setFieldValue('city', propDetail);
        formik.setFieldValue('city_id', propDetail?.city_details?.id);
        // formik.setFieldValue('state', propDetail);
        formik.setFieldValue('state_id', propDetail?.city_details?.state_id);
        // formik.setFieldValue('cluster', propDetail);
        formik.setFieldValue('cluster_id', propDetail?.cluster_details?.id);
        formik.setFieldValue('location', propDetail?.google_map_link)
        formik.setFieldValue('invoice_series', propDetail?.invoice_series);
        formik.setFieldValue('property_gstin', propDetail?.property_gstin)
    }

    const validationSchema = Yup.object().shape({
        property_name: Yup.string().optional(),
        property_code: Yup.string().optional(),
        capacity_no_of_beds: Yup.number().optional(),
        postal_address_1: Yup.string().optional(),
        postal_address_2: Yup.string().optional(),
        pincode: Yup.string().optional().matches(/^[0-9]{6}$/, 'Pin code must be exactly 6 digits'),
        cluster_id: Yup.number().optional(),
        cluster: Yup.object().optional(),
        state_id: Yup.number().optional(),
        state: Yup.object().optional(),
        city_id: Yup.number().optional(),
        city: Yup.object().optional(),
        location: Yup.string().optional(),
        invoice_series: Yup.string().matches(/^[a-zA-Z0-9]+$/, "Invoice series code Must be alphanumeric").min(10, 'Invoice series code Must be 10 alphanumeric characters').max(10, 'Invoice series code Must be 10 alphanumeric characters'),
        property_gstin: Yup.string().matches(/^[a-zA-Z0-9]+$/, "GSTIN Must be alphanumeric").min(15, 'GSTIN Must be 15 alphanumeric characters').max(15, 'GSTIN code Must be 15 alphanumeric characters'),
      });
    
      const initialValues = {
        property_name: '',
        property_code: '',
        capacity_no_of_beds: '',
        postal_address_1: '',
        postal_address_2: '',
        pincode: '',
        city_id: null,
        city: {},
        state_id: null,
        state: {},
        cluster_id: null,
        cluster: {},
        location: '',
        id:'',
        invoice_series: '',
        property_gstin: '',
      };
    
      const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit: async (values) => {
          try {

            const requestObj = {
              property_name: values.property_name,
            //   property_code: values.property_code,
              capacity_no_of_beds: values.capacity_no_of_beds,
              postal_address_1: values.postal_address_1,
              postal_address_2: values.postal_address_2,
              pincode: values.pincode,
              cluster_id: values.cluster_id,
              city_id:values.city_id,
              state_id:values.state_id,
              google_map_link:values.location,
              invoice_series: values.invoice_series,
              property_gstin: values.property_gstin,
            };

            // console.log("requestObj -- ",values.id,requestObj)
    
            let response = await updateProperty(values.id,requestObj);
    
            if (response && response?.status === 'success') {
                await toast.success('Property has been updated successfully.');
                loadPropertyDetails();
                handleCloseConfirmNocModal();
            } else {
              await toast.error('Something went wrong while updatig property details.');
            }
          } catch (error) {
            console.log(error);
          }
        },
      });

    return (
        <>
            <div className='row'>
                <div className='col-md-6'>
                    <div className='brd_box_card'>
                        <div className='box_head'>
                            <h2> <img src='/images/bank-note.svg' className='pr-2' alt='' /> Basic information</h2>
                        </div>

                        <div className='box_contain'>
                            <div className='d-flex justify-content-between  box_card_data'>
                                <h3>Name</h3>
                                <i>:</i>
                                <span>{propDetail?.property_name}</span>
                            </div>
                            <div className='d-flex justify-content-between  box_card_data'>
                                <h3>Code</h3>
                                <i>:</i>
                                <span>{propDetail?.property_code}</span>
                            </div>
                            <div className='d-flex justify-content-between  box_card_data'>
                                <h3>State</h3>
                                <i>:</i>
                                <span>{propDetail?.state_details?.name}</span>
                            </div>
                            <div className='d-flex justify-content-between  box_card_data'>
                                <h3>City</h3>
                                <i>:</i>
                                <span>{propDetail?.city_details?.city_name}</span>
                            </div>
                            <div className='d-flex justify-content-between  box_card_data'>
                                <h3>Cluster</h3>
                                <i>:</i>
                                <span>{propDetail?.cluster_details?.cluster_name}</span>
                            </div>
                            <div className='d-flex justify-content-between  box_card_data'>
                                <h3>Postal address 1</h3>
                                <i>:</i>
                                <span>{propDetail?.postal_address_1}</span>
                            </div>
                            <div className='d-flex justify-content-between  box_card_data'>
                                <h3>Postal address 2</h3>
                                <i>:</i>
                                <span>{propDetail?.postal_address_2}</span>
                            </div>
                            <div className='d-flex justify-content-between  box_card_data'>
                                <h3>Pincode</h3>
                                <i>:</i>
                                <span>{propDetail?.pincode}</span>
                            </div>
                            <div className='d-flex justify-content-between  box_card_data'>
                                <h3>Property location</h3>
                                <i>:</i>
                                <span> <a href={propDetail?.google_map_link} target="_blank">{propDetail?.google_map_link}</a> </span>
                            </div>
                            <div className='d-flex justify-content-between  box_card_data'>
                                <h3>Invoice series code</h3>
                                <i>:</i>
                                <span>{propDetail?.invoice_series ? propDetail.invoice_series : "--"}</span>
                            </div>
                            <div className='d-flex justify-content-between  box_card_data'>
                                <h3>GSTIN</h3>
                                <i>:</i>
                                <span>{propDetail?.property_gstin ? propDetail.property_gstin : "--"}</span>
                            </div>
                        </div>
                       
                    </div>
                </div>
            </div>
            {
                roles?.super_admin || roles?.tech_admin?
                    <div style={{
                            position:'fixed',
                            bottom:25,
                            right:25
                        }}>
                            <button
                                style={{border:"none"}}
                                data-bs-toggle="tooltip" data-bs-placement="top" title="Edit"
                                className="edit_button"
                                onClick={handleCreatePropertyModal}
                            >
                                <img src="/images/edit.svg" alt="Edit Icon"/> Edit
                            </button>
                    </div>
                : null
            }

            <Modal
                show={showUpdatePropertyModal}
                onHide={handleCloseConfirmNocModal}
                centered
                size="lg"
                className="confirm_noc_modal"
            >
                <Modal.Body>
                <div className="head_part_date_modal create-prop_modal">
                    <div className="d-flex justify-content-between align-items-center mb-4">
                    <div>
                        <h2 className="title">Edit property details</h2>
                    </div>
                    <div style={{cursor:"pointer"}}>
                        <span onClick={handleCloseConfirmNocModal} className="">
                        <img src="/images/cross_modal.svg" alt="Close Icon" />
                        </span>
                    </div>
                    </div>
                </div>
                <div className="modal_data_part">
                <div className="create-prop_detail">
                    <form onSubmit={formik.handleSubmit}>
                        <div className="TrailWrap mb-4">
                            <div>
                                <Form.Label>Name</Form.Label>
                                <Form.Control
                                placeholder="Name"
                                id="property_name"
                                name="property_name"
                                value={formik.values.property_name}
                                onChange={(e) => formik.setFieldValue('property_name', e.target.value)}
                                />
                                {
                                formik.touched.property_name && formik.errors.property_name ? (
                                    <div className="error-label">{formik.errors.property_name}</div>
                                ) : null
                                }
                            </div>
                            <div>
                                <Form.Label>Code*</Form.Label>
                                <Form.Control
                                placeholder="Enter Property Code"
                                id="property_code"
                                name="property_code"
                                value={formik.values.property_code}
                                onChange={(e) => formik.setFieldValue('property_code', e.target.value)}
                                disabled={true}
                                />
                                {
                                formik.touched.property_code && formik.errors.property_code ? (
                                    <div className="error-label">{formik.errors.property_code}</div>
                                ) : null
                                }
                            </div>
                            <div>
                                <Form.Label>Capacity no of beds</Form.Label>
                                <Form.Control
                                placeholder="Enter No. of Beds"
                                type="number"
                                id="capacity_no_of_beds"
                                name="capacity_no_of_beds"
                                value={formik.values.capacity_no_of_beds}
                                onChange={(e) => formik.setFieldValue('capacity_no_of_beds', e.target.value)}
                                />
                                {
                                formik.touched.capacity_no_of_beds && formik.errors.capacity_no_of_beds ? (
                                    <div className="error-label">{formik.errors.capacity_no_of_beds}</div>
                                ) : null
                                }
                            </div>
                            <div>
                                <Form.Label>Postal address 1</Form.Label>
                                <Form.Control
                                placeholder="Enter address 1"
                                id="postal_address_1"
                                name="postal_address_1"
                                value={formik.values.postal_address_1}
                                onChange={(e) => formik.setFieldValue('postal_address_1', e.target.value)}
                                />
                                {
                                formik.touched.postal_address_1 && formik.errors.postal_address_1 ? (
                                    <div className="error-label">{formik.errors.postal_address_1}</div>
                                ) : null
                                }
                            </div>
                            <div>
                                <Form.Label>Postal address 2</Form.Label>
                                <Form.Control
                                placeholder="Enter address 2"
                                id="postal_address_2"
                                name="postal_address_2"
                                value={formik.values.postal_address_2}
                                onChange={(e) => formik.setFieldValue('postal_address_2', e.target.value)}
                                />
                                {
                                formik.touched.postal_address_2 && formik.errors.postal_address_2 ? (
                                    <div className="error-label">{formik.errors.postal_address_2}</div>
                                ) : null
                                }
                            </div>
                            <div>
                                <Form.Label>Pincode</Form.Label>
                                <Form.Control
                                placeholder="Enter Pincode"
                                type="number"
                                id="pincode"
                                name="pincode"
                                value={formik.values.pincode}
                                onChange={(e) => formik.setFieldValue('pincode', e.target.value)}
                                style={formik.touched.pincode && formik.errors.pincode ? {
                                    borderColor:'red'
                                }: null}
                                />
                                {
                                formik.touched.pincode && formik.errors.pincode ? (
                                    <div className="error-label">{formik.errors.pincode}</div>
                                ) : null
                                }
                            </div>
                            {/* <div className="custom-single-select">
                                <label className="form-label" htmlFor="city">City</label>
                                <Select
                                id="city_id"
                                name="city_id"
                                placeholder="City"
                                className="mt-1"
                                options={cities}
                                value={formik.values.city_id}
                                onChange={(value) => {
                                    formik.setFieldValue('city', value[0]);
                                    formik.setFieldValue('city_id', value[0].value);
                                }}
                                />
                                {
                                formik.touched.city && formik.errors.city ? (
                                    <div className="error-label">{formik.errors.city}</div>
                                ) : null
                                }
                            </div> */}


                            <div className="custom-single-select">
                                <Form.Label>City</Form.Label>
                                <Form.Select 
                                placeholder="Select City"
                                id="city_id"
                                isInvalid={formik.touched.city_id && formik.errors.city_id}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.city_id}
                                aria-label="Default select example">
                                    <option value="">Select City</option>
                                    {
                                        cities.map(city=><>
                                        <option value={city.value}>{city.label}</option>
                                        </>)
                                    }
                                </Form.Select>
                                <Form.Control.Feedback type="invalid">
                                        {formik.errors.city_id}
                                </Form.Control.Feedback>
                            </div>


                            {/* <div className="custom-single-select">
                                <label className="form-label" htmlFor="state">State</label>
                                <Select
                                id="state"
                                name="state"
                                placeholder="State"
                                className="mt-1"
                                options={states}
                                value={formik.values.state}
                                onChange={(value) => {
                                    formik.setFieldValue('state', value[0]);
                                    formik.setFieldValue('state_id', value[0].value);
                                }}
                                />
                                {
                                formik.touched.state && formik.errors.state ? (
                                    <div className="error-label">{formik.errors.state}</div>
                                ) : null
                                }
                            </div> */}

                            <div className="custom-single-select">
                                <Form.Label>State</Form.Label>
                                <Form.Select 
                                placeholder="Select State"
                                id="state_id"
                                isInvalid={formik.touched.state_id && formik.errors.state_id}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.state_id}
                                aria-label="Default select example">
                                    <option value="">Select State</option>
                                    {
                                        states.map(state=><>
                                        <option value={state.value}>{state.label}</option>
                                        </>)
                                    }
                                </Form.Select>
                                <Form.Control.Feedback type="invalid">
                                        {formik.errors.state_id}
                                </Form.Control.Feedback>
                            </div>

                            {/* <div className="custom-single-select">
                                <label className="form-label" htmlFor="cluster">Cluster</label>
                                <Select
                                id="cluster"
                                name="cluster"
                                placeholder="Cluster"
                                className="mt-1"
                                options={clusters}
                                value={formik.values.cluster}
                                onChange={(value) => {
                                    formik.setFieldValue('cluster', value[0]);
                                    formik.setFieldValue('cluster_id', value[0].value);
                                }}
                                />
                                {
                                formik.touched.cluster && formik.errors.cluster ? (
                                    <div className="error-label">{formik.errors.cluster}</div>
                                ) : null
                                }
                            </div> */}

                            <div className="custom-single-select">
                                <Form.Label>Cluster</Form.Label>
                                <Form.Select 
                                placeholder="Select Cluster"
                                id="cluster_id"
                                isInvalid={formik.touched.cluster_id && formik.errors.cluster_id}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.cluster_id}
                                aria-label="Default select example">
                                    <option value="">Select Cluster</option>
                                    {
                                        clusters.map(cluster=><>
                                        <option value={cluster.value}>{cluster.label}</option>
                                        </>)
                                    }
                                </Form.Select>
                                <Form.Control.Feedback type="invalid">
                                        {formik.errors.cluster_id}
                                </Form.Control.Feedback>
                            </div>

                            {/* <div className="custom-single-select">
                                <label className="form-label" htmlFor="cluster">check select</label>
                                <Select
                                id="admin"
                                name="admin"
                                placeholder="Select admin"
                                // isMulti={true}
                                // value={formik.values.admin}
                                options={[
                                  { value: "rohit-nanjan", label: "Rohit Nanjan" },
                                  { value: "rohit-n", label: "Rohit N" },
                                  { value: "rohit-narayanan", label: "Rohit Narayanan" },
                                ]}
                                />
                            </div> */}

                            <div>
                                <Form.Label>Property Location</Form.Label>
                                <Form.Control
                                type="url"
                                id="location"
                                name="location"
                                placeholder="Enter Location Link"
                                value={formik.values.location}
                                onChange={(e) => formik.setFieldValue('location', e.target.value)}
                                />
                                {
                                formik.touched.location && formik.errors.location ? (
                                    <div className="error-label">{formik.errors.location}</div>
                                ) : null
                                }
                            </div>
                        </div>

                        <h3 className="title mb-4">BILLING INFORMATION</h3>

                            <div className="TrailWrap">
                            <div>
                                <Form.Label>Invoice series code</Form.Label>
                                <Form.Control
                                type="text"
                                id="invoice_series"
                                name="invoice_series"
                                placeholder="XXXXX-XXXXX"
                                value={formik.values.invoice_series}
                                onChange={(e) => formik.setFieldValue('invoice_series', e.target.value)}
                                style={formik.touched.invoice_series && formik.errors.invoice_series ? {
                                    borderColor:'red'
                                }: null}
                                />
                                {
                                formik.touched.invoice_series && formik.errors.invoice_series ? (
                                    <div className="error-label">{formik.errors.invoice_series}</div>
                                ) : null
                                }
                            </div>
                            <div>
                                <Form.Label>GSTIN (State)</Form.Label>
                                <Form.Control
                                type="text"
                                id="property_gstin"
                                name="property_gstin"
                                placeholder="XXXXX-XXXXX-XXXXX"
                                value={formik.values.property_gstin}
                                onChange={(e) => formik.setFieldValue('property_gstin', e.target.value)}
                                />
                                {
                                formik.touched.property_gstin && formik.errors.property_gstin ? (
                                    <div className="error-label">{formik.errors.property_gstin}</div>
                                ) : null
                                }
                            </div>

                            </div>

                        <div className="modal-footer">
                        <button 
                            type="button" 
                            className="orange_brd_button p-12-16-button mr-3"
                            onClick={()=>handleCloseConfirmNocModal()}
                        >CANCEL</button>
                        <button type="submit" className="basic-button">SAVE</button>
                        </div>
                    </form>
                    </div>
                </div>
                </Modal.Body>
            </Modal>
           
        </>
    );

}

export default DetailsTab;