import React, { useMemo } from "react";
import { Modal } from "react-bootstrap";
import styles from "./index.module.scss";

export default function DocumentApprovalModal({ type, show, close, onAction }) {
  const info = useMemo(() => {
    const result = {
      heading: "",
      single: "",
      cta: {
        no: "",
        yes: "",
      },
    };
    if (type === "approved") {
      result.heading = "Approval";
      result.single = "Approve";
      result.cta = {
        no: "NO, CANCEL",
        yes: "YES, APPROVE",
      };
    } else if (type === "rejected") {
      result.heading = "Rejection";
      result.single = "Reject";
      result.cta = {
        no: "NO, CANCEL",
        yes: "YES, REJECT",
      };
    }
    return result;
  }, [type]);
  return (
    <Modal
      show={show}
      onHide={close}
      backdrop="static"
      dialogClassName={styles["dialog-container"]}
      keyboard={false}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id={styles["#modal-title"]}>
          Document {info.heading}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p className={"mb-0 " + styles["body-content"]}>
          Do you want to <span className="fw-600">{info.single}</span> this
          entry?
        </p>
      </Modal.Body>
      <Modal.Footer>
        <button
          className={"btn " + styles["btn-cancel"]}
          onClick={() => close()}
        >
          {info.cta.no}
        </button>
        <button
          className={"btn " + styles["btn-submit"]}
          onClick={() => onAction(type)}
        >
          {info.cta.yes}
        </button>
      </Modal.Footer>
    </Modal>
  );
}
