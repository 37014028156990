import React, { useEffect, useState } from "react";
import styles from './index.module.scss'
import ManageCurrentMenu from "../CurrentMenu";
import UpcomingMenu from "../UpcomingMenu";
import { getApiMeals } from "../../../../helpers/services/foodservices";

export default function ManageMenu() {

  const [activeSubMenu, setActiveSubMenu] = useState('Upcoming Menu');
  const [meals, setMeals] = useState([]);

  const getAllMeals = async () => {
    let items = [];
    try {
      const response = await getApiMeals();
      if (response.data) {
        items = response.data;
      }
    } catch (e) {
      console.error(e)
    }
    setMeals(items)
  }


  useEffect(() => {
    getAllMeals();
  }, [])

  return (
    <div className={styles['managemenu-container']}>

      <div className="d-flex align-items-center">

        <div className="d-flex align-items-center gap-12 me-auto">

          <div onClick={() => { setActiveSubMenu('Current Menu') }} className={styles['capsule'] + " " + ((activeSubMenu === 'Current Menu') ? styles['active'] : "")}>
            <span>Current Menu</span>
          </div>
          <div onClick={() => { setActiveSubMenu('Upcoming Menu') }} className={styles['capsule'] + " " + ((activeSubMenu === 'Upcoming Menu') ? styles['active'] : "")}>
            <span>Upcoming Menu</span>
          </div>

        </div>
        {/* {
          (activeSubMenu === "Current Menu") ? (<div className="ms-auto">
            <div className={styles['cta-wastage']}>
              <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M12.5 3.99609V19.9961M4.5 11.9961H20.5" stroke="#FF5700" strokeWidth="2" strokeLinecap="round" />
              </svg>
              <span>Mark Wastage</span>
            </div>
          </div>) : (<></>)
        } */}


      </div>

      {
        (activeSubMenu === "Current Menu") ? (<ManageCurrentMenu
          meals={meals}
        />) : (<></>)
      }

      {
        (activeSubMenu === "Upcoming Menu") ? (
          <UpcomingMenu
            meals={meals}
          />
        ) : (<></>)
      }

    </div>
  );
}
