import { Course, Meal, Poll, PollMenu } from "../interface"
import { Menu, MenuMeal } from "../interface/menu"

export const getConvertedMenu = (menu, mealsList = []) => {

    const properties = menu.property_details.map((_ipd) => {
        _ipd.label = _ipd.property_name;
        _ipd.value = _ipd.id;
        return _ipd;
    });

    const mealsInfoList = JSON.parse(JSON.stringify(mealsList)).map((meal) => {
        return new MenuMeal(meal.id, meal.name, []);
    })

    const menuItem = new Menu(1, menu.menu_name, properties, mealsInfoList);
    menu.menu_details.forEach((detailsInfo) => {
        const findMealIndex = menuItem.meals.findIndex((meal) => meal.id === detailsInfo.meal_session_detail.id);
        if (findMealIndex !== -1) {
            const date = detailsInfo.date;
            const selectedMeal = menuItem.meals[findMealIndex];
            const findMealCourseIndex = selectedMeal.courses.findIndex((course) => course.id === detailsInfo.food_item_detail.category_details.id);
            if (findMealCourseIndex !== -1) {
                const selectedCourse = menuItem.meals[findMealIndex].courses[findMealCourseIndex];
                if (selectedCourse.dateCourse[date]) {
                    menuItem.meals[findMealIndex].courses[findMealCourseIndex].dateCourse[date].push(detailsInfo.food_item_detail);
                } else {
                    menuItem.meals[findMealIndex].courses[findMealCourseIndex].dateCourse[date] = [detailsInfo.food_item_detail]
                }
            } else {
                menuItem.meals[findMealIndex].courses.push({
                    ...detailsInfo.food_item_detail.category_details,
                    dateCourse: {
                        [date]: [detailsInfo.food_item_detail]
                    }
                })
            }
        }
    })
    return menuItem;
}

const getConvertedPollMenuItem = (poll, mealsList) => {
    const properties = poll.property_details.map((_ipd) => {
        _ipd.label = _ipd.property_name;
        _ipd.value = _ipd.id;
        return _ipd;
    })

    const pollMenuItem = new PollMenu(poll.id, poll.menu_name, properties, JSON.parse(JSON.stringify(mealsList)));

    const info = poll.property_food_item_preferences.reduce((result, current) => {

        const mealId = current.meal_session.id;
        const courseId = current.food_item?.category_details?.id;
        const mealIndex = result.meals.findIndex((mealInfo) => {
            return mealInfo.id === mealId;
        })
        const food_item = current?.food_item || {}
        if (!result.mealSession[mealId]) {
            result.meals[mealIndex].courses = [];
            result.mealSession[mealId] = true
        }

        const courseIndex = result.meals[mealIndex].courses.findIndex((courseInfo) => {
            return courseInfo.id === courseId;
        })

        if (courseIndex === -1) {
            result.meals[mealIndex].courses.push(
                new Course(courseId, food_item?.category_details.name, [{ ...food_item, label: food_item.food_name, value: food_item.id }], "" + current.votes_per_student)
            );
        } else {
            result.meals[mealIndex].courses[courseIndex].courseItems.push({ ...food_item, label: food_item.food_name, value: food_item.id })
        }

        return result;
    }, {
        mealSession: {},
        meals: JSON.parse(JSON.stringify(pollMenuItem.meals))
    })

    pollMenuItem.meals = info.meals;
    return pollMenuItem
}

export const getConvertedPollMenu = ({ startDate, endDate, meals, polls }) => {
    const pollMenu = new Poll(startDate, endDate);
    const mealsList = meals.map((meal) => {
        const course = new Course();
        return new Meal(meal.id, meal.name, [course]);
    })
    pollMenu.polls = polls.map((poll) => getConvertedPollMenuItem(poll, mealsList))
    return pollMenu;
}