import React from "react";
import { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import {
  employee_roles_list,
  changeEmployeRole,
  listOfRoles,
  updateEmployedata,
  employee_property_list,
  fetchProperties,
  create_property_employee,
  update_property_employee,
} from "../../helpers/services/api.services";
import { Modal } from "react-bootstrap";
import { Form } from "react-bootstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import toast from "react-hot-toast";
import Select from "react-select";
import styles from "../revenue/revenueManualPage/modals/UploadAdjustmentModal/index.module.scss";

const activateBtn = {
  fontSize: "14px",
  fontWeight: 800,
  letterSpacing: "1.12px",
  textAlign: "center",
  background: "linear-gradient(89deg, #FF5700 4.28%, #FF9057 94.58%)",
  borderRadius: "20px",
  padding: "4px 15px",
  border: "none",
  color: "#fff",
};
const deactivateBtn = {
  fontSize: "14px",
  fontWeight: 500,
  letterSpacing: "1.12px",
  backgroundColor: "white",
  padding: "4px 15px",
  textAlign: "center",
  borderRadius: "20px",
  border: "0.2px solid #FF5700",
  color: "#FF5700",
};

const UpdateEmployee = ({
  showUpdateModal,
  setShowUpdateModal,
  employeeData,
  loadData,
}) => {
  const handleCloseEmployeecModal = () => {
    setShowUpdateModal(false);
  };

  const [showActiveModal, setShowActiveModal] = useState(false);
  const [selectedRole, setSelectedRole] = useState(null);
  const [allProperties, setAllProperties] = useState([]);
  const [jobTitle, setjobTitle] = useState([]);
  const [showAssignPropertyModal, setShowAssignPropertyModal] = useState(false);
  const [showpropertyStatusAModal, setshowpropertyStatusAModal] =
    useState(false);
  const [action, setAction] = useState(null);
  const [propertyAction, setpropertyAction] = useState(null);
  const [selectedPropertyId, setSelectedPropertyId] = useState(null);
  const [employeeProperties, setEmployeeProperties] = useState([]);
  const [employeeRoles, setEmployeeRoles] = useState([]);
  const [propertyemployeeID, setPropertyEmployeeID] = useState(null);

  const handleAssignProperty = (propertyId) => {
    if (propertyId !== undefined) {
      setSelectedPropertyId(propertyId);
      setShowAssignPropertyModal(true);
    }
  };

  const handleAddProperty = async () => {
    try {
      if (selectedPropertyId) {
        const data = {
          property_id: selectedPropertyId,
          employee_id: [employeeData?.id],
        };

        const response = await create_property_employee(data);

        fetch_employee_property_list();

        if (response.status == "success") {
          toast.success("Property Added");
          setShowAssignPropertyModal(false);
          setSelectedPropertyId(null);
        } else {
          toast.error("Error on adding property");
          setShowAssignPropertyModal(false);
        }
      }
    } catch (error) {
      console.error("Error adding property:", error);
    }
  };

  const handlePropertystatusChange = async () => {
    try {
      if (propertyemployeeID) {
        const data = {
          id: propertyemployeeID,
          status: propertyAction == "active" ? true : false,
        };

        const response = await update_property_employee(data);

        if (response.status == "success") {
          toast.success("Status updated");
          setshowpropertyStatusAModal(false);
          fetch_employee_property_list();
        } else {
          toast.error("Error on updating status");
        }
      }
    } catch (error) {
      console.error("Error adding property:", error);
    }
  };

  const handleRoleChange = (selectedOptions) => {
    const selectedRoles = selectedOptions.map((option) => option.value);
    const removedRoles = employeeRoles.filter(
      (role) => !selectedRoles.includes(role)
    );
    if (removedRoles.length > 0) {
      setAction("remove");
      setSelectedRole(removedRoles[0]);
    } else {
      setAction("add");
      setSelectedRole(
        selectedOptions.length > 0
          ? selectedOptions[selectedOptions.length - 1].value
          : null
      );
    }
    setShowActiveModal(true);
  };

  const initialValues = {
    email: employeeData?.email || "",
    employee_first_name: employeeData?.employee_first_name || "",
    employee_last_name: employeeData?.employee_last_name || "",
    mobile_number: employeeData?.mobile_number || "",
    salary: 0,
    department: employeeData?.department || "",
    employee_code: employeeData?.employee_code || "",
  };

  const validationSchema = Yup.object().shape({
    email: Yup.string().email("Invalid email").required("Email is required"),
    employee_first_name: Yup.string().required("First Name is required"),
    employee_last_name: Yup.string(),
    mobile_number: Yup.string().required("Mobile Number is required"),
    salary: Yup.number().required("Salary is required"),
    department: Yup.string(),
    employee_code: Yup.string().required("employee_code is required"),
  });

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values) => {
      try {
        const response = await updateEmployedata(employeeData?.id, values);
        if (response.status == "success") {
          toast.success("Employee Updated");
          handleCloseEmployeecModal();
          loadData();
        } else {
          toast.error(JSON.stringify(response.data?.errors || `Something went wrong please try again`));
        }
      } catch (error) {
        console.log(error);
      }
    },
  });

  const handleRoleChangeApi = async () => {
    try {
      const data = {
        id: employeeData?.id,
        action_type: action,
        role: selectedRole,
      };

      const response = await changeEmployeRole(data);

      if (response.status == "success") {
        toast.success(`Role changed`);
        fetch_employee_roles_list();
      } else {
        toast.error(JSON.stringify(response.data));
      }
    } catch (error) {}
  };

  const fetch_employee_roles_list = async () => {
    try {
      const response = await employee_roles_list(employeeData?.id);
      setEmployeeRoles(response.data);
    } catch (error) {
      console.error("Error fetching employee roles:", error);
    }
  };

  const fetch_employee_property_list = async () => {
    try {
      const response = await employee_property_list(employeeData?.id);
      setEmployeeProperties(response.data);
    } catch (error) {
      console.error("Error fetching employee roles:", error);
    }
  };

  const fetch_roles_list = async () => {
    try {
      const response = await listOfRoles();
      if (response.data) {
        const roles = response.data;
        const convertedRoles = roles.map((role) => ({
          label: role,
          value: role,
        }));
        setjobTitle((prevJobTitles) => [...prevJobTitles, ...convertedRoles]);
      }
    } catch (error) {
      console.error("Error fetching employee roles:", error);
    }
  };

  const fetchAllProperties = async () => {
    try {
      const response = await fetchProperties("", "", "", 1000);
      if (response.data) {
        setAllProperties(response.data);
      }
    } catch (error) {
      console.error("Error fetching employee roles:", error);
    }
  };

  useEffect(() => {
    fetchAllProperties();
    fetch_employee_roles_list();
    fetch_employee_property_list();
    fetch_roles_list();
  }, []);

  return (
    <div>
      <Modal
        show={showUpdateModal}
        onHide={handleCloseEmployeecModal}
        centered
        size="lg"
        className="confirm_noc_modal"
      >
        <Modal.Body>
          <div className="head_part_date_modal create-prop_modal">
            <div className="d-flex justify-content-between align-items-center mb-4">
              <div>
                <h2 className="title">Update Employee</h2>
              </div>
              <div style={{ cursor: "pointer" }}>
                <span onClick={handleCloseEmployeecModal} className="">
                  <img src="/images/cross_modal.svg" alt="Close Icon" />
                </span>
              </div>
            </div>
          </div>
          <div className="modal_data_part">
            <div className="create-prop_detail">
              <form onSubmit={formik.handleSubmit}>
                <div className="TrailWrap mb-4">
                  <div>
                    <div>
                      <Form.Label>Email</Form.Label>
                      <Form.Control
                        placeholder="Enter Email"
                        id="email"
                        name="email"
                        value={formik.values.email}
                        onChange={(e) =>
                          formik.setFieldValue("email", e.target.value)
                        }
                        style={
                          formik.touched.email && formik.errors.email
                            ? {
                                borderColor: "red",
                              }
                            : null
                        }
                      />
                      {formik.touched.email && formik.errors.email ? (
                        <div className="error-label">{formik.errors.email}</div>
                      ) : null}
                    </div>
                  </div>

                  <div>
                    <div>
                      <Form.Label>First Name</Form.Label>
                      <Form.Control
                        placeholder="Enter First Name"
                        id="employee_first_name"
                        name="employee_first_name"
                        value={formik.values.employee_first_name}
                        onChange={(e) =>
                          formik.setFieldValue(
                            "employee_first_name",
                            e.target.value
                          )
                        }
                        style={
                          formik.touched.employee_first_name &&
                          formik.errors.employee_first_name
                            ? {
                                borderColor: "red",
                              }
                            : null
                        }
                      />
                      {formik.touched.employee_first_name &&
                      formik.errors.employee_first_name ? (
                        <div className="error-label">
                          {formik.errors.employee_first_name}
                        </div>
                      ) : null}
                    </div>
                  </div>

                  <div>
                    <div>
                      <Form.Label>Last Name</Form.Label>
                      <Form.Control
                        placeholder="Enter Last Name"
                        id="employee_last_name"
                        name="employee_last_name"
                        value={formik.values.employee_last_name}
                        onChange={(e) =>
                          formik.setFieldValue(
                            "employee_last_name",
                            e.target.value
                          )
                        }
                        style={
                          formik.touched.employee_last_name &&
                          formik.errors.employee_last_name
                            ? {
                                borderColor: "red",
                              }
                            : null
                        }
                      />
                      {formik.touched.employee_last_name &&
                      formik.errors.employee_last_name ? (
                        <div className="error-label">
                          {formik.errors.employee_last_name}
                        </div>
                      ) : null}
                    </div>
                  </div>

                  <div>
                    <div>
                      <Form.Label>Mobile Number</Form.Label>
                      <Form.Control
                        placeholder="Enter Mobile Number"
                        id="mobile_number"
                        name="mobile_number"
                        value={formik.values.mobile_number}
                        onChange={(e) =>
                          formik.setFieldValue("mobile_number", e.target.value)
                        }
                        style={
                          formik.touched.mobile_number &&
                          formik.errors.mobile_number
                            ? {
                                borderColor: "red",
                              }
                            : null
                        }
                      />
                      {formik.touched.mobile_number &&
                      formik.errors.mobile_number ? (
                        <div className="error-label">
                          {formik.errors.mobile_number}
                        </div>
                      ) : null}
                    </div>
                  </div>
                  <div>
                    <div>
                      <Form.Label>Department</Form.Label>
                      <Form.Control
                        placeholder="Enter Department"
                        id="department"
                        name="department"
                        value={formik.values.department}
                        onChange={(e) =>
                          formik.setFieldValue("department", e.target.value)
                        }
                        style={
                          formik.touched.department && formik.errors.department
                            ? {
                                borderColor: "red",
                              }
                            : null
                        }
                      />
                      {formik.touched.department && formik.errors.department ? (
                        <div className="error-label">
                          {formik.errors.department}
                        </div>
                      ) : null}
                    </div>
                  </div>

                  <div>
                    <div>
                      <Form.Label>Employee Code</Form.Label>
                      <Form.Control
                        placeholder="Enter employee code"
                        id="employee_code"
                        name="employee_code"
                        value={formik.values.employee_code}
                        onChange={(e) =>
                          formik.setFieldValue("employee_code", e.target.value)
                        }
                        style={
                          formik.touched.employee_code &&
                          formik.errors.employee_code
                            ? {
                                borderColor: "red",
                              }
                            : null
                        }
                      />
                      {formik.touched.employee_code &&
                      formik.errors.employee_code ? (
                        <div className="error-label">
                          {formik.errors.employee_code}
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>

                <div>
                  <Form.Label>Assigned Property</Form.Label>
                  <Select
                    options={allProperties?.filter(
                        (property) =>
                          !employeeProperties.some(
                            (empProp) => empProp.property.id === property.id
                          )
                      )
                      .map((property) => ({
                        label: property.property_name,
                        value: property.id,
                      }))}
                    onChange={(selectedOption) =>
                      handleAssignProperty(selectedOption?.value)
                    }
                    placeholder="Select a property"
                    isClearable={true}
                    isSearchable={true}
                  />

                  <div className="mx-3 my-2">
                    {employeeProperties.map((property) => (
                      <div key={property.id}>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <div>
                            <span>
                              <b>
                                <small>{property.property.property_name}</small>
                              </b>
                            </span>
                          </div>
                          <div>
                            <button
                              className="mx-3 my-2"
                              onClick={() => {
                                setshowpropertyStatusAModal(true);
                                setpropertyAction("deactive");
                                setPropertyEmployeeID(property?.id);
                              }}
                              disabled={!property.is_active}
                              type="button"
                              style={
                                !property.is_active
                                  ? activateBtn
                                  : deactivateBtn
                              }
                            >
                              Deactive
                            </button>
                            <button
                              className={""}
                              type="button"
                              disabled={property.is_active}
                              onClick={() => {
                                setshowpropertyStatusAModal(true);
                                setpropertyAction("active");
                                setPropertyEmployeeID(property?.id);
                              }}
                              style={
                                property.is_active ? activateBtn : deactivateBtn
                              }
                            >
                              Active
                            </button>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>

                <div>
                  <Form.Label>Assigned Role</Form.Label>
                  <Select
                    options={jobTitle}
                    isMulti
                    value={employeeRoles.map((selectedJob) =>
                      jobTitle.find((job) => job.value === selectedJob)
                    )}
                    onChange={(selectedOptions) => {
                      handleRoleChange(selectedOptions);
                    }}
                  />
                </div>

                <div className="modal-footer">
                  <button
                    type="button"
                    className="orange_brd_button p-12-16-button mr-3"
                    onClick={() => handleCloseEmployeecModal()}
                  >
                    CANCEL
                  </button>
                  <button type="submit" className="basic-button">
                    Save
                  </button>
                </div>
              </form>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        show={showActiveModal}
        onHide={() => {
          setShowActiveModal(false);
          setSelectedRole(null);
        }}
        backdrop="static"
        dialogClassName={styles["dialog-container"]}
        keyboard={false}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id={styles["#modal-title"]}>CHANGE STATUS</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className={styles["body-content"]}>
            <div>
              <p className={"mb-0 " + styles["label-title"]}>
                Are you sure you want to {action} role?
              </p>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            className={"btn " + styles["btn-cancel"]}
            onClick={() => {
              setShowActiveModal(false);
              setSelectedRole(null);
            }}
          >
            CANCEL
          </button>
          <button
            className={"btn " + styles["btn-submit"]}
            onClick={() => {
              handleRoleChangeApi();
              setShowActiveModal(false);
              setSelectedRole(null);
            }}
          >
            {action == "add" ? "Add Role" : "Remove Role"}
          </button>
        </Modal.Footer>
      </Modal>
      <Modal
        show={showAssignPropertyModal}
        onHide={() => setShowAssignPropertyModal(false)}
        backdrop="static"
        dialogClassName={styles["dialog-container"]}
        keyboard={false}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id={styles["#modal-title"]}>ADD PROPERTY</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className={styles["body-content"]}>
            <div>
              <p className={"mb-0 " + styles["label-title"]}>
                Are you sure you want to assign new property ?
              </p>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            className={"btn " + styles["btn-cancel"]}
            onClick={() => setShowAssignPropertyModal(false)}
          >
            CANCEL
          </button>
          <button
            className={"btn " + styles["btn-submit"]}
            onClick={handleAddProperty}
          >
            Add Property
          </button>
        </Modal.Footer>
      </Modal>
      <Modal
        show={showpropertyStatusAModal}
        onHide={() => setshowpropertyStatusAModal(false)}
        backdrop="static"
        dialogClassName={styles["dialog-container"]}
        keyboard={false}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id={styles["#modal-title"]}>
            CHANGE PROPERTY STATUS
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className={styles["body-content"]}>
            <div>
              <p className={"mb-0 " + styles["label-title"]}>
                Are you sure you want to{" "}
                {propertyAction == "active" ? "Activate" : "Deactivate"} ?
              </p>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            className={"btn " + styles["btn-cancel"]}
            onClick={() => setshowpropertyStatusAModal(false)}
          >
            CANCEL
          </button>
          <button
            className={"btn " + styles["btn-submit"]}
            onClick={handlePropertystatusChange}
          >
            {propertyAction == "active" ? "Activate" : "Deactivate"}
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default UpdateEmployee;
