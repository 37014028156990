import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import toast from "react-hot-toast";
import {
  uploadImage,
  updateWebsiteTestimonials,
} from "../../../../helpers/services/websiteServices";
const ModalTestimonial = ({
  testimonialConfigData,
  testimonialMedialData,
  handler,
  additional_data,
  openModal,
  testimonialIndex,
  actionType,
  mediaModal,
  mediaModalHandler,
  deleteModalHandler,
  deleteModal,
}) => {
  const [data, setData] = useState({
    name: "",
    image: "",
    city: "",
    review: "",
  });

  const [selectedImage, setSelectedImage] = useState(null);
  const [file, setFile] = useState(null);
  const [media_name, setMedia_Name] = useState("");
  const [showInput, setShowInput] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredMediaData, setFilteredMediaData] = useState([]);
  const [imageChange, setImageChange] = useState(false);

  const uploadFile = async () => {
    try {
      const imageData = new FormData();
      imageData.append("media_image", file);
      imageData.append("media_name", media_name);
      imageData.append("media_type", additional_data.name);
      imageData.append("media_display_order", "1");
      imageData.append("media_reference_type", "GlobalConfig");
      imageData.append("media_reference_id", additional_data.id);
      imageData.append("is_active", "true");
      imageData.append("media_tag", "image");
      const response = await uploadImage(imageData);
      if (response?.status && response?.status == 422) {
        return toast.error(response.data.error);
      }
      setData((prevData) => ({
        ...prevData,
        image: response.data.media_url,
      }));
      console.log(response);
      return response;
    } catch (error) {
      console.log("errorrr", error);
    }
  };

  const handleSubmit = async () => {
    try {
      if (data.image == "" || !data.image) {
        return toast.error("No image selected");
      }
      if (actionType == "update") {
        testimonialConfigData[testimonialIndex] = data;
      } else if (actionType == "add") {
        testimonialConfigData.push(data);
      }
      const bodyData = {
        config_details: testimonialConfigData,
      };
      const response = await updateWebsiteTestimonials(bodyData);
      if (response.status == "success") {
        toast.success(
          `${
            actionType == "update"
              ? `Testimonal #${testimonialIndex + 1} updated`
              : "New testimonal added"
          }`
        );
        setImageChange(false);
        handler(false);
        mediaModalHandler(false);
      } else {
        toast.error("Error while updation");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchData = () => {
    if (actionType == "update") {
      const filterData = testimonialConfigData.filter(
        (data, ind) => ind === testimonialIndex
      );
      setData(filterData[0]);
    } else {
      setData({
        name: "",
        image: "",
        city: "",
        review: "",
      });
    }
  };
  useEffect(() => {
    if (actionType == "add") {
      clearData();
    }
  }, [actionType]);

  useEffect(() => {
    if (actionType == "update") {
      fetchData();
    }
  }, [testimonialConfigData, testimonialIndex]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const clearData = () => {
    setData({
      name: "",
      image: "",
      city: "",
      review: "",
    });
    setSelectedImage(null);
  };

  useEffect(() => {
    if (actionType === "add") {
      clearData();
    } else if (actionType === "update") {
      const filterData = testimonialConfigData.filter(
        (data, ind) => ind === testimonialIndex
      );
      setData(filterData[0]);
    }
  }, [actionType, testimonialConfigData, testimonialIndex]);

  const handleFileChange = (e) => {
    setData((prevData) => ({
      ...prevData,
      image: null,
    }));

    const file = e.target.files[0];
    if (!file) return;
    setFile(file);
    const reader = new FileReader();
    reader.onload = () => {
      const imageUrl = reader.result;
      setSelectedImage(imageUrl);
    };
    reader.readAsDataURL(file);
  };

  const filterMediaData = () => {
    const filteredData = testimonialMedialData.filter((data) =>
      data.media_name.toLowerCase().includes(searchQuery.toLowerCase())
    );
    setFilteredMediaData(filteredData);
  };

  useEffect(() => {
    filterMediaData();
  }, [searchQuery, testimonialMedialData]);

  const handleUpdateImage = () => {
    if (actionType == "add") {
      mediaModalHandler(false);
    } else {
      handleSubmit();
    }
  };

  const handleDelete = async () => {
    try {
      const filterData = testimonialConfigData.filter(
        (data, index) => index !== testimonialIndex
      );

      const bodyData = {
        config_details: filterData,
      };
      const response = await updateWebsiteTestimonials(bodyData);
      if (response.status == "success") {
        toast.success("Testimonial Deleted");
        deleteModalHandler(false);
      } else {
        toast.error("Error while updation");
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div>
      <Modal
        show={openModal}
        onHide={() => {
          handler(false);
          clearData();
        }}
        centered
        size="lg"
        className="common_modal"
      >
        <Modal.Header>
          <Modal.Title className="underline">
            {actionType === "add"
              ? "Add New Testimonial"
              : `Testimonial #${testimonialIndex + 1}`}
          </Modal.Title>
          <img
            src="/images/cross_modal.svg"
            className="cursor-pointer"
            alt="Close Icon"
            onClick={() => {
              handler();
              clearData();
            }}
          />
        </Modal.Header>
        <Modal.Body>
          <div className="form_control_box">
            <label htmlFor="name" className="form-label">
              <b>Name</b>
            </label>
            <input
              type="text"
              id="name"
              className="form-control mt-1"
              name="name"
              onChange={handleChange}
              value={data?.name}
            />
          </div>
          <div className="form_control_box">
            <label htmlFor="city" className="form-label">
              <b>City</b>
            </label>
            <input
              type="text"
              id="city"
              className="form-control mt-1"
              name="city"
              onChange={handleChange}
              value={data?.city}
            />
          </div>

          {actionType == "add" && (
            <>
              <div>
                <img
                  src={data?.image}
                  alt=""
                  style={{
                    height: "70px",
                    width: "70px",
                    borderRadius: "50%",
                  }}
                />
              </div>
              <div
                className="form_control_box underline"
                onClick={() => mediaModalHandler(true)}
              >
                Select Image
              </div>
            </>
          )}
          <div className="form_control_box">
            <label htmlFor="review" className="form-label">
              <b>Review</b>
            </label>
            <textarea
              id="review"
              className="form-control mt-1"
              name="review"
              rows={4}
              onChange={handleChange}
              value={data?.review}
            />
          </div>
        </Modal.Body>

        <Modal.Footer>
          <button
            type="submit"
            className={"basic-button"}
            onClick={handleSubmit}
          >
            {actionType === "add" ? "ADD NEW TESTIMONIAL" : "UPDATE"}
          </button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={mediaModal}
        onHide={() => {
          setShowInput(false);
          mediaModalHandler(false);
        }}
        centered
        size="lg"
        className="common_modal"
      >
        <Modal.Header>
          <b>
            Choose Image {actionType == "update" && `#${testimonialIndex + 1}`}
          </b>
          <img
            src="/images/cross_modal.svg"
            className="cursor-pointer"
            alt="Close Icon"
            onClick={() => {
              mediaModalHandler(false);
            }}
          />
        </Modal.Header>

        <Modal.Body>
          <div className="d-flex justify-content-between">
            <div>
              {data?.image && data?.image != "" && (
                <>
                  <div className="">
                    <img
                      src={data?.image}
                      alt=""
                      style={{
                        height: "70px",
                        width: "70px",
                        borderRadius: "50%",
                      }}
                    />
                  </div>
                </>
              )}
            </div>
            <div>
              <button
                className="basic-button"
                onClick={() => setShowInput(!showInput)}
              >
                New Image
              </button>
            </div>
          </div>

          {showInput && (
            <>
              <div className="form_control_box mt-3">
                <input
                  type="file"
                  id="image"
                  className="form-control mt-1"
                  name="image"
                  onChange={handleFileChange}
                />
              </div>

              <div className="form_control_box mt-3">
                <input
                  type="text"
                  id="city"
                  className="form-control my-2"
                  name="city"
                  placeholder="Enter Media Name"
                  onChange={(e) => setMedia_Name(e.target.value)}
                  value={media_name}
                />
              </div>
              {selectedImage && (
                <div className="form_control_box mt-3">
                  <img
                    src={selectedImage}
                    style={{
                      height: "300px",
                    }}
                    alt="Selected"
                    className="img-fluid"
                  />
                </div>
              )}
            </>
          )}

          <div className="form_control_box mt-3">
            <input
              type="text"
              id="search"
              className="form-control my-2"
              name="search"
              placeholder="Search "
              onChange={(e) => setSearchQuery(e.target.value)}
              value={searchQuery}
            />
          </div>

          {filteredMediaData?.length > 0 ? (
            <>
              <div className="row mb-2 mx-2">
                {filteredMediaData?.map((data, index) => (
                  <div className="col-3 mt-3 text-center" key={index}>
                    <img
                      src={data?.media_url}
                      alt=""
                      style={{
                        height: "70px",
                        width: "70px",
                        borderRadius: "50%",
                      }}
                      onClick={() => {
                        setData((prevData) => ({
                          ...prevData,
                          image: data?.media_url,
                        }));
                        setImageChange(true);
                      }}
                    />
                    <div className="text-sm">
                      <b> {data?.media_name} </b>
                    </div>
                  </div>
                ))}
              </div>
            </>
          ) : (
            <>No Image Found</>
          )}
        </Modal.Body>
        <Modal.Footer>
          {showInput && selectedImage && (
            <>
              <button className="basic-button" onClick={uploadFile}>
                Upload Image
              </button>
            </>
          )}
          {imageChange && (
            <>
              <button className="basic-button" onClick={handleUpdateImage}>
                Update Image
              </button>
            </>
          )}
        </Modal.Footer>
      </Modal>

      <Modal
        show={deleteModal}
        onHide={() => {
          deleteModalHandler(false);
        }}
        centered
        size="md"
        className="common_modal"
      >
        <Modal.Header>
          <b>Delete Testimonial #{testimonialIndex + 1}</b>
          <img
            src="/images/cross_modal.svg"
            className="cursor-pointer"
            alt="Close Icon"
            onClick={() => {
              deleteModalHandler(false);
            }}
          />
        </Modal.Header>
        <Modal.Body>Are you sure want to delete the testimonal ?</Modal.Body>
        <Modal.Footer>
          <button className="basic-button" onClick={handleDelete}>
            Delete Testimonial
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default ModalTestimonial;
