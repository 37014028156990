import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { updateWebsiteWhySpace } from "../../../../helpers/services/websiteServices";
import toast from "react-hot-toast";
import MediaStorageModal from "./MediaStorageModal";

const ModalWhySpace = ({
  carouselConfigData,
  carouselMediaStorage,
  handler,
  openModal,
  carousalIndex,
  actionType,
  additional_data,
  deleteModal,
  deleteModalHandler,
}) => {
  const [data, setData] = useState({
    image_tag: "",
    description: "",
    image: "",
    order: "",
  });

  const [selectedImage, setSelectedImage] = useState(null);
  const [openMediaModal, setMediaModal] = useState(false);

  const handleSubmit = async () => {
    try {
      const orderExists = carouselConfigData.filter(
        (item, index) => item.order == data.order
      );
      if (
        orderExists.length > 0 &&
        carouselConfigData[carousalIndex].order != data.order
      ) {
        return toast.error("Order is already assigned to another item.");
      }

      if (actionType == "update") {
        carouselConfigData[carousalIndex] = data;
      } else if (actionType == "add") {
        carouselConfigData.push(data);
      }
      const bodyData = {
        config_details: carouselConfigData,
      };
      const response = await updateWebsiteWhySpace(bodyData);
      if (response.status == "success") {
        toast.success(
          `${
            actionType == "update"
              ? `Data # ${carousalIndex + 1} updated`
              : "New Data added"
          }`
        );
        handler();
        setData({
          image_tag: "",
          description: "",
          image: "",
          order: "",
        });
      } else {
        toast.error("Error while updation");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const clearData = () => {
    setData({
      image_tag: "",
      description: "",
      image: "",
      order: "",
    });
    setSelectedImage(null);
  };

  useEffect(() => {
    if (actionType == "add") {
      clearData();
    }
  }, [actionType]);

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (!file) return;

    const reader = new FileReader();
    reader.onload = () => {
      const imageUrl = reader.result;
      setSelectedImage(imageUrl);
    };
    reader.readAsDataURL(file);
  };

  const carousalData = async () => {
    if (actionType == "update") {
      const filterData = carouselConfigData.filter(
        (data, ind) => ind === carousalIndex
      );
      setData(filterData[0]);
    } else {
      setData({
        image_tag: "",
        description: "",
        image: "",
        order: "",
      });
    }
  };

  useEffect(() => {
    carousalData();
  }, [carouselConfigData, carousalIndex, openModal]);

  const handleDelete = async () => {
    try {
      const filterData = carouselConfigData.filter(
        (data, index) => index !== carousalIndex
      );

      const bodyData = {
        config_details: filterData,
      };
      const response = await updateWebsiteWhySpace(bodyData);
      if (response.status == "success") {
        toast.success("Data Deleted");
        deleteModalHandler(false);
      } else {
        toast.error("Error while updation");
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div>
      <Modal
        show={openModal}
        onHide={() => {
          handler();
          clearData();
        }}
        centered
        size="lg"
        className="commen_modal"
      >
        <Modal.Header>
          <Modal.Title className="underline">
            {actionType == "add"
              ? "Add New Data"
              : `Data #${Number(carousalIndex) + 1}`}
          </Modal.Title>
          <img
            src="/images/cross_modal.svg"
            className="cursor-pointor rounded"
            alt="Close Icon"
            onClick={handler}
          />
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-6">
              {data.image == "" ? (
                <div
                  className="bg-light h-100 w-100 row container"
                  style={{
                    cursor: "pointer",
                  }}
                  onClick={() => setMediaModal(true)}
                >
                  <div className="col d-flex justify-content-center align-items-center">
                    Select Image
                  </div>
                </div>
              ) : (
                <>
                  {" "}
                  <img
                    src={data?.image}
                    alt=""
                    onClick={() => setMediaModal(true)}
                  />
                </>
              )}
            </div>
            <div className="col-6">
              <div className="form_control_box">
                <label htmlFor="image_tag" className="form-label">
                  <b>Image Tag</b>
                </label>
                <input
                  type="text"
                  id="image_tag"
                  className="form-control mt-1"
                  name="image_tag"
                  onChange={handleChange}
                  value={data?.image_tag}
                />
              </div>
              <div className="form_control_box">
                <label htmlFor="description" className="form-label">
                  <b>Description</b>
                </label>
                <textarea
                  type="text"
                  id="description"
                  className="form-control mt-1"
                  name="description"
                  onChange={handleChange}
                  value={data?.description}
                />
              </div>
              <div className="form_control_box">
                <label htmlFor="order" className="form-label">
                  <b>Order</b>
                </label>
                <input
                  type="number"
                  id="order"
                  className="form-control mt-1"
                  name="order"
                  onChange={handleChange}
                  value={Number(data?.order)}
                />
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            type="submit"
            className={"basic-button"}
            onClick={handleSubmit}
          >
            {actionType == "add" ? "ADD NEW Carousel" : "UPDATE"}
          </button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={deleteModal}
        onHide={() => {
          deleteModalHandler(false);
        }}
        centered
        size="md"
        className="common_modal"
      >
        <Modal.Header>
          <b>Delete Data #{carousalIndex + 1}</b>
          <img
            src="/images/cross_modal.svg"
            className="cursor-pointer"
            alt="Close Icon"
            onClick={() => {
              deleteModalHandler(false);
            }}
          />
        </Modal.Header>
        <Modal.Body>Are you sure want to delete the carousel ?</Modal.Body>
        <Modal.Footer>
          <button className="basic-button" onClick={handleDelete}>
            Delete Carousel
          </button>
        </Modal.Footer>
      </Modal>

      <MediaStorageModal
        data={data}
        setData={setData}
        mediaData={carouselMediaStorage}
        openModal={openMediaModal}
        modalHandler={setMediaModal}
        additional_data={additional_data}
      />
    </div>
  );
};

export default ModalWhySpace;
