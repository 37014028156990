import { useEffect,useState,useRef, useContext } from "react";
import { fetchPropertyOwnerDetails,updatePropertyOwner,createPropertyOwner } from "../../../helpers/services/api.services"

import { Modal } from "react-bootstrap";
import { Form, InputGroup } from "react-bootstrap";
import Select from "react-dropdown-select";
import { useFormik } from 'formik';
import * as Yup from 'yup';
import toast from "react-hot-toast";
import { updateProperty } from '../../../helpers/services/api.services'
import ReactDatePicker from 'react-datepicker';
import moment from 'moment';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';
import GlobalStateContext from "../../../GlobalStateContext";

function AddIcon(props) {
  return (
    <SvgIcon {...props}>
      <g id="plus-circle" clip-path="url(#clip0_1621_20790)">
      <path id="Icon" d="M10.0003 6.66602V13.3327M6.66699 9.99935H13.3337M18.3337 9.99935C18.3337 14.6017 14.6027 18.3327 10.0003 18.3327C5.39795 18.3327 1.66699 14.6017 1.66699 9.99935C1.66699 5.39698 5.39795 1.66602 10.0003 1.66602C14.6027 1.66602 18.3337 5.39698 18.3337 9.99935Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
      </g>
      {/* <defs>
      <clipPath id="clip0_1621_20790">
      <rect width="20" height="20" fill="white"/>
      </clipPath>
      </defs> */}
    </SvgIcon>
  );
}

function OwnerKyc({propDetail,documentDetails}) {

  const {roles} = useContext(GlobalStateContext);
  const [ownerDetails, setOwnerDetails] = useState({})
  const [fileName, setFileName] = useState("");
  const [showUpdatePropertyModal, setShowUpdatePropertyModal] = useState(false);
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const handleCloseUpdatePropertyModal = () => setShowUpdatePropertyModal(false);
  const handleUpdatePropertyModal = () => setShowUpdatePropertyModal(true);

  const [b_w_owner_Url,setB_w_owner_Url] = useState("")
  const [tracking_Url,setTracking_Url] = useState("")
  const [cancelled_cheque,setCancelled_cheque] = useState("")
  const [ca_number_Url,setCa_number_Url] = useState("")
  const [connect_number_Url,setConnect_number_Url] = useState("")

  const fileInputRef = useRef();
  const trackingFileRef = useRef();
  const cancelledChequeRef = useRef();
  const caNumberRef = useRef();
  const connectNumberRef = useRef();

  useEffect(()=>{
    loadOwnerAndKycDetails();
  },[propDetail.id])

  useEffect(()=>{ 
    // console.log("data.document_type -- ",documentDetails)
    let b_w_file = documentDetails?.filter(data=>data.document_type == "bw_owner")?.[0]?.document_url
    if(b_w_file){
      setB_w_owner_Url(b_w_file)
      formik.setFieldValue("b_w_file.file.name",b_w_file?.split("/")[b_w_file.split("/").length-1])
    }
    let tracking_file = documentDetails?.filter(data=>data.document_type == "tracking")?.[0]?.document_url
    if(tracking_file){
      setTracking_Url(tracking_file)
      formik.setFieldValue("tracking_file.file.name",tracking_file?.split("/")[tracking_file.split("/").length-1])
    }
    let cancelled_cheque_file = documentDetails?.filter(data=>data.document_type == "cancelled_check")?.[0]?.document_url
    if(cancelled_cheque_file){
      setCancelled_cheque(cancelled_cheque_file)
      formik.setFieldValue("cancelled_cheque_file.file.name",cancelled_cheque_file?.split("/")[cancelled_cheque_file.split("/").length-1])
    }
    let ca_number_file = documentDetails?.filter(data=>data.document_type == "ca_number")?.[0]?.document_url
    if(ca_number_file){
      setCa_number_Url(ca_number_file)
      formik.setFieldValue("ca_number_file.file.name",ca_number_file?.split("/")[ca_number_file.split("/").length-1])
    }
    let connect_number_file = documentDetails?.filter(data=>data.document_type == "connection_number")?.[0]?.document_url
    if(connect_number_file){
      setConnect_number_Url(connect_number_file)
      formik.setFieldValue("connect_number_file.file.name",connect_number_file?.split("/")[connect_number_file.split("/").length-1])
    }
    // setB_w_owner_Url(documentDetails?.filter(data=>data.document_type == "bw_owner")[0]?.document_url)
    // setTracking_Url(documentDetails?.filter(data=>data.document_type == "tracking")[0]?.document_url)
    // setCancelled_cheque(documentDetails?.filter(data=>data.document_type == "cancelled_check")[0]?.document_url)
    // setCa_number_Url(documentDetails?.filter(data=>data.document_type == "ca_number")[0]?.document_url)
    // setConnect_number_Url(documentDetails?.filter(data=>data.document_type == "connection_number")[0]?.document_url)
  },[documentDetails])

  const loadOwnerAndKycDetails = async () =>{
    try {
      const response = await fetchPropertyOwnerDetails(propDetail.id)
      // console.log("loadOwnerAndKycDetails response -- ",response.data[0])
      if(response.data.length>0){
        let data = response.data[0]
        // console.log("owner  data -- ",data)
        setOwnerDetails(data)
        // console.log("OwnerDetails -- ",data)
        formik.setFieldValue('id', data.id)
        formik.setFieldValue('ownerName', data?.owner_name)
        formik.setFieldValue('rent', data?.rent_amount)
        // formik.setFieldValue('id', "")
        // formik.setFieldValue('id',)
        formik.setFieldValue('rentShare', data?.rent_increment_type_value)

        formik.setFieldValue('lockIn', data?.lockin_period)
        // formik.setFieldValue('aggrement_duration', data?.agreement_duration)
        formik.setFieldValue('startDate', data?.agreement_start_date ? new Date(data.agreement_start_date) : "")
        formik.setFieldValue('endDate', data?.agreement_end_date ? new Date(data?.agreement_end_date) : "")
        // formik.setFieldValue('rentShare', data?.rent_increment_type_value)
        // formik.setFieldValue('tenure', data?.rent_increment_type_value)
        formik.setFieldValue('rentStartDate', data?.rent_start_date ? new Date(data.rent_start_date) : "")
        formik.setFieldValue('rentFreePeriod', data?.rent_free_period ? new Date(data.rent_free_period) : "")

        formik.setFieldValue('bankAccount', data?.meta?.account_number)
        formik.setFieldValue('ifsc', data?.meta?.ifsc_code)
        formik.setFieldValue('bankName', data?.meta?.bank_name)
        formik.setFieldValue('branch', data?.meta?.branch)
        formik.setFieldValue('name', data?.meta?.account_name)

        formik.setFieldValue('electricity', data?.electricity_provider ? data.electricity_provider : "")
        formik.setFieldValue('water',data?.water_supplier ? data.water_supplier : "")
      }
    } catch (error) {
      console.log("Error while fetching Owner And Kyc Details")
    }
  }

  const handleBrowseClick = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      // You can perform your file upload logic here.
      // console.log("Uploading file:", file);
      setFileName(file?.name);
    }
  };

  const validationSchema = Yup.object().shape({
    ownerName: Yup.string().matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed"),
    rent: Yup.number().positive("Rent must be positive number").integer("Rent must be an integer"),
    // aggrement_duration : Yup.number().positive("Aggrement Duration must be positive number").integer("Aggrement Duration must be an integer"),
    rentShare: Yup.number().min(0, "Percentage must be positive number").max(100, "Percentage can't be greater than 100"),
    rentIncrement: Yup.string(),
    lockIn: Yup.number().positive("Lockin period must be positive number").integer("Lockin period must be an integer"),
    // bankAccount: Yup.number().test('len', 'Back Account number must be 20 digit', val => Math.ceil(Math.log10(val + 1)) === 20),
    bankAccount: Yup.number().when("bankAccount", (val, schema) => {
      if(val) {  //if address exist then apply min max else not
         return Yup.number().test('len', 'Back Account number must be 20 digit', val => Math.ceil(Math.log10(val + 1)) === 20);
      } else { 
         return Yup.number().notRequired();
      }
    }),
    ifsc: Yup.string().matches(/^[a-zA-Z0-9]+$/, "IFCS Must be alphanumeric").min(10, 'IFCS Must be 10 alphanumeric characters').max(10, 'IFCS Must be 10 alphanumeric characters'),
    bankName: Yup.string().matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed"),
    branch: Yup.string().matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed"),
    name: Yup.string().matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed"),
    electricity: Yup.string(),
    // caNumber: Yup.number().nullable().optional().test('len', 'Connection number must be 15 digit', val => Math.ceil(Math.log10(val + 1)) === 15),
    water: Yup.string(),
    // connctionNumber: Yup.number().nullable().optional().test('len', 'CA number must be 15 digit', val => Math.ceil(Math.log10(val + 1)) === 15),
  },["bankAccount","bankAccount"]);

  const initialValues={
    ownerName: '',
    rent: '',
    rentShare: '',
    rentIncrement: '',
    lockIn: '',
    startDate: '',
    endDate: '',
    rentStartDate: '',
    rentFreePeriod:'',
    company_type: '',
    bankAccount: '',
    ifsc: '',
    bankName: '',
    branch: '',
    name: '',
    electricity: '',
    caNumber: '',
    water: '',
    connctionNumber: '',
    // aggrement_duration: '',
    id:'',
    b_w_file:'',
    tracking_file:'',
    cancelled_cheque_file:'',
    connect_number_file:'',
    ca_number_file:''
  }

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values) => {
      try {
        // console.log("values -- ",values)
      let documents_attributes_array = []

            if(values.b_w_file && values.b_w_file.base64_string){
              let b_w_obj={
                  document_name: values.b_w_file.file.name,
                  document_type: "bw_owner", // jpg, jpeg, webp,
                  description: "bw owner file",
                  document_reference_type: "PropertyOwner",
                  is_verified: true,
                  document_image:values.b_w_file.base64_string.split(",")[1],
                  content_type:values.b_w_file.file.type.split("/")[1]
                }
              documents_attributes_array.push(b_w_obj)
            }
            if(values.tracking_file && values.tracking_file.base64_string){
                let tracking_obj={
                    document_name: values.tracking_file.file.name,
                    document_type: "tracking", // jpg, jpeg, webp,
                    description: "tracking file",
                    document_reference_type: "PropertyOwner",
                    is_verified: true,
                    document_image:values.tracking_file.base64_string.split(",")[1],
                    content_type:values.tracking_file.file.type.split("/")[1]
                  }
                documents_attributes_array.push(tracking_obj)
            }
            if(values.cancelled_cheque_file && values.cancelled_cheque_file.base64_string){
               let cancelled_obj={
                    document_name: values.cancelled_cheque_file.file.name,
                    document_type: "cancelled_check", // jpg, jpeg, webp,
                    description: "cancelled cheque file",
                    document_reference_type: "PropertyOwner",
                    is_verified: true,
                    document_image:values.cancelled_cheque_file.base64_string.split(",")[1],
                    content_type:values.cancelled_cheque_file.file.type.split("/")[1]
                  }
                documents_attributes_array.push(cancelled_obj)
            }
            if(values.ca_number_file && values.ca_number_file.base64_string){
              let ca_obj={
                document_name: values.ca_number_file.file.name,
                document_type: "ca_number", // jpg, jpeg, webp,
                description: "ca number file",
                document_reference_type: "PropertyOwner",
                is_verified: true,
                document_image:values.ca_number_file.base64_string.split(",")[1],
                content_type:values.ca_number_file.file.type.split("/")[1]
              }
              documents_attributes_array.push(ca_obj)
            }
            if(values.connect_number_file && values.connect_number_file.base64_string){
              let connection_obj={
                document_name: values.connect_number_file.file.name,
                document_type: "connection_number", // jpg, jpeg, webp,
                description: "connection number file",
                document_reference_type: "PropertyOwner",
                is_verified: true,
                document_image:values.connect_number_file.base64_string.split(",")[1],
                content_type:values.connect_number_file.file.type.split("/")[1]
              }
              documents_attributes_array.push(connection_obj)
            }

            const requestObj = {
              // id:91,
              // property_id:values.id,
              owner_name:values.ownerName,
              rent_amount:values.rent,
              // bw_owners,
              // owner_gst,
              rent_increment_type:values.rentIncrement,
              rent_increment_type_value:values.rentShare,
              lockin_period:values.lockIn,
              // agreement_duration:values.aggrement_duration,
              agreement_start_date: values.startDate ? moment(values.startDate).format('YYYY-MM-DD') : "",
              agreement_end_date: values.endDate ? moment(values.endDate).format('YYYY-MM-DD') : "",
              rent_start_date:values.rentStartDate ? moment(values.rentStartDate).format('YYYY-MM-DD') : "",
              rent_free_period:values.rentFreePeriod ? moment(values.rentFreePeriod).format('YYYY-MM-DD') : "",
              is_msme:values.company_type === "msme" ? true : false,
              company_type:values.company_type,
              meta:{
                account_number: values.bankAccount,
                account_name: values.name,
                ifsc_code: values.ifsc,
                bank_name: values.bankName,
                branch: values.branch
              },
              // electricity_provider:values.electricity,
              // electricity_provider_number,
              // water_supplier:values.water,
              // water_supplier_number,
          }

      let response = ""
      // console.log("ownerDetails?.id -- ",ownerDetails?.id)
      if(ownerDetails?.id){
        response = await updatePropertyOwner(values.id,documents_attributes_array.length > 0 ? {...requestObj,documents_attributes:documents_attributes_array} : requestObj);
      }else{
        const create_request_object = {
          ...requestObj,
          property_id:propDetail.id,
          documents_attributes:documents_attributes_array
        }
        // console.log("create payload -- ",create_request_object)
        response = await createPropertyOwner(create_request_object);
      }

        if (response && response?.status === 'success') {
            await toast.success('Owner and KYC details has been updated successfully.');
            loadOwnerAndKycDetails();
            handleCloseUpdatePropertyModal();
        } else {
          await toast.error('Something went wrong while updatig Owner and KYC details.');
        }
      } catch (error) {
        console.log(error);
      }
    },
  });

  const isDateInRange = (date) => {
    // console.log("Date")
    if (!startDate || !endDate) return false;
    return date >= startDate && date <= endDate;
};

function getBase64(file,cb) {
  let reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = function () {
      cb(reader.result)
  };
  reader.onerror = function (error) {
      console.log('Error: ', error);
  };
}

  return (
    <>
    <div  className="owner-kyc">
        
      <div className="brd_box_card mb-3">
        <div className="box_head">
          <div className="d-flex align-items-center">
            <h2>
              {" "}
              <img
                src="/images/corner-down-right.svg"
                className="pr-2"
                alt=""
              />{" "}
              PROPERTY OWNER DETAILS
            </h2>
          </div>
        </div>
        <div className="box_contain">
          <div className="sub_exit_box">

            <div className="d-flex align-items-center pb-4">
              <div className="sub_exit_data_txt">
                <h3>Name</h3>
                <span>{ownerDetails?.owner_name ? ownerDetails?.owner_name : "--"}</span>
              </div>
              <div className="sub_exit_data_txt">
                <h3>Rent</h3>
                <span>{ownerDetails?.rent_amount ? ownerDetails?.rent_amount : "--"}</span>
              </div>
              <div className="sub_exit_data_txt">
                <h3>Rent share</h3>
                <span>{ownerDetails?.owner_name ? ownerDetails?.owner_name : "--"}</span>
              </div>
              <div className="sub_exit_data_txt">
                <h3>B/W Owner</h3>
                {
                        b_w_owner_Url ?
                        <span 
                          className='pdf_clr'
                          onClick={()=>window.open(b_w_owner_Url, "_blank")} 
                          style={{cursor:"pointer"}}
                        >
                          <img src="/images/book.svg" className="pr-1" alt="" />   
                            {b_w_owner_Url.split("/")[b_w_owner_Url.split("/").length-1]}
                        </span>
                        :
                        <span>--</span>
                      }
              </div>
              <div className="sub_exit_data_txt">
                <h3>Annual rent Increment</h3>
                <span>{ownerDetails?.rent_increment_type_value ? `${ownerDetails?.rent_increment_type_value}%` : "--"}</span>
              </div>
              <div className="sub_exit_data_txt">
                <h3>Tracking</h3>
                  {
                        tracking_Url ?
                        <span 
                          className='pdf_clr'
                          onClick={()=>window.open(tracking_Url, "_blank")} 
                          style={{cursor:"pointer"}}
                        >
                          <img src="/images/book.svg" className="pr-1" alt="" />   
                            {tracking_Url.split("/")[tracking_Url.split("/").length-1]}
                        </span>
                        :
                        <span>--</span>
                      }
              </div>
            </div>
           

            {/*  */}
            <div className="d-flex align-items-center pb-4">
              <div className="sub_exit_data_txt">
                <h3>Lock - in</h3>
                <span>{ownerDetails?.lockin_period ? ownerDetails?.lockin_period : "--"}</span>
              </div>
              <div className="sub_exit_data_txt">
                <h3>Agreement Period</h3>
                <span>{
                    ownerDetails?.agreement_start_date ? moment(ownerDetails?.agreement_start_date).format('DD-MM-YYYY') : ""
                  } - {
                    ownerDetails?.agreement_end_date ? moment(ownerDetails?.agreement_end_date).format('DD-MM-YYYY') : ""
                  }</span>
              </div>
              {/* <div className="sub_exit_data_txt">
                <h3>Tenure</h3>
                <span>Fixed /Revenue share</span>
              </div> */}
              <div className="sub_exit_data_txt">
                <h3>Rent free period</h3>
                <span>{ownerDetails?.rent_free_period ? ownerDetails?.rent_free_period : "--"}</span>
              </div>
              <div className="sub_exit_data_txt">
                <h3>Is MSME Registered ?</h3>
                <span>{ownerDetails?.is_msme ? "Yes" : "No"}</span>
              </div>
            </div>
            {/* <div className="d-flex align-items-center ">
              <div className="sub_exit_data_txt">
                <h3>If MSME Registration Document</h3>
                <span>Registration Document.excel</span>
              </div>
            </div> */}

          </div>
        </div>
      </div>

      <div className="brd_box_card mb-3">
        <div className="box_head">
          <div className="d-flex align-items-center">
            <h2>
              {" "}
              <img
                src="/images/corner-down-right.svg"
                className="pr-2"
                alt=""
              />{" "}
              KYC
            </h2>
            {/* <div className="tit_point green_point mx-3">Initiated</div> */}
          </div>
        </div>
        <div className="box_contain">
          <div className="sub_exit_box">

            <div className="d-flex align-items-center pb-4">
              <div className="sub_exit_data_txt">
                <h3>Bank account number</h3>
                <span>{ownerDetails?.meta?.account_number ? ownerDetails.meta.account_number : "--"}</span>
              </div>
              <div className="sub_exit_data_txt">
                <h3>IFSC code</h3>
                <span>{ownerDetails?.meta?.ifsc_code ? ownerDetails.meta.ifsc_code : "--"}</span>
              </div>
              <div className="sub_exit_data_txt">
                <h3>Bank Name</h3>
                <span>{ownerDetails?.meta?.bank_name ? ownerDetails.meta.bank_name : "--"}</span>
              </div>
              <div className="sub_exit_data_txt">
                <h3>Branch</h3>
                <span>{ownerDetails?.meta?.branch ? ownerDetails.meta.branch : "--"}</span>
              </div>
            </div>
           

            {/*  */}
            <div className="d-flex align-items-center pb-4">
              <div className="sub_exit_data_txt">
                <h3>Name as per bank records</h3>
                <span>{ownerDetails?.meta?.account_name ? ownerDetails.meta.account_name : "--"}</span>
              </div>
              <div className="sub_exit_data_txt">
                <h3>Cancelled check</h3>
                {
                        cancelled_cheque ?
                        <span 
                          className='pdf_clr'
                          onClick={()=>window.open(cancelled_cheque, "_blank")} 
                          style={{cursor:"pointer"}}
                        >
                          <img src="/images/book.svg" className="pr-1" alt="" />   
                            {cancelled_cheque.split("/")[cancelled_cheque.split("/").length-1]}
                        </span>
                        :
                        <span>--</span>
                      }
              </div>
            </div>
            

          </div>
        </div>
      </div>

      <div className="brd_box_card">
        <div className="box_head">
          <div className="d-flex align-items-center">
            <h2>
              {" "}
              <img
                src="/images/corner-down-right.svg"
                className="pr-2"
                alt=""
              />{" "}
              UTILITIES
            </h2>
            {/* <div className="tit_point green_point mx-3">Initiated</div> */}
          </div>
        </div>
        <div className="box_contain">
          <div className="sub_exit_box">

            <div className="d-flex align-items-center pb-4">
              {/* <div className="sub_exit_data_txt">
                <h3>Electricity</h3>
                <span>{ownerDetails?.electricity_provider ? ownerDetails?.electricity_provider : ""}</span>
              </div> */}
              <div className="sub_exit_data_txt">
                <h3>Electricity - CA number</h3>
                 {
                        ca_number_Url ?
                        <span 
                          className='pdf_clr'
                          onClick={()=>window.open(ca_number_Url, "_blank")} 
                          style={{cursor:"pointer"}}
                        >
                          <img src="/images/book.svg" className="pr-1" alt="" />   
                            {ca_number_Url.split("/")[ca_number_Url.split("/").length-1]}
                        </span>
                        :
                        <span>--</span>
                      }
              </div>
              {/* <div className="sub_exit_data_txt">
                <h3>Water</h3>
                <span>{ownerDetails?.water_supplier ? ownerDetails?.water_supplier : ""}</span>
              </div> */}
              <div className="sub_exit_data_txt">
                <h3>Water - Connection number</h3>
                {
                        connect_number_Url ?
                        <span 
                          className='pdf_clr'
                          onClick={()=>window.open(connect_number_Url, "_blank")} 
                          style={{cursor:"pointer"}}
                        >
                          <img src="/images/book.svg" className="pr-1" alt="" />   
                            {connect_number_Url.split("/")[connect_number_Url.split("/").length-1]}
                        </span>
                        :
                        <span>--</span>
                      }
              </div>
            </div>
            
          </div>
        </div>
      </div>
      {
        roles?.super_admin || roles?.tech_admin ? 
          <div style={{
                position:'fixed',
                bottom:25,
                right:25
            }}>
                <button
                    style={{border:"none"}}
                    data-bs-toggle="tooltip" data-bs-placement="top" title={ownerDetails?.id ? "Edit" : "Add owner details"}
                    className="edit_button"
                    onClick={handleUpdatePropertyModal}
                >
                  {ownerDetails?.id ?
                    <><img src="/images/edit.svg" alt="Edit Icon"/> Edit</>
                  :
                    <><AddIcon fontSize="small" /> Add</>
                  } 
                </button>
          </div>
          :
          null
        }
    </div>

            <Modal
                show={showUpdatePropertyModal}
                onHide={handleCloseUpdatePropertyModal}
                centered
                size="lg"
                className="confirm_noc_modal"
            >
                <Modal.Body>
                <div className="head_part_date_modal create-prop_modal">
                    <div className="d-flex justify-content-between align-items-center mb-4">
                    <div>
                        <h2 className="title">{ownerDetails?.id ? "Edit" : "Add"} property details</h2>
                    </div>
                    <div style={{cursor:"pointer"}}>
                        <span onClick={handleCloseUpdatePropertyModal} className="">
                        <img src="/images/cross_modal.svg" alt="Close Icon" />
                        </span>
                    </div>
                    </div>
                </div>
                <div className="modal_data_part">
                    {/* <div className="create-prop_detail">
                    <form onSubmit={formik.handleSubmit}>
                        <div className="TrailWrap mb-4">
                            <div>
                                <Form.Label>Name</Form.Label>
                                <Form.Control
                                placeholder="Name"
                                id="property_name"
                                name="property_name"
                                value={formik.values.property_name}
                                onChange={(e) => formik.setFieldValue('property_name', e.target.value)}
                                />
                                {
                                formik.touched.property_name && formik.errors.property_name ? (
                                    <div className="error-label">{formik.errors.property_name}</div>
                                ) : null
                                }
                            </div>
                            <div>
                                <Form.Label>Code*</Form.Label>
                                <Form.Control
                                placeholder="Enter Property Code"
                                id="property_code"
                                name="property_code"
                                value={formik.values.property_code}
                                onChange={(e) => formik.setFieldValue('property_code', e.target.value)}
                                disabled={true}
                                />
                                {
                                formik.touched.property_code && formik.errors.property_code ? (
                                    <div className="error-label">{formik.errors.property_code}</div>
                                ) : null
                                }
                            </div>
                            <div>
                                <Form.Label>Capacity no of beds</Form.Label>
                                <Form.Control
                                placeholder="Enter No. of Beds"
                                type="number"
                                id="capacity_no_of_beds"
                                name="capacity_no_of_beds"
                                value={formik.values.capacity_no_of_beds}
                                onChange={(e) => formik.setFieldValue('capacity_no_of_beds', e.target.value)}
                                />
                                {
                                formik.touched.capacity_no_of_beds && formik.errors.capacity_no_of_beds ? (
                                    <div className="error-label">{formik.errors.capacity_no_of_beds}</div>
                                ) : null
                                }
                            </div>
                            <div>
                                <Form.Label>Postal address 1</Form.Label>
                                <Form.Control
                                placeholder="Enter address 1"
                                id="postal_address_1"
                                name="postal_address_1"
                                value={formik.values.postal_address_1}
                                onChange={(e) => formik.setFieldValue('postal_address_1', e.target.value)}
                                />
                                {
                                formik.touched.postal_address_1 && formik.errors.postal_address_1 ? (
                                    <div className="error-label">{formik.errors.postal_address_1}</div>
                                ) : null
                                }
                            </div>
                            <div>
                                <Form.Label>Postal address 2</Form.Label>
                                <Form.Control
                                placeholder="Enter address 2"
                                id="postal_address_2"
                                name="postal_address_2"
                                value={formik.values.postal_address_2}
                                onChange={(e) => formik.setFieldValue('postal_address_2', e.target.value)}
                                />
                                {
                                formik.touched.postal_address_2 && formik.errors.postal_address_2 ? (
                                    <div className="error-label">{formik.errors.postal_address_2}</div>
                                ) : null
                                }
                            </div>
                            <div>
                                <Form.Label>Pincode</Form.Label>
                                <Form.Control
                                placeholder="Enter Pincode"
                                type="number"
                                id="pincode"
                                name="pincode"
                                value={formik.values.pincode}
                                onChange={(e) => formik.setFieldValue('pincode', e.target.value)}
                                style={formik.touched.pincode && formik.errors.pincode ? {
                                    borderColor:'red'
                                }: null}
                                />
                                {
                                formik.touched.pincode && formik.errors.pincode ? (
                                    <div className="error-label">{formik.errors.pincode}</div>
                                ) : null
                                }
                            </div>
                            <div className="custom-single-select">
                                <label className="form-label" htmlFor="city">City</label>
                                <Select
                                id="city"
                                name="city"
                                placeholder="City"
                                className="mt-1"
                                options={cities}
                                value={formik.values.city}
                                onChange={(value) => {
                                    formik.setFieldValue('city', value[0]);
                                    formik.setFieldValue('city_id', value[0].value);
                                }}
                                />
                                {
                                formik.touched.city && formik.errors.city ? (
                                    <div className="error-label">{formik.errors.city}</div>
                                ) : null
                                }
                            </div>
                            <div className="custom-single-select">
                                <label className="form-label" htmlFor="state">State</label>
                                <Select
                                id="state"
                                name="state"
                                placeholder="State"
                                className="mt-1"
                                options={states}
                                value={formik.values.state}
                                onChange={(value) => {
                                    formik.setFieldValue('state', value[0]);
                                    formik.setFieldValue('state_id', value[0].value);
                                }}
                                />
                                {
                                formik.touched.state && formik.errors.state ? (
                                    <div className="error-label">{formik.errors.state}</div>
                                ) : null
                                }
                            </div>
                            <div className="custom-single-select">
                                <label className="form-label" htmlFor="cluster">Cluster</label>
                                <Select
                                id="cluster"
                                name="cluster"
                                placeholder="Cluster"
                                className="mt-1"
                                options={clusters}
                                value={formik.values.cluster}
                                onChange={(value) => {
                                    formik.setFieldValue('cluster', value[0]);
                                    formik.setFieldValue('cluster_id', value[0].value);
                                }}
                                />
                                {
                                formik.touched.cluster && formik.errors.cluster ? (
                                    <div className="error-label">{formik.errors.cluster}</div>
                                ) : null
                                }
                            </div>

                            <div>
                                <Form.Label>Property Location</Form.Label>
                                <Form.Control
                                type="url"
                                id="location"
                                name="location"
                                placeholder="Enter Location Link"
                                value={formik.values.location}
                                onChange={(e) => formik.setFieldValue('location', e.target.value)}
                                />
                                {
                                formik.touched.location && formik.errors.location ? (
                                    <div className="error-label">{formik.errors.location}</div>
                                ) : null
                                }
                            </div>
                        </div>

                        <h3 className="title mb-4">BILLING INFORMATION</h3>

                            <div className="TrailWrap">
                            <div>
                                <Form.Label>Invoice series code</Form.Label>
                                <Form.Control
                                type="text"
                                id="invoice_series"
                                name="invoice_series"
                                placeholder="XXXXX-XXXXX"
                                value={formik.values.invoice_series}
                                onChange={(e) => formik.setFieldValue('invoice_series', e.target.value)}
                                style={formik.touched.invoice_series && formik.errors.invoice_series ? {
                                    borderColor:'red'
                                }: null}
                                />
                                {
                                formik.touched.invoice_series && formik.errors.invoice_series ? (
                                    <div className="error-label">{formik.errors.invoice_series}</div>
                                ) : null
                                }
                            </div>
                            <div>
                                <Form.Label>GSTIN (State)</Form.Label>
                                <Form.Control
                                type="text"
                                id="property_gstin"
                                name="property_gstin"
                                placeholder="XXXXX-XXXXX-XXXXX"
                                value={formik.values.property_gstin}
                                onChange={(e) => formik.setFieldValue('property_gstin', e.target.value)}
                                />
                                {
                                formik.touched.property_gstin && formik.errors.property_gstin ? (
                                    <div className="error-label">{formik.errors.property_gstin}</div>
                                ) : null
                                }
                            </div>

                            </div>

                        <div className="modal-footer">
                          <button 
                              type="button" 
                              className="orange_brd_button p-12-16-button mr-3"
                              onClick={()=>handleCloseUpdatePropertyModal()}
                          >CANCEL</button>
                          <button type="submit" className="basic-button">SAVE</button>
                        </div>
                    </form>
                    </div> */}
                    <div className="create-owner_kyc">
                      <h3 className="title">PROPERTY OWNER DETAILS</h3>
                      <form onSubmit={formik.handleSubmit}>
                        <div className="TrailWrap">
                          <div>
                            <Form.Label>Name</Form.Label>
                            <Form.Control
                              id="ownerName"
                              type="text"
                              placeholder="Enter your name"
                              isInvalid={formik.touched.ownerName && formik.errors.ownerName}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values.ownerName}
                              style={formik.touched.ownerName && formik.errors.ownerName ? {
                                borderColor:'red'
                              }: null}
                            />
                            <Form.Control.Feedback type="invalid">
                                    {formik.errors.ownerName}
                            </Form.Control.Feedback>
                          </div>

                          <div>
                            <Form.Label>Rent</Form.Label>
                            <Form.Control
                              id="rent"
                              type="number"
                              placeholder="₹  Enter Rent"
                              // value={rent}
                              // onChange={(e) => setRent(e.target.value)}
                              isInvalid={formik.touched.rent && formik.errors.rent}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values.rent}
                              style={formik.touched.rent && formik.errors.rent ? {
                                borderColor:'red'
                              }: null}
                            />
                            <Form.Control.Feedback type="invalid">
                                    {formik.errors.rent}
                            </Form.Control.Feedback>
                          </div>
                          <div>
                            <Form.Label>Rent share (In Percentage)</Form.Label>
                            <Form.Control
                              placeholder="%"
                              // value={rentShare}
                              // onChange={(e) => setRentShare(e.target.value)}
                              id="rentShare"
                              type="number"
                              isInvalid={formik.touched.rentShare && formik.errors.rentShare}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values.rentShare}
                              style={formik.touched.rentShare && formik.errors.rentShare ? {
                                borderColor:'red'
                              }: null}
                            />
                            <Form.Control.Feedback type="invalid">
                                    {formik.errors.rentShare}
                            </Form.Control.Feedback>
                          </div>
                          <div>
                            <Form.Label>Annual Rent Increment</Form.Label>
                            {/* <Form.Control
                              placeholder="₹ Enter Increment amount"
                              value={rentIncrement}
                              onChange={(e) => setRentIncrement(e.target.value)}
                            /> */}
                            <Form.Select 
                            placeholder="Annual Rent Increment"
                            id="rentIncrement"
                            isInvalid={formik.touched.rentIncrement && formik.errors.rentIncrement}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.rentIncrement}
                            aria-label="Default select example">
                              <option value="">Select Annual Rent Increment</option>
                              <option value="1">One</option>
                              <option value="2">Two</option>
                              <option value="3">Three</option>
                            </Form.Select>
                            <Form.Control.Feedback type="invalid">
                                    {formik.errors.rentIncrement}
                            </Form.Control.Feedback>
                          </div>
                        </div>
                        <div className="TrailWrap-prop">
                          {/* <div>
                            <Form.Label>B/W Owner</Form.Label>
                            <InputGroup className="mb-3">
                              <Form.Control
                                placeholder="Select your room list"
                                aria-label="Amount (to the nearest dollar)"
                              />
                              <InputGroup.Text>BROWSE</InputGroup.Text>
                            </InputGroup>
                          </div> */}
                          <div>
                              <Form.Label>B/W Owner</Form.Label>
                              <InputGroup className="mb-3">
                                <Form.Control
                                  aria-label="Amount (to the nearest dollar)"
                                  value={formik.values?.b_w_file?.file?.name}
                                />
                                <Form.Control
                                  id="fileInput"
                                  type="file"
                                  ref={fileInputRef}
                                  // accept=".xlsx,.xls"
                                  accept=".csv"
                                  style={{ display: "none" }}
                                  // onChange={handleFileChange}
                                  onChange={(e)=>{
                                    const file = e.target.files[0];
                                    getBase64(file,(base64_string)=>{
                                      formik.setFieldValue("b_w_file",{
                                        file,
                                        base64_string
                                      })
                                    })
                                  }}
                                />
                                <InputGroup.Text 
                                  // onClick={handleBrowseClick} 
                                  onClick={()=>fileInputRef.current.click()}
                                className="pe-auto">
                                  UPLOAD
                                </InputGroup.Text>
                                {/* <InputGroup.Text>BROWSE</InputGroup.Text> */}
                              </InputGroup>
                            </div>
                        </div>
                        <div className="TrailWrap">
                          <div>
                            <Form.Label>Lock in</Form.Label>
                            <Form.Control
                              id="lockIn"
                              type="number"
                              placeholder="Enter lock in period"
                              isInvalid={formik.touched.lockIn && formik.errors.lockIn}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values.lockIn}
                              style={formik.touched.lockIn && formik.errors.lockIn ? {
                                borderColor:'red'
                              }: null}
                            />
                            <Form.Control.Feedback type="invalid">
                                    {formik.errors.lockIn}
                            </Form.Control.Feedback>
                          </div>

                          <div className="form_control_box" style={{marginTop:5}}>
                              <div className='modal_input_main dt_range_picker'>
                                    <Form.Label>Agreement Period</Form.Label>
                                      <ReactDatePicker
                                          // selected={startDate}
                                          selectsRange={true}
                                          onChange={(date) => {
                                            setStartDate(date[0]);
                                            setEndDate(date[1]);
                                    
                                            formik.setFieldValue('startDate', date[0]);
                                            formik.setFieldValue('endDate', date[1]);
                                          }}
                                          dateFormat={'dd/MM/yyyy'}
                                          monthsShown={2}
                                          startDate={formik.values.startDate}
                                          endDate={formik.values.endDate}
                                          id="notice-start-date"
                                          placeholderText="DD/MM/YY - DD/MM/YY"
                                          highlightDates={isDateInRange}
                                          minDate={new Date()}
                                      />
                                      <Form.Control.Feedback type="invalid">
                                              {formik.errors.rentFreePeriod}
                                      </Form.Control.Feedback>
                              </div>
                            </div>

                          {/* <div>
                            <Form.Label>Tenure</Form.Label>
                            <Form.Control
                              placeholder="Enter tenure"
                              value={tenure}
                              onChange={(e) => setTenure(e.target.value)}
                            />
                          </div> */}
                          <div className="form_control_box" style={{marginTop:5}}>
                            <div className='modal_input_main'>
                              <Form.Label>Rent Free Period</Form.Label>
                              <ReactDatePicker
                                placeholderText="DD/MM/YY"
                                selected={formik.values.rentFreePeriod}
                                onChange={(date) => formik.setFieldValue('rentFreePeriod', date)}
                              />
                              <Form.Control.Feedback type="invalid">
                                      {formik.errors.lockIn}
                              </Form.Control.Feedback>
                            </div>
                          </div>
                          {/* <div>
                            <Form.Label>Rent Free Period</Form.Label>
                            <Form.Control
                              placeholder="Start date - End date"
                              value={rentFreePeriod}
                              onChange={(e) => setRentFreePeriod(e.target.value)}
                            />
                          </div> */}
                          <div className="form_control_box" style={{marginTop:5}}>
                            <div className='modal_input_main'>
                              <Form.Label>Rent Start Date</Form.Label>
                              <ReactDatePicker
                                placeholderText="DD/MM/YY"
                                selected={formik.values.rentStartDate}
                                onChange={(date) => formik.setFieldValue('rentStartDate', date)}
                              />
                              <Form.Control.Feedback type="invalid">
                                      {formik.errors.lockIn}
                              </Form.Control.Feedback>
                            </div>
                          </div>
                          <div className="flex-track">
                            <div className="form_control_box d-block">
                              <label className="form-label">Company Type</label>
                              <div className="d-flex flex-row">
                                <div className="form-check-inline form-check">
                                  <input
                                    type="radio"
                                    id="company_type"
                                    name="company_type"
                                    value={"individual"}
                                    checked={formik.values.company_type === "individual"}
                                    onChange={formik.handleChange}
                                    className="form-check-input"
                                    // onChange={formik.handleChange}
                                  />
                                  <label htmlFor="company_type" className="form-check-label">
                                    Individual
                                  </label>
                                </div>
                                <div className="form-check-inline form-check">
                                  <input
                                    type="radio"
                                    id="company_type"
                                    name="company_type"
                                    value={"private_limited"}
                                    checked={formik.values.company_type === "private_limited"}
                                    onChange={formik.handleChange}
                                    className="form-check-input"
                                    // defaultChecked
                                  />
                                  <label htmlFor="company_type" className="form-check-label">
                                    Private Limited
                                  </label>
                                </div>
                                <div className="form-check-inline form-check">
                                  <input
                                    type="radio"
                                    id="company_type"
                                    name="company_type"
                                    value={"partnership_firm"}
                                    checked={formik.values.company_type === "partnership_firm"}
                                    onChange={formik.handleChange}
                                    className="form-check-input"
                                    // defaultChecked
                                  />
                                  <label htmlFor="company_type" className="form-check-label">
                                    Partnership Firm
                                  </label>
                                </div>
                                <div className="form-check-inline form-check">
                                  <input
                                    type="radio"
                                    id="company_type"
                                    name="company_type"
                                    value={"msme"}
                                    checked={formik.values.company_type === "msme"}
                                    onChange={formik.handleChange}
                                    className="form-check-input"
                                    // onChange={formik.handleChange}
                                    defaultChecked
                                  />
                                  <label htmlFor="company_type " className="form-check-label">
                                    MSME
                                  </label>
                                </div>
                              </div>
                            </div>
                            <div>
                              <Form.Label>Tracking</Form.Label>
                              <InputGroup className="mb-3">
                                <Form.Control
                                  aria-label="Amount (to the nearest dollar)"
                                  // value={fileName}
                                  value={formik.values.tracking_file?.file?.name}
                                />
                                <Form.Control
                                  id="fileInput"
                                  type="file"
                                  ref={trackingFileRef}
                                  // accept=".csv"
                                  accept=".xlsx,.xls"
                                  style={{ display: "none" }}
                                  onChange={(e)=>{
                                    const file = e.target.files[0];
                                    getBase64(file,(base64_string)=>{
                                      formik.setFieldValue("tracking_file",{
                                        file,
                                        base64_string
                                      })
                                    })
                                  }}
                                />
                                <InputGroup.Text 
                                  onClick={()=>trackingFileRef.current.click()}
                                  // onClick={handleBrowseClick} 
                                  className="pe-auto"
                                >
                                  UPLOAD
                                </InputGroup.Text>
                                {/* <InputGroup.Text>BROWSE</InputGroup.Text> */}
                              </InputGroup>
                            </div>
                          </div>
                        </div>
                      {/* </Form> */}

                      <h3 className="title">KYC</h3>
                      {/* <Form> */}
                        <div className="TrailWrap">
                          <div>
                            <Form.Label>Bank account number</Form.Label>
                            <Form.Control
                              id="bankAccount"
                              type="number"
                              placeholder="xxxxx-xxxxx-xxxxx-xxxxx"
                              isInvalid={formik.touched.bankAccount && formik.errors.bankAccount}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values.bankAccount}
                              style={formik.touched.bankAccount && formik.errors.bankAccount ? {
                                borderColor:'red'
                              }: null}
                            />
                            <Form.Control.Feedback type="invalid">
                                    {formik.errors.bankAccount}
                            </Form.Control.Feedback>
                          </div>
                          <div>
                            <Form.Label>IFSC Code</Form.Label>
                            <Form.Control
                              id="ifsc"
                              type="text"
                              placeholder="xxxxx-xxxxx"
                              isInvalid={formik.touched.ifsc && formik.errors.ifsc}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values.ifsc}
                              style={formik.touched.ifsc && formik.errors.ifsc ? {
                                borderColor:'red'
                              }: null}
                            />
                            <Form.Control.Feedback type="invalid">
                                    {formik.errors.ifsc}
                            </Form.Control.Feedback>
                          </div>
                          <div>
                            <Form.Label>Bank Name</Form.Label>
                            <Form.Control
                              id="bankName"
                              type="text"
                              placeholder="Enter bank name here"
                              isInvalid={formik.touched.bankName && formik.errors.bankName}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values.bankName}
                              style={formik.touched.bankName && formik.errors.bankName ? {
                                borderColor:'red'
                              }: null}
                            />
                            <Form.Control.Feedback type="invalid">
                                    {formik.errors.bankName}
                            </Form.Control.Feedback>
                          </div>
                          <div>
                            <Form.Label>Branch</Form.Label>
                            <Form.Control
                              id="branch"
                              type="text"
                              placeholder="Enter Branch name"
                              isInvalid={formik.touched.branch && formik.errors.branch}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values.branch}
                              style={formik.touched.branch && formik.errors.branch ? {
                                borderColor:'red'
                              }: null}
                            />
                            <Form.Control.Feedback type="invalid">
                                    {formik.errors.branch}
                            </Form.Control.Feedback>
                          </div>
                          <div>
                            <Form.Label>Name as per bank records</Form.Label>
                            <Form.Control
                              id="name"
                              type="text"
                              placeholder="Enter name as per bank records"
                              isInvalid={formik.touched.name && formik.errors.name}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values.name}
                              style={formik.touched.name && formik.errors.name ? {
                                borderColor:'red'
                              }: null}
                            />
                            <Form.Control.Feedback type="invalid">
                                    {formik.errors.name}
                            </Form.Control.Feedback>
                          </div>
                        </div>
                        <div className="TrailWrap-prop">
                          <div>
                              <Form.Label>Cancelled check</Form.Label>
                              <InputGroup className="mb-3">
                                <Form.Control
                                  aria-label="Amount (to the nearest dollar)"
                                  // value={fileName}
                                  value={formik.values.cancelled_cheque_file?.file?.name}
                                />
                                <Form.Control
                                  id="fileInput"
                                  type="file"
                                  ref={cancelledChequeRef}
                                  accept=".jpg"
                                  style={{ display: "none" }}
                                  onChange={(e)=>{
                                    const file = e.target.files[0];
                                    getBase64(file,(base64_string)=>{
                                      formik.setFieldValue("cancelled_cheque_file",{
                                        file,
                                        base64_string
                                      })
                                    })
                                  }}
                                />
                                <InputGroup.Text 
                                  // onClick={handleBrowseClick} 
                                  onClick={()=>cancelledChequeRef.current.click()}
                                  className="pe-auto"
                                >
                                  UPLAOD
                                </InputGroup.Text>
                                {/* <InputGroup.Text>BROWSE</InputGroup.Text> */}
                              </InputGroup>
                            </div>
                        </div>
                      {/* </Form> */}

                      <h3 className="title">UTILITIES</h3>
                      {/* <Form> */}
                        <div className="uti-div">
                          {/* <div>
                            <Form.Label>Electricity</Form.Label>
                            <Form.Select 
                              placeholder="Select your provider"
                              id="electricity"
                              isInvalid={formik.touched.electricity && formik.errors.electricity}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values.electricity}
                              aria-label="Default select example">
                                <option value="">Select your provider</option>
                                <option value="Single">Single</option>
                                <option value="Double">Double</option>
                                <option value="Dormitory">Dormitory</option>
                            </Form.Select>
                            <Form.Control.Feedback type="invalid">
                                    {formik.errors.electricity}
                            </Form.Control.Feedback>
                          </div> */}
                          <div>
                              <Form.Label>Electricity - CA Number</Form.Label>
                              <InputGroup className="mb-3">
                                <Form.Control
                                  aria-label="Amount (to the nearest dollar)"
                                  // value={fileName}
                                  value={formik.values.ca_number_file?.file?.name}
                                />
                                <Form.Control
                                  id="fileInput"
                                  type="file"
                                  ref={caNumberRef}
                                  // accept=".xlsx,.xls,.csv"
                                  accept=".csv"
                                  style={{ display: "none" }}
                                  onChange={(e)=>{
                                    const file = e.target.files[0];
                                    getBase64(file,(base64_string)=>{
                                      formik.setFieldValue("ca_number_file",{
                                        file,
                                        base64_string
                                      })
                                    })
                                  }}
                                />
                                <InputGroup.Text onClick={()=>caNumberRef.current.click()} className="pe-auto">
                                  UPLOAD
                                </InputGroup.Text>
                                {/* <InputGroup.Text>BROWSE</InputGroup.Text> */}
                              </InputGroup>
                            </div>
                          {/* <div>
                            <Form.Label>Water</Form.Label>
                            <Form.Select 
                            placeholder="Select your provider"
                            id="water"
                            isInvalid={formik.touched.water && formik.errors.water}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.water}
                            aria-label="Default select example">
                              <option value="">Select your provider</option>
                              <option value="Single">Single</option>
                              <option value="Double">Double</option>
                              <option value="Dormitory">Dormitory</option>
                            </Form.Select>
                            <Form.Control.Feedback type="invalid">
                                    {formik.errors.water}
                            </Form.Control.Feedback>
                          </div> */}
                          <div>
                              <Form.Label>Water - Connection Number</Form.Label>
                              <InputGroup className="mb-3">
                                <Form.Control
                                  aria-label="Amount (to the nearest dollar)"
                                  // value={fileName}
                                  value={formik.values.connect_number_file?.file?.name}
                                />
                                <Form.Control
                                  id="fileInput"
                                  type="file"
                                  ref={connectNumberRef}
                                  accept=".xlsx,.xls,.csv"
                                  // accept=".csv"
                                  style={{ display: "none" }}
                                  onChange={(e)=>{
                                    const file = e.target.files[0];
                                    getBase64(file,(base64_string)=>{
                                      formik.setFieldValue("connect_number_file",{
                                        file,
                                        base64_string
                                      })
                                    })
                                  }}
                                />
                                <InputGroup.Text onClick={()=>connectNumberRef.current.click()} className="pe-auto">
                                  UPLOAD
                                </InputGroup.Text>
                                {/* <InputGroup.Text>BROWSE</InputGroup.Text> */}
                              </InputGroup>
                            </div>
                        </div>
                        <div className="modal-footer">
                          <button type="button" className="orange_brd_button p-12-16-button mr-3" onClick={handleCloseUpdatePropertyModal}>
                            CANCEL
                          </button>
                          <button 
                            type="submit"
                            className="basic-button" 
                          >
                            SAVE
                          </button>
                        </div>
                      </form>
                    </div>
                </div>
                </Modal.Body>
            </Modal>

    </>
  );
}

export default OwnerKyc;
