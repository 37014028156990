import React, { useRef, useState } from "react";
import { Form, InputGroup } from "react-bootstrap";
import { addOwnerAndKyc,createPropertyOwner } from "../../../helpers/services/api.services";

import * as formik from 'formik';
import * as Yup from 'yup';
import ReactDatePicker from 'react-datepicker';
import moment from 'moment';
import toast from "react-hot-toast";

export default function OwnerKyc({savedPropertyId}) {

  const { Formik } = formik;

  // const [ownerName, setOwnerName] = useState("");
  // const [rent, setRent] = useState("");
  // const [rentShare, setRentShare] = useState("");
  // const [rentIncrement, setRentIncrement] = useState("");
  // const [lockIn, setLockIn] = useState("");
  // const [agreementPeriod, setAgreementPeriod] = useState("");
  // const [tenure, setTenure] = useState("");
  // const [rentFreePeriod, setRentFreePeriod] = useState("");
  // const [isMsmeRegistered, setIsMsmeRegistered] = useState(true);
  // const [bankAccount, setBankAccount] = useState("");
  // const [ifsc, setIfsc] = useState("");
  // const [bankName, setBankName] = useState("");
  // const [branch, setBranch] = useState("");
  // const [name, setName] = useState("");
  // const [electricity, setElectricity] = useState("");
  // const [caNumber, setCaNumber] = useState("");
  // const [water, setWater] = useState("");
  // const [connctionNumber, setConnctionNumber] = useState("");
  // const [fileName, setFileName] = useState("");
  const fileInputRef = useRef();
  const trackingFileRef = useRef();
  const cancelledChequeRef = useRef();
  const caNumberRef = useRef();
  const connectNumberRef = useRef();

  const [startDate, setStartDate] = useState();
    const [endDate, setEndDate] = useState();


  // const handleRadioChange = (e) => {
  //   console.log(e.target.value, "msmeeeee");
  //   setIsMsmeRegistered(e.target.value);
  // };

  // const CreateOwnerAndKyc = async () => {
  //   const data = JSON.stringify({
  //     owner_name: ownerName,
  //     rent_amount: Number(rent),
  //     // bw_owners: "Example BW Owners",
  //     // owner_gst: "ABC123",
  //     rent_increment_type: "percentage",
  //     rent_increment_type_value: Number(rentShare),
  //     lockin_period: Number(lockIn),
  //     agreement_duration: Number(agreementPeriod),
  //     // rent_start_date: "2023-07-15",
  //     rent_free_period: rentFreePeriod,
  //     is_msme: isMsmeRegistered,
  //     meta: {},
  //   });
  //   console.log(data, "owner and kyc details data ");
  //   try {
  //     let response = await addOwnerAndKyc(data);
  //     console.log(response.data, "add owner and kyc property responseee");
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  // const handleBrowseClick = () => {
  //   fileInputRef.current.click();
  // };

  function getBase64(file,cb) {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      // console.log("reader.result -- ",reader.result)
      // setBase64URL(reader.result)
        // cb(reader.result)
        cb(reader.result)
    };
    reader.onerror = function (error) {
        console.log('Error: ', error);
    };
}

  // const handleFileChange = (e) => {
  //   const file = e.target.files[0];
  //   if (file) {
  //     console.log("Uploading file:", file);
  //     getBase64(file)
  //     // fileParser(file)
  //     setFileName(file?.name);
  //   }
  // };

  const schema = Yup.object().shape({
    ownerName: Yup.string().matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed"),
    rent: Yup.number().positive("Rent must be positive number").integer("Rent must be an integer"),
    aggrement_duration : Yup.number().positive("Aggrement Duration must be positive number").integer("Aggrement Duration must be an integer"),
    rentShare: Yup.number().min(0, "Percentage must be positive number").max(100, "Percentage can't be greater than 100"),
    rentIncrement: Yup.string(),
    lockIn: Yup.number().positive("Lockin period must be positive number").integer("Lockin period must be an integer"),
    // bankAccount: Yup.number().test('len', 'Back Account number must be 20 digit', val => Math.ceil(Math.log10(val + 1)) === 20),
    bankAccount: Yup.number().when("bankAccount", (val, schema) => {
      if(val) {  //if address exist then apply min max else not
         return Yup.number().test('len', 'Back Account number must be 20 digit', val => Math.ceil(Math.log10(val + 1)) === 20);
      } else { 
         return Yup.number().notRequired();
      }
    }),
    ifsc: Yup.string().matches(/^[a-zA-Z0-9]+$/, "IFCS Must be alphanumeric").min(10, 'IFCS Must be 10 alphanumeric characters').max(10, 'IFCS Must be 10 alphanumeric characters'),
    bankName: Yup.string().matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed"),
    branch: Yup.string().matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed"),
    name: Yup.string().matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed"),
    electricity: Yup.string(),
    // caNumber: Yup.number().nullable().optional().test('len', 'Connection number must be 15 digit', val => Math.ceil(Math.log10(val + 1)) === 15),
    water: Yup.string(),
    // connctionNumber: Yup.number().nullable().optional().test('len', 'CA number must be 15 digit', val => Math.ceil(Math.log10(val + 1)) === 15),
  },["bankAccount","bankAccount"]);

  const isDateInRange = (date) => {
    console.log("Date")
    if (!startDate || !endDate) return false;
    return date >= startDate && date <= endDate;
};

  return (
    <div className="create-owner_kyc">
      <h3 className="title">PROPERTY OWNER DETAILS</h3>
      <Formik
        validationSchema={schema}
        onSubmit={async (values)=>{
          try {

            let documents_attributes_array = []

            if(values.b_w_file){
              let b_w_obj={
                  document_name: values.b_w_file.file.name,
                  document_type: "bw_owner", // jpg, jpeg, webp,
                  description: "bw owner file",
                  document_reference_type: "PropertyOwner",
                  is_verified: true,
                  document_image:values.b_w_file.base64_string.split(",")[1],
                  content_type:values.b_w_file.file.type.split("/")[1]
                }
              documents_attributes_array.push(b_w_obj)
            }
            if(values.tracking_file){
                let tracking_obj={
                    document_name: values.tracking_file.file.name,
                    document_type: "tracking", // jpg, jpeg, webp,
                    description: "tracking file",
                    document_reference_type: "PropertyOwner",
                    is_verified: true,
                    document_image:values.tracking_file.base64_string.split(",")[1],
                    content_type:values.tracking_file.file.type.split("/")[1]
                  }
                documents_attributes_array.push(tracking_obj)
            }
            if(values.cancelled_cheque_file){
               let cancelled_obj={
                    document_name: values.cancelled_cheque_file.file.name,
                    document_type: "cancelled_check", // jpg, jpeg, webp,
                    description: "cancelled cheque file",
                    document_reference_type: "PropertyOwner",
                    is_verified: true,
                    document_image:values.cancelled_cheque_file.base64_string.split(",")[1],
                    content_type:values.cancelled_cheque_file.file.type.split("/")[1]
                  }
                documents_attributes_array.push(cancelled_obj)
            }
            if(values.ca_number_file){
              let ca_obj={
                document_name: values.ca_number_file.file.name,
                document_type: "ca_number", // jpg, jpeg, webp,
                description: "ca number file",
                document_reference_type: "PropertyOwner",
                is_verified: true,
                document_image:values.ca_number_file.base64_string.split(",")[1],
                content_type:values.ca_number_file.file.type.split("/")[1]
              }
              documents_attributes_array.push(ca_obj)
            }
            if(values.connect_number_file){
              let connection_obj={
                document_name: values.connect_number_file.file.name,
                document_type: "connection_number", // jpg, jpeg, webp,
                description: "connection number file",
                document_reference_type: "PropertyOwner",
                is_verified: true,
                document_image:values.connect_number_file.base64_string.split(",")[1],
                content_type:values.connect_number_file.file.type.split("/")[1]
              }
              documents_attributes_array.push(connection_obj)
            }

            const requestObj = {
                // id:91,
                property_id:savedPropertyId,
                owner_name:values.ownerName,
                rent_amount:values.rent,
                // bw_owners,
                // owner_gst,
                rent_increment_type:values.rentIncrement,
                rent_increment_type_value:values.rentShare,
                lockin_period:values.lockIn,
                // agreement_duration:values.aggrement_duration,
                agreement_start_date: values.startDate ? moment(values.startDate).format('YYYY-MM-DD') : "",
                agreement_end_date: values.endDate ? moment(values.endDate).format('YYYY-MM-DD') : "",
                rent_start_date:values.rentStartDate ? moment(values.rentStartDate).format('YYYY-MM-DD') : "",
                rent_free_period:values.rentFreePeriod ? moment(values.rentFreePeriod).format('YYYY-MM-DD') : "",
                is_msme:values.company_type === "msme" ? true : false,
                company_type:values.company_type,
                meta:{
                  account_number: values.bankAccount,
                  account_name: values.name,
                  ifsc_code: values.ifsc,
                  bank_name: values.bankName,
                  branch: values.branch
                },
                // electricity_provider:values.electricity,
                // electricity_provider_number,
                // water_supplier:values.water,
                // water_supplier_number
                documents_attributes:documents_attributes_array
            }
            // console.log("Handle submit values -- ",values)
      
            let response = await createPropertyOwner(requestObj);
            toast.success('Owner details has been saved successfully.');
          } catch (error) {
            console.log(error);
            toast.error('Something went wrong while adding owner details');
          }
        }}
        initialValues={{
          ownerName: '',
          rent: '',
          rentShare: '',
          rentIncrement: '',
          lockIn: '',
          startDate: '',
          endDate: '',
          rentStartDate: '',
          rentFreePeriod:'',
          company_type: '',
          bankAccount: '',
          ifsc: '',
          bankName: '',
          branch: '',
          name: '',
          electricity: '',
          caNumber: '',
          water: '',
          connctionNumber: '',
          aggrement_duration: '',
          b_w_file:'',
          tracking_file:'',
          cancelled_cheque_file:'',
          connect_number_file:'',
          ca_number_file:''
        }}
      >
        {({ handleSubmit, handleChange, handleBlur, isSubmitting, values, setFieldValue, touched, errors }) =>(
      <Form noValidate onSubmit={handleSubmit}>
        <div className="TrailWrap">
          <div>
            <Form.Label>Name</Form.Label>
            <Form.Control
              id="ownerName"
              type="text"
              placeholder="Enter your name"
              isInvalid={touched.ownerName && errors.ownerName}
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.ownerName}
              style={touched.ownerName && errors.ownerName ? {
                borderColor:'red'
              }: null}
            />
            <Form.Control.Feedback type="invalid">
                    {errors.ownerName}
            </Form.Control.Feedback>
          </div>

          <div>
            <Form.Label>Rent</Form.Label>
            <Form.Control
              id="rent"
              type="number"
              placeholder="₹  Enter Rent"
              // value={rent}
              // onChange={(e) => setRent(e.target.value)}
              isInvalid={touched.rent && errors.rent}
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.rent}
              style={touched.rent && errors.rent ? {
                borderColor:'red'
              }: null}
            />
             <Form.Control.Feedback type="invalid">
                    {errors.rent}
            </Form.Control.Feedback>
          </div>
          <div>
            <Form.Label>Rent share (In Percentage)</Form.Label>
            <Form.Control
              placeholder="%"
              // value={rentShare}
              // onChange={(e) => setRentShare(e.target.value)}
              id="rentShare"
              type="number"
              isInvalid={touched.rentShare && errors.rentShare}
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.rentShare}
              style={touched.rentShare && errors.rentShare ? {
                borderColor:'red'
              }: null}
            />
             <Form.Control.Feedback type="invalid">
                    {errors.rentShare}
            </Form.Control.Feedback>
          </div>
          <div>
            <Form.Label>Annual Rent Increment</Form.Label>
            {/* <Form.Control
              placeholder="₹ Enter Increment amount"
              value={rentIncrement}
              onChange={(e) => setRentIncrement(e.target.value)}
            /> */}
            <Form.Select 
            placeholder="Annual Rent Increment"
            id="rentIncrement"
            isInvalid={touched.rentIncrement && errors.rentIncrement}
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.rentIncrement}
            aria-label="Default select example">
              <option value="">Select Annual Rent Increment</option>
              <option value="1">One</option>
              <option value="2">Two</option>
              <option value="3">Three</option>
            </Form.Select>
            <Form.Control.Feedback type="invalid">
                    {errors.rentIncrement}
            </Form.Control.Feedback>
          </div>
        </div>
        <div className="TrailWrap-prop">
          {/* <div>
            <Form.Label>B/W Owner</Form.Label>
            <InputGroup className="mb-3">
              <Form.Control
                placeholder="Select your room list"
                aria-label="Amount (to the nearest dollar)"
              />
              <InputGroup.Text>BROWSE</InputGroup.Text>
            </InputGroup>
          </div> */}
          <div>
              <Form.Label>B/W Owner</Form.Label>
              <InputGroup className="mb-3">
                <Form.Control
                  aria-label="Amount (to the nearest dollar)"
                  value={values?.b_w_file?.file?.name}
                />
                <Form.Control
                  id="fileInput"
                  type="file"
                  ref={fileInputRef}
                  // accept=".xlsx,.xls,.csv"
                  accept=".csv"
                  style={{ display: "none" }}
                  onChange={(e)=>{
                    const file = e.target.files[0];
                    getBase64(file,(base64_string)=>{
                      setFieldValue("b_w_file",{
                        file,
                        base64_string
                      })
                    })
                  }}
                />
                <InputGroup.Text onClick={()=>fileInputRef.current.click()} className="pe-auto">
                  UPLOAD
                </InputGroup.Text>
                {/* <InputGroup.Text>BROWSE</InputGroup.Text> */}
              </InputGroup>
            </div>
          {/* <div>
            <Form.Label>Tracking</Form.Label>
            <InputGroup className="mb-3">
              <Form.Control
                placeholder="Select your room list"
                aria-label="Amount (to the nearest dollar)"
              />
              <InputGroup.Text>BROWSE</InputGroup.Text>
            </InputGroup>
          </div> */}
        </div>
        <div className="TrailWrap">
          <div>
            <Form.Label>Lock in</Form.Label>
            {/* <Form.Control
              placeholder="Enter lock in period"
              value={lockIn}
              onChange={(e) => setLockIn(e.target.value)}
            /> */}
            <Form.Control
              id="lockIn"
              type="number"
              placeholder="Enter lock in period"
              isInvalid={touched.lockIn && errors.lockIn}
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.lockIn}
              style={touched.lockIn && errors.lockIn ? {
                borderColor:'red'
              }: null}
            />
             <Form.Control.Feedback type="invalid">
                    {errors.lockIn}
            </Form.Control.Feedback>
          </div>
          <div className="form_control_box" style={{marginTop:5}}>
            <div className='modal_input_main dt_range_picker'>
                  <Form.Label>Agreement Period</Form.Label>
                    <ReactDatePicker
                        // selected={startDate}
                        selectsRange={true}
                        onChange={(date) => {
                          setStartDate(date[0]);
                          setEndDate(date[1]);
                  
                          setFieldValue('startDate', date[0]);
                          setFieldValue('endDate', date[1]);
                         }}
                        dateFormat={'dd/MM/yyyy'}
                        monthsShown={2}
                        startDate={values.startDate}
                        endDate={values.endDate}
                        id="notice-start-date"
                        placeholderText="DD/MM/YY - DD/MM/YY"
                        highlightDates={isDateInRange}
                        minDate={new Date()}
                    />
                    <Form.Control.Feedback type="invalid">
                            {errors.rentFreePeriod}
                    </Form.Control.Feedback>
            </div>
          </div>

          {/* <div>
            <Form.Label>Agreement Period</Form.Label>
            <Form.Control
              id="aggrement_duration"
              type="number"
              placeholder="Enter Aggrement Period"
              isInvalid={touched.aggrement_duration && errors.aggrement_duration}
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.aggrement_duration}
              style={touched.aggrement_duration && errors.aggrement_duration ? {
                borderColor:'red'
              }: null}
            />
             <Form.Control.Feedback type="invalid">
                    {errors.aggrement_duration}
            </Form.Control.Feedback>
          </div> */}

          {/* <div>
            <Form.Label>Tenure</Form.Label>
            <Form.Control
              placeholder="Enter tenure"
              value={tenure}
              onChange={(e) => setTenure(e.target.value)}
            />
          </div> */}
          <div className="form_control_box" style={{marginTop:5}}>
            <div className='modal_input_main'>
              <Form.Label>Rent Free Period</Form.Label>
              <ReactDatePicker
                placeholderText="DD/MM/YY"
                selected={values.rentFreePeriod}
                onChange={(date) => setFieldValue('rentFreePeriod', date)}
              />
              <Form.Control.Feedback type="invalid">
                      {errors.lockIn}
              </Form.Control.Feedback>
            </div>
          </div>
          {/* <div>
            <Form.Label>Rent Free Period</Form.Label>
            <Form.Control
              placeholder="Start date - End date"
              value={rentFreePeriod}
              onChange={(e) => setRentFreePeriod(e.target.value)}
            />
          </div> */}
          <div className="form_control_box" style={{marginTop:5}}>
            <div className='modal_input_main'>
              <Form.Label>Rent Start Date</Form.Label>
              <ReactDatePicker
                placeholderText="DD/MM/YY"
                selected={values.rentStartDate}
                onChange={(date) => setFieldValue('rentStartDate', date)}
              />
              <Form.Control.Feedback type="invalid">
                      {errors.lockIn}
              </Form.Control.Feedback>
            </div>
          </div>
          <div className="flex-track">
            <div className="form_control_box d-block">
              <label className="form-label">Company Type</label>
              <div className="d-flex flex-row">
                <div className="form-check-inline form-check">
                  <input
                    type="radio"
                    id="company_type"
                    name="company_type"
                    value={"individual"}
                    checked={values.company_type === "individual"}
                    onChange={handleChange}
                    className="form-check-input"
                    // onChange={formik.handleChange}
                  />
                  <label htmlFor="company_type" className="form-check-label">
                    Individual
                  </label>
                </div>
                <div className="form-check-inline form-check">
                  <input
                    type="radio"
                    id="company_type"
                    name="company_type"
                    value={"private_limited"}
                    checked={values.company_type === "private_limited"}
                    onChange={handleChange}
                    className="form-check-input"
                    // defaultChecked
                  />
                  <label htmlFor="company_type" className="form-check-label">
                    Private Limited
                  </label>
                </div>
                <div className="form-check-inline form-check">
                  <input
                    type="radio"
                    id="company_type"
                    name="company_type"
                    value={"partnership_firm"}
                    checked={values.company_type === "partnership_firm"}
                    onChange={handleChange}
                    className="form-check-input"
                    // defaultChecked
                  />
                  <label htmlFor="company_type" className="form-check-label">
                    Partnership Firm
                  </label>
                </div>
                <div className="form-check-inline form-check">
                  <input
                    type="radio"
                    id="company_type"
                    name="company_type"
                    value={"msme"}
                    checked={values.company_type === "msme"}
                    onChange={handleChange}
                    className="form-check-input"
                    // onChange={formik.handleChange}
                    defaultChecked
                  />
                  <label htmlFor="company_type " className="form-check-label">
                    MSME
                  </label>
                </div>
              </div>
            </div>
            {/* <div>
              <Form.Label>Tracking</Form.Label>
              <InputGroup className="mb-3">
                <Form.Control
                  aria-label="Amount (to the nearest dollar)"
                  value={fileName}
                />
                <Form.Control
                  id="fileInput"
                  type="file"
                  // ref={fileInputRef}
                  accept=".xlsx,.xls,.csv"
                  style={{ display: "none" }}
                  onChange={handleFileChange}
                />
                <InputGroup.Text onClick={handleBrowseClick} className="pe-auto">
                  UPLOAD
                </InputGroup.Text>
              </InputGroup>
            </div> */}
            <div>
              <Form.Label>Tracking</Form.Label>
              <InputGroup className="mb-3">
                <Form.Control
                  aria-label="Amount (to the nearest dollar)"
                  value={values.tracking_file?.file?.name}
                />
                <Form.Control
                  id="fileInput"
                  type="file"
                  ref={trackingFileRef}
                  accept=".csv"
                  style={{ display: "none" }}
                  onChange={(e)=>{
                    const file = e.target.files[0];
                    getBase64(file,(base64_string)=>{
                      setFieldValue("tracking_file",{
                        file,
                        base64_string
                      })
                    })
                  }}
                />
                <InputGroup.Text onClick={()=>trackingFileRef.current.click()} className="pe-auto">
                  UPLOAD
                </InputGroup.Text>
              </InputGroup>
            </div>
          </div>
        </div>
      {/* </Form> */}

      <h3 className="title">KYC</h3>
      {/* <Form> */}
        <div className="TrailWrap">
          <div>
            <Form.Label>Bank account number</Form.Label>
            {/* <Form.Control
              placeholder="xxxxx-xxxxx-xxxxx-xxxxx"
              value={bankAccount}
              onChange={(e) => setBankAccount(e.target.value)}
            /> */}
             <Form.Control
              id="bankAccount"
              type="number"
              placeholder="xxxxx-xxxxx-xxxxx-xxxxx"
              isInvalid={touched.bankAccount && errors.bankAccount}
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.bankAccount}
              style={touched.bankAccount && errors.bankAccount ? {
                borderColor:'red'
              }: null}
            />
             <Form.Control.Feedback type="invalid">
                    {errors.bankAccount}
            </Form.Control.Feedback>
          </div>
          <div>
            <Form.Label>IFSC Code</Form.Label>
            {/* <Form.Control
              placeholder="xxxxx-xxxxx"
              value={ifsc}
              onChange={(e) => setIfsc(e.target.value)}
            /> */}
            <Form.Control
              id="ifsc"
              type="text"
              placeholder="xxxxx-xxxxx"
              isInvalid={touched.ifsc && errors.ifsc}
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.ifsc}
              style={touched.ifsc && errors.ifsc ? {
                borderColor:'red'
              }: null}
            />
             <Form.Control.Feedback type="invalid">
                    {errors.ifsc}
            </Form.Control.Feedback>
          </div>
          <div>
            <Form.Label>Bank Name</Form.Label>
            {/* <Form.Control
              placeholder="Enter bank name here"
              value={bankName}
              onChange={(e) => setBankName(e.target.value)}
            /> */}
            <Form.Control
              id="bankName"
              type="text"
              placeholder="Enter bank name here"
              isInvalid={touched.bankName && errors.bankName}
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.bankName}
              style={touched.bankName && errors.bankName ? {
                borderColor:'red'
              }: null}
            />
            <Form.Control.Feedback type="invalid">
                    {errors.bankName}
            </Form.Control.Feedback>
          </div>
          <div>
            <Form.Label>Branch</Form.Label>
            {/* <Form.Control
              placeholder="Enter Branch name"
              value={branch}
              onChange={(e) => setBranch(e.target.value)}
            /> */}
            <Form.Control
              id="branch"
              type="text"
              placeholder="Enter Branch name"
              isInvalid={touched.branch && errors.branch}
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.branch}
              style={touched.branch && errors.branch ? {
                borderColor:'red'
              }: null}
            />
            <Form.Control.Feedback type="invalid">
                    {errors.branch}
            </Form.Control.Feedback>
          </div>
          <div>
            <Form.Label>Name as per bank records</Form.Label>
            {/* <Form.Control
              placeholder="Enter name as per bank records"
              value={name}
              onChange={(e) => setName(e.target.value)}
            /> */}
            <Form.Control
              id="name"
              type="text"
              placeholder="Enter name as per bank records"
              isInvalid={touched.name && errors.name}
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.name}
              style={touched.name && errors.name ? {
                borderColor:'red'
              }: null}
            />
            <Form.Control.Feedback type="invalid">
                    {errors.name}
            </Form.Control.Feedback>
          </div>
        </div>
        <div className="TrailWrap-prop">
          {/* <div>
            <Form.Label>Cancelled check</Form.Label>
            <InputGroup className="mb-3">
              <Form.Control
                placeholder="Upload cancelled check"
                aria-label="Amount (to the nearest dollar)"
              />
              <InputGroup.Text>BROWSE</InputGroup.Text>
            </InputGroup>
          </div> */}
          <div>
              <Form.Label>Cancelled check</Form.Label>
              <InputGroup className="mb-3">
                <Form.Control
                  aria-label="Amount (to the nearest dollar)"
                  value={values.cancelled_cheque_file?.file?.name}
                />
                <Form.Control
                  id="fileInput"
                  type="file"
                  ref={cancelledChequeRef}
                  accept=".jpg"
                  style={{ display: "none" }}
                  onChange={(e)=>{
                    const file = e.target.files[0];
                    getBase64(file,(base64_string)=>{
                      setFieldValue("cancelled_cheque_file",{
                        file,
                        base64_string
                      })
                    })
                  }}
                />
                <InputGroup.Text onClick={()=>cancelledChequeRef.current.click()} className="pe-auto">
                  UPLOAD
                </InputGroup.Text>
                {/* <InputGroup.Text>BROWSE</InputGroup.Text> */}
              </InputGroup>
            </div>
        </div>
      {/* </Form> */}

      <h3 className="title">UTILITIES</h3>
      {/* <Form> */}
        <div className="uti-div">
          {/* <div>
            <Form.Label>Electricity</Form.Label>
            <Form.Select 
              placeholder="Select your provider"
              id="electricity"
              isInvalid={touched.electricity && errors.electricity}
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.electricity}
              aria-label="Default select example">
                <option value="">Select your provider</option>
                <option value="Single">Single</option>
                <option value="Double">Double</option>
                <option value="Dormitory">Dormitory</option>
            </Form.Select>
            <Form.Control.Feedback type="invalid">
                    {errors.electricity}
            </Form.Control.Feedback>
          </div> */}
          {/* <div>
            <Form.Label>CA Number</Form.Label>
             <Form.Control
              id="caNumber"
              type="number"
              placeholder="xxxxx-xxxxx"
              isInvalid={touched.caNumber && errors.caNumber}
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.caNumber}
              style={touched.caNumber && errors.caNumber ? {
                borderColor:'red'
              }: null}
            />
             <Form.Control.Feedback type="invalid">
                    {errors.caNumber}
            </Form.Control.Feedback>
          </div> */}
          <div>
              <Form.Label>Electricity - CA Number</Form.Label>
              <InputGroup className="mb-3">
                <Form.Control
                  aria-label="Amount (to the nearest dollar)"
                  value={values.ca_number_file?.file?.name}
                />
                <Form.Control
                  id="fileInput"
                  type="file"
                  ref={caNumberRef}
                  // accept=".xlsx,.xls,.csv"
                  accept=".csv"
                  style={{ display: "none" }}
                  onChange={(e)=>{
                    const file = e.target.files[0];
                    getBase64(file,(base64_string)=>{
                      setFieldValue("ca_number_file",{
                        file,
                        base64_string
                      })
                    })
                  }}
                />
                <InputGroup.Text onClick={()=>caNumberRef.current.click()} className="pe-auto">
                  UPLOAD
                </InputGroup.Text>
                {/* <InputGroup.Text>BROWSE</InputGroup.Text> */}
              </InputGroup>
            </div>
          {/* <div>
            <Form.Label>Water</Form.Label>
            <Form.Select 
            placeholder="Select your provider"
            id="water"
            isInvalid={touched.water && errors.water}
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.water}
            aria-label="Default select example">
              <option value="">Select your provider</option>
              <option value="Single">Single</option>
              <option value="Double">Double</option>
              <option value="Dormitory">Dormitory</option>
            </Form.Select>
            <Form.Control.Feedback type="invalid">
                    {errors.water}
            </Form.Control.Feedback>
          </div> */}
          {/* <div>
            <Form.Label>Connection Number</Form.Label>
             <Form.Control
              id="connctionNumber"
              type="number"
              placeholder="xxxxx-xxxxx-xxxxx-xxxxx"
              isInvalid={touched.connctionNumber && errors.connctionNumber}
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.connctionNumber}
              style={touched.connctionNumber && errors.connctionNumber ? {
                borderColor:'red'
              }: null}
            />
             <Form.Control.Feedback type="invalid">
                    {errors.connctionNumber}
            </Form.Control.Feedback>
          </div> */}
           <div>
              <Form.Label>Water - Connection Number</Form.Label>
              <InputGroup className="mb-3">
                <Form.Control
                  aria-label="Amount (to the nearest dollar)"
                  value={values.connect_number_file?.file?.name}
                />
                <Form.Control
                  id="fileInput"
                  type="file"
                  ref={connectNumberRef}
                  accept=".xlsx,.xls,.csv"
                  // accept=".csv"
                  style={{ display: "none" }}
                  onChange={(e)=>{
                    const file = e.target.files[0];
                    getBase64(file,(base64_string)=>{
                      setFieldValue("connect_number_file",{
                        file,
                        base64_string
                      })
                    })
                  }}
                />
                <InputGroup.Text onClick={()=>connectNumberRef.current.click()} className="pe-auto">
                  UPLOAD
                </InputGroup.Text>
                {/* <InputGroup.Text>BROWSE</InputGroup.Text> */}
              </InputGroup>
            </div>
        </div>
        <div className="modal-footer">
          <button type="button" className="orange_brd_button p-12-16-button mr-3" >
            CANCEL
          </button>
          <button 
            type="submit"
            className="basic-button" 
            // onClick={CreateOwnerAndKyc}
          >
            SAVE
          </button>
        </div>
      </Form>
    )}
    </Formik>
    </div>
  );
}
