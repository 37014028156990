import { useEffect, useState } from 'react';
import '../../../css/onboarding-details-tab.css';
import { resentLink } from '../../../helpers/services/api.services';
import toast from "react-hot-toast";
import styles from "../../../pages/revenue/revenueBulkUploadPage/modals/UploadBulkModal/index.module.scss";
import { Modal } from "react-bootstrap";

function AgreementTab({ residentInfo, onboardingInfo }) {
    const [basicDetails, setBasicDetails] = useState(null);
    const [guardianDetails, setGuardianDetails] = useState(null);
    const [agreementStatus, setAgreementStatus] = useState({ signed_by_guardian: false, signed_by_resident: false });
    const [openErrorBox, setOpenErrorBox] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);
    const [resendLoader , setResendLoader] = useState(false)
    const [resendLoaderParent , setResendLoaderParent] = useState(false)

    useEffect(() => {
        // if (residentInfo && residentInfo?.basic_details) setBasicDetails(residentInfo?.basic_details);
        if (onboardingInfo) setBasicDetails(onboardingInfo);
        if (residentInfo && residentInfo?.guardian_details) setGuardianDetails(residentInfo?.guardian_details);

        if (onboardingInfo && onboardingInfo?.agreement_details) {
            setAgreementStatus(onboardingInfo?.agreement_details);
        }
    }, [residentInfo, onboardingInfo]);

    const handleResendLink = async (is_parent) => {
        if(is_parent){
            setResendLoaderParent(true)
          }else{
            setResendLoader(true)
          }
        try {
            setErrorMessage(null);
            setOpenErrorBox(false);
              let response = await resentLink(basicDetails?.resident_id,is_parent);
              console.log(response);
              if (response && response?.status === 'success') {
                await toast.success(`Link has been sent successfully.`);
              } else {
                setErrorMessage(response?.response?.data?.errors);
                setOpenErrorBox(true);
              }
              if(is_parent){
                setResendLoaderParent(false)
              }else{
                setResendLoader(false)
              }
          } catch (error) {
            if(is_parent){
                setResendLoaderParent(false)
              }else{
                setResendLoader(false)
              }
            console.log(error);
          }finally{
            if(is_parent){
              setResendLoaderParent(false)
            }else{
              setResendLoader(false)
            }
          }
    }

    return (
        <>
            <div className='mob_details_tab_box agreement_detail'>
                <div className="table_main_tit d-flex justify-content-between align-items-center  list-header-mob p-0 mb-3">
                    <div>
                        <h2>Current Agreements</h2>
                    </div>
                    <div>
                        <a
                            className="#"
                            href={residentInfo?.contract_details?.contract_url ? residentInfo?.contract_details?.contract_url : ''}
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{pointerEvents:residentInfo?.contract_details?.contract_url ? "" : "none"}}
                        >
                            <img src="/images/download_orange.svg" className="pr-1" alt="" />
                        </a>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-md-12'>
                        <div className='brd_box_card pd-24-20 agreement_details_data'>
                            <div className="img_round_icon_box"><img src="/images/Ellips1.png" alt="" /></div>
                            <h3>Resident Mail ID</h3>
                            <span>{basicDetails?.email ?? '--'}</span>
                            {/* <div className='row'>
                                <div className="col-6 sub_exit_data_txt">
                                    <h3>Generated On</h3>
                                    <span>--</span>
                                </div>
                                <div className="col-6 sub_exit_data_txt">
                                    <h3>Signed On</h3>
                                    <span>--</span>
                                </div>
                                <div className="col-6 sub_exit_data_txt">
                                    <h3>Verify Entity</h3>
                                    <span>--</span>
                                </div>
                            </div> */}
                            {/* {
                                !agreementStatus?.signed_by_resident && (
                                    <div className='sub_exit_data_txt pt-3'>
                                        <a href='#'>
                                            <img src="/images/join_link.svg" className="pr-1" alt="" /> RESEND LINK TO STUDENT
                                        </a>
                                    </div>
                                )
                            } */}
                             <div className="sub_exit_data_txt">
                                {
                                    agreementStatus?.signed_by_resident ? (
                                        <span className='agreement_badge'>Signed by resident</span>
                                    ) : (
                                        <button
                    className="custom_button border-0 bg-white"
                    onClick={() => handleResendLink(false)}
                    disabled={resendLoader || resendLoaderParent}
                  >
                    <img src="/images/join_link.svg" className="pr-1" alt="" />{" "}
                    {!resendLoaderParent && !resendLoader  &&  "RESEND LINK TO STUDENT"}
                    {!resendLoaderParent && resendLoader  &&  "Sending..."}
                     {resendLoaderParent && !resendLoader  &&  "Sending Parent agreement please wait"}
                  </button>
                                    )
                                }
                            </div>
                        </div>

                        {
                        residentInfo?.basic_details?.resident_type !== "working_professional" ?
                        <div className='brd_box_card pd-24-20 agreement_details_data'>
                            <div className="img_round_icon_box"><img src="/images/Ellips1.png" alt="" /></div>
                            <h3>Parent Mail ID</h3>
                            <span>{guardianDetails?.email ?? '--'}</span>
                            {/* <div className='row'>
                                <div className="col-6 sub_exit_data_txt">
                                    <h3>Generated On</h3>
                                    <span>--</span>
                                </div>
                                <div className="col-6 sub_exit_data_txt">
                                    <h3>Signed On</h3>
                                    <span>--</span>
                                </div>
                                <div className="col-6 sub_exit_data_txt">
                                    <h3>Verify Entity</h3>
                                    <span>--</span>
                                </div>
                            </div> */}
                            {/* {
                                !agreementStatus?.signed_by_guardian && (
                                    <div className='sub_exit_data_txt pt-3'>
                                        <a href='#'>
                                            <img src="/images/join_link.svg" className="pr-1" alt="" /> RESEND LINK TO PARENT
                                        </a>
                                    </div>
                                )
                            } */}
                            <div className="sub_exit_data_txt">
                                    {
                                        agreementStatus?.signed_by_guardian ? (
                                            <span className='agreement_badge'>Signed by parent</span>
                                        ) : (
                                            <button
                                            className="custom_button border-0 bg-white"
                                            onClick={() => handleResendLink(true)}
                                            disabled={resendLoader || resendLoaderParent}
                                          >
                                            <img
                                              src="/images/join_link.svg"
                                              className="pr-1"
                                              alt=""
                                            />{" "}
                                             {!resendLoaderParent && !resendLoader  &&  "RESEND LINK TO PARENT"}
                      {resendLoaderParent && !resendLoader  &&  "Sending..."}
                      {!resendLoaderParent && resendLoader  &&  "Sending Resident agreement please wait"}
                                          </button>
                                        )
                                    }
                            </div>
                        </div>
                        :
                        null
                        }

                    </div>
                </div>
            </div>
            {openErrorBox && (
        <>
          <Modal
            show={openErrorBox}
            onHide={() => setOpenErrorBox(false)}
            backdrop="static"
            keyboard={false}
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >
            {/* <Modal.Header closeButton>
              <Modal.Title id={styles["#modal-title"]}>
                {errorMessage}
              </Modal.Title>
            </Modal.Header> */}
            <div
              style={{
                padding: "7px",
                borderRadius: "8px",
                backgroundColor: "white",
                borderTop: "4px solid red",
                position : "relative"
              }}
            >
              <div className=" d-flex justify-content-between">
                <div style={{
                    margin : "20px"
                }}>
                    <h5><b>Error!</b></h5>
                    {errorMessage}</div>
                <div className="" style={{
                    position : "absolute",
                    right : 10,
                    top : 2
                }}>
                  {/* <img  src="/images/cross_modal.svg" /> */}
                  <button
                    className=" btn-close"
                    onClick={() => {
                      setErrorMessage(null);
                      setOpenErrorBox(false);
                    }}
                    style={{
                      height: "5px",
                      width: "5px",
                    }}
                  ></button>
                </div>
              </div>
            </div>
          </Modal>
        </>
      )}
        </>
    );

}

export default AgreementTab;
