import { useState } from "react";

function SosTab({ engagementStatus }) {

    const [activeStatus, setActiveStatus] = useState("All");

    const filteredEngagementData = async (status) => {
        setActiveStatus(status);

        // Logic for filter data
    };

    return (
        <>
            {/* Status */}
            <div className="d-flex justify-content-between align-items-center pb-32">
                <div className="status-box">
                    {engagementStatus.map((status, index) => (
                        <a
                            key={index}
                            href="#"
                            className={status?.label === activeStatus ? "active" : ""}
                            onClick={() => filteredEngagementData(status?.label)}
                        >
                            {status?.label}
                        </a>
                    ))}
                </div>
                <div className="status-box">
                    <a href="#">
                        <img src="/images/filter.svg" className="pr-1" alt="" /> Filter
                    </a>
                </div>
            </div>

            {/* Table */}
            <div className="sos_tab">
                <div className="page-table-box">
                    <table>
                        <thead>
                            <tr>
                                <th>Name</th>
                                <th>Start date</th>
                                <th>End date</th>
                                <th>Disable time</th>
                                <th>Disabled by</th>
                                <th>Student Ph</th>
                                <th>PM</th>
                                <th>Parent Ph</th>
                                <th>Last GPS link</th>
                                <th className="w120">Status</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>Courtney Henry David</td>
                                <td>25 Aug 2023</td>
                                <td>25 Aug 2023</td>
                                <td>Yes</td>
                                <td>Brooklyn Simmons</td>
                                <td>99999 99999</td>
                                <td>Brooklyn Simmons</td>
                                <td>99999 99999</td>
                                <td>
                                    <a href="https://google.com" target="_blank" className="clr_blue">Open link</a>
                                </td>
                                <td className="w120">
                                    <a className="bg_green_txt">
                                        Active
                                    </a>
                                    <a className="disable_btn" href="#">
                                        <img src="/images/bell-off.svg" className="pr-1" alt="" />  DISABLE
                                    </a>
                                </td>
                            </tr>
                            <tr>
                                <td>Courtney Henry David</td>
                                <td>25 Aug 2023</td>
                                <td>25 Aug 2023</td>
                                <td>Yes</td>
                                <td>Brooklyn Simmons</td>
                                <td>99999 99999</td>
                                <td>Brooklyn Simmons</td>
                                <td>99999 99999</td>
                                <td>
                                    <a href="https://google.com" target="_blank" className="clr_blue">Open link</a>
                                </td>
                                <td className="w120">
                                    <a className="bg_warning_txt">
                                        Inactive
                                    </a>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </>
    );
}

export default SosTab;