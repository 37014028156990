import { useEffect, useState } from 'react';
import '../../../css/resident-details-tab.css';
import { dateFormat } from '../../../helpers/common/common.function';

function ReferAFriendTab({ residentOnboardingInfo }) {

    const [referralDetails, setReferralDetails] = useState();

    useEffect(() => {
        if (residentOnboardingInfo && residentOnboardingInfo?.referral_details) {
            setReferralDetails(residentOnboardingInfo?.referral_details);
        }
    }, [residentOnboardingInfo]);

    return (
        <>
            <div className='mb-4'>
                <div className='d-flex small_main_box'>
                    <div className='small_box_data'>
                        <h2>Invite Sent</h2>
                        <span>{referralDetails?.invite_sent ?? 0}</span>
                    </div>
                    <div className='small_box_data'>
                        <h2>Amount Credited</h2>
                        <span>{referralDetails?.amount_credited ?? 0}</span>
                    </div>
                </div>
            </div>

            <div className='refer_table commen_table'>
                <div className='page-table-box'>
                    <table>
                        <thead>
                            <tr>
                                <th>Name</th>
                                <th>Phone number</th>
                                <th>Invite Date</th>
                                <th>Status</th>
                                <th className='text-right'>Amount</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                (referralDetails && (referralDetails?.referral_leads && referralDetails?.referral_leads.length > 0)) ?
                                    referralDetails?.referral_leads.map((item, index) => (
                                        <tr key={index}>
                                            <td>{item?.resident_name}</td>
                                            <td>{item?.mobile_number}</td>
                                            <td>{dateFormat(item?.date)}</td>
                                            <td>
                                                <span className="bg_green_txt">{item?.current_status}</span>
                                            </td>
                                            <td className='text-right'>{item?.amount}</td>
                                        </tr>
                                    )) : (
                                        <tr>
                                            <td colSpan={4}>No data found</td>
                                        </tr>
                                    )
                            }
                        </tbody>
                    </table>
                </div>
            </div>
        </>
    );

}

export default ReferAFriendTab;