import React, { useState } from "react";
import withDefaultDashBoardLayout from "../../components/layouts/withDefaultDashBoardLayout";
import TabsViewComponent from "./components/TabsViewComponent/index";
import styles from "./foodPage.module.scss";
import ManageMenu from "./components/ManageMenu";
import moment from "moment";
import BreadCrumb from "../../components/BreadCrumb";

const Food = () => {
  const year = moment().format("YY");
  const [label, setLabel] = useState("");

  return (
    <div className={styles["food-page"]}>
      {/* Header Starts*/}
      <div className="d-flex flex-column gap-8">
        <div className="d-flex align-items-center gap-8">
          <h1 className={styles["heading"] + " font-zkga text-gray-900 mb-0"}>
            {label}
          </h1>
          <div
            className={
              "bg-gray-100 d-flex align-items-center justify-content-center " +
              styles["heading-fy"]
            }
          >
            <p className="text-gray-600 font-manrope">BLS{year}</p>
          </div>
        </div>
        <div>
          <BreadCrumb
            seperator="/"
            list={[
              {
                label: "Food",
                link: "",
                active: false,
              },
              {
                label: label,
                link: "",
                active: true,
              },
            ]}
          />
        </div>
      </div>
      {/* Header Ends*/}

      <div className="mt-3 pt-3">
        <TabsViewComponent
          labelPropCallBack={(str) => { setLabel(str) }}
          defaultTabLabel={"Manage Menu"}
          tabs={[
            {
              label: "Production",
              component: <></>,
              show: false,
            },
            {
              label: "Consumption",
              component: <></>,
              show: false,
            },
            {
              label: "Packing",
              component: <></>,
              show: false,
            },
            {
              label: "Recipes",
              component: <></>,
              show: false,
            },
            {
              label: "Manage Menu",
              component: <ManageMenu />,
              show: true,
            },
          ]}
        />
      </div>
    </div>
  );
};

export default withDefaultDashBoardLayout(Food);
