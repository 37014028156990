import React from "react";
import { useEffect, useState } from "react";
import { getWebsiteWhyYourSpace } from "../../../helpers/services/websiteServices";
import { activateBtn } from "../../../pages/masters/EmployeList";
import DataTable from "react-data-table-component";
import ModalWhyYourSpace from "../../../pages/website/home-page/modals/ModalWhySpace";

const WhyYourSpace = () => {
  const [whySpaceConfigData, setwhySpaceConfigData] = useState([]);
  const [whySpaceMediaData, setwhySpaceMediaData] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [modalAction, setModalAction] = useState("");
  const [selectedIndex, setSelectedIndex] = useState("");
  const [additional_data, setAdditional_data] = useState({});

  const handler = () => {
    setOpenModal(!openModal);
  };

  const fetchwhySpaceData = async () => {
    try {
      const data = await getWebsiteWhyYourSpace();

      if (data.status == "success") {
        setwhySpaceConfigData(data.data.global_config_data.config_details);
        setwhySpaceMediaData(data.data.media_storages);
        setAdditional_data({
          id: data.data.global_config_data.id,
          name: data.data.global_config_data.name,
        });
      }
    } catch (error) {
      console.log("Error Fetching data ---> ", error);
    }
  };

  const columns = [
    {
      name: "Image",
      selector: (row) => row.image_tag,
      width: "20%",
      cell: (row, index) => (
        <>
          <img
            src={row.image}
            alt=""
            style={{
              height: "100px",
              width: "100px",
            }}
            className="py-1 "
          />
        </>
      ),
    },
    {
      name: "Description",
      selector: (row) => row.description,
      width: "40%",
      sortable: true,
    },
    {
      name: "Image Tag",
      selector: (row) => row.image_tag,
      width: "15%",
      sortable: true,
    },
    {
      name: "Order",
      selector: (row) => row.order,
      width: "10%",
      sortable: true,
    },
    {
      name: "Action",
      width: "10%",
      cell: (row, index) => (
        <img
          src="/images/edit.svg"
          alt=""
          className="p-3"
          onClick={() => {
            setModalAction("update");
            setSelectedIndex(index);
            setOpenModal(true);
          }}
        />
      ),
    },
    {
      name: "",
      width: "5%",
      cell: (row, index) => (
        <img
          src="/images/trash-03.png"
          alt=""
          className="p-3"
          onClick={() => {
            setSelectedIndex(index);
            setDeleteModal(true);
          }}
        />
      ),
    },
  ];

  useEffect(() => {
    fetchwhySpaceData();
  }, [deleteModal, selectedIndex, openModal]);
  return (
    <div>
      <div className="d-flex justify-content-end">
        <button
          className="basic-button"
          onClick={() => {
            setModalAction("add");
            setOpenModal(true);
          }}
        >
          Add New Data
        </button>
      </div>
      <div className="sr_data_table mt-4">
        <DataTable
          columns={columns}
          data={whySpaceConfigData}
          fixedHeader={true}
          fixedHeaderScrollHeight={"430px"}
        ></DataTable>
      </div>
      {/* <div className="bg-light">
        <hr />
        <div className="row">
          <div className="col-3">
            <b className="mx-2">Image</b>
          </div>
          <div className="col-8 text-center">
            <b>Details</b>
          </div>
          <div className="col-1">
            <b>Action</b>
          </div>
        </div>
        <hr />
      </div> */}
      {/* {whySpaceConfigData &&
        whySpaceConfigData.config_details?.map((data, index) => (
          <div className="p-2" key={index}>
            <div className="row">
              <div className="col-2">
                <img
                  src={data.image}
                  alt=""
                  style={{
                    height: "150px",
                    width: "150px",
                  }}
                />
              </div>
              <div className="col-9">
                <div className="d-flex">
                  <button style={activateBtn}>Image Tag : </button>
                  <div className="mx-3">
                    <b>{data.image_tag}</b>
                  </div>
                </div>
                <div className="d-flex mt-3">
                  <button style={activateBtn}>Description : </button>
                  <div className="mx-3">
                    <>{data.description}</>
                  </div>
                </div>
                <div className="d-flex mt-3">
                  <button style={activateBtn}>
                    Order : <b>{data.order}</b>
                  </button>
                </div>
              </div>
              <div className="col-1 d-flex justify-content-center align-items-center">
                <button className="" style={activateBtn}>
                  Edit
                </button>
              </div>
            </div>
            <hr />
          </div>
        ))} */}

      <ModalWhyYourSpace
        carouselConfigData={whySpaceConfigData}
        carouselMediaStorage={whySpaceMediaData}
        carousalIndex={selectedIndex}
        openModal={openModal}
        handler={handler}
        actionType={modalAction}
        deleteModal={deleteModal}
        deleteModalHandler={setDeleteModal}
        additional_data={additional_data}
      />
    </div>
  );
};

export default WhyYourSpace;
