import React from "react";
import { Modal } from "react-bootstrap";
import styles from "../UploadBulkModal/index.module.scss";
import { approveBulkUpload } from "../../../../../helpers/services/admin.services";
import toast from "react-hot-toast";

const Approval = ({ show, setShow, id, setSelectedId }) => {

    const handleSubmit = async(approval_method)=>{
        try {
            const response = await approveBulkUpload(id , approval_method)
            if( response.status == "Failure"  ){
                setShow(false)
                setSelectedId(null)
                return toast.error(response.errors)
            }else{
                setShow(false)
                setSelectedId(null)
                toast.success(`${approval_method} successfully`)
            }
        } catch (error) {
            console.log(error)
        }
    }

  return (
    <div>
      <Modal
        show={show}
        onHide={() => setShow(false)}
        backdrop="static"
        dialogClassName={styles["dialog-container"]}
        keyboard={false}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id={styles["#modal-title"]}>Action</Modal.Title>
        </Modal.Header>
        <div className={styles["body-content"]}>
          <div className="my-4 mx-3">
            <b>Are you sure want to perform this action ?</b>
          </div>
        </div>
        <Modal.Footer>
          <button className={"btn " + styles["btn-cancel"]} onClick={()=> handleSubmit("rejected")}>REJECT</button>
          <button className={"btn " + styles["btn-submit"] + " "} onClick={()=> handleSubmit("approved")}>
            APPROVE
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default Approval;
