import React, { useEffect, useState } from "react";
import MobileLeadModal from "../../components/customModals/MobileLeadModal";
import { verifyPropertyCode } from "../../helpers/services/admin.services";
import toast from "react-hot-toast";

const LeadCreation = () => {
  const [openModal, setOpenModal] = useState(false);
  const [code, setCode] = useState("");
  const [verified, setVerified] = useState(false);
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);

  const property_code = localStorage.getItem("property_code");

  const handleVerifyCode = async () => {
    setLoading(true);

    try {
      const response = await verifyPropertyCode(property_code || code);

      if (response.status == "success") {
        setVerified(true);
        setData(response.data);
        localStorage.setItem("property_code", property_code || code);
        toast.success("Code verified");
      } else {
        toast.error(response.data.errors[0]);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const handleReEnterCode = () => {
    localStorage.removeItem("property_code");
    setVerified(false);
    setCode("");
    setData("");
  };

  useEffect(() => {
    if (property_code && !verified) {
      setCode(property_code);
      handleVerifyCode();
    }
  }, [property_code]);

  return (
    <div style={{ height: "97vh", backgroundColor: "#ffffff", zIndex: "1" }}>
      <div
        className="d-flex align-items-center justify-content-center"
        style={{ paddingTop: "20vh", zIndex: "10" }}
      >
        <div>
          <div className="d-flex align-items-center justify-content-center">
            <img
              src="/images/logo.png"
              className="mb-3"
              alt=""
              style={{ width: "180px" }}
            />
          </div>
          <div style={{ marginTop: "65px" }}>
            <h5 className="">
              <b>{!verified ? "Sign-in" : ""}</b>
            </h5>
            <div style={{ position: "relative" }}>
              <input
                className="form-control "
                value={code}
                placeholder="Enter the property code "
                onChange={(e) => setCode(e.target.value)}
                disabled={verified}
                style={{ width: "280px" }}
              />

              {code.length > 0 && !verified && (
                <div
                  style={{
                    position: "absolute",
                    top: "8px",
                    right: "10px",
                    color: "#FF5700",
                    cursor: "pointer",
                  }}
                  onClick={handleVerifyCode}
                >
                  {loading ? (
                    <>
                      <div className="loader-small"></div>
                    </>
                  ) : (
                    <>
                      <b>Verify</b>
                    </>
                  )}
                </div>
              )}
              {property_code && verified && (
                <div
                  style={{
                    position: "absolute",
                    top: "8px",
                    right: "5px",
                    fontSize: "14px",
                    color: "#FF5700",
                    cursor: "pointer",
                  }}
                  onClick={handleReEnterCode}
                >
                  <b>Logout</b>
                </div>
              )}
            </div>
          </div>
          {/* {verified && data && <>
            <h5 className="text-white mt-4"><b>{data?.property_name}</b></h5>
          </>} */}
          {verified && (
            <button
              className="basic-button mt-3"
              onClick={() => setOpenModal(true)}
              style={{ width: "280px" }}
            >
              CREATE NEW LEAD
            </button>
          )}
        </div>
        {verified && (
          <div
            style={{ position: "absolute", bottom: "8px" , fontSize:"15px" }}
            className="text-center"
          >
            <span>
              <b style={{ textDecoration: "underline" }}>Note :</b> You are
              creating lead for <b>{data?.property_name}</b>
            </span>
          </div>
        )}
      </div>
      {openModal && (
        <MobileLeadModal
          setShowModal={setOpenModal}
          property={data}
          showModal={openModal}
        />
      )}
    </div>
  );
};

export default LeadCreation;
