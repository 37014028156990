import React from "react";
import moment from "moment";

const TicketHistory = ({ ticketHistory }) => {
  return (
    <div>
      <div className="scroll-bar-hide" style={{ height: "90vh", overflowY: "scroll" }}>
        {ticketHistory?.map((data, index) => (
          <div className="ledger_table commen_table my-4" key={index}>
            <div className="page-table-box">
              <table>
                <thead>
                  <tr>
                    <th>Task</th>
                    <th>Previous Action</th>
                    <th>New Action</th>
                    <th>Created By</th>
                    <th>Created at</th>
                  </tr>
                </thead>
                {data?.changeLogs?.map((chainData, chainIndex) => (
                  <tbody key={chainIndex}>
                    <tr>
                      <td scope="row"> {chainData?.fieldName} </td>
                      <td scope="row"> {chainData?.oldString} </td>
                      <td scope="row"> {chainData?.newString} </td>
                      <td scope="col">{data?.updatedBy?.displayName} </td>
                      <td scope="col">  {moment(data?.updatedOn).format("MMMM D, YYYY [at] h:mm A")} </td>
                    </tr>
                    
                  </tbody>
                ))}
              </table>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default TicketHistory;
