import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import toast from "react-hot-toast";
import { uploadImage } from "../../../../helpers/services/websiteServices";

const MediaStorageModal = ({
  mediaData,
  openModal,
  modalHandler,
  additional_data,
  data,
  setData,
}) => {
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredMediaData, setFilteredMediaData] = useState([]);
  const [file, setFile] = useState(null);
  const [media_name, setMedia_Name] = useState("");
  const [showInput, setShowInput] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);

  const uploadFile = async () => {
    try {
      const imageData = new FormData();
      imageData.append("media_image", file);
      imageData.append("media_name", media_name);
      imageData.append("media_type", additional_data.name);
      imageData.append("media_display_order", "1");
      imageData.append("media_reference_type", "GlobalConfig");
      imageData.append("media_reference_id", additional_data.id);
      imageData.append("is_active", "true");
      imageData.append("media_tag", "image");
      const response = await uploadImage(imageData);
      if (response?.status && response?.status == 422) {
        return toast.error(response.data.error);
      }
      setData((prevData) => ({
        ...prevData,
        image: response.data.media_url,
      }));
      modalHandler(false);
      console.log(response);
      return response;
    } catch (error) {
      console.log("errorrr", error);
    }
  };

  const handleFileChange = (e) => {
    setData((prevData) => ({
      ...prevData,
      image: null,
    }));

    const file = e.target.files[0];
    if (!file) return;
    setFile(file);
    const reader = new FileReader();
    reader.onload = () => {
      const imageUrl = reader.result;
      setSelectedImage(imageUrl);
    };
    reader.readAsDataURL(file);
  };

  const filterMediaData = () => {
    const filteredData = mediaData.filter((data) =>
      data.media_name.toLowerCase().includes(searchQuery.toLowerCase())
    );
    setFilteredMediaData(filteredData);
  };

  useEffect(() => {
    filterMediaData();
  }, [searchQuery, mediaData]);

  return (
    <div>
      <Modal
        show={openModal}
        onHide={() => {
          modalHandler(false);
        }}
        centered
        size="lg"
        className="common_modal"
      >
        <Modal.Header>
          <b>Select Image </b>
          <img
            src="/images/cross_modal.svg"
            className="cursor-pointer"
            alt="Close Icon"
            onClick={() => {
              modalHandler(false);
            }}
          />
        </Modal.Header>

        <Modal.Body>
          <div className="d-flex justify-content-between">
            <div>
              {data?.image && data?.image != "" && (
                <>
                  <div className="">
                    <img
                      src={data?.image}
                      alt=""
                      style={{
                        height: "70px",
                        width: "140px",
                        borderRadius: "5%",
                      }}
                    />
                  </div>
                </>
              )}
            </div>
            <div>
              <button
                className="basic-button"
                onClick={() => setShowInput(!showInput)}
              >
                New Image
              </button>
            </div>
          </div>

          {showInput && (
            <>
              <div className="form_control_box mt-3">
                <input
                  type="file"
                  id="image"
                  className="form-control mt-1"
                  name="image"
                  onChange={handleFileChange}
                />
              </div>

              <div className="form_control_box mt-3">
                <input
                  type="text"
                  id="city"
                  className="form-control my-2"
                  name="city"
                  placeholder="Enter Media Name"
                  onChange={(e) => setMedia_Name(e.target.value)}
                  value={media_name}
                />
              </div>
              {selectedImage && (
                <div className="form_control_box mt-3">
                  <img
                    src={selectedImage}
                    style={{
                      height: "300px",
                    }}
                    alt="Selected"
                    className="img-fluid"
                  />
                </div>
              )}
            </>
          )}

          <div className="form_control_box mt-3">
            <input
              type="text"
              id="search"
              className="form-control my-2"
              name="search"
              placeholder="Search "
              onChange={(e) => setSearchQuery(e.target.value)}
              value={searchQuery}
            />
          </div>

          {filteredMediaData?.length > 0 ? (
            <>
              <div className="row mb-2 mx-2">
                {filteredMediaData?.map((data, index) => (
                  <div className="col-3 mt-3 text-center" key={index}>
                    <img
                      src={data?.media_url}
                      alt=""
                      style={{
                        height: "70px",
                        width: "70px",
                        borderRadius: "50%",
                      }}
                      onClick={() => {
                        setData((prevData) => ({
                          ...prevData,
                          image: data?.media_url,
                        }));
                      }}
                    />
                    <div className="text-sm">
                      <b> {data?.media_name} </b>
                    </div>
                  </div>
                ))}
              </div>
            </>
          ) : (
            <>No Image Found</>
          )}
        </Modal.Body>
        <Modal.Footer>
          {showInput && selectedImage ? (
            <>
              <button className="basic-button" onClick={uploadFile}>
                Upload Image
              </button>
            </>
          ) : (
            <>
              <button
                className="basic-button"
                onClick={() => modalHandler(false)}
              >
                Change Image
              </button>
            </>
          )}
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default MediaStorageModal;
