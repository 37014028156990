import React, { useState } from "react";
import { Link } from "react-router-dom";
import moment from "moment";
import { Tab, Tabs } from "react-bootstrap";
import TicketHistory from "./TicketHistory";
import { Modal } from "react-bootstrap";

function decodeHtmlEntities(str) {
  // Decode HTML entities
  let decodedStr = str
    .replace(/\\u003c/g, "<")
    .replace(/\\u003e/g, ">")
    .replace(/\\u0022/g, '"')
    .replace(/\\u0027/g, "'")
    .replace(/\\u0026/g, "&")
    .replace(/\\u003d/g, "=");

  // Remove specific text if necessary
  const staticText =
    "Agent (Ticket Support) updated on behalf of the requester.";
  const index = decodedStr.indexOf(staticText);

  if (index !== -1) {
    decodedStr = decodedStr.substring(index + staticText.length).trim();
  }

  // Remove HTML tags
  decodedStr = decodedStr.replace(/<\/?[^>]+(>|$)/g, "");

  // Replace <br> tags and trim whitespace
  decodedStr = decodedStr
    .replace(/<br\s*\/?>/gi, "\n")
    .replace(/<p>\s*<\/p>/gi, "\n")
    .trim();

  return decodedStr;
}

const TicketResponsive = ({
  ticketDetail,
  handleStatusChange,
  notes,
  attachmentsDetails,
  setMessage,
  handleFileChange,
  handleModalSubmit,
  message,
  imageFile,
  submitLoader,
}) => {
  const [openReplyModal, setOpenReplyModal] = useState(false);

  return (
    <>
      <div className="main-content-mobile">
        <div className="mob_details_header table_main_tit d-flex justify-content-between align-items-center pb-20">
          <div>
            <h2>
              {" "}
              <Link to={"/tickets"}>
                <img src="/images/white_Back.svg" className="pr-2" alt="" />
              </Link>
              {ticketDetail?.customFields?.cf_name}{" "}
            </h2>
          </div>
          <div>
            <div className="details_right_top_part">{ticketDetail?.id}</div>
          </div>
        </div>
        <div className="mob_details_middle bg_white">
          <div className="main_tab">
            <Tabs
              defaultActiveKey="details"
              id="resident-details-view"
              className="mb-4"
            >
              <Tab eventKey="details" title="Details">
                <div
                  className=""
                  style={{ maxHeight: "600px", overflowY: "scroll" }}
                >
                  <div className="col-md-12">
                    <div className="brd_box_card pd-24-20">
                      <div className="guest_part">
                        <div className="d-flex align-items-center justify-content-between">
                          <div className="d-flex align-items-center">
                            <div className="">
                              <h1>{ticketDetail?.category?.name ?? "--"}</h1>
                              <p style={{ marginTop: "-13px" }}>
                                <small>
                                  {
                                    ticketDetail?.customFields?.cf_sub_category
                                      ?.name
                                  }
                                </small>
                              </p>
                              <div
                                className="d-flex align-items-center"
                                style={{ whiteSpace: "pre-wrap" }}
                              >
                                {/* <span>Description :</span> */}
                                <b>
                                  <div
                                    dangerouslySetInnerHTML={{
                                      __html: decodeHtmlEntities(
                                        ticketDetail?.description ?? "--"
                                      ),
                                    }}
                                  />
                                </b>
                              </div>
                            </div>
                          </div>
                          <div className="custom-single-select form_control_box ">
                            <label htmlFor="status-select">
                              {" "}
                              <b>Status</b>{" "}
                            </label>
                            <br />
                            {ticketDetail?.status?.description?.toLowerCase() ==
                            "closed" ? (
                              <>
                                <input
                                  type="text"
                                  value={ticketDetail?.status?.description}
                                  disabled
                                />
                              </>
                            ) : (
                              <>
                                <select
                                  id="status-select"
                                  value={
                                    ticketDetail?.status?.description
                                      ?.toLowerCase()
                                      .replace(/\s+/g, "_") ?? "--"
                                  }
                                  onChange={handleStatusChange}
                                  className="cursor-pointer"
                                  style={{ width: "100px" }}
                                >
                                  <option value="open">Open</option>
                                  <option value="in_progress">
                                    In Progress
                                  </option>
                                  <option value="waiting_for_customer">
                                    Waiting for Customer
                                  </option>
                                  <option value="on_hold">On Hold</option>
                                  <option value="solved">Solved</option>
                                  {/* <option value="closed">Closed</option> */}
                                </select>
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="info_box d-flex align-items-center justify-content-between">
                        <div>
                          <small>
                            <strong>
                              {moment(ticketDetail?.createdOn).format(
                                "MMMM D, YYYY [at] h:mm A"
                              )}
                            </strong>
                          </small>
                        </div>
                        <div
                          className="cursor-pointer mx-3 d-flex border px-3 py-1"
                          onClick={() => {
                            setOpenReplyModal(true);
                          }}
                          title="Reply ticket"
                          style={{ borderRadius: "5px" }}
                        >
                          <img
                            src="/images/reply_Icon.png"
                            alt=""
                            style={{ height: "18px", marginTop: "2px" }}
                            className="mr-2"
                          />
                          <div> Reply </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="brd_box_card mt-3 zindex9">
                    <div className="box_head">
                      <div className="d-flex align-items-center">
                        <h2>
                          {" "}
                          <img
                            src="/images/file-shield.svg"
                            className="pr-2"
                            alt=""
                          />{" "}
                          ATTACHMENTS
                        </h2>
                      </div>
                    </div>

                    <div className="exit_tab_box">
                      <div className="box_contain ">
                        <div className="sub_exit_box">
                          {attachmentsDetails?.map((attachmentData, index) => (
                            <div
                              className="d-flex align-items-center justify-content-between mb-4"
                              key={index}
                            >
                              <div>
                                <a
                                  href={attachmentData?.fileUrl}
                                  target="_blanks"
                                >
                                  {attachmentData?.name}
                                </a>
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="brd_box_card mt-3 zindex9">
                    <div className="box_head">
                      <div className="d-flex align-items-center">
                        <h2>
                          {" "}
                          <img
                            src="/images/file-shield.svg"
                            className="pr-2"
                            alt=""
                          />{" "}
                          NOTES
                        </h2>
                      </div>
                    </div>

                    <div className="exit_tab_box">
                      <div className="box_contain ">
                        <div className="sub_exit_box">
                          {notes?.map((notesData, index) => (
                            <div
                              className="mb-4"
                              key={index}
                              style={{ position: "relative" }}
                            >
                              <div className="text-end mb-2">
                                <small>
                                  <strong>
                                    {moment(notesData?.updatedOn).format(
                                      "MMMM D, YYYY [at] h:mm A"
                                    )}
                                  </strong>{" "}
                                </small>
                              </div>
                              <div style={{ whiteSpace: "pre-wrap" }}>
                                {decodeHtmlEntities(notesData?.description)}
                                <br />
                                <br />
                                {notesData?.attachments?.length > 0 && (
                                  <>
                                    {notesData?.attachments?.map(
                                      (attachmentData, index) => (
                                        <div
                                          className="d-flex align-items-center justify-content-between mb-4"
                                          key={index}
                                        >
                                          <div>
                                            <a
                                              href={attachmentData?.fileUrl}
                                              target="_blanks"
                                            >
                                              {attachmentData?.name}
                                            </a>
                                          </div>
                                        </div>
                                      )
                                    )}
                                  </>
                                )}
                              </div>
                              <hr />
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Tab>
              {/* <Tab eventKey="history" title="History">
                History
              </Tab> */}
            </Tabs>
          </div>
        </div>
      </div>

      {/* Reply Modal */}
      <Modal
        show={openReplyModal}
        onHide={() => setOpenReplyModal(false)}
        backdrop="static"
        className="check_popup_mobile"
        keyboard={false}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Reply</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className={""}>
            <div className="form_control_box">
              <input
                type="file"
                accept="image/*"
                onChange={handleFileChange}
                // value={imageFile}
                id="image-file-input"
                className=" form-control w-100"
              />
            </div>
            <div className="form_control_box">
              {/* <label htmlFor="note">Note Type</label>
              <select
                name="noteType"
                id="note"
                className="w-100 mb-2"
                value={noteType}
                onChange={(e) => setNoteType(e.target.value)}
              >
                <option value="Public">Public</option>
                <option value="Private">Private</option>
              </select> */}
              <textarea
                className="w-100 "
                placeholder="Enter your reply here..."
                value={message}
                onChange={(e) => setMessage(e.target.value)}
              />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="orange_brd_button p-2 mr-3"
            style={{ width: "fit-content" }}
            onClick={() => setOpenReplyModal(false)}
          >
            CANCEL
          </button>
          <button
            className={"basic-button"}
            onClick={handleModalSubmit}
            disabled={submitLoader || (!message && !imageFile)}
          >
            {submitLoader ? "SUBMITTING..." : "SUBMIT"}
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default TicketResponsive;
