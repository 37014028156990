export default {
    onboardingStatus: [
        { key: '', label: 'All onboardings' },
        { key: 'booking_tab', label: 'Booking' },
        { key: 'registration_tab', label: 'Registration' },
        { key: 'checkin_tab', label: 'Pending Check-Ins' },
        { key: 'verification_tab', label: 'Pending Assets Verification' },
        { key: 'completed_tab', label: 'Completed' }
    ]
};
