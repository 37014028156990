import { useState } from "react";
import { Modal } from "react-bootstrap";
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Select from "react-dropdown-select";
import ReactDatePicker from 'react-datepicker';

function NoticeTab({ engagementStatus }) {
    const [activeStatus, setActiveStatus] = useState("All");
    const [showNoticeModal, setShowNoticeModal] = useState(false);
    const [startDate, setStartDate] = useState();
    const [endDate, setEndDate] = useState();

    // Form Validations
    const validationSchema = Yup.object().shape({
        title: Yup.string().required('Title is required'),
        description: Yup.string().required('Description is required'),
        startDate: Yup.date().required('Start date is required'),
        endDate: Yup.date()
            .required('End date is required')
            .min(Yup.ref('startDate'), 'End date must be after start date'),
        markAsRead: Yup.string().required('Please select an option'),
        internalLink: Yup.object().required('Internal link is required'),
        function: Yup.object().required('Function is required'),
        visiblityLevel: Yup.object().required('Visibility level is required'),
    });

    const initialValues = {
        title: '',
        description: '',
        startDate: '',
        endDate: '',
        markAsRead: 'no',
        internalLink: {},
        function: {},
        visiblityLevel: {},
    };

    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit: (values) => {
            // Handle form submission here
            console.log(values);
        },
    });

    const handleDateChange = (date) => {
        setStartDate(date[0]);
        setEndDate(date[1]);

        formik.setFieldValue('startDate', date[0]);
        formik.setFieldValue('endDate', date[1]);
    };

    const isDateInRange = (date) => {
        if (!startDate || !endDate) return false;
        return date >= startDate && date <= endDate;
    };

    const handleCloseNoticeModal = () => setShowNoticeModal(false);
    const handleShowNoticeModal = () => setShowNoticeModal(true);

    const filteredEngagementData = async (status) => {
        setActiveStatus(status);

        // Logic for filter data
    };

    const handleReset = () => {
        formik.resetForm();
        handleCloseNoticeModal();
    };

    return (
        <>
            {/* Status */}
            <div className="d-flex justify-content-between align-items-center pb-32">
                <div className="status-box">
                    {engagementStatus.map((status, index) => (
                        <a
                            key={index}
                            href="#"
                            className={status?.label === activeStatus ? "active" : ""}
                            onClick={() => filteredEngagementData(status?.label)}
                        >
                            {status?.label}&nbsp;
                            <span className="red-badge" hidden={status?.totalCount === 0}>{status?.totalCount}</span>
                        </a>
                    ))}
                </div>
                <div className="status-box">
                    <a href="#">
                        <img src="/images/filter.svg" className="pr-1" alt="" /> Filter
                    </a>
                    <button
                        className="orange_brd_button p-12-16-button bg_btn_orange"
                        onClick={handleShowNoticeModal}
                    >
                        <img src="/images/plus_orange.svg" className="pr-1 mt--2" alt="" /> CREATE NOTICE
                    </button>
                </div>
            </div>

            {/* Table */}
            <div className="notice_tab">
                <div className="page-table-box">
                    <table>
                        <thead>
                            <tr>
                                <th>Title</th>
                                <th>Start date</th>
                                <th>End date</th>
                                <th>Disabled flag</th>
                                <th>Internal Redirection</th>
                                <th>Function</th>
                                <th>Created by</th>
                                <th>Visiblity level</th>
                                <th className="w120">Status</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>Water shortage</td>
                                <td>25 Aug 2023</td>
                                <td>25 Aug 2023</td>
                                <td>Yes</td>
                                <td>NA</td>
                                <td>CRM</td>
                                <td>Brooklyn Simmons</td>
                                <td>36% <span>of 360</span></td>
                                <td className="w120">
                                    <a className="bg_green_txt">
                                        Active
                                    </a>
                                </td>
                            </tr>
                            <tr>
                                <td>Water shortage</td>
                                <td>25 Aug 2023</td>
                                <td>25 Aug 2023</td>
                                <td>Yes</td>
                                <td>NA</td>
                                <td>CRM</td>
                                <td>Brooklyn Simmons</td>
                                <td>36% <span>of 360</span></td>
                                <td className="w120">
                                    <a className="bg_warning_txt">
                                        Inactive
                                    </a>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>

            {/* Modal */}
            <Modal
                show={showNoticeModal}
                onHide={handleCloseNoticeModal}
                centered
                size="lg"
                className="notice_modal commen_modal"
            >
                <Modal.Header>
                    <Modal.Title>Create Notice</Modal.Title>
                    <img src="/images/cross_modal.svg" className="cursor-pointor" alt="Close Icon" onClick={handleCloseNoticeModal} />
                </Modal.Header>
                <Modal.Body>
                    <form onSubmit={formik.handleSubmit}>
                        <div className="row">
                            <div className="col-12">
                                <div className="form_control_box">
                                    <label className="form-label" htmlFor="title">Title</label>
                                    <input
                                        type="text"
                                        id="title"
                                        name="title"
                                        placeholder="Enter notice title"
                                        className="form-control"
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.title}
                                    />
                                    {formik.touched.title && formik.errors.title ? (
                                        <div className="error-label">{formik.errors.title}</div>
                                    ) : null}
                                </div>
                            </div>

                            <div className="col-12">
                                <div className="form_control_box">
                                    <label className="form-label" htmlFor="description">Description</label>
                                    <textarea
                                        id="description"
                                        name="description"
                                        title="Add description"
                                        className="form-control"
                                        placeholder="Add Description"
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.description}
                                    />
                                    {formik.touched.description && formik.errors.description ? (
                                        <div className="error-label">{formik.errors.description}</div>
                                    ) : null}
                                </div>
                            </div>

                            <div className="col-4">
                                <div className="form_control_box">
                                    <div className='modal_input_main dt_range_picker'>
                                        <label className="form-label" htmlFor="startDate">Start Date</label>
                                        <ReactDatePicker
                                            selected={startDate}
                                            selectsRange={true}
                                            onChange={handleDateChange}
                                            dateFormat={'dd/MM/yyyy'}
                                            monthsShown={2}
                                            startDate={startDate}
                                            endDate={endDate}
                                            id="notice-start-date"
                                            placeholderText="DD/MM/YY"
                                            highlightDates={isDateInRange}
                                            minDate={new Date()}
                                        />
                                        {formik.touched.startDate && formik.errors.startDate ? (
                                            <div className="error-label">{formik.errors.startDate}</div>
                                        ) : null}
                                    </div>
                                </div>
                            </div>

                            <div className="col-4">
                                <div className="form_control_box">
                                    <div className='modal_input_main dt_range_picker'>
                                        <label className="form-label" htmlFor="endDate">End Date</label>
                                        <ReactDatePicker
                                            selected={endDate}
                                            selectsRange={true}
                                            onChange={handleDateChange}
                                            dateFormat={'dd/MM/yyyy'}
                                            monthsShown={2}
                                            startDate={startDate}
                                            endDate={endDate}
                                            id="notice-end-date"
                                            placeholderText="DD/MM/YY"
                                            highlightDates={isDateInRange}
                                            minDate={new Date()}
                                        />
                                        {formik.touched.endDate && formik.errors.endDate ? (
                                            <div className="error-label">{formik.errors.endDate}</div>
                                        ) : null}
                                    </div>
                                </div>
                            </div>

                            <div className="col-4">
                                <div className="form_control_box">
                                    <label className="form-label">Enable "Mark as Read" for residents</label>
                                    <div className="form-check-inline form-check">
                                        <input
                                            type="radio"
                                            id="radio-yes"
                                            name="markAsRead"
                                            value="yes"
                                            className="form-check-input"
                                            onChange={formik.handleChange}
                                        />
                                        <label htmlFor="radio-yes" className="form-check-label">Yes</label>
                                    </div>
                                    <div className="form-check-inline form-check">
                                        <input
                                            type="radio"
                                            id="radio-no"
                                            name="markAsRead"
                                            value="no"
                                            className="form-check-input"
                                            onChange={formik.handleChange}
                                            defaultChecked
                                        />
                                        <label htmlFor="radio-no" className="form-check-label">No</label>
                                    </div>
                                    {formik.touched.markAsRead && formik.errors.markAsRead ? (
                                        <div className="error-label">{formik.errors.markAsRead}</div>
                                    ) : null}
                                </div>
                            </div>

                            <div className="col-4 custom-single-select">
                                <label className="form-label" htmlFor="internalLink">Internal Link</label>
                                <Select
                                    id="internalLink"
                                    name="internalLink"
                                    placeholder="Search & select"
                                    options={[
                                        { value: 'option1', label: 'Option 1' },
                                        { value: 'option2', label: 'Option 2' },
                                    ]}
                                    value={formik.values.internalLink}
                                    onChange={(value) => formik.setFieldValue('internalLink', value)}
                                    onBlur={formik.handleBlur}
                                />
                                {/* <CustomSelect
                                        options={interLinksOptions}
                                        selectedOptions={formik.values.internalLink}
                                        setSelectedOptions={(value) => formik.setFieldValue('internalLink', value)}
                                        placeholder="Search & select"
                                    /> */}
                                {formik.touched.internalLink && formik.errors.internalLink ? (
                                    <div className="error-label">{formik.errors.internalLink}</div>
                                ) : null}
                            </div>
                            <div className="col-4 custom-single-select">
                                <label className="form-label" htmlFor="function">Function</label>
                                <Select
                                    id="function"
                                    name="function"
                                    placeholder="Select Function"
                                    options={[
                                        { value: 'crm', label: 'CRM' },
                                        { value: 'function1', label: 'Function 1' },
                                        { value: 'function2', label: 'Function 2' },
                                    ]}
                                    value={formik.values.function}
                                    onChange={(value) => formik.setFieldValue('function', value)}
                                    onBlur={formik.handleBlur}

                                />
                                {/* {formik.touched.function && formik.errors.function ? (
                                    <div className="error-label">{formik.errors.function}</div>
                                ) : null} */}
                            </div>

                            <div className="col-4 custom-single-select">
                                <label className="form-label" htmlFor="visiblityLevel">Visibility Level</label>
                                <Select
                                    id="visiblityLevel"
                                    name="visiblityLevel"
                                    placeholder="Select Visibility"
                                    options={[
                                        { value: 36, label: '36%' },
                                        { value: 50, label: '50%' },
                                        { value: 100, label: '100%' },
                                    ]}
                                    value={formik.values.visiblityLevel}
                                    onChange={(value) => formik.setFieldValue('visiblityLevel', value)}
                                    onBlur={formik.handleBlur}
                                />
                                {/* {formik.touched.visiblityLevel && formik.errors.visiblityLevel ? (
                                    <div className="error-label">{formik.errors.visiblityLevel}</div>
                                ) : null} */}
                            </div>

                            <div className="modal_btn_footer mt-4">
                                <button type="button" onClick={handleReset} className="orange_brd_button p-12-16-button mr-3">CANCEL</button>
                                <button type="submit" disabled={!formik.isSubmitting} className={!formik.isSubmitting ? 'basic-button bg_gray_button' : 'basic-button'}>CONFIRM</button>
                            </div>
                        </div>
                    </form>
                </Modal.Body>
            </Modal>
        </>
    );
}

export default NoticeTab;