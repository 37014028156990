import moment from "moment";
import { BASE_API_URL } from "../constants";
import apiClient from "./apiClient";

const BASE_CRM_DASHBOARD_URL = BASE_API_URL + "/crm_dashboard/v1/admin";


export const postBulkManualAdjustmentApi = async (body) => {
    try {
        return await apiClient.post(BASE_CRM_DASHBOARD_URL + "/create_manual_adjustment", body)
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                return error;
            });
    } catch (error) {
        console.error('Error fetching user data:', error);
        throw error;
    }
}

export const getManualAdjustmentEntriesApi = async () => {
    try {
        return await apiClient.get(BASE_CRM_DASHBOARD_URL + "/get_manual_adjustment")
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                return error;
            });
    } catch (error) {
        console.error('Error fetching user data:', error);
        throw error;
    }
}

export const updateManualAdjustmentEntryApi = async (id, body) => {
    try {
        return await apiClient.put(BASE_CRM_DASHBOARD_URL + "/" + id + "/approve_manual_adjustment", body)
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                return error;
            });
    } catch (error) {
        console.error('Error fetching user data:', error);
        throw error;
    }
}

export const getBulkUploadEntriesApi = async (type = '') => {
    try {
        let query = "";
        if (type) {
            query = "?by_file_type=" + type;
        }
        return await apiClient.get(BASE_CRM_DASHBOARD_URL + "/get_bulk_uploads" + query)
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                return error;
            });
    } catch (error) {
        console.error('Error fetching user data:', error);
        throw error;
    }
}

export const postBulkUploadApi = async (body) => {
    try {
        return await apiClient.post(BASE_CRM_DASHBOARD_URL + "/bulk_uploads", body)
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                return error;
            });
    } catch (error) {
        console.error('Error fetching user data:', error);
        throw error;
    }
}

export const getPDCEntriesApi = async (status,page=1,dateRange , resident_id) => {
    try {
        let query = "",subQuery="";
        if (status) {
            subQuery="by_status=" + status
            query += (query)?"&"+subQuery:"?"+subQuery
        }
        if(page){
            subQuery="page="+page;
            query += (query)?"&"+subQuery:"?"+subQuery
        }
        if(dateRange.startDate && dateRange.endDate){
            subQuery="start_date="+moment(dateRange.startDate).format("DD-MM-YYYY")+"&end_date="+moment(dateRange.endDate).format("DD-MM-YYYY");
            query += (query)?"&"+subQuery:"?"+subQuery
        }
        if(resident_id){
            subQuery="by_resident_id="+resident_id;
            query += (query)?"&"+subQuery:"?"+subQuery
        }
        return await apiClient.get(BASE_CRM_DASHBOARD_URL + "/resident_cheques" + query)
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                return error;
            });
    } catch (error) {
        console.error('Error fetching user data:', error);
        throw error;
    }
}

export const putPDCEntriesApi = async (body) => {
    try {
        return await apiClient.put(BASE_CRM_DASHBOARD_URL + "/update_resident_cheque", body)
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                return error;
            });
    } catch (error) {
        console.error('Error fetching data:', error);
        throw error;
    }
}


export const getDirectPayEntriesApi = async (page , search) => {
    try {
        let query = "",subQuery="";
        if(page){
            subQuery="page="+page;
            query += (query)?"&"+subQuery:"?"+subQuery
        }
        return await apiClient.get(BASE_CRM_DASHBOARD_URL + "/direct_payments" + query + "&employee_name=" + search)
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                return error;
            });
    } catch (error) {
        console.error('Error fetching user data:', error);
        throw error;
    }
}


export const getDirectPayLedgerEntriesApi = async (id,status="",page=1) => {
    try {
        let query = "",subQuery="";
        if(id){
            subQuery="by_direct_payment="+id;
            query += (query)?"&"+subQuery:"?"+subQuery
        }
        if(status){
            subQuery="by_status="+status;
            query += (query)?"&"+subQuery:"?"+subQuery
        }
        if(page){
            subQuery="page="+page;
            query += (query)?"&"+subQuery:"?"+subQuery
        }
        return await apiClient.get(BASE_CRM_DASHBOARD_URL + "/direct_payment_ledgers" + query)
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                return error;
            });
    } catch (error) {
        console.error('Error fetching user data:', error);
        throw error;
    }
}

export const putDirectPayLedgerEntryApi = async (body) => {
    try {
        return await apiClient.put(BASE_CRM_DASHBOARD_URL + "/direct_payment_ledger", body)
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                return error;
            });
    } catch (error) {
        console.error('Error fetching data:', error);
        throw error;
    }
}