import React from 'react'
import { Modal } from 'react-bootstrap'
import styles from './index.module.scss';
import InputBox from '../../../../../components/formInput/InputBox';
import InputSelect from '../../../../../components/formInput/InputSelect';

export default function SubmmitedPollModal({ dateRange, open, onClose, polls }) {
    return (
        <Modal
            dialogClassName={styles['submittedpoll-modal']}
            show={open}
            onHide={onClose}
            size="xl"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            backdrop="static"
            keyboard={false}
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    <p className='mb-0' dangerouslySetInnerHTML={{ __html: `Poll for ${dateRange?.start} - ${dateRange?.end} menu` }}></p>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className={styles['body-content']}>
                    {
                        (polls && polls?.length) ? (<>
                            {
                                polls.map((poll) => {
                                    return (<div className={styles['menu-content']} key={"SelectedPolls_" + poll.id}>
                                        <div className={styles['sub-division']}>
                                            <div className='row'>
                                                <div className='col-6'>
                                                    <InputBox
                                                        type="text"
                                                        label={"Menu Name"}
                                                        placeHolder={"Enter name"}
                                                        disabled
                                                        value={poll.name}
                                                    />
                                                </div>
                                                <div className='col-6'>
                                                    <InputSelect
                                                        label={"Property List"}
                                                        value={poll.properties}
                                                        isMulti
                                                        isDisabled
                                                        placeholder={"Search here"}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        {
                                            (poll.meals).map((mealInfo) => {
                                                if (mealInfo.courses.length === 0) {
                                                    return (<React.Fragment key={"Poll_" + poll.id + "_Meal_" + mealInfo.id}></React.Fragment>)
                                                }
                                                return (<div className={styles['sub-division']} key={"Poll_" + poll.id + "_Meal_" + mealInfo.id}>
                                                    <div className='row'>
                                                        <div className='col-3'>
                                                            <InputSelect
                                                                label={"Meal"}
                                                                isDisabled
                                                                name="meal"
                                                                value={{ value: mealInfo.id, label: mealInfo.name }}
                                                                options={[{ value: mealInfo.id, label: mealInfo.name }]}
                                                                placeholder={"Search meal"}
                                                            />
                                                        </div>
                                                        <div className='col-9'>
                                                            {
                                                                (mealInfo.courses).map((courseInfo) => {
                                                                    return (<div key={"Poll_" + poll.id + "_Meal_" + mealInfo.id + "_course_" + courseInfo.id} className={'row gap-y-10 mt-3 ' + styles['courses-section']}>
                                                                        <div className='col-4'>
                                                                            <InputBox
                                                                                type="text"
                                                                                label={"Course"}
                                                                                placeHolder={"Enter Course"}
                                                                                disabled
                                                                                value={courseInfo.name}
                                                                            />
                                                                        </div>
                                                                        <div className='col-8'>
                                                                            <InputSelect
                                                                                label={"Options for voting"}
                                                                                value={courseInfo.courseItems}
                                                                                isMulti
                                                                                isDisabled
                                                                                placeholder={"Search here"}
                                                                            />
                                                                        </div>
                                                                        <div className='col-4'>
                                                                            <InputBox
                                                                                label={"No.of votes per student"}
                                                                                type="text"
                                                                                placeHolder={"Enter votes"}
                                                                                disabled
                                                                                value={courseInfo.studentVotes}
                                                                            />
                                                                        </div>
                                                                    </div>)
                                                                })
                                                            }
                                                        </div>
                                                    </div>
                                                </div>)
                                            })
                                        }

                                    </div>)
                                })
                            }
                        </>) : (<></>)
                    }
                </div>
            </Modal.Body>
            <Modal.Footer>

            </Modal.Footer>
        </Modal>
    )
}
