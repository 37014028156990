import '../../../css/onboarding-details-tab.css';
import { useEffect } from 'react';
import { useState } from 'react';

function CollageContactsTab({ residentInfo, onboardingInfo }) {
    const [collegeContactsDetails, setCollegeContactsDetails] = useState(null);
    const [emergencyContacts, setEmergencyContactsDetails] = useState([]);

    useEffect(() => {
        setCollegeContactsDetails(onboardingInfo?.college_and_contacts?.college_data);

        if (onboardingInfo?.college_and_contacts && onboardingInfo?.college_and_contacts?.guardians && onboardingInfo?.college_and_contacts?.guardians.length > 0) {
            setEmergencyContactsDetails(onboardingInfo?.college_and_contacts?.guardians);
        }
    }, [residentInfo, onboardingInfo]);

    return (
        <>
            <div className='exit_tab_box collage_con'>
                <div className='row'>
                    <div className='col-md-6 zindex9'>

                        {/* COLLEGE DETAILS */}
                        {
                            onboardingInfo?.resident_type == "working_professional" ?
                                <div className='brd_box_card'>
                                    <div className="box_head">
                                        <h2><img src="/images/luggage-02.svg" className="pr-2" alt="" />COMPANY DETAILS</h2>
                                    </div>
                                    <div className="box_contain details_tab">
                                        <div className="d-flex justify-content-between  box_card_data">
                                            <h3>Company Name</h3>
                                            <i>:</i>
                                            <span>{collegeContactsDetails?.company_name ? collegeContactsDetails?.company_name : '--'}</span>
                                        </div>
                                        <div className="d-flex justify-content-between  box_card_data">
                                            <h3>Designation</h3>
                                            <i>:</i>
                                            <span>{collegeContactsDetails?.designation ? collegeContactsDetails?.designation : '--'}</span>
                                        </div>
                                        <div className="d-flex justify-content-between  box_card_data">
                                            <h3>Appointment letter/ID</h3>
                                            <i>:</i>
                                            {
                                                collegeContactsDetails?.document_url ? (
                                                    <span className='pdf_clr cursor-pointor'>
                                                        <a
                                                            href={collegeContactsDetails?.document_url}
                                                            download
                                                            target='_blank'
                                                        ><img src="/images/book.svg" className="pr-1" alt="Book" />{' '}Appointment Letter.pdf</a>
                                                    </span>
                                                ) : (
                                                    <span className='pdf_clr'>--</span>
                                                )
                                            }
                                        </div>
                                    </div>
                                </div>
                            :
                                <div className='brd_box_card'>
                                    <div className="box_head">
                                        <h2><img src="/images/graduation.svg" className="pr-2" alt="" /> COLLEGE ADDRESS</h2>
                                    </div>
                                    <div className="box_contain details_tab">
                                        <div className="d-flex justify-content-between  box_card_data">
                                            <h3>University</h3>
                                            <i>:</i>
                                            <span>{collegeContactsDetails?.college_name ? collegeContactsDetails?.college_name : '--'}</span>
                                        </div>
                                        <div className="d-flex justify-content-between  box_card_data">
                                            <h3>Course</h3>
                                            <i>:</i>
                                            <span>{collegeContactsDetails?.course_name ? collegeContactsDetails?.course_name : '--'}</span>
                                        </div>
                                        <div className="d-flex justify-content-between  box_card_data">
                                            <h3>ID card/Admission letter</h3>
                                            <i>:</i>
                                            {
                                                collegeContactsDetails?.document_url ? (
                                                    <span className='pdf_clr cursor-pointor'>
                                                        <a
                                                            href={collegeContactsDetails?.document_url}
                                                            download
                                                            target='_blank'
                                                        ><img src="/images/book.svg" className="pr-1" alt="Book" />{' '}ID-Card.pdf</a>
                                                    </span>
                                                ) : (
                                                    <span className='pdf_clr'>--</span>
                                                )
                                            }
                                        </div>
                                    </div>
                                </div>
                        }

                        {/* GUARDIAN DETAILS */}

                        {
                            emergencyContacts && emergencyContacts.length ? emergencyContacts.map((contact, index) => (
                                <div className='brd_box_card' key={index}>
                                    <div className="box_head">
                                        <h2> <img src="/images/user.svg" className="pr-2" alt="" /> EMERGENCY CONTACT - {index + 1} </h2>
                                    </div>
                                    <div className="box_contain details_tab">
                                        <div className="d-flex justify-content-between  box_card_data">
                                            <h3>Parent / Guardian name</h3>
                                            <i>:</i>
                                            <span>{contact?.full_name ? contact?.full_name : '--'}</span>
                                        </div>
                                        <div className="d-flex justify-content-between  box_card_data">
                                            <h3>Phone number</h3>
                                            <i>:</i>
                                            <span>{contact?.mobile_number ? contact?.mobile_number : '--'}</span>
                                        </div>
                                        <div className="d-flex justify-content-between  box_card_data">
                                            <h3>Email Id</h3>
                                            <i>:</i>
                                            <span>{contact?.email ? contact?.email : '--'}</span>
                                        </div>
                                    </div>
                                </div>
                            )) : ''
                        }
                    </div>
                </div>
                <div className='details_bg'><img src="/images/bg_tab_img.png" alt="" /></div>
            </div>
        </>
    );

}

export default CollageContactsTab;