import { useContext, useEffect, useRef, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import DataTable from 'react-data-table-component';
import Avatar from 'react-avatar';
import { Modal } from "react-bootstrap";
import Select from "react-dropdown-select";
import ReactDatePicker from 'react-datepicker';

import { fetchOnboardingList } from "../../helpers/services/api.services";
import OnboardingConstant from "../../helpers/constants/onboarding";
import withDefaultDashBoardLayout from "../../components/layouts/withDefaultDashBoardLayout";
import { dateFormat, getFiscalYearForListing } from "../../helpers/common/common.function";

import { addNewCity, updateCity } from '../../helpers/services/api.services'
import { Form } from "react-bootstrap";
import { useFormik } from 'formik';
import * as Yup from 'yup';
import toast from "react-hot-toast";
import { id } from "date-fns/locale";
import { fetchCities } from '../../helpers/services/admin.services'
import { width } from "@mui/system";
import MobileLeadModal from "../../components/customModals/MobileLeadModal";
import { NotificationIcon } from "../../components/notification/NotificationIcon";
import GlobalStateContext from "../../GlobalStateContext";
import Loader from "../../components/layouts/Loader";

function OnboardingList() {
    const navigate = useNavigate();
    const {activeResidentStatus, setActiveResidentStatus,tabKey, setTabKey, serachObject, setSerachObject, setCheckinCount,setVerificationCount} = useContext(GlobalStateContext);
    const list = useRef();

    const [showFilterModal, setShowFilterModal] = useState(false);

    const handleCloseFilterModal = () => setShowFilterModal(false);
    const handleShowFilterModal = () => {
        if (Object.keys(serachObject).length) {
            Object.keys(serachObject).map((key) => {
                formik.setFieldValue(key, serachObject[key])
            })
        }
        setShowFilterModal(true);
    }

    const [onboardingStatuses, setOnboardingStatuses] = useState([]);

    // Data-table
    const [onboardingRows, setOnboardingRows] = useState([]);
    const [loading, setLoading] = useState(false);
    const [totalRows, setTotalRows] = useState(0);
    const [page, setPage] = useState(1);
    const [sortObject, setSortObject] = useState({})
    const [cities, setCities] = useState([]);
    const [filterAcitveCount, setFilterAcitveCount] = useState(0)
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [showLeadModal, setShowLeadModal] = useState(false)

    useEffect(() => {
        setOnboardingStatuses(OnboardingConstant.onboardingStatus);
        loadCities()
    }, []);

    useEffect(() => {
        loadOnboardingData(1, serachObject, sortObject, rowsPerPage);
        }, [serachObject]);

    const loadCities = async () => {
        let response = await fetchCities();

        if (response && response.data && response.data.length > 0) {
            let cities = response.data.map(element => {
                return { value: element?.id, label: element?.city_name }
            });
            setCities(cities);
        }
    }

    const loadOnboardingData = async (currentPage, searchPayload, sortPayload, current_rowPerPage) => {
        setLoading(true);
        let response = await fetchOnboardingList(currentPage, searchPayload, sortPayload, current_rowPerPage);

        if (response && response?.status === "success" && response.data && response.data.length > 0) {
            setOnboardingRows(response.data);
            setCheckinCount(response?.meta?.checkin_lead_count)
            setVerificationCount(response?.meta?.verification_lead_count)

            if (response?.meta && response?.meta?.total_data_count && response?.meta?.total_pages) {
                setTotalRows(response?.meta?.total_data_count);
            }
            setPage(currentPage);
            handleCloseFilterModal();
            setLoading(false);
        } else {
            setOnboardingRows([]);
            handleCloseFilterModal();
            setLoading(false);
        }
    }

    const filteredResidentsData = async (status) => {
        setActiveResidentStatus(status);

        // Logic for filter onboardingRows data
    }

    const handleDetailsPageRedirection = (data) => {
        navigate(`/onboarding-details/${data?.id}`);
    }

    const getRandomColor = () => {
        return Math.floor(Math.random() * 16777215).toString(16);
    }

    const columns = [
        {
            name: 'Name',
            selector: (row) => row.resident_name,
            width: "20%",
            cell: row => (
                <div>
                    <Avatar
                        name={row?.resident_name ? row.resident_name.charAt(0) : ''}
                        color={`#${getRandomColor()}`}
                        round={true}
                        size="24"
                        textBackground="transparent"
                        textColor="#ffffff"
                    />
                    <span className="user_name">{row?.resident_name}</span>
                </div>
            ),
            sortable: true,
            sortField: "resident_name",
        },
        {
            name: 'CRM Lead ID',
            selector: (row) => row.id,
            sortable: true,
            sortField: "id",
        },
        {
            name: 'LMS Lead ID',
            selector: (row) => row?.lms_lead_id ?? 'N/A',
            // sortable: true,
        },
        {
            name: 'City',
            // selector: (row) => row.city_name?.city_code,
            selector: (row) => row.city_name?.city_name,
            // sortable: true,
            // sortField:"city_name"
        },
        {
            name: 'Property',
            selector: (row) => row?.property_details?.property_code,
            // sortable: true,
            // sortField:"property_code"
        },
        // {
        //     name: 'Sales POC',
        //     selector: (row) => row?.property_details?.property_name?.sales_poc_details?.employee_first_name,
        //     sortable: true,
        // },
        // {
        //     name: 'Ph no.',
        //     selector: (row) => row?.mobile_number,
        //     sortable: true,
        //     sortField:"mobile_number"
        // },
        {
            name: 'Cluster Name',
            selector: (row) => row?.cluster_name,
            // sortable: true,
            // sortField:"cluster_name"
        },
        {
            name: 'Check-in date',
            selector: (row) => row?.moving_date,
            sortable: true,
            sortField: "moving_date"
        },
        {
            name: 'Lead Status',
            width: "15%",
            selector: (row) => row?.basic_details_status,
            cell: row => (
                <span className="success_status_badge">{row?.lead_status}</span>
            ),
            // sortable: true,
            // sortField:"basic_details_status"
        },
        {
            name: '',
            selector: (row) => row?.id,
            width: "3%",
            cell: row => (
                <Link to={`/onboarding-details/${row?.id}`}>
                    <img src="/images/right_arrow.svg" alt="Details Icon" />
                </Link>
            )
        },
    ];

    const handlePageChange = (page) => {
        loadOnboardingData(page, serachObject, sortObject, rowsPerPage);
    };

    const onScroll = async () => {
        if (list.current) {
            const { scrollTop, scrollHeight, clientHeight } = list.current;
            let totalHeight = scrollTop + clientHeight;

            if (Math.round(totalHeight) === scrollHeight) {
                let currentPage = page + 1;
                setPage(currentPage);

                await onScrollFetchOnboardingData(currentPage);
            }
        }
    };

    const onScrollFetchOnboardingData = async (currentPage) => {
        let response = await fetchOnboardingList(currentPage, serachObject, sortObject);

        if (response && response?.status === "success" && response.data && response.data.length > 0) {
            setOnboardingRows(onboardingRows => [...onboardingRows, ...response.data]);
            setCheckinCount(response?.meta?.checkin_lead_count)
            setVerificationCount(response?.meta?.verification_lead_count)
        }
    }

    const handleSort = async (column, sortDirection) => {
        let sort_obj = {
            sort_by: column.sortField,
            sort_direction: sortDirection
        }
        if (column.sortField) {
            setSortObject(sort_obj)
            loadOnboardingData(page, serachObject, sort_obj, rowsPerPage);
        }
    };


    const initialValues = {
        email: "",
        name: "",
        resident_id: "",
        // moving_date: "",
        mobile_number: "",
        property_code: "",
        crm_lead_id: "",
        city_id: "",
        // checkin_date:"",
        start_checkin_date: "",
        end_checkin_date: ""
    }

    const validationSchema = null

    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit: async (values) => {

            let search_obj = JSON.parse(JSON.stringify(values))
            const has_value = Object.values(values).some(val => val ? true : false)
            Object.keys(values).map((key) => {
                if (!values[key]) {
                    delete search_obj[key]
                }
            })
            if (has_value) {
                setSerachObject(search_obj);
                setFilterAcitveCount(Object.keys(search_obj).length)
            }
        },
    });

    const handleClickFilterClose = (e) => {
        e.stopPropagation()
        setSerachObject({})
        setFilterAcitveCount(0)
        formik.resetForm();
    }

    const handleRowsPerPageChange = (currentRowsPerPage, currentPage) => {
        setPage(currentPage)
        setRowsPerPage(currentRowsPerPage)
        loadOnboardingData(currentPage, {}, sortObject, currentRowsPerPage);
    }

    const handleResetFilter = (e) => {
        formik.setValues({ ...initialValues });
    }

    return (
        <>
            <div className="main-content-web">
                <div className="breadcumb-header">
                    <div className="d-flex justify-content-between align-items-center pb-2">


                        <div>
                            <div className="d-flex pb-2">
                                <h2>Onboarding - <span className="year-text">{getFiscalYearForListing()}</span></h2>
                            </div>
                            <div className="breadcum">
                                <ul>
                                    <li><a href="#">Tenants</a> <span>/</span></li>
                                    <li>Onboarding</li>
                                </ul>
                            </div>
                        </div>
                        <div className="create-prop d-flex align-items-center gap-4">
                            <button className="basic-button" onClick={() => setShowLeadModal(!showLeadModal)}>
                                <img
                                    src="/images/plus-circle.svg"
                                    className="pr-2"
                                    alt=""
                                />
                                New Lead
                            </button>
                            <NotificationIcon />
                        </div>
                    </div>
                </div>

                <div className="d-flex justify-content-between align-items-center pb-32">
                    <div className="status-box">
                        {
                            onboardingStatuses.map((status, index) => <a
                                key={index}
                                className={(status?.label === activeResidentStatus) ? 'active cursor-pointer' : 'cursor-pointer'}
                                onClick={() => {
                                    let search_obj = { ...serachObject, status: status.key }
                                    setSerachObject(search_obj)
                                    setTabKey(status.key)
                                    filteredResidentsData(status?.label);
                                }}
                            >{status?.label}</a>)
                        }
                    </div>
                    <div className="status-box">
                        <a
                            onClick={handleShowFilterModal}
                            className="cursor-pointor"
                            style={{
                                display: "flex",
                                direction: 'row'
                            }}
                        >
                            <img src="/images/filter.svg" className="pr-1" alt="" /> Filter
                            {
                                filterAcitveCount > 0 ?
                                    <>
                                        <div
                                            style={{
                                                width: 22,
                                                height: 22,
                                                backgroundColor: '#FF5700',
                                                borderRadius: "50%",
                                                marginLeft: 4,
                                                color: '#FFF4ED',
                                                textAlign: 'center',
                                                justifyContent: 'center',
                                                font: 'Manrope',
                                                fontSize: 11,
                                                fontStyle: 'normal',
                                                fontWeight: 800,
                                                letterSpacing: '0.88px',
                                            }}
                                        >
                                            {filterAcitveCount}
                                        </div>
                                        <button
                                            style={{
                                                background: 'none',
                                                border: 'none',
                                            }}
                                            onClick={handleClickFilterClose}
                                        >
                                            <img src="/images/close_with_bg.svg" className="pr-1" alt="" />
                                        </button>
                                    </>
                                    : null
                            }
                        </a>
                    </div>
                </div>

                {/*
                    Status Class Names ->
                    Success: bg_green_txt
                    Warning: bg_warning_txt
                    Error: bg_red_txt
                */}

                <div className="sr_data_table">
                    <DataTable
                        columns={columns}
                        data={onboardingRows}
                        progressPending={loading}
                        pagination
                        paginationServer
                        paginationTotalRows={totalRows}
                        onChangePage={handlePageChange}
                        fixedHeader={true}
                        fixedHeaderScrollHeight={'430px'}
                        onRowClicked={handleDetailsPageRedirection}
                        onSort={handleSort}
                        sortServer
                        onChangeRowsPerPage={handleRowsPerPageChange}
                    ></DataTable>
                </div>
            </div>

            {/* RESPONSIVE UI */}
            <div className="main-content-mobile">
                {/* =========================== */}
                <div className="table_main_tit d-flex justify-content-between align-items-center  list-header-mob">
                    <div>
                        <span className="header-text">Onboarding</span>
                        <h5>Total <b>{totalRows}</b> Residents</h5>
                    </div>
                    <div className="status-box d-flex">
                        <a onClick={() => handleShowFilterModal()} className="d-flex">
                            <img src="/images/filter-orange.svg" className="pr-1" alt="" />
                            <span style={{ marginTop: filterAcitveCount > 0 ? 3 : 0 }}>Filter</span>
                            {
                                filterAcitveCount > 0 ?
                                    <>
                                        <div
                                            style={{
                                                width: 20,
                                                height: 20,
                                                backgroundColor: '#FF5700',
                                                borderRadius: "50%",
                                                marginLeft: 4,
                                                color: '#FFF4ED',
                                                textAlign: 'center',
                                                justifyContent: 'center',
                                                font: 'Manrope',
                                                fontSize: 11,
                                                fontStyle: 'normal',
                                                fontWeight: 800,
                                                letterSpacing: '0.88px',
                                                marginTop: 3,
                                            }}
                                        >
                                            {filterAcitveCount}
                                        </div>
                                        <button
                                            style={{
                                                background: 'none',
                                                border: 'none',
                                            }}
                                            onClick={handleClickFilterClose}
                                        >
                                            <img src="/images/close_with_bg.svg" className="pr-1" alt="" />
                                        </button>
                                    </>
                                    : null
                            }
                        </a>

                        <NotificationIcon />
                    </div>
                </div>
                {/* =========================== */}
                <div
                    className="list_scroll_mob"
                    onScroll={() => onScroll()}
                    ref={list}
                >
                    {
                        loading ?
                        <>
                        <Loader />
                        </> :
                        <>
                        {
                        (onboardingRows && onboardingRows.length > 0) ?
                            onboardingRows?.map((row, index) => (
                                <Link to={`/onboarding-details/${row?.id}`} key={index + '_resp'}>
                                    <div className="brd_box_card pd-20-16 list_main_mob">
                                        {/* =========================== */}
                                        <div className="d-flex justify-content-between align-items-center list_brd_btm_mob">
                                            <div className="d-flex align-items-center">
                                                <div className="img_round_icon_box">
                                                    <img src="/images/Ellips1.png" alt="" />
                                                </div>
                                                <div className="mob_list_data">
                                                    <h2>{row?.resident_name}</h2>
                                                    <h4>{row?.id}</h4>
                                                </div>
                                            </div>
                                            <div>
                                                <span className="bg_green_txt">{row?.lead_status}</span>
                                            </div>
                                        </div>
                                        {/* =========================== */}
                                        <div className="row">
                                            <div className="col">
                                                <div className="mob-list-sub-data">
                                                    <h5>Property</h5>
                                                    <h3>{row?.property_details?.property_code}</h3>
                                                </div>
                                            </div>
                                            <div className="col">
                                                <div className="mob-list-sub-data">
                                                    <h5>Sales SPOC</h5>
                                                    <h3>{row?.property_details?.sales_poc_details?.employee_first_name || row?.property_details?.sales_poc_details?.employee_last_name ?
                                                        `${row?.property_details?.sales_poc_details?.employee_first_name} ${row?.property_details?.sales_poc_details?.employee_last_name}`
                                                        :
                                                        "--"}</h3>
                                                </div>
                                            </div>
                                            <div className="col">
                                                <div className="mob-list-sub-data">
                                                    <h5>Check in date</h5>
                                                    <h3>{dateFormat(row?.moving_date)}</h3>
                                                </div>
                                            </div>
                                            {
                                                row?.lead_status === 'contract_signed' || row?.lead_status === 'final_amount_paid' ?
                                                    <div className="col-12 pt-4">
                                                        <button
                                                            className="orange_brd_button p-16-20-button"
                                                            onClick={(e) => {
                                                                e.preventDefault()
                                                                navigate(`/onboarding-details/${row?.id}`, {
                                                                    state: {
                                                                        direct_checkin: true
                                                                    }
                                                                });
                                                            }}
                                                        >
                                                            <img
                                                                src="/images/log-in-03.svg"
                                                                className="pr-2"
                                                                alt=""
                                                            />CHECK IN RESIDENT
                                                        </button>
                                                    </div>
                                                    : null
                                            }
                                        </div>
                                        {/* =========================== */}
                                    </div>
                                </Link>
                            )
                            ) : (
                                <div>
                                    <h4>No records found</h4>
                                </div>
                            )
                    }
                        </>
                    }
                </div>
            </div>

            {/* FILTER MODAL */}
            <Modal
                show={showFilterModal}
                onHide={handleCloseFilterModal}
                centered
                size='lg'
                className="commen_modal"
            >
                <Modal.Header>
                    <Modal.Title>Filters</Modal.Title>
                    <img src="/images/cross_modal.svg" className="cursor-pointor" alt="Close Icon" onClick={handleCloseFilterModal} />
                </Modal.Header>
                <Modal.Body>
                    <form onSubmit={formik.handleSubmit}>
                        <div className="row">
                            <div className="col-md-6 col-lg-4 col-sm-6">
                                <div className="form_control_box">
                                    <label className="form-label" htmlFor="residentName">Resident Name</label>
                                    <input
                                        type="text"
                                        id="residentName"
                                        name="residentName"
                                        placeholder="Enter Name"
                                        className="form-control"
                                        value={formik.values.name}
                                        onChange={(e) => formik.setFieldValue('name', e.target.value)}
                                    />
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-4 col-sm-6">
                                <div className="form_control_box">
                                    <label className="form-label" htmlFor="crmLeadId">CRM Lead ID</label>
                                    <input
                                        type="text"
                                        id="crmLeadId"
                                        name="crmLeadId"
                                        placeholder="Enter CRM Lead ID"
                                        className="form-control"
                                        value={formik.values.crm_lead_id}
                                        onChange={(e) => formik.setFieldValue('crm_lead_id', e.target.value)}
                                    />
                                </div>
                            </div>

                            <div className="col-md-6 col-lg-4 col-sm-6">
                                <div className="form_control_box">
                                    <label className="form-label" htmlFor="emailId">Email ID</label>
                                    <input
                                        type="email"
                                        id="emailId"
                                        name="emailId"
                                        placeholder="Enter Email ID"
                                        className="form-control"
                                        value={formik.values.email}
                                        onChange={(e) => formik.setFieldValue('email', e.target.value)}
                                    />
                                </div>
                            </div>

                            <div className="col-md-6 col-lg-4 col-sm-6">
                                <div className="form_control_box">
                                    <label className="form-label" htmlFor="phoneNumber">Resident Phone Number</label>
                                    <input
                                        type="number"
                                        id="phoneNumber"
                                        name="phoneNumber"
                                        placeholder="Enter Phone number"
                                        className="form-control"
                                        value={formik.values.mobile_number}
                                        onChange={(e) => formik.setFieldValue('mobile_number', e.target.value)}
                                    />
                                </div>
                            </div>
                            {/* <div className="col-md-6 col-lg-4 col-sm-6 custom-single-select mb-4">
                                <label className="form-label" htmlFor="residentType">Student / Working Professional</label>
                                <Select
                                    id="residentType"
                                    name="residentType"
                                    placeholder='Select Resident type'
                                    options={[
                                        { value: 1, label: 'NRI' },
                                        { value: 2, label: 'Local' },
                                        { value: 3, label: 'Other State' },
                                    ]}
                                />
                            </div> */}
                            {/* <div className="col-md-6 col-lg-4 col-sm-6">
                                <div className="form_control_box">
                                    <label className="form-label" htmlFor="residentWorkplace">Resident College / Workplace</label>
                                    <input
                                        type="text"
                                        id="residentWorkplace"
                                        name="residentWorkplace"
                                        placeholder="Enter College / Workplace"
                                        className="form-control"
                                    />
                                </div>
                            </div> */}

                            <div className="custom-single-select col-md-6 col-lg-4 col-sm-6">
                                <Form.Label>City</Form.Label>
                                <Form.Select
                                    placeholder="Select City"
                                    id="city_id"
                                    isInvalid={formik.touched.city_id && formik.errors.city_id}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.city_id}
                                    aria-label="Default select example">
                                    <option value="">Select City</option>
                                    {
                                        cities.map(city => <>
                                            <option value={city.value}>{city.label}</option>
                                        </>)
                                    }
                                </Form.Select>
                            </div>
                            {/* <div className="col-md-6 col-lg-4 col-sm-6">
                                <div className="form_control_box">
                                    <label className="form-label" htmlFor="cluster">Cluster</label>
                                    <input
                                        type="text"
                                        id="cluster"
                                        name="cluster"
                                        placeholder="Enter Cluster"
                                        className="form-control"
                                    />
                                </div>
                            </div> */}
                            <div className="col-md-6 col-lg-4 col-sm-6">
                                <div className="form_control_box">
                                    <label className="form-label" htmlFor="propertyCode">Property Code</label>
                                    <input
                                        type="text"
                                        id="propertyCode"
                                        name="propertyCode"
                                        placeholder="Enter Property Code"
                                        className="form-control"
                                        value={formik.values.property_code}
                                        onChange={(e) => formik.setFieldValue('property_code', e.target.value)}
                                    />
                                </div>
                            </div>

                            <div className="col-md-6 col-lg-4 col-sm-6">
                                <div className="form_control_box">
                                    <label className="form-label" htmlFor="residentId">Resident ID</label>
                                    <input
                                        type="text"
                                        id="residentId"
                                        name="residentId"
                                        placeholder="Enter Resident ID"
                                        className="form-control"
                                        value={formik.values.resident_id}
                                        onChange={(e) => formik.setFieldValue('resident_id', e.target.value)}
                                    />
                                </div>
                            </div>


                            {/* <div className="col-md-6 col-lg-4 col-sm-6">
                                <div className="form_control_box">
                                    <label className="form-label" htmlFor="lmsLeadId">LMS Lead ID</label>
                                    <input
                                        type="text"
                                        id="lmsLeadId"
                                        name="lmsLeadId"
                                        placeholder="Enter LMS Lead ID"
                                        className="form-control"
                                    />
                                </div>
                            </div> */}
                            {/* <div className="col-md-6 col-lg-4 col-sm-6">
                                <div className="form_control_box">
                                    <label className="form-label" htmlFor="salesPoc">Sales POC</label>
                                    <input
                                        type="text"
                                        id="salesPoc"
                                        name="salesPoc"
                                        placeholder="Enter Sales POC"
                                        className="form-control"
                                    />
                                </div>
                            </div> */}
                            {/* <div className="col-md-6 col-lg-4 col-sm-6 custom-single-select">
                                <label className="form-label" htmlFor="appStatus">App Status</label>
                                <Select
                                    id="appStatus"
                                    name="appStatus"
                                    placeholder='Select App Status'
                                    options={[
                                        { value: 1, label: 'Agreement' },
                                        { value: 2, label: 'Quick Info' },
                                    ]}
                                />
                            </div> */}
                            <div className="col-md-6 col-lg-4 col-sm-6">
                                <div className="form_control_box">
                                    <div className='modal_input_main'>
                                        <label className="form-label" htmlFor="checkInDate">Check-in Date</label>
                                        {/* <ReactDatePicker
                                            id="checkInDate"
                                            name="checkInDate"
                                            placeholderText="DD/MM/YY"
                                            selected={formik.values.checkin_date}
                                            onChange={(date) => formik.setFieldValue('checkin_date', date)}
                                        /> */}
                                        <ReactDatePicker
                                            // selected={startDate}
                                            selectsRange={true}
                                            onChange={(date) => {
                                                formik.setFieldValue('start_checkin_date', date[0]);
                                                formik.setFieldValue('end_checkin_date', date[1]);
                                            }}
                                            dateFormat={'dd/MM/yyyy'}
                                            monthsShown={2}
                                            startDate={formik.values.start_checkin_date}
                                            endDate={formik.values.end_checkin_date}
                                            id="notice-start-date"
                                            placeholderText="DD/MM/YY - DD/MM/YY"
                                            // highlightDates={isDateInRange}
                                            // minDate={new Date()}
                                            autoComplete="off"
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="modal_btn_footer mt-4">
                                <button type="button" className="orange_brd_button p-12-16-button mr-3" style={{ width: 'fit-content' }} onClick={() => handleResetFilter()}>RESET</button>
                                <button type="submit" className={'basic-button'}>SEARCH</button>
                            </div>
                        </div>
                    </form>
                </Modal.Body>
            </Modal>
            {showLeadModal && <MobileLeadModal setShowModal={setShowLeadModal} showModal={showLeadModal} />}
        </>
    );
}

export default withDefaultDashBoardLayout(OnboardingList);
