import React from 'react'
import { PollMenu } from '../../interface'
import styles from './index.module.scss';
import InputBox from '../../../../components/formInput/InputBox';
import InputSelect from '../../../../components/formInput/InputSelect';
import CourseView from './CourseView';
import { getPropertiesByName } from '../../../../helpers/services/foodservices';

export default function PollView({ info = new PollMenu(), onAddCourse, onDeleteCourse, onChange, meals, savedIdProperties=[] }) {

    const onLoadOptions = async (input) => {
        try {
            const { data } = await getPropertiesByName(input);
            return data.map((item) => {
                item.label = item.property_name;
                item.value = item.id;
                return item;
            }).filter((item)=>{
                return savedIdProperties.indexOf(item.id) === -1;
            })
        } catch (e) {
            console.error(e);
        }
        return []
    }

    return (
        <div className={styles['poll-container']}>
            <div className={styles['poll-header']}>
                <div className={styles['header-child']}>
                    <InputBox
                        type="text"
                        label={"Menu Name"}
                        placeHolder={"Enter name"}
                        value={info.name}
                        disabled={info.id > 0}
                        onChange={(...args) => { if (info.id === 0) { onChange(...args) } }}
                        name="name"
                    />
                </div>
                <div className={styles['header-child']}>
                    <InputSelect
                        isAsync
                        loadOptions={onLoadOptions}
                        label={"Property List"}
                        name="properties"
                        value={info.properties.map((item) => ({ value: item.id, label: item.property_name, id: item.id, property_name: item.property_name }))}
                        isMulti
                        isSearchable
                        placeholder={"Search here"}
                        onChange={onChange}
                        defaultOptions
                    />
                </div>
            </div>
            {
                info.meals.map((mealInfo, index) => {

                    const selectedMeal = meals.find((mealItemInfo) => {
                        return mealItemInfo.id === mealInfo.id
                    })

                    const coursesIds = mealInfo.courses.reduce((_r,_cs)=>{if(_cs.id){ _r.push(_cs.id);} return _r;},[]);

                    return (<div className={styles['poll-menu']} key={"MealInfo" + mealInfo.name}>
                        <div className='row mx-0'>
                            <div className={'col-3 ps-0 ' + styles['poll-menu-first-col']}>
                                <InputSelect
                                    label={"Meal"}
                                    isDisabled
                                    name="meal"
                                    value={(selectedMeal) ? { label: selectedMeal.name, value: selectedMeal.id } : null}
                                    isSearchable
                                    options={meals.map((option) => ({ ...option, value: option.id, label: option.name }))}
                                    placeholder={"Search meal"}
                                />
                            </div>
                            <div className='col-9 pr-0'>
                                <div className=''>
                                    {
                                        mealInfo.courses.map((course, childIndex) => {
                                            return (<CourseView
                                                index={childIndex}
                                                coursesIds={coursesIds}
                                                meal={selectedMeal}
                                                className={(childIndex === 0) ? "" : "mt-3"}
                                                onDeleteCourse={() => onDeleteCourse(index, childIndex)} key={"MealInfo" + mealInfo.name + "_" + childIndex}
                                                info={course}
                                                onChange={(e) => onChange(e, index, childIndex)} />)
                                        })
                                    }
                                    <div className='mt-3'>
                                        <div className='d-flex align-items-end gap-8'>
                                            <svg className={'cursor-pointer'} onClick={() => onAddCourse(index)} width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M12.5 3.99609V19.9961M4.5 11.9961H20.5" stroke="#FF5700" strokeWidth="2" strokeLinecap="round" />
                                            </svg>
                                            <span className={styles['add-course'] + " text-base"} onClick={() => onAddCourse(index)}>ADD COURSE</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>)
                })
            }
        </div>
    )
}
