import React, { useCallback, useEffect, useMemo, useState } from 'react'
import moment from 'moment';
import styles from './index.module.scss'
import PollView from '../PollView';
import { Course, Poll, getInitialDefaultPoll } from '../../interface';
import { getPollMenu, postPollMenu, putPollMenu, putPublishPollMenu } from '../../../../helpers/services/foodservices';
import { getConvertedPollMenu } from '../../utils/conversion';
import CreateMenuComponent from '../CreateMenu';
import SubmmitedPollModal from './SubmmitedPollModal';
import Swal from 'sweetalert2'

export default function UpcomingMenu({ meals }) {
    const [savedPollInfo, setSavedPollInfo] = useState(null);
    const [pollInfo, setPollInfo] = useState(null);
    const [enableBack, setEnableBack] = useState(false);
    const [openSubmittedPopup, setSubmittedPopup] = useState(false)
    const initPoll = useCallback(
        async () => {
            const startMoment = moment().add(1, 'weeks').startOf('isoWeek');
            const endMoment = moment().add(1, 'weeks').endOf('isoWeek');
            const mealsList = (meals).map((meal) => ({ name: meal.name, id: meal.id, courses: [] }));
            try {
                const startDate = moment(startMoment).format("YYYY-MM-DD");
                const endDate = moment(endMoment).format("YYYY-MM-DD")
                const response = await getPollMenu(startDate, endDate);
                const draftPolls = [], publishedPolls = [];
                if (response.data && typeof response.data === 'object') {
                    const pollResponse = response.data;
                    for (const pollKey in pollResponse) {
                        const pollItems = pollResponse[pollKey];
                        if (pollItems && pollItems.length) {
                            const pollItemInfo = JSON.parse(JSON.stringify(pollItems[0]));
                            pollItemInfo.property_details = [];
                            pollItems.forEach((_pollItemInfo) => {
                                pollItemInfo.property_details.push(_pollItemInfo.property_details);
                            })
                            if (!pollItemInfo.is_active) {
                                draftPolls.push(pollItemInfo)
                            } else {
                                publishedPolls.push(pollItemInfo)
                            }
                        }

                    }
                }
                const _draftPoll = getConvertedPollMenu({
                    startDate: startMoment,
                    endDate: endMoment,
                    polls: draftPolls,
                    meals: mealsList
                })
                if (publishedPolls.length) {
                    setSavedPollInfo(getConvertedPollMenu({
                        startDate: startMoment,
                        endDate: endMoment,
                        polls: publishedPolls,
                        meals: mealsList
                    }));
                }

                if (publishedPolls.length !== 0 && draftPolls.length === 0) {
                    setPollInfo(new Poll(startMoment, endMoment))
                    return null;
                } else if (draftPolls.length) {
                    setPollInfo(_draftPoll);
                    return null;
                }
            } catch (e) {
                console.error(e);
            }

            setPollInfo(() => {
                const st = getInitialDefaultPoll(mealsList);
                st.startDate = startMoment;
                st.endDate = endMoment;
                return st;
            })
        },
        [setPollInfo, meals],
    )

    useEffect(() => {
        if (meals?.length) {
            initPoll()
        }
    }, [meals, initPoll]);

    const formatDateRange = useMemo(() => {
        if (pollInfo && pollInfo.endDate && pollInfo.startDate) {
            return {
                start: moment(pollInfo.startDate).format('DD MMM’YY'),
                end: moment(pollInfo.endDate).format('DD MMM’YY'),
            }
        }
        return null
    }, [pollInfo])

    const onChangePoll = (e, pollIndex, mealIndex, courseIndex) => {
        const { name, value } = e.target;
        switch (name) {
            case 'name':
                setPollInfo((prev) => {
                    const st = { ...prev };
                    if (st.polls[pollIndex]) {
                        st.polls[pollIndex].name = value;
                    }
                    return st;
                });
                break;
            case 'properties':
                setPollInfo((prev) => {
                    const st = { ...prev };
                    if (st.polls[pollIndex]) {
                        st.polls[pollIndex].properties = value;
                    }
                    return st;
                });
                break;
            case 'course':
                setPollInfo((prev) => {
                    const st = { ...prev };
                    if (st.polls[pollIndex].meals[mealIndex].courses[courseIndex].id !== value.id) {
                        st.polls[pollIndex].meals[mealIndex].courses[courseIndex].id = value.id;
                        st.polls[pollIndex].meals[mealIndex].courses[courseIndex].name = value.label;
                        st.polls[pollIndex].meals[mealIndex].courses[courseIndex].courseItems = [];
                    }
                    return st;
                });
                break;
            case 'recipes':
                setPollInfo((prev) => {
                    const st = { ...prev };
                    st.polls[pollIndex].meals[mealIndex].courses[courseIndex].courseItems = value;
                    if (value.length === 0 || value.length === 1) {
                        st.polls[pollIndex].meals[mealIndex].courses[courseIndex].studentVotes = value.length;
                    }
                    return st;
                });
                break;
            case 'studentVotes':
                if (value === "" || (value && /^\d+$/.test(value) && String(value).indexOf("-") === -1)) {
                    setPollInfo((prev) => {
                        const st = { ...prev };
                        st.polls[pollIndex].meals[mealIndex].courses[courseIndex].studentVotes = value;
                        return st;
                    });
                }
                break;
            default:
        }
    }

    const onActionCourse = (pollIndex, mealIndex, action = 'add', courseIndex = -1) => {
        let allPolls = [...pollInfo.polls];
        if (action === 'delete' && courseIndex !== -1) {
            allPolls[pollIndex].meals[mealIndex].courses.splice(courseIndex, 1);
        } else if (action === 'add') {
            allPolls[pollIndex].meals[mealIndex].courses.push(new Course());
        }
        setPollInfo((prev) => {
            let st = { ...prev };
            st.polls = [...allPolls];
            return st;
        })
    }


    const onAddPoll = (enableBackCta = false) => {
        setEnableBack(enableBackCta);
        const pollInfoDataCopy = JSON.parse(JSON.stringify(pollInfo));
        const pollinfoData = getInitialDefaultPoll(meals);
        pollInfoDataCopy.polls.push(...pollinfoData.polls)
        setPollInfo(pollInfoDataCopy)
    }

    const onGoBack = () => {
        setEnableBack(false);
        setPollInfo((prev) => {
            let st = { ...prev };
            st.polls = [];
            return st;
        })
    }

    const enableSubmit = useMemo(() => {
        if (pollInfo?.polls && pollInfo.polls.length) {
            let pollsLength = pollInfo.polls.length, pollsfilled = 0;
            let increment = 0;
            for (let i = 0; i < pollsLength; i++) {
                const meals = pollInfo.polls[i].meals;
                increment = 0;
                mealLoop: for (let j = 0; j < meals.length; j++) {
                    const meal = meals[j];
                    for (let k = 0; k < meal.courses.length; k++) {
                        const course = meal.courses[k];
                        if (course.id && course.courseItems.length && course.studentVotes) {
                            increment++;
                            break mealLoop;
                        }
                    }
                }
                if (pollInfo.polls[i].properties.length && pollInfo.polls[i].name) {
                    pollsfilled += increment;
                }
            }
            return pollsfilled && (pollsLength === pollsfilled);
        }
        return false;
    }, [pollInfo])

    const doSavePoll = async (request) => {
        try {
            let response;
            if (request.id) {
                response = await putPollMenu(request);
            } else {
                response = await postPollMenu(request);
            }
            return response;
        } catch (e) {
            console.error(e)
            return null;
        }
    }

    const doPublishPoll = async (menuName) => {
        try {
            const response = await putPublishPollMenu({ menu_name: menuName })
            if (response.data) {
                return response.data;
            }
        } catch (e) {
            console.error(e)
            return null;
        }
    }

    const onSaveDraft = async (publish = false) => {
        const requests = pollInfo.polls.map((pollItem) => {
            const request = {
                "id": pollItem.id,
                "start_date": moment(pollInfo.startDate).format('YYYY-MM-DD'),
                "end_date": moment(pollInfo.endDate).format('YYYY-MM-DD'),
                "menu_name": pollItem.name,
                "property_ids": pollItem.properties.map((property) => {
                    return property.id
                }),
                "food_menu_preferences": []
            }
            request.food_menu_preferences = pollItem.meals.reduce((results, mealInfo) => {
                if (mealInfo.id) {
                    mealInfo.courses.forEach((courseItemInfo) => {
                        if (courseItemInfo.id && courseItemInfo.courseItems.length) {
                            const _items = courseItemInfo.courseItems.map((courseItem) => ({
                                "food_item_id": courseItem.id,
                                "meal_session_id": mealInfo.id,
                                "votes_per_student": (courseItemInfo.studentVotes) ? parseInt(courseItemInfo.studentVotes) : 0
                            }));
                            results.push(
                                ..._items
                            );
                        }
                    })
                }
                return results;
            }, [])
            return request;
        })
        let _rCounter = 0,errors=[];
        for (let i = 0; i < requests.length; i++) {
            const responseInfo = await doSavePoll(requests[i]);
            console.log(responseInfo)
            if (responseInfo?.errors?.length === 0) {
                if (publish) {
                    await doPublishPoll(requests[i].menu_name);
                }
                _rCounter++;
            }else{
                errors.push(...responseInfo.errors)
            }
        }
        if (_rCounter) {
            Swal.fire({
                title: "Poll Menu " + ((publish) ? "Publish" : "Draft") + " Submission Success",
                text: "",
                icon: "success"
            });
            initPoll();
        } else {
            Swal.fire({
                title: "Poll Menu " + ((publish) ? "Publish" : "Draft") + " Submission Failed",
                text: errors.join(" "),
                icon: "error"
            });
        }
    }

    const savedIdProperties = useMemo(() => {
        if (savedPollInfo && savedPollInfo?.polls && savedPollInfo?.polls?.length) {
            return savedPollInfo.polls.reduce((_r, _c) => {
                if (_c?.properties?.length)
                    _r.push(..._c.properties.map((e) => e.id));
                return _r;
            }, [])
        }
        return [];
    }, [savedPollInfo?.polls]);

    if (!(pollInfo && formatDateRange)) {
        return (<></>)
    }
    return (<>

        {
            (savedPollInfo?.polls?.length) ? (<>
                <SubmmitedPollModal open={openSubmittedPopup} onClose={() => { setSubmittedPopup(false) }} dateRange={formatDateRange} polls={savedPollInfo?.polls} />
                <div className={styles['saved-upcoming-container']}>
                    <p className={'mb-0 ' + styles['menu-title']}>Poll for {formatDateRange.start} - {formatDateRange.end} menu  is in progress</p>
                    <p className={'mb-0 ' + styles['menu-submmited-poll-view']} onClick={() => { setSubmittedPopup(true) }}>
                        <span>View submitted poll</span>
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M5 12H19M19 12L12 5M19 12L12 19" stroke="#FF5700" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                    </p>
                </div>
            </>) : (<></>)
        }

        {
            (pollInfo.polls.length) ? (<>
                <div className="d-flex align-items-center">
                    <h4 className={styles["menu-title"] + " me-auto"}>Poll for {formatDateRange.start} - {formatDateRange.end} menu</h4>
                    <div className='ms-auto d-flex align-item-center gap-24'>
                        {
                            (enableBack) ? (<button className={'btn ' + styles['draft']} onClick={() => { onGoBack(); }}>CANCEL</button>) : (<></>)
                        }
                        {
                            (enableSubmit) ? (<>
                                <button className={'btn ' + styles['draft']} onClick={() => { onSaveDraft() }}>SAVE DRAFT</button>
                                <button className={'btn ' + styles['publish']} onClick={() => { onSaveDraft(true) }}>PUBLISH</button>
                            </>) : (<>
                                {
                                    pollInfo.polls.length === 0 ? (<>
                                        <button className={'btn ' + styles['draft']} onClick={() => { onAddPoll() }}>ADD POLL</button>
                                    </>) : (<></>)
                                }
                            </>)
                        }
                    </div>

                </div>
                {
                    pollInfo.polls.map((poll, index) => {
                        return (<PollView
                            savedIdProperties={savedIdProperties}
                            meals={meals}
                            key={"PollView" + index}
                            info={poll}
                            onChange={(e, mealIndex = -1, courseIndex = -1) => onChangePoll(e, index, mealIndex, courseIndex)}
                            onAddCourse={(mealIndex) => onActionCourse(index, mealIndex)}
                            onDeleteCourse={(mealIndex, courseIndex) => onActionCourse(index, mealIndex, 'delete', courseIndex)}
                        />)
                    })
                }

                {/* <div className='text-center'>
                    <button className={'btn ' + styles['next-poll']} onClick={onAddPoll}>
                        <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M12.5 3.99609V19.9961M4.5 11.9961H20.5" stroke="#FF5700" strokeWidth="2" strokeLinecap="round" />
                        </svg>
                        <span>ADD NEXT POLL</span>
                    </button>
                </div> */}
            </>) : (<>
                <CreateMenuComponent
                    onAddPoll={onAddPoll}
                    onGoBack={onGoBack}
                    dateRange={formatDateRange}
                    meals={meals}
                />
            </>)
        }
    </>)
}
