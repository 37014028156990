import React, { useEffect, useMemo, useState } from "react";
import withDefaultDashBoardLayout from "../../../components/layouts/withDefaultDashBoardLayout";
import styles from "./revenueDirectPayPage.module.scss";
import BreadCrumb from "../../../components/BreadCrumb";
import { Pagination } from "react-bootstrap";
import { getDirectPayEntriesApi } from "../../../helpers/services/revenueservices";
import {
  formatToRupees,
  getPaginationList,
} from "../../../helpers/constants/func";
import { Link } from "react-router-dom";
import { getFiscalYear } from "../../../helpers/common/common.function";

function RevenueDirectPayPage() {
  const [searchQuery, setSearchQuery] = useState("");
  const [filters, setFilters] = useState({
    dateRange: { startDate: "", endDate: "" },
    page: 1,
  });
  const [listInfo, setListInfo] = useState({
    items: [],
    meta: {
      current_page: 1,
      total_data_count: 0,
      total_pages: 0,
    },
  });

  const getEntries = async (currentPage) => {
    try {
      const { data, meta } = await getDirectPayEntriesApi(
        currentPage || filters.page,
        searchQuery.trim()
      );
      setListInfo({
        items: data.map((_i) => {
          _i.deposited_amount = formatToRupees(_i.deposited_amount || 0.0);
          _i.collected_amount = formatToRupees(_i.collected_amount || 0.0);
          _i.approved_amount = formatToRupees(_i.approved_amount || 0.0);
          // console.log(_i);
          return _i;
        }),
        meta,
      });
    } catch (e) {
      setListInfo({
        items: [],
        meta: {
          current_page: 1,
          total_data_count: 0,
          total_pages: 0,
        },
      });
    }
  };

  useEffect(() => {
    getEntries(filters.page);
  }, [filters]);

  const onHandlePagination = (value) => {
    setFilters((prev) => {
      let st = { ...prev };
      st.page = value;
      return st;
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setFilters((prev) => {
      let st = { ...prev };
      st.page = 1;
      return st;
    });
    getEntries(1);
  };

  useEffect(() => {
    if (searchQuery.length === 0) {
      getEntries();
    }
  }, [searchQuery]);

  const paginateItems = useMemo(() => {
    if (listInfo.meta.total_pages) {
      return getPaginationList(filters.page, listInfo.meta.total_pages);
    }
    return [];
  }, [listInfo, filters.page]);

  return (
    <div className={styles["revenue-directpay-page"]}>
      <div className={styles["heading-container"]}>
        <div className={styles["heading-left"]}>
          <h1 className={"mb-0 " + styles["heading"]}>
            <span className={"font-zkga fw-800 text-gray-900"}>
              Direct Pay -{" "}
            </span>
            <span className={"font-manrope fw-700 text-gray-600"}>
              {getFiscalYear()}
            </span>
          </h1>
          <div>
            <BreadCrumb
              seperator="/"
              list={[
                {
                  label: "Revenue",
                  link: "",
                  active: false,
                },
                {
                  label: "Direct Pay",
                  link: "",
                  active: true,
                },
              ]}
            />
          </div>
        </div>
      </div>

      <div className={styles["content-container"]}>
        <div className="form_control_box">
          <form onSubmit={handleSubmit}>
            <input
              type="text"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              className="form-control"
              placeholder="Search by Employee name"
            />
          </form>
        </div>
        <div className={styles["content-table"] + " table-responsive"}>
          <table className="table align-middle">
            <thead>
              <tr>
                <th>Employee ID</th>
                <th>Employee Name</th>
                <th>Total Amount To Be Deposited</th>
                <th>Total Amount To Be Approved</th>
                <th>Total Amount Approved</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {listInfo.items.map((item) => {
                return (
                  <tr key={"Employee_" + item.id}>
                    <td>{item.employee_id}</td>
                    <td>{item.employee_name}</td>
                    <td>{item.collected_amount}</td>
                    <td>{item.deposited_amount}</td>
                    <td>{item.approved_amount}</td>
                    <td>
                      <Link
                        to={
                          "/revenue/direct-pay/" +
                          item.employee_name +
                          "/" +
                          item.id
                        }
                      >
                        <svg
                          width="16"
                          height="16"
                          viewBox="0 0 16 16"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M6 4L10 8L6 12"
                            stroke="#A2AFB2"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </Link>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
          {paginateItems.length > 1 ? (
            <div className="d-flex justify-content-end">
              <Pagination>
                <Pagination.Item
                  disabled={filters.page === 1}
                  onClick={() => {
                    if (filters.page > 1) {
                      onHandlePagination(filters.page - 1);
                    }
                  }}
                >
                  <svg
                    className="rotate-270deg"
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M4 10.5L8 6.5L12 10.5"
                      stroke="#FF5700"
                      stroke-width="1.25"
                      stroke-linecap="round"
                    />
                  </svg>
                </Pagination.Item>
                {paginateItems.map((item) => {
                  return (
                    <Pagination.Item
                      onClick={() => {
                        onHandlePagination(item);
                      }}
                      key={"PaginateItem_" + item}
                      active={item === filters.page}
                    >
                      {item}
                    </Pagination.Item>
                  );
                })}
                <Pagination.Item
                  disabled={!(filters.page < listInfo.meta.total_pages)}
                  onClick={() => {
                    if (filters.page < listInfo.meta.total_pages) {
                      onHandlePagination(filters.page + 1);
                    }
                  }}
                >
                  <svg
                    className="rotate-90deg"
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M4 10.5L8 6.5L12 10.5"
                      stroke="#FF5700"
                      stroke-width="1.25"
                      stroke-linecap="round"
                    />
                  </svg>
                </Pagination.Item>
              </Pagination>
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>
    </div>
  );
}

export default withDefaultDashBoardLayout(RevenueDirectPayPage);
