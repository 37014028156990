import React from 'react'
import InputSelect from '../../../../components/formInput/InputSelect'
import InputBox from '../../../../components/formInput/InputBox'
import RangeSlider from 'react-bootstrap-range-slider';
import { getApiCourseItems, getApiCourses } from '../../../../helpers/services/foodservices'

export default function CourseView({ index, info, meal, className, onDeleteCourse, onChange, coursesIds }) {

    const onLoadCategories = async (input = "") => {
        try {
            const { data } = await getApiCourses(input);
            return data.map((item) => {
                item.label = item.name;
                item.value = item.id;
                return item;
            }).filter((item) => {
                return coursesIds.indexOf(item.id) === -1;
            })
        } catch (e) {
            console.error(e)
        }
        return []
    }

    const onLoadFoodItems = async (input) => {
        if (info.id) {
            try {
                const { data } = await getApiCourseItems(info.id, input);
                return data.map((item) => {
                    item.label = item.food_name;
                    item.value = item.id;
                    return item;
                })
            } catch (e) {
                console.error(e)
            }
        }
        return []
    }

    const courseKey = coursesIds.join("_");

    return (
        <div className={'row mx-0 gap-y-10 ' + className}>
            <div className={'col-4 ps-0 '}>
                <InputSelect
                    key={courseKey ? "courseKey" + index + "_" + courseKey : "courseKey" + index}
                    isAsync
                    label={"Course"}
                    name="course"
                    value={(info.id) ? { value: info.id, label: info.name, id: info.id, name: info.name } : null}
                    isSearchable
                    loadOptions={onLoadCategories}
                    placeholder={"Search course"}
                    onChange={onChange}
                    defaultOptions
                />
            </div>
            <div className='col-8'>
                <InputSelect
                    isAsync
                    key={(meal?.id ?? 1) + "CourseSelected" + (info.id ?? 1)}
                    label={"Options for Voting"}
                    name="recipes"
                    value={info.courseItems.map((item) => ({ value: item.id, label: item.food_name, id: item.id, food_name: item.food_name }))}
                    isSearchable
                    loadOptions={onLoadFoodItems}
                    placeholder={"Search options"}
                    onChange={onChange}
                    isMulti
                    defaultOptions
                />
            </div>
            <div className='col-4 ps-0'>
                <div className={'d-flex flex-column gap-8'}>
                    <div className='d-flex align-items-end gap-12'>
                        {
                            (info.courseItems.length>1) ? (<div className='flex-1 mb-3'>
                                <InputBox
                                    type="hidden"
                                    label={"No.of votes per student"}
                                    placeHolder={"Enter number"}
                                    value={info.studentVotes}
                                    name="studentVotes"
                                // onChange={onChange}
                                />
                                <RangeSlider
                                    className='warn'
                                    value={(info.studentVotes ? parseInt("" + info.studentVotes) : 1)}
                                    name="studentVotes"
                                    onChange={onChange}
                                    tooltip='on'
                                    size="sm"
                                    tooltipPlacement="bottom"
                                    variant="base"
                                    defaultValue={1}
                                    min={1}
                                    max={info.courseItems.length}
                                />
                            </div>) : (<></>)
                        }

                        <div>
                            <div onClick={() => onDeleteCourse()} className='cursor-pointer rounded-circle d-flex align-items-center justify-content-center' style={{ width: "44px", height: "44px", backgroundColor: 'rgba(222, 17, 53, 0.06)' }}>
                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M7.5 2.5H12.5M2.5 5H17.5M15.8333 5L15.2489 13.7661C15.1612 15.0813 15.1174 15.7389 14.8333 16.2375C14.5833 16.6765 14.206 17.0294 13.7514 17.2497C13.235 17.5 12.5759 17.5 11.2578 17.5H8.74221C7.42409 17.5 6.76503 17.5 6.24861 17.2497C5.79396 17.0294 5.41674 16.6765 5.16665 16.2375C4.88259 15.7389 4.83875 15.0813 4.75107 13.7661L4.16667 5M8.33333 8.75V12.9167M11.6667 8.75V12.9167" stroke="#DE1135" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                </svg>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
