import React from "react";
import { Form } from "react-bootstrap";
import Select from "react-dropdown-select";
import { useFormik } from 'formik';
import * as Yup from 'yup';
import toast from "react-hot-toast";

import { addNewCreateProperty } from "../../../helpers/services/api.services";

export default function DetailsTab({
  onSaveButtonClick,
  handleCloseModal,
  refreshData,
  clusters,
  states,
  cities,
  setupPropertyData
}) {

  // Form Validations
  const validationSchema = Yup.object().shape({
    property_name: Yup.string().optional(),
    capacity_no_of_beds: Yup.number().optional(),
    postal_address_1: Yup.string().optional(),
    postal_address_2: Yup.string().optional(),
    pincode: Yup.string().optional().matches(/^[0-9]{6}$/, 'Pin code must be exactly 6 digits'),
    // cluster_id: Yup.number().optional(),
    cluster: Yup.object().optional(),
    // state_id: Yup.number().optional(),
    state: Yup.object().optional(),
    // city_id: Yup.number().optional(),
    city: Yup.object().optional(),
    location: Yup.string().optional(),
    invoice_series: Yup.string().matches(/^[a-zA-Z0-9]+$/, "Invoice series code Must be alphanumeric").min(10, 'Invoice series code Must be 10 alphanumeric characters').max(10, 'Invoice series code Must be 10 alphanumeric characters'),
    property_gstin: Yup.string().matches(/^[a-zA-Z0-9]+$/, "GSTIN Must be alphanumeric").min(15, 'GSTIN Must be 15 alphanumeric characters').max(15, 'GSTIN code Must be 15 alphanumeric characters'),
  });
  // Yup.object().shape({
  //   property_name: Yup.string().optional(),
  //   // property_code: Yup.string().optional(),
  //   capacity_no_of_beds: Yup.number().optional(),
  //   postal_address_1: Yup.string().optional(),
  //   postal_address_2: Yup.string().optional(),
  //   pincode: Yup.string().optional().matches(/^[0-9]{6}$/, 'Pin code must be exactly 6 digits'),
  //   cluster_id: Yup.number().optional(),
  //   cluster: Yup.object().optional(),
  //   state_id: Yup.number().optional(),
  //   state: Yup.object().optional(),
  //   city_id: Yup.number().optional(),
  //   city: Yup.object().optional(),
  //   location: Yup.string().optional(),
  //   // invoice_series: Yup.string().optional(),
  //   invoice_series: Yup.string().matches(/^[a-zA-Z0-9]+$/, "Invoice series code Must be alphanumeric").min(10, 'Invoice series code Must be 10 alphanumeric characters').max(10, 'Invoice series code Must be 10 alphanumeric characters'),
  //   // property_gstin: Yup.string().optional(),
  //   property_gstin: Yup.string().matches(/^[a-zA-Z0-9]+$/, "GSTIN Must be alphanumeric").min(15, 'GSTIN Must be 15 alphanumeric characters').max(15, 'GSTIN code Must be 15 alphanumeric characters'),
  //   // gender_allowed: Yup.object().optional(),
  //   // gender: Yup.string().optional(),
  // });

  const initialValues = {
    property_name: '',
    property_code: '',
    capacity_no_of_beds: '',
    postal_address_1: '',
    postal_address_2: '',
    pincode: '',
    city_id: null,
    city: {},
    state_id: null,
    state: {},
    cluster_id: null,
    cluster: {},
    location: '',
    invoice_series: '',
    property_gstin: '',
    gender_allowed: {},
    gender: '',
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values) => {
      try {
        const requestObj = {
          property_name: values.property_name,
          capacity_no_of_beds: values.capacity_no_of_beds,
          // description: null,
          // property_code: values.property_code,
          // property_slug: values.location,
          state_id: values.state_id,
          city_id: values.city_id,
          cluster_id: values.cluster_id,
          // gender_allowed: values.gender,
          postal_address_1: values.postal_address_1,
          postal_address_2: values.postal_address_2,
          pincode: values.pincode,
          invoice_series: values.invoice_series,
          google_map_link: values.location,
          property_gstin: values.property_gstin,
        };

        // console.log("Requiest object -- ",requestObj)

        let response = await addNewCreateProperty(requestObj);

        if (response && response?.status === 'success') {
          await toast.success('Property has been added successfully.');
          
          // handleCloseModal();
          setupPropertyData(response?.data?.id);
          refreshData();
        } else {
          await toast.error('Something went wrong while add a property details.');
        }
      } catch (error) {
        console.log(error);
      }
    },
  });

  return (
    <div className="create-prop_detail">
      <form onSubmit={formik.handleSubmit}>
        <div className="TrailWrap mb-4">
          <div>
            <Form.Label>Name</Form.Label>
            <Form.Control
              placeholder="Name"
              id="property_name"
              name="property_name"
              value={formik.values.property_name}
              onChange={(e) => formik.setFieldValue('property_name', e.target.value)}
            />
            {
              formik.touched.property_name && formik.errors.property_name ? (
                <div className="error-label">{formik.errors.property_name}</div>
              ) : null
            }
          </div>
          {/* <div>
            <Form.Label>Code*</Form.Label>
            <Form.Control
              placeholder="Enter Property Code"
              id="property_code"
              name="property_code"
              value={formik.values.property_code}
              onChange={(e) => formik.setFieldValue('property_code', e.target.value)}
            />
            {
              formik.touched.property_code && formik.errors.property_code ? (
                <div className="error-label">{formik.errors.property_code}</div>
              ) : null
            }
          </div> */}
          <div>
            <Form.Label>Capacity no of beds</Form.Label>
            <Form.Control
              placeholder="Enter No. of Beds"
              type="number"
              id="capacity_no_of_beds"
              name="capacity_no_of_beds"
              value={formik.values.capacity_no_of_beds}
              onChange={(e) => formik.setFieldValue('capacity_no_of_beds', e.target.value)}
            />
            {
              formik.touched.capacity_no_of_beds && formik.errors.capacity_no_of_beds ? (
                <div className="error-label">{formik.errors.capacity_no_of_beds}</div>
              ) : null
            }
          </div>
          <div>
            <Form.Label>Postal address 1</Form.Label>
            <Form.Control
              placeholder="Enter address 1"
              id="postal_address_1"
              name="postal_address_1"
              value={formik.values.postal_address_1}
              onChange={(e) => formik.setFieldValue('postal_address_1', e.target.value)}
            />
            {
              formik.touched.postal_address_1 && formik.errors.postal_address_1 ? (
                <div className="error-label">{formik.errors.postal_address_1}</div>
              ) : null
            }
          </div>
          <div>
            <Form.Label>Postal address 2</Form.Label>
            <Form.Control
              placeholder="Enter address 2"
              id="postal_address_2"
              name="postal_address_2"
              value={formik.values.postal_address_2}
              onChange={(e) => formik.setFieldValue('postal_address_2', e.target.value)}
            />
            {
              formik.touched.postal_address_2 && formik.errors.postal_address_2 ? (
                <div className="error-label">{formik.errors.postal_address_2}</div>
              ) : null
            }
          </div>
          <div>
            <Form.Label>Pincode</Form.Label>
            <Form.Control
              placeholder="Enter Pincode"
              type="number"
              id="pincode"
              name="pincode"
              value={formik.values.pincode}
              onChange={(e) => formik.setFieldValue('pincode', e.target.value)}
              style={formik.touched.pincode && formik.errors.pincode ? {
                borderColor:'red'
              }: null}
            />
            {
              formik.touched.pincode && formik.errors.pincode ? (
                <div className="error-label">{formik.errors.pincode}</div>
              ) : null
            }
          </div>
          <div className="custom-single-select">
            <label className="form-label" htmlFor="city">City</label>
            <Select
              id="city"
              name="city"
              placeholder="City"
              className="mt-1"
              options={cities}
              value={formik.values.city}
              onChange={(value) => {
                formik.setFieldValue('city', value[0]);
                formik.setFieldValue('city_id', value[0].value);
              }}
            />
            {
              formik.touched.city && formik.errors.city ? (
                <div className="error-label">{formik.errors.city}</div>
              ) : null
            }
          </div>
          <div className="custom-single-select">
            <label className="form-label" htmlFor="state">State</label>
            <Select
              id="state"
              name="state"
              placeholder="State"
              className="mt-1"
              options={states}
              value={formik.values.state}
              onChange={(value) => {
                formik.setFieldValue('state', value[0]);
                formik.setFieldValue('state_id', value[0].value);
              }}
            />
            {
              formik.touched.state && formik.errors.state ? (
                <div className="error-label">{formik.errors.state}</div>
              ) : null
            }
          </div>
          <div className="custom-single-select">
            <label className="form-label" htmlFor="cluster">Cluster</label>
            <Select
              id="cluster"
              name="cluster"
              placeholder="Cluster"
              className="mt-1"
              options={clusters}
              value={formik.values.cluster}
              onChange={(value) => {
                formik.setFieldValue('cluster', value[0]);
                formik.setFieldValue('cluster_id', value[0].value);
              }}
            />
            {
              formik.touched.cluster && formik.errors.cluster ? (
                <div className="error-label">{formik.errors.cluster}</div>
              ) : null
            }
          </div>
          <div>
            <Form.Label>Property Location</Form.Label>
            <Form.Control
              type="url"
              id="location"
              name="location"
              placeholder="Enter Location Link"
              value={formik.values.location}
              onChange={(e) => formik.setFieldValue('location', e.target.value)}
            />
            {
              formik.touched.location && formik.errors.location ? (
                <div className="error-label">{formik.errors.location}</div>
              ) : null
            }
          </div>
          {/* <div>
            <Form.Label>Invoice series code</Form.Label>
            <Form.Control
              type="text"
              id="invoice_series"
              name="invoice_series"
              placeholder="XXXXX-XXXXX"
              value={formik.values.invoice_series}
              onChange={(e) => formik.setFieldValue('invoice_series', e.target.value)}
            />
            {
              formik.touched.invoice_series && formik.errors.invoice_series ? (
                <div className="error-label">{formik.errors.invoice_series}</div>
              ) : null
            }
          </div>
          <div>
            <Form.Label>GSTIN (State)</Form.Label>
            <Form.Control
              type="text"
              id="property_gstin"
              name="property_gstin"
              placeholder="XXXXX-XXXXX-XXXXX"
              value={formik.values.property_gstin}
              onChange={(e) => formik.setFieldValue('property_gstin', e.target.value)}
            />
            {
              formik.touched.property_gstin && formik.errors.property_gstin ? (
                <div className="error-label">{formik.errors.property_gstin}</div>
              ) : null
            }
          </div>
          <div className="custom-single-select">
            <label className="form-label" htmlFor="gender_allowed">Gender</label>
            <Select
              id="gender_allowed"
              name="gender_allowed"
              placeholder="Gender"
              className="mt-1"
              options={[
                { value: 'Male', label: 'Male' },
                { value: 'Female', label: 'Female' },
                { value: 'Both', label: 'Both' },
              ]}
              value={formik.values.gender_allowed}
              onChange={(value) => {
                formik.setFieldValue('gender_allowed', value[0]);
                formik.setFieldValue('gender', value[0].value);
              }}
            />
            {
              formik.touched.gender_allowed && formik.errors.gender_allowed ? (
                <div className="error-label">{formik.errors.gender_allowed}</div>
              ) : null
            }
          </div> */}
        </div>

        <h3 className="title mb-4">BILLING INFORMATION</h3>

        <div className="TrailWrap">
          <div>
            <Form.Label>Invoice series code</Form.Label>
            <Form.Control
              type="text"
              id="invoice_series"
              name="invoice_series"
              placeholder="XXXXX-XXXXX"
              value={formik.values.invoice_series}
              onChange={(e) => formik.setFieldValue('invoice_series', e.target.value)}
              style={formik.touched.invoice_series && formik.errors.invoice_series ? {
                borderColor:'red'
              }: null}
            />
            {
              formik.touched.invoice_series && formik.errors.invoice_series ? (
                <div className="error-label">{formik.errors.invoice_series}</div>
              ) : null
            }
          </div>
          <div>
            <Form.Label>GSTIN (State)</Form.Label>
            <Form.Control
              type="text"
              id="property_gstin"
              name="property_gstin"
              placeholder="XXXXX-XXXXX-XXXXX"
              value={formik.values.property_gstin}
              onChange={(e) => formik.setFieldValue('property_gstin', e.target.value)}
            />
            {
              formik.touched.property_gstin && formik.errors.property_gstin ? (
                <div className="error-label">{formik.errors.property_gstin}</div>
              ) : null
            }
          </div>
          {/* <div className="custom-single-select">
            <label className="form-label" htmlFor="gender_allowed">Gender</label>
            <Select
              id="gender_allowed"
              name="gender_allowed"
              placeholder="Gender"
              className="mt-1"
              options={[
                { value: 'Male', label: 'Male' },
                { value: 'Female', label: 'Female' },
                { value: 'Both', label: 'Both' },
              ]}
              value={formik.values.gender_allowed}
              onChange={(value) => {
                formik.setFieldValue('gender_allowed', value[0]);
                formik.setFieldValue('gender', value[0].value);
              }}
            />
            {
              formik.touched.gender_allowed && formik.errors.gender_allowed ? (
                <div className="error-label">{formik.errors.gender_allowed}</div>
              ) : null
            }
          </div> */}
        </div>

        <div className="modal-footer">
          <button 
            type="button" 
            className="orange_brd_button p-12-16-button mr-3"
            onClick={()=>handleCloseModal()}
          >CANCEL</button>
          <button type="submit" className="basic-button">SAVE</button>
        </div>
      </form>
    </div>
  );
}
