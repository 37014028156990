import { BASE_API_URL } from "../constants";
import apiClient from "./apiClient";
import API_ACTION from "../actions/api.action";



const BASE_CRM_DASHBOARD_URL = BASE_API_URL + "/crm_dashboard/v1/admin/";

export const getWebsiteAmenities = async () => {
    try {
        return await apiClient.get(BASE_CRM_DASHBOARD_URL + "get_website_homepage/" + API_ACTION.getwebsiteAmenities)
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                return error;
            });
    } catch (error) {
        console.error('Error fetching user data:', error);
        throw error;
    }
}
export const getWebsiteWhyYourSpace = async () => {
    try {
        return await apiClient.get(BASE_CRM_DASHBOARD_URL + "get_website_homepage/" + API_ACTION.getWebsiteWhyYourSpace)
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                return error;
            });
    } catch (error) {
        console.error('Error fetching user data:', error);
        throw error;
    }
}
export const getWebsiteTestimonials = async () => {
    try {
        return await apiClient.get(BASE_CRM_DASHBOARD_URL + "get_website_homepage/" + API_ACTION.getWebsiteTestimonials)
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                return error;
            });
    } catch (error) {
        console.error('Error fetching user data:', error);
        throw error;
    }
}
export const getWebsiteFaqs = async () => {
    try {
        return await apiClient.get(BASE_CRM_DASHBOARD_URL + "get_website_homepage/" + API_ACTION.getWebsiteFaqs)
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                return error;
            });
    } catch (error) {
        console.error('Error fetching user data:', error);
        throw error;
    }
}
export const getWebsiteCarousal= async () => {
    try {
        return await apiClient.get(BASE_CRM_DASHBOARD_URL + "get_website_homepage/" + API_ACTION.getWebsiteCarousal)
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                return error;
            });
    } catch (error) {
        console.error('Error fetching user data:', error);
        throw error;
    }
}

export const updateWebsiteFaqs = async (data) => {
    try {
        return await apiClient.put(BASE_CRM_DASHBOARD_URL + "update_website_homepage/" + API_ACTION.getWebsiteFaqs, data )
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                return error;
            });
    } catch (error) {
        console.error('Error fetching user data:', error);
        throw error;
    }
}
export const updateWebsiteCarousel = async (data) => {
    try {
        return await apiClient.put(BASE_CRM_DASHBOARD_URL + "update_website_homepage/" + API_ACTION.getWebsiteCarousal, data )
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                return error;
            });
    } catch (error) {
        console.error('Error fetching user data:', error);
        throw error;
    }
}

export const updateWebsiteTestimonials = async (data) => {
    try {
        return await apiClient.put(BASE_CRM_DASHBOARD_URL + "update_website_homepage/" + API_ACTION.getWebsiteTestimonials, data )
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                return error;
            });
    } catch (error) {
        console.error('Error fetching user data:', error);
        throw error;
    }
}

export const updateWebsiteAmanites = async (data) => {
    try {
        return await apiClient.put(BASE_CRM_DASHBOARD_URL + "update_website_homepage/" + API_ACTION.getwebsiteAmenities, data )
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                return error;
            });
    } catch (error) {
        console.error('Error fetching user data:', error);
        throw error;
    }
}
export const updateWebsiteWhySpace = async (data) => {
    try {
        return await apiClient.put(BASE_CRM_DASHBOARD_URL + "update_website_homepage/" + API_ACTION.getWebsiteWhyYourSpace, data )
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                return error;
            });
    } catch (error) {
        console.error('Error fetching user data:', error);
        throw error;
    }
}

export const uploadImage = async(data)=>{
    try {
        return await apiClient.post(BASE_API_URL + "/crm_dashboard/v1/document/media_file_upload",data)
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                return error;
            });
    } catch (error) {
        console.error('Error fetching user data:', error);
        throw error;
    }

}
