export class Course {
    constructor(id = 0, name="", courseItems = [], studentVotes = "") {
        this.id = id;
        this.name = name;
        this.courseItems = courseItems;
        this.studentVotes = studentVotes;
    }
}

export class Meal {
    constructor(id = 0, name = "", courses = []) {
        this.id = id;
        this.name = name;
        this.courses = courses;
    }
}

export class PollMenu {
    constructor(id = 0, name = "", properties = [], meals = []) {
        this.id = id;
        this.name = name;
        this.properties = properties;
        this.meals = meals;
    }
}

export class Poll {
    constructor(startDate = "", endDate = "", polls = []) {
        this.startDate = startDate;
        this.endDate = endDate;
        this.polls = polls;
    }
}

// class PollProperty {
//     constructor(id = 0, name = "") {
//         this.id = id;
//         this.name = name;
//     }
// }


export const getInitialDefaultPoll = (mealNamesList = []) => {

    const pollMenu = new PollMenu();
    pollMenu.properties = [];
    pollMenu.meals = mealNamesList.map((meal) => {
        const course = new Course();
        return new Meal(meal.id, meal.name, [course]);
    })

    const poll = new Poll();
    poll.polls = [pollMenu];
    return poll;
}