import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
// import { Modal, Tab, Tabs } from "react-bootstrap";
import DataTable from 'react-data-table-component';
import { fetchRoomTypes } from "../../helpers/services/admin.services";
import withDefaultDashBoardLayout from "../../components/layouts/withDefaultDashBoardLayout";

import { addNewRoomType,updateRoomType } from '../../helpers/services/api.services'
import { Modal } from "react-bootstrap";
import { Form } from "react-bootstrap";
import { useFormik } from 'formik';
import * as Yup from 'yup';
import toast from "react-hot-toast";
 
const salesMasterFilters = [
    {
        label:"RM Items",
        value:"rm items",
    },
    {
        label:"Dishes",
        value:'dishes',
    },
    {
        label:"Meals",
        value:'meals',
    },
    {
        label:"Kitchen",
        value:'kitchen',
    },
    {
        label:"Services",
        value:'services',
    },
    {
        label:"Courses",
        value:'courses',
    },
    {
        label:"Recipes",
        value:'recipes',
    }
]


function RoomTypeList() {
  const navigate = useNavigate();

  const [activeItem, setActiveItem] = useState({
    label:"Dishes",
    value:'dishes'
});

  // const [activePropertyStatus, setActivePropertyStatus] = useState("All");
  // Data-table
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(100);
  // const [page, setPage] = useState(1);

  const [operation,setOperation] = useState("Create");

  const [showCreatePropertyModal, setShowCreatePropertyModal] = useState(false);
  const handleCloseConfirmNocModal = () => {
    formik.resetForm();
    setShowCreatePropertyModal(false);
  }
  const handleCreatePropertyModal = () => setShowCreatePropertyModal(true);


  useEffect(() => {
    loadData();
  }, []);

  const loadData = async () => {
    setLoading(true);

    let response = await fetchRoomTypes();

    if (response && response.data && response.data.length > 0) {
      setData(response.data);
      if (response?.meta && response?.meta?.total_data_count && response?.meta?.total_pages) {
        setTotalRows(response?.meta?.total_data_count);
      }
      setLoading(false);
    } else {
      setData([]);
      setLoading(false);
    }
  };

  // const filteredResidentsData = async (status) => {
  //   setActivePropertyStatus(status);
  // };

  // const handlePageChange = () => {
  //   loadData();
  // };

  const columns = [
    {
      name: 'ID',
      selector: (row) => row.id,
      width: "10%",
      sortable: true,
    },
    {
      name: 'Name',
      width: "20%",
      selector: (row) => row.name,
      sortable: true,
    },
    {
      name: 'Slug',
      selector: (row) => row.slug,
      sortable: true,
    },
    {
        name: 'Beds Per Room',
        selector: (row) => row.beds_per_room,
        sortable: true,
      },
    {
      name: 'IS_ACTIVE',
      selector: (row) => row.is_active,
      sortable: true,
      cell: row => row.is_active ? "YES" : "NO",
    },
    {
        name: 'IS_DELETED',
        selector: (row) => row.is_deleted,
        sortable: true,
        cell: row => row.is_active ? "YES" : "NO",
      },
      {
        name: ' Created at',
        selector: (row) => row.created_at,
        sortable: true,
      },
      {
        name: 'Updated at',
        selector: (row) => row.updated_at,
        sortable: true,
      },
      {
        name: '',
        selector: (row) => row?.id,
        // width: "3%",
        cell: row => (
          <button
            style={{border:"none"}}
            data-bs-toggle="tooltip" data-bs-placement="top" title="Edit"
            onClick={()=>{
              setOperation("Edit")
              const fields = ['id','name', 'beds_per_room', 'is_active','is_deleted'];
              fields.forEach(field => formik.setFieldValue(field, row[field], false));
              handleCreatePropertyModal()
            }}
          >
            <img src="/images/edit.svg" alt="Edit Icon"/>
          </button>
        )
      },
  ];

//   const handleDetailsPageRedirection = (data) => {
//     navigate(`/property-details/${data?.id}`);
//   }

const initialValues ={
  name: "",
  beds_per_room: 0,
  is_active: true,
  is_deleted: false
}

const validationSchema = Yup.object().shape({
  name: Yup.string().matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed").required(),
  beds_per_room: Yup.number(),
});

const formik = useFormik({
  initialValues,
  validationSchema,
  onSubmit: async (values) => {
    try {
      // console.log("Submit values -- ",values)
      if(operation==="Create"){
        let response = await addNewRoomType(values);

        if (response && response?.status === 'success') {
          await toast.success('Room type has been added successfully.');
          handleCloseConfirmNocModal();
          loadData();
        } else {
          await toast.error('Something went wrong while add a room type details.');
        }
      }else{
        const {id,...rest}= values
        let response = await updateRoomType(id, rest);

        if (response && response?.status === 'success') {
          await toast.success('Room type has been updated successfully.');
          handleCloseConfirmNocModal();
          loadData();
        } else {
          await toast.error('Something went wrong while updating a room type details.');
        }
      }
    } catch (error) {
      console.log(error);
    }
  },
});

  return (
    <>
      <div className="property-header">
        <div className="right-side-header">
          <div className="d-flex justify-content-between align-items-center pb-2">
            <div className="d-flex align-items-center">
              <Link to={"/"}>
                <img src="/images/Back.svg" alt="Back" />
              </Link>
              <h2>Room Type</h2>
            </div>
          </div>
          <div className="breadcum">
            <ul>
              <li>
                <a href="#">Masters</a> <span>/</span>
              </li>
              <li>Room Type</li>
            </ul>
          </div>
        </div>
        <div className="create-prop">
          <button className="basic-button" onClick={()=>{
            setOperation('Create');
            handleCreatePropertyModal();
          }}>
            <img
                src="/images/plus-circle.svg"
                className="pr-2"
                alt=""
            />
            Add Room Type
          </button>
        </div>
      </div>

        {/* <div className={"content-container"}>
            <div className={'d-flex align-items-center gap-12'}>
                {
                    salesMasterFilters.map((bulkFilter) => {
                        return (<div onClick={() => { setActiveItem(bulkFilter) }} className={"tab-pane" + ' ' + ((activeItem?.value === bulkFilter.value) ? "active" : '')} key={"bulkFilter_" + bulkFilter.value}>
                            {bulkFilter.label}
                        </div>)
                    })
                }
            </div>
        </div> */}

      {/* <div className="d-flex justify-content-between align-items-center pb-32">
        <div className="status-box">
          {propertyStatus.map((status, index) => (
            <a
              key={index}
              href="#"
              className={status?.label === activePropertyStatus ? "active" : ""}
              onClick={() => filteredResidentsData(status?.label)}
            >
              {status?.label}
                </a>
            ))}
            </div>

            <div className="status-box">
            <a href="#">
                <img src="/images/filter.svg" className="pr-1" alt="" /> Filter
            </a>
            </div>
        </div> */}

      <div className="sr_data_table mt-4">
        <DataTable
          columns={columns}
          data={data}
          progressPending={loading}
        //   pagination
        //   paginationServer
        //   paginationTotalRows={totalRows}
        //   onChangePage={handlePageChange}
          fixedHeader={true}
          fixedHeaderScrollHeight={'430px'}
        //   onRowClicked={handleDetailsPageRedirection}
        ></DataTable>
      </div> 

      <Modal
        show={showCreatePropertyModal}
        onHide={handleCloseConfirmNocModal}
        centered
        size="lg"
        className="confirm_noc_modal"
      >
        <Modal.Body>
        <div className="head_part_date_modal create-prop_modal">
            <div className="d-flex justify-content-between align-items-center mb-4">
              <div>
                <h2 className="title">{operation} Room Type</h2>
              </div>
              <div style={{cursor:"pointer"}}>
                <span onClick={handleCloseConfirmNocModal} className="">
                  <img src="/images/cross_modal.svg" alt="Close Icon" />
                </span>
              </div>
            </div>
          </div>
          <div className="modal_data_part">
        <div className="create-prop_detail">
            <form onSubmit={formik.handleSubmit}>
              <div className="TrailWrap mb-4">
                <div>
                  <Form.Label>Name</Form.Label>
                  <Form.Control
                    placeholder="Enter Name"
                    id="room_type_name"
                    name="room_type_name"
                    value={formik.values.name}
                    onChange={(e) => formik.setFieldValue('name', e.target.value)}
                    style={formik.touched.name && formik.errors.name ? {
                      borderColor:'red'
                    }: null}
                  />
                  {
                    formik.touched.name && formik.errors.name ? (
                      <div className="error-label">{formik.errors.name}</div>
                    ) : null
                  }
                </div>
                <div>
                  <Form.Label>Beds Per Room</Form.Label>
                  <Form.Control
                    placeholder="Beds Per Room"
                    id="beds_per_room"
                    name="beds_per_room"
                    value={formik.values.beds_per_room}
                    onChange={(e) => formik.setFieldValue('beds_per_room', e.target.value)}
                    style={formik.touched.beds_per_room && formik.errors.beds_per_room ? {
                      borderColor:'red'
                    }: null}
                  />
                  {
                    formik.touched.beds_per_room && formik.errors.beds_per_room ? (
                      <div className="error-label">{formik.errors.beds_per_room}</div>
                    ) : null
                  }
                </div>
              </div>

              <div className="modal-footer">
                <button 
                  type="button" 
                  className="orange_brd_button p-12-16-button mr-3"
                  onClick={()=>handleCloseConfirmNocModal()}
                >CANCEL</button>
                <button type="submit" className="basic-button">{operation === "Create" ? 'SAVE' : 'UPDATE' }</button>
              </div>
            </form>
          </div>
        </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default withDefaultDashBoardLayout(RoomTypeList);
