import React, { useMemo } from "react";
import Modal from "react-bootstrap/Modal";
import styles from "./index.module.scss";
import StatusBadge from "../../../../../components/StatusBadge";
import { statusMapper } from "../../utils";
import { putPDCEntriesApi } from "../../../../../helpers/services/revenueservices";
import moment from "moment";

export default function PDCDetailsModal({ info, show, close , showButton }) {
  const statusBadge = statusMapper[info?.status] ?? statusMapper.collected;
  const btnInfo = useMemo(() => {
    if (info) {
      if (info.status === "rejected") {
        return {
          success: {
            label: "Collect",
            value: "collected",
          },
          fail: {
            label: "",
            value: "",
          },
        };
      } else if (info.status === "collected") {
        return {
          success: {
            label: "Approve",
            value: "approved",
          },
          fail: {
            label: "Reject",
            value: "rejected",
          },
        };
      } else if (info.status === "approved") {
        return {
          success: {
            label: "Deposit",
            value: "deposited",
          },
          fail: {
            label: "",
            value: "",
          },
        };
      }
      // Commented for not needed for manual changing status to utr_updated or bounced
      // else if (info.status === 'deposited') {
      //   return {
      //     success: {
      //       label: "UTR Update",
      //       value: "utr_updated"
      //     },
      //     fail: {
      //       label: "Bounce",
      //       value: "bounced"
      //     }
      //   }
      // }
    }
    return null;
  }, [info]);

  if (!info) {
    return <></>;
  }

  const onSave = async (value) => {
    try {
      await putPDCEntriesApi({
        id: info.id,
        status: value,
      });
      close(value, true);
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <Modal
      show={show}
      onHide={close}
      backdrop="static"
      dialogClassName={styles["dialog-pdc-container"]}
      keyboard={false}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id={styles["#modal-title"]}>
          <span>{info.cheque_number}</span>{" "}
          <StatusBadge
            style={{ ...statusBadge.style, marginLeft: "1rem" }}
            label={statusBadge.label}
          />
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
          <div className="d-flex flex-wrap">
            <div className={styles["column-5"]}>
              <div className={styles["view-block"]}>
                <label>Resident Name</label>
                <p>{info.lead_details?.resident_name}</p>
              </div>
            </div>
            <div className={styles["column-5"]}>
              <div className={styles["view-block"]}>
                <label>Student ID</label>
                <p>{info.lead_details?.lms_lead_id}</p>
              </div>
            </div>
            <div className={styles["column-5"]}>
              <div className={styles["view-block"]}>
                <label>Property ID</label>
                <p>{info.property_details?.property_code}</p>
              </div>
            </div>
            <div className={styles["column-5"]}>
              <div className={styles["view-block"]}>
                <label>Collection SPOC</label>
                <p>
                  <small>{info.created_by}</small>
                </p>
              </div>
            </div>
            <div className={styles["column-5"]}>
              <div className={styles["view-block"]}>
                <label>Date of Collection</label>
                <p>{moment(info.received_date).format("YYYY-MM-DD")}</p>
                </div>
            </div>

            <div className={styles["column-5"]}>
              <div className={styles["view-block"]}>
                <label>Cheque number</label>
                <p>{info?.cheque_number}</p>
              </div>
            </div>
            <div className={styles["column-5"]}>
              <div className={styles["view-block"]}>
                <label>Due Date</label>
                <p>{moment(info.due_date).format("YYYY-MM-DD")}</p>
              </div>
            </div>
            <div className={styles["column-5"]}>
              <div className={styles["view-block"]}>
                <label>Amount</label>
                <p>{info?.payment_amount}</p>
              </div>
            </div>
            <div className={styles["column-5"]}>
              <div className={styles["view-block"]} style={{ whiteSpace: "pre-wrap" }}>
                <label>Bank Name</label>
                <p>{info?.remarks}</p>
              </div>
            </div>
            {info.status === "utr_updated" ? (
              <div className={styles["column-5"]}>
                <div className={styles["view-block"]}>
                  <label>UTR Number</label>
                  <p>{info?.utr_number}</p>
                </div>
              </div>
            ) : (
              <></>
            )}
          </div>
          <hr />
          <div>
            {info?.cheque_image ? (
              <>
                <img src={info?.cheque_image} alt="" className="" style={{ height: "40vh", width: "100%", objectFit: "contain" }}  />
              </>
            ) : (
              <div className="my-32"><b>No Cheque Image</b></div>
            )}
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        {showButton && btnInfo ? (
          <>
            <button
              className={"btn " + styles["btn-cancel"]}
              style={{ visibility: btnInfo.fail.value ? "visible" : "hidden" }}
              onClick={() => onSave(btnInfo.fail.value)}
            >
              {btnInfo.fail.label}
            </button>
            <button
              className={"btn " + styles["btn-submit"]}
              style={{
                visibility: btnInfo.success.value ? "visible" : "invisible",
              }}
              onClick={() => onSave(btnInfo.success.value)}
            >
              {btnInfo.success.label}
            </button>
          </>
        ) : (
          <></>
        )}
      </Modal.Footer>
    </Modal>
  );
}
