import { Routes, Route, BrowserRouter  } from "react-router-dom";
import { Toaster } from "react-hot-toast";

// CSS
import "react-bootstrap-range-slider/dist/react-bootstrap-range-slider.scss";
import "./css/preload.scss";
import 'bootstrap/dist/css/bootstrap.min.css';
import "./App.scss";
// import { routes } from "./routes";
import AppRoutes from './routes'
import LoginPage from "./pages/login/login";
import PrivateRoutes from "./routes/privateRoute";
import { GoogleOAuthProvider } from '@react-oauth/google';
import GlobalStateProvider from "./GlobalStateProvider";

function App() {
  return (
    <GoogleOAuthProvider 
      clientId="995754363391-458v5ccoac6qoanr4d6h1r73qqjh1q5a.apps.googleusercontent.com"
    >
      <GlobalStateProvider>
        <div className="App">
            {/* <BrowserRouter>
              <Routes>
                <Route element={<PrivateRoutes />}>
                  {routes.map((route, index) => {
                      const { element: Element, ...rest } = route;
                      return (
                        <Route
                          {...rest}
                          key={route.path + "_" + index}
                          element={<Element />}
                        />
                      );
                    })}
                </Route>
                <Route element={<LoginPage/>} path="/"/>
              </Routes>
            </BrowserRouter> */}
          <AppRoutes />
          <Toaster position="bottom-right" reverseOrder={false} />
        </div>
      </GlobalStateProvider>
    </GoogleOAuthProvider>
  );
}

export default App;
