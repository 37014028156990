import React from "react";
import styles from "./index.module.scss";

export default function InputBox({ id, label, ...rest }) {
  return (
    <div
      className={"d-flex flex-column gap-1 " + styles["input-box-container"]}
    >
      <label className="font-manrope text-gray-600" htmlFor={id}>
        {label}
      </label>
      <input
        {...rest}
        id={id || ""}
        className={
          styles["input"] + " text-gray-500 font-manrope " + ((rest.className || ""))
        }
      />
    </div>
  );
}
