export const revenueBulkFilters = [
  {
    label: "PDC Status Update",
    value: "utr_update",
    ctaLabel: "BULK UPDATE UTR",
    popup: {
      heading: "Bulk Update UTR",
      filePlaceHolder: "Upload PDC report",
      filePlaceHolderDescription: "Upload CSV (Max 2 mb)",
    },
  },
  {
    label: "Wallet Recharge",
    value: "wallet_recharge",
    ctaLabel: "BULK WALLET RECHARGE",
    popup: {
      heading: "Bulk Wallet Recharge",
      filePlaceHolder: "Upload Wallet Recharge file",
      filePlaceHolderDescription: "Upload CSV (Max 2 mb)",
    },
  },
  {
    label: "Refund Process",
    value: "refund_process",
    ctaLabel: "BULK UPLOAD REFUND",
    popup: {
      heading: "Bulk Upload Refund",
      filePlaceHolder: "Upload Refund Status file",
      filePlaceHolderDescription: "Upload CSV (Max 2 mb)",
    },
  },
  {
    label: "Terminate Residents",
    value: "terminate_contract",
    ctaLabel: "BULK TERMINATE RESIDENTS",
    popup: {
      heading: "Terminate Resident Upload",
      filePlaceHolder: "Upload Bulk terminate Status file",
      filePlaceHolderDescription: "Upload CSV (Max 2 mb)",
    },
  },
  {
    label: "Lien Amount",
    value: "lien_amount",
    ctaLabel: "BULK LIEN AMOUNT",
    popup: {
      heading: "Bulk Lien Amount Upload",
      filePlaceHolder: "Upload Bulk Lien Amount file",
      filePlaceHolderDescription: "Upload CSV (Max 2 mb)",
    },
  },
  {
    label: "Late Fee Removal",
    value: "late_fee_removal",
    ctaLabel: "BULK Late Fee Removal",
    popup: {
      heading: "Bulk Late Fee Removal Upload",
      filePlaceHolder: "Upload Bulk Late Removal Fee file",
      filePlaceHolderDescription: "Upload CSV (Max 2 mb)",
    },
  },
  {
    label: "Transfer SD",
    value: "transfer_wallet_amount",
    ctaLabel: "BULK Transfer Wallet Amount",
    popup: {
      heading: "Bulk Transfer Wallet Amount Upload",
      filePlaceHolder: "Upload Bulk Transfer Wallet Amount file",
      filePlaceHolderDescription: "Upload CSV (Max 2 mb)",
    },
  },
  {
    label: "Forfeite SD",
    value: "forfeited_sd_balance",
    ctaLabel: "BULK Forfeite SD Balance",
    popup: {
      heading: "Bulk Forfeite SD Balance Upload",
      filePlaceHolder: "Upload Bulk Forfeite SD Balance file",
      filePlaceHolderDescription: "Upload CSV (Max 2 mb)",
    },
  },
];