import React, { useEffect, useState } from "react";
import { Modal, Form } from "react-bootstrap";
import ReactDatePicker from "react-datepicker";
import { Formik, Field, Form as FormikForm, ErrorMessage } from "formik";
import * as Yup from "yup";
import axios from "axios"; // For API calls
import { createResidentCheque } from "../../../helpers/services/api.services";
import toast from "react-hot-toast";
import RevenuePDCPage from "../../../pages/revenue/revenuePDCPage/revenuePDCPage";
import { getPDCEntriesApi } from "../../../helpers/services/revenueservices";
import DataTable from "react-data-table-component";
import moment from "moment";
import PDCDetailsModal from "../../../pages/revenue/revenuePDCPage/modal/PDCDetailsModal";

const PdcTab = ({ resident_id, onboarding_info, residentInfo }) => {
  const [openPdcModal, setOpenPdcModal] = useState(false);
  const [photo, setPhoto] = useState(null);
  const [submitLoader, setSubmitLoader] = useState(false);
  const [pdcData, setPdcData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(0); // Total rows for pagination
  const [page, setPage] = useState(1); // Current page
  const [perPage, setPerPage] = useState(10);
  const [openInfoModal, setOpenInfoModal] = useState(false);
  const [selectedInfo, setSelectedInfo] = useState({});

  const fetchPdcData = async (status, page, dateRange) => {
    setLoading(true);
    const response = await getPDCEntriesApi(
      status,
      page,
      dateRange,
      resident_id
    );
    if (response.status == "success") {
      setPdcData(response.data);
    }
    setLoading(false);
  };

  const initialValues = {
    chequeNumber: "",
    paymentDate: null,
    receivingDate: null,
    amount: "",
    bankName: "",
    photo: null,
    remarks: "",
  };

  const validationSchema = Yup.object({
    chequeNumber: Yup.string().required("Cheque number is required"),
    paymentDate: Yup.date().nullable().required("Payment date is required"),
    receivingDate: Yup.date().nullable().required("Receiving date is required"),
    amount: Yup.number()
      .required("Amount is required")
      .typeError("Amount must be a number"),
    remarks: Yup.string().required("Bank name is required"),
    photo: Yup.mixed().required("Image is required"),
  });

  const handleFileChange = (event, setFieldValue) => {
    const file = event.target.files[0];
    setFieldValue("photo", file); // Set the file in Formik's state
    setPhoto(file); // Also store it in the component state if needed
  };

  const handleSubmit = async (values, { resetForm }) => {
    setSubmitLoader(true);
    try {
      const formData = new FormData();
      formData.append("cheque_number", values.chequeNumber);
      formData.append("payment_date", values.paymentDate);
      formData.append("received_date", values.receivingDate);
      formData.append("payment_amount", values.amount);
      formData.append("bank_name", values.bankName);
      formData.append("document", values.photo); // Append photo from Formik state
      formData.append("remarks", values.remarks); // Append remarks

      // Static data for testing. Replace with dynamic data.
      formData.append(
        "lead_generation_id",
        residentInfo?.basic_details?.lead_generation_id
      );
      formData.append("property_id", onboarding_info?.property_details?.id);
      formData.append("resident_id", resident_id);

      const response = await createResidentCheque(formData);

      if (response?.status == "success") {
        toast.success("Form submitted");
        resetForm();
        setOpenPdcModal(false);
        setSubmitLoader(false);
        fetchPdcData(filters.status, 1, filters.dateRange);
      } else {
        toast.error(response?.response?.data?.errors);
      }
    } catch (error) {
      toast.error("Something went wrong please try again");
      console.error("API Error: ", error);
    }
  };

  const [filters, setFilters] = useState({
    dateRange: { startDate: "", endDate: "" },
    status: "",
    page: 1,
  });

  useEffect(() => {
    fetchPdcData(filters.status, 1, filters.dateRange);
  }, []);

  const handlePageChange = (page) => {
    setPage(page);
    fetchPdcData("", page, {}); // Fetch data for the new page
  };

  const columns = [
    {
      name: "Name",
      selector: (row) => row?.lead_details?.resident_name,
      width: "10%",
      sortable: true,
    },
    {
      name: "Cheque Number",
      selector: (row) => row?.cheque_number,
      width: "15%",
      sortable: true,
    },
    {
      name: "Property ID",
      selector: (row) => row?.property_details?.property_code,
      width: "10%",
      sortable: true,
    },
    {
      name: "Collection SPOC",
      selector: (row) => row?.created_by,
      width: "25%",
      sortable: true,
    },
    {
      name: "Date of Collection",
      selector: (row) => moment(row?.received_date).format("DD MMM YYYY"),
      width: "15%",
      sortable: true,
    },
    {
      name: "Due Date",
      selector: (row) => moment(row?.due_date).format("DD MMM YYYY"),
      width: "15%",
      sortable: true,
    },
    {
      name: "Status",
      selector: (row) => row?.status,
      width: "10%",
      sortable: true,
    },
  ];

  const handleDetailsPageRedirection = (data) => {
    setSelectedInfo(data);
    setOpenInfoModal(!openInfoModal);
  };

  return (
    <div>
      <div className="d-flex justify-content-between">
        <div></div>
        {residentInfo?.basic_details?.lead_generation_id && (
          <button
            className="basic-button"
            onClick={() => setOpenPdcModal(true)}
          >
            <img src="/images/plus-circle.svg" className="pr-2" alt="" /> Add
            New PDC
          </button>
        )}
      </div>

      <div className="sr_data_table mt-2">
        <DataTable
          columns={columns}
          data={pdcData}
          progressPending={loading}
          pagination
          paginationServer
          paginationTotalRows={totalRows}
          onChangePage={handlePageChange}
          fixedHeader={true}
          fixedHeaderScrollHeight={"430px"}
          onRowClicked={handleDetailsPageRedirection}
          //   onSort={handleSort}
          sortServer
          //   onChangeRowsPerPage={handleRowsPerPageChange}
        ></DataTable>
      </div>

      <PDCDetailsModal
        info={selectedInfo}
        show={openInfoModal}
        close={() => setOpenInfoModal(false)}
        showButton={false}
      />

      <Modal
        show={openPdcModal}
        onHide={() => setOpenPdcModal(false)}
        centered
        size="lg"
        className="check_popup_mobile"
      >
        <Modal.Header>
          <Modal.Title style={{ fontSize: "18px" }}>
            <b>Add PDC</b>
          </Modal.Title>
          <img
            src="/images/cross_modal.svg"
            className="cursor-pointer"
            alt="Close Icon"
            onClick={() => setOpenPdcModal(false)}
          />
        </Modal.Header>

        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ setFieldValue, values }) => (
            <FormikForm>
              <Modal.Body>
                <div className="row">
                  <div className="col-md-12 col-lg-6 col-sm-6">
                    <div className="form_control_box">
                      <label htmlFor="chequeNumber">Cheque Number</label>
                      <Field
                        type="text"
                        id="chequeNumber"
                        name="chequeNumber"
                        placeholder="Enter Cheque number"
                        className="form-control"
                      />
                      <ErrorMessage
                        name="chequeNumber"
                        component="div"
                        className="text-danger"
                      />
                    </div>

                    <div className="form_control_box" style={{ marginTop: 5 }}>
                      <div className="modal_input_main">
                        <Form.Label>Payment Date</Form.Label>
                        <ReactDatePicker
                          selected={
                            values.paymentDate
                              ? new Date(values.paymentDate)
                              : null
                          }
                          onChange={(val) => setFieldValue("paymentDate", val)}
                          className="form-control"
                          placeholderText="DD/MM/YY"
                          dateFormat="dd/MM/yyyy"
                        />
                        <ErrorMessage
                          name="paymentDate"
                          component="div"
                          className="text-danger"
                        />
                      </div>
                    </div>

                    <div className="form_control_box" style={{ marginTop: 5 }}>
                      <div className="modal_input_main">
                        <Form.Label>Receiving Date</Form.Label>
                        <ReactDatePicker
                          selected={
                            values.receivingDate
                              ? new Date(values.receivingDate)
                              : null
                          }
                          onChange={(val) =>
                            setFieldValue("receivingDate", val)
                          }
                          className="form-control"
                          placeholderText="DD/MM/YY"
                          dateFormat="dd/MM/yyyy"
                        />
                        <ErrorMessage
                          name="receivingDate"
                          component="div"
                          className="text-danger"
                        />
                      </div>
                    </div>
                  </div>

                  <div className="col-md-12 col-lg-6 col-sm-6">
                    <div className="form_control_box" style={{ marginTop: 5 }}>
                      <label htmlFor="amount">Amount</label>
                      <Field
                        type="number"
                        id="amount"
                        name="amount"
                        placeholder="Enter Amount"
                        className="form-control"
                      />
                      <ErrorMessage
                        name="amount"
                        component="div"
                        className="text-danger"
                      />
                    </div>

                    <div className="form_control_box" style={{ marginTop: 5 }}>
                      <label htmlFor="bankName">Bank Name</label>
                      <Field
                        type="text"
                        id="remarks"
                        name="remarks"
                        placeholder="Enter Bank Name"
                        className="form-control"
                      />
                      <ErrorMessage
                        name="bankName"
                        component="div"
                        className="text-danger"
                      />
                    </div>
                    <div className="form_control_box" style={{ marginTop: 5 }}>
                      <label>Upload Cheque Photo</label>
                      <input
                        type="file"
                        accept="image/*"
                        className="form-control"
                        onChange={(event) =>
                          handleFileChange(event, setFieldValue)
                        }
                        required
                      />
                      <ErrorMessage
                        name="photo"
                        component="div"
                        className="text-danger"
                      />
                    </div>
                  </div>
                </div>
              </Modal.Body>

              <Modal.Footer>
                <button
                  type="button"
                  className="orange_brd_button p-12-16-button mr-3"
                  style={{ width: "fit-content" }}
                  onClick={() => {
                    // Reset the form and clear uploaded photo
                    setPhoto(null);
                    setFieldValue("chequeNumber", "");
                    setFieldValue("paymentDate", null);
                    setFieldValue("receivingDate", null);
                    setFieldValue("amount", "");
                    setFieldValue("bankName", "");
                    setFieldValue("remarks", "");
                  }}
                >
                  RESET
                </button>
                <button
                  type="submit"
                  className="basic-button"
                  disabled={submitLoader}
                >
                  <div className="d-flex">
                    <div>{submitLoader ? "Submitting..." : "Submit"}</div>
                  </div>
                </button>
              </Modal.Footer>
            </FormikForm>
          )}
        </Formik>
      </Modal>
    </div>
  );
};

export default PdcTab;
