import { useEffect, useState, useContext } from "react";
import { Link, useParams } from "react-router-dom";
import { Tab, Tabs } from 'react-bootstrap';

import PropertyConstant from "../../helpers/constants/properties";
import withDefaultDashBoardLayout from "../../components/layouts/withDefaultDashBoardLayout";
import DetailsTab from "../../components/tabs/property-details/details.tab";
import BookingPLan from "../../components/tabs/property-details/booking.plan";
import OwnerKyc from "../../components/tabs/property-details/owner.kyc";
import WebsiteListing from "../../components/tabs/property-details/website.listing";
import Operations from "../../components/tabs/property-details/operations";
import { getPropertyDetail } from "../../helpers/services/api.services";
import { fetchClusters, fetchCities, fetchStates, } from "../../helpers/services/admin.services";
import GlobalStateContext from "../../GlobalStateContext";
import { getFiscalYear } from "../../helpers/common/common.function";

function PropertyDetails() {

    const {roles} = useContext(GlobalStateContext);
    let [propDetail, setPropDetail] = useState({});
    const { propertyId } = useParams();
    const [clusters, setClusters] = useState([]);
    const [states, setStates] = useState([]);
    const [cities, setCities] = useState([]);

    useEffect(() => {
        loadPropertyDetails();
        loadClusters();
        loadStates();
        loadCities();
    }, [propertyId]);

    const loadClusters = async () => {
        let response = await fetchClusters();
    
        if (response && response.data && response.data.length > 0) {
          let clusters = response.data.map(element => {
            return { value: element?.id, label: element?.cluster_name }
          });
    
          setClusters(clusters);
        }
      }
    
      const loadStates = async () => {
        let response = await fetchStates();
    
        if (response && response.data && response.data.length > 0) {
          let states = response.data.map(element => {
            return { value: element?.id, label: element?.name }
          });
          setStates(states);
        }
      }
    
      const loadCities = async () => {
        let response = await fetchCities();
    
        if (response && response.data && response.data.length > 0) {
          let cities = response.data.map(element => {
            return { value: element?.id, label: element?.city_name }
          });
          setCities(cities);
        }
      }

    const loadPropertyDetails = async () => {
        try {
            let response = await getPropertyDetail(propertyId);
            if (response && response.data) {
                setPropDetail(response.data);
            } else {
                setPropDetail([]);
            }
        }
        catch (error) {
            console.log(error)
        }

    };

    // console.log("propDetail -- ",propDetail?.owner_documents_details)
    return (
        <>
            <div className="rdp-right-side-header">
                <div className="d-flex justify-content-between align-items-center pb-2">
                    <div className="d-flex align-items-center">
                        <Link to={'/properties'}>
                            <img src="/images/Back.svg" alt="Back" />
                        </Link>
                        <div className="tit_point mx-3">{getFiscalYear()}</div>
                        <h2 className="m-0">{propDetail?.property_name}</h2>

                    </div>
                </div>
                <div className="breadcum">
                    <ul>
                        <li><Link to={'/properties'}>Properties</Link><span>/</span></li>
                        <li>{propDetail?.property_name}</li>
                    </ul>
                </div>
            </div>

            {/* Details Tab View */}
            <div className="main_tab">
                <Tabs
                    defaultActiveKey="detailsTab"
                    id="resident-details-view"
                    className="mb-4"
                >
                    <Tab eventKey="detailsTab" title="Details">
                        <DetailsTab 
                            propDetail={propDetail}
                            clusters={clusters}
                            states={states}
                            cities={cities}
                            loadPropertyDetails={()=>loadPropertyDetails()}
                        />
                    </Tab>
                    <Tab eventKey="bookingPlan" title="BookingPlans">
                        <BookingPLan propertyId={propDetail.id} room_details={propDetail?.room_details?.length > 0 ? propDetail.room_details : [] } 
                        documentDetails={propDetail?.owner_documents_details ?? []}
                        />
                    </Tab>
                    <Tab eventKey="owner-kyc" title="Owner & Kyc">
                        <OwnerKyc 
                            propDetail={propDetail}
                            documentDetails={propDetail?.owner_documents_details ?? []}
                        />
                    </Tab>
                    {/* <Tab eventKey="website-list" title="Website Listing">
                        <WebsiteListing />
                    </Tab> */}
                    {
                        roles?.super_admin ?
                        <Tab eventKey="exit" title="Operations">
                            <Operations/>
                        </Tab>
                        : null
                    }
                </Tabs>
               
            </div>
        </>
    );
}

export default withDefaultDashBoardLayout(PropertyDetails);