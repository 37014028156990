import React, { useEffect, useState } from "react";
import axios from "axios";
import { Formik, Form, Field, FieldArray, useFormik } from "formik";
import ReactDatePicker from "react-datepicker";
import * as Yup from "yup";
import toast from "react-hot-toast";
import { Modal } from "react-bootstrap";
import { fetchContractExpire } from "../../../helpers/services/admin.services";

export const DownloadModal = ({ showModal, setShowModal, propertyId }) => {
  const [dataType, setDataType] = useState("");
  const [selectedDate, setSelectedDate] = useState(new Date());

  const handleCloseModal = () => {
    setShowModal(false);
    setDataType("");
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (dataType == "") {
      return toast.error("Please select any option");
    } else if (selectedDate == "") {
      return toast.error("Please select a date");
    }

    const date = selectedDate ? new Date(selectedDate) : new Date();
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    const formattedDate = `${year}-${month}-${day}`;

    let response = await fetchContractExpire(
      dataType,
      formattedDate,
      propertyId
    );
    if (response.data && response.data.errors) {
      toast.error(response.data.errors);
    } else {
      handleCloseModal();
      const blob = new Blob([response], { type: "text/csv" });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      console.log("urrlll", url);
      link.href = url;
      const fileName = dataType +  "_" +year + "_" + month + ".csv";
      link.setAttribute("download", fileName);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    }
  };

  return (
    <div>
      <Modal show={showModal} onHide={handleCloseModal} centered size="lg">
        <Modal.Body>
          <div className="head_part_date_modal">
            <span onClick={handleCloseModal} className="close_modal">
              <img src="/images/cross_modal.svg" alt="Close Icon" />
            </span>
            <h2>Export Data</h2>
            <hr />
          </div>

          <form onSubmit={handleSubmit} id="abscond_form">
            <div className="d-flex items-center gap-4">
              <p><b>Choose Report type</b></p>
              <div className="d-flex items-center gap-24">
                <div class="form-check">
                  <input
                    class="form-check-input"
                    value="contract_expire_excel"
                    type="radio"
                    name="excelData"
                    onChange={(e) => setDataType(e.target.value)}
                    id="contract_expire"
                  />
                  <label class="form-check-label" htmlFor="contract_expire">
                    Contract Expiring
                  </label>
                </div>
                <div class="form-check">
                  <input
                    class="form-check-input"
                    value="get_schedule_excel"
                    type="radio"
                    name="excelData"
                    onChange={(e) => setDataType(e.target.value)}
                    id="schedule_excel"
                  />
                  <label class="form-check-label" htmlFor="schedule_excel">
                    Pending Payments
                  </label>
                </div>
                <div class="form-check">
                  <input
                    class="form-check-input"
                    value="get_occupancy_report"
                    type="radio"
                    name="excelData"
                    onChange={(e) => setDataType(e.target.value)}
                    id="occupancy_report"
                  />
                  <label class="form-check-label" htmlFor="schedule_excel">
                    Bed Allocation Report
                  </label>
                </div>
              </div>
            </div>

            <div className="modal_input_main">
              <div className="form-group">
                <label htmlFor="exampleFormControlSelect1">Enter Date</label>
                <ReactDatePicker
                  id="date_"
                  name="date_"
                  selected={selectedDate ? selectedDate : new Date()}
                  onChange={(date) => setSelectedDate(date)}
                  dateFormat="MM/yyyy"
                  showMonthYearPicker
                />
              </div>

              <div className="modal_btn_footer">
                <button
                  type="button"
                  onClick={handleCloseModal}
                  className="orange_brd_button p-12-16-button mr-3"
                >
                  CANCEL
                </button>
                <button
                  type="submit"
                  className="basic-button"
                  form="abscond_form"
                >
                  DOWNLOAD
                </button>
              </div>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </div>
  );
};
