import moment from "moment";

export const getFiscalYear = () => {
  const date = new Date(Date.now());

  const year = date.getFullYear();
  const month = date.getMonth() + 1;

  let financialYear;
  if (month >= 4) {
    financialYear = `${String(year).slice(-2)}-${String(year + 1).slice(-2)}`;
  } else {
    financialYear = `${String(year - 1).slice(-2)}-${String(year).slice(-2)}`;
  }
  return `FY ${financialYear}`;
};

export const dateFormat = (date) => {
  return moment(new Date(date)).format("DD MMM YYYY");
};

export const getFiscalYearForListing = () => {
  const date = new Date(Date.now());

  const year = date.getFullYear();
  const month = date.getMonth() + 1;

  let financialYear;
  if (month >= 4) {
    financialYear = `${String(year).slice(-2)}-${String(year + 1).slice(-2)}`;
  } else {
    financialYear = `${String(year - 1).slice(-2)}-${String(year).slice(-2)}`;
  }
  return `FY ${financialYear}`;
};
