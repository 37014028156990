export const revenuePDCFilters = [
  {
    label: "All Cheques",
    value: "",
  },
  {
    label: "Collected",
    value: "collected",
  },
  {
    label: "Approved",
    value: "approved",
  },
  {
    label: "Deposited",
    value: "deposited",
  },
  {
    label: "UTR updated",
    value: "utr_updated",
  },
  {
    label: "Bounced",
    value: "bounced",
  },
  {
    label: "Rejected",
    value: "rejected",
  },
];

export const statusMapper = {
  deposited: {
    style: {
      color: "#247D58",
      background: "#E3F9E1",
    },
    label: "Deposited",
  },
  collected: {
    style: {
      color: "#9A6600",
      background: "#FFEEDB",
    },
    label: "Collected",
  },
  invalid: {
    style: {
      color: "#B56C29",
      background: "#FFE6DB",
    },
    label: "Invalid",
  },
  rejected: {
    style: {
      color: "#B56C29",
      background: "#FFE6DB",
    },
    label: "Rejected",
  },
  approved: {
    style: {
      color: "#2C786A",
      background: "#D2FCFF",
    },
    label: "Approved",
  },
  bounced: {
    style: {
      color: "#DE1135",
      background: "#DE11350D",
    },
    label: "Bounced",
  },
  utr_updated: {
    style: {
      color: "#616ABD",
      background: "#CEE1FE",
    },
    label: "UTR Updated",
  },
};
