import React, { useEffect, useState } from "react";
import { getWebsiteAmenities } from "../../../helpers/services/websiteServices";
import DataTable from "react-data-table-component";
import Modalamenity from "../../../pages/website/home-page/modals/ModalAmenities";

const Amenities = () => {
  const [amenitiesConfigData, setAmenitiesConfigData] = useState([]);
  const [amenitiesMediaData, setAmenitiesMediaData] = useState([]);
  const [selectedAmenity, setSelectedAmenity] = useState("");
  const [selectedAmenityData, setSelectedAmenityData] = useState({});
  const [aminitiesIndex, setamaniteIndex] = useState();
  const [openModal, setOpenModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [modalAction, setModalAction] = useState("");

  const columns = [
    {
      name: "Name",
      selector: (row) => row.name,
      width: "10%",
      sortable: true,
    },
    {
      name: "Description",
      selector: (row) => row.discription,
      width: "80%",
      sortable: true,
    },
    {
      name: "Action",
      width: "5%",
      cell: (row, index) => (
        <img
          src="/images/edit.svg"
          alt=""
          className="p-3"
          onClick={() => {
            setModalAction("update");
            setamaniteIndex(index);
            setOpenModal(true);
          }}
        />
      ),
    },
    {
      name: "",
      width: "5%",
      cell: (row, index) => (
        <img
          src="/images/trash-03.png"
          alt=""
          className="p-3"
          onClick={() => {
            setamaniteIndex(index);
            setDeleteModal(true);
          }}
        />
      ),
    },
  ];

  const fetchAmenitiesData = async () => {
    try {
      const data = await getWebsiteAmenities();

      if (data.status == "success") {
        setAmenitiesConfigData(data.data.global_config_data.config_details);
        setAmenitiesMediaData(data.data.media_storages);
        setSelectedAmenity(data.data.global_config_data.config_details[0].name);
      }
    } catch (error) {
      console.log("Error Fetching data ---> ", error);
    }
  };

  useEffect(() => {
    fetchAmenitiesData();
  }, [deleteModal , aminitiesIndex]);

  const filterAmenitiesData = () => {
    const filterData = amenitiesConfigData.filter(
      (data) => data.name === selectedAmenity
    );
    setSelectedAmenityData(filterData[0]);
  };

  useEffect(() => {
    filterAmenitiesData();
  }, [selectedAmenity, aminitiesIndex, deleteModal]);

  return (
    <div>
      <div className="d-flex justify-content-between">
        <div>{/* <h4><b>Amenities</b></h4> */}</div>
        <div>
          <button
            className="basic-button"
            onClick={() => {
              setModalAction("add");
              setOpenModal(true);
            }}
          >
            Add Amenities
          </button>
        </div>
      </div>
      <div className="sr_data_table mt-4">
        <DataTable
          columns={columns}
          data={amenitiesConfigData}
          fixedHeader={true}
          fixedHeaderScrollHeight={"430px"}
        ></DataTable>
      </div>
      {/* {amenitiesConfigData.length > 0 && (
        <>
          <div className="row mt-2">
            <div className="col-2">
              <h6>
                <b>Name</b>
              </h6>
              {amenitiesConfigData?.map((data, index) => (
                <div
                  className="mt-4 status-box"
                  key={index}
                  onClick={() => setSelectedAmenity(data.name)}
                >
                  <a
                    className={
                      selectedAmenity === data.name
                        ? "basic-button text-white "
                        : ""
                    }
                  >
                    <b>{data.name}</b>
                  </a>
                </div>
              ))}
            </div>
            <div className="col-6">
              <h6>
                <b>Description</b>
              </h6>
              <div className="mt-3 text-lg">
                {selectedAmenityData?.discription}
              </div>
              <div>
                <img src="/images/room_confirmed.png" alt="" />
              </div>
            </div>
            <div className="col-3">
              <h6>
                <b></b>
              </h6>
              {selectedAmenityData?.bullet_points?.map((points, index) => (
                <div className="d-flex mt-2" key={index}>
                  <div>
                    <img src={points.icon} alt="" />
                  </div>
                  <div className="mt-1 mx-3">
                    <b>{points.name}</b>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </>
      )} */}
      <Modalamenity
        openModal={openModal}
        handler={setOpenModal}
        amenityConfigData={amenitiesConfigData}
        amenityIndex={aminitiesIndex}
        actionType={modalAction}
        deleteModal={deleteModal}
        setDeleteModal={setDeleteModal}
      />
    </div>
  );
};

export default Amenities;
