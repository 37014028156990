import { useEffect, useState } from 'react';
import { Tab, Tabs } from 'react-bootstrap';

// Tabs
import DetailsTab from '../onboarding-details/details.tab';
import PropertyTab from '../onboarding-details/property.tab';
import CollageContactsTab from '../onboarding-details/college_contacts.tab';
import { fetchOnboardingDetails } from '../../../helpers/services/api.services';
import KycTab from '../onboarding-details/kyc.tab';
import AgreementTab from '../onboarding-details/agreement.tab';
import PocCheckinTab from '../onboarding-details/check_in.tab';

import '../../../css/resident-details-tab.css';

function OnBoardingInfoTab({ residentInfo }) {

    const [onboardingInfo, setOnboardingInfo] = useState({});

    useEffect(() => {
        if(residentInfo?.basic_details?.lead_generation_id){
            loadOnboardingDetails();
        }
    }, [residentInfo]);

    const loadOnboardingDetails = async () => {
        let response = await fetchOnboardingDetails(residentInfo?.basic_details?.lead_generation_id);

        if (response && response.status === "success") {
            await setOnboardingInfo(response.data);
        }
    }

    return (
        <>
            {/* Details Tab View */}
            <Tabs
                defaultActiveKey="details"
                id="resident-onboarding-view"
                className="mb-3"
            >
                <Tab eventKey="details" title="Details">
                    <DetailsTab residentInfo={residentInfo} onboardingInfo={onboardingInfo} />
                </Tab>
                <Tab eventKey="property" title="Property">
                    <PropertyTab residentInfo={residentInfo} onboardingInfo={onboardingInfo} />
                </Tab>
                <Tab eventKey="collegeContacts" title="College & Contacts">
                    <CollageContactsTab residentInfo={residentInfo} onboardingInfo={onboardingInfo} />
                </Tab>
                <Tab eventKey="kyc" title="KYC">
                    <KycTab onboardingInfo={onboardingInfo} />
                </Tab>
                <Tab eventKey="agreement" title="Agreement">
                    <AgreementTab residentInfo={residentInfo} onboardingInfo={onboardingInfo} />
                </Tab>
                <Tab eventKey="checkIn" title="Check-in">
                    <PocCheckinTab onboardingInfo={onboardingInfo} onAction={loadOnboardingDetails} />
                </Tab>
            </Tabs>
        </>
    );

}

export default OnBoardingInfoTab;