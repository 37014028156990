import React, { useEffect, useRef, useState } from "react";
import { Modal } from "react-bootstrap";
import styles from "./index.module.scss";
import { fileToBase64 } from "../../../../../helpers/constants/func";
import { postBulkManualAdjustmentApi } from "../../../../../helpers/services/revenueservices";
import toast from "react-hot-toast";

export default function UploadAdjustmentModal({ show, close }) {
  const fileRef = useRef(null);
  const [invoiceType, setInvoiceType] = useState("");
  const [uploadFile, setUploadFile] = useState({
    filename: "",
    value: "",
    count: 0,
  });

  const [loading , setLoading] = useState(false)

  useEffect(() => {
    if (show) {
      setInvoiceType("");
      setUploadFile({
        filename: "",
        value: "",
        count: 0,
      });
    }
  }, [show]);

  const onOpenFile = () => {
    fileRef.current.click();
  };

  const onSubmit = async () => {
    try {
      setLoading(true)
      const response = await postBulkManualAdjustmentApi({
        file_type: invoiceType,
        file_name: uploadFile.filename,
        file: uploadFile.value,
      });
      if (response.data) {
        setLoading(false)
        close(true);
        toast.success("File uploaded successfully. Please check the uploaded sheet after few minutes")
      }
    } catch (e) {
      setLoading(false)
      toast.error("Error while uploading the file")
      console.log(e);
    }
  };

  const onFileChange = async (e) => {
    const { files } = e.target;
    if (files.length) {
      const file = files[0];
      if (
        file.size &&
        file.name.toLowerCase().lastIndexOf(".csv") === file.name.length - 4
      ) {
        const size = (file.size / (1024 * 1024)).toFixed(2);
        if (size) {
          let base64Text = await fileToBase64(file);
          base64Text = base64Text.replace("data:text/csv;base64,", "");
          setUploadFile((prev) => {
            let st = { ...prev };
            st.filename = files[0].name;
            st.value = base64Text;
            st.count++;
            return st;
          });
          return null;
        }
      }
    }
    setUploadFile((prev) => {
      let st = { ...prev };
      st.count++;
      return st;
    });
  };

  const onChangeInvoiceType = (e) => {
    const { checked, value } = e.target;
    if (checked) {
      setInvoiceType(value);
    }
  };

  const disabled = !(invoiceType && uploadFile.value);

  return (
    <Modal
      show={show}
      onHide={close}
      backdrop="static"
      dialogClassName={styles["dialog-container"]}
      keyboard={false}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id={styles["#modal-title"]}>
          Upload Adjustment file
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className={styles["body-content"]}>
          <div className="d-flex items-center gap-4">
            <p className={styles["row-label"]}>Choose Invoice Type</p>
            <div className="d-flex items-center gap-24">
              <div class="form-check">
                <input
                  onChange={onChangeInvoiceType}
                  class="form-check-input"
                  value="proforma_invoice"
                  type="radio"
                  name="invoiceType"
                  id="invoiceType1"
                />
                <label class="form-check-label" htmlFor="invoiceType1">
                  Pro-forma Invoice
                </label>
              </div>
              <div class="form-check">
                <input
                  onChange={onChangeInvoiceType}
                  class="form-check-input"
                  value="credit_note"
                  type="radio"
                  name="invoiceType"
                  id="creditNote"
                />
                <label class="form-check-label" htmlFor="creditNote">
                  Credit Note
                </label>
              </div>
            </div>
          </div>

          <div>
            <input
              type="file"
              className="d-none"
              ref={fileRef}
              accept=".csv"
              onChange={onFileChange}
              key={"fileInput_" + uploadFile.count}
            />
            <div className={styles["upload-container"]} onClick={onOpenFile}>
              <div className="" style={{ marginTop: "-3px" }}>
                <svg
                  width="21"
                  height="20"
                  viewBox="0 0 21 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M12.3304 1.89124V5.33335C12.3304 5.80006 12.3304 6.03342 12.4212 6.21168C12.5011 6.36848 12.6286 6.49596 12.7854 6.57586C12.9637 6.66669 13.197 6.66669 13.6637 6.66669H17.1059M12.3304 1.66663H7.99707C6.59694 1.66663 5.89687 1.66663 5.36209 1.93911C4.89169 2.17879 4.50924 2.56124 4.26955 3.03165C3.99707 3.56643 3.99707 4.26649 3.99707 5.66663V14.3333C3.99707 15.7334 3.99707 16.4335 4.26955 16.9683C4.50924 17.4387 4.89169 17.8211 5.36209 18.0608C5.89687 18.3333 6.59694 18.3333 7.99707 18.3333H13.3304C14.7305 18.3333 15.4306 18.3333 15.9654 18.0608C16.4358 17.8211 16.8182 17.4387 17.0579 16.9683C17.3304 16.4335 17.3304 15.7334 17.3304 14.3333V6.66663L12.3304 1.66663Z"
                    stroke="#0F7691"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M13.1641 12.5L10.6641 10M10.6641 10L8.16406 12.5M10.6641 10L10.6641 15"
                    stroke="#0F7691"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </div>
              {uploadFile.value ? (
                <p className={"mb-0 " + styles["label-filled"]}>
                  {uploadFile.filename}
                </p>
              ) : (
                <p className={"mb-0 " + styles["label-title"]}>
                  Upload bulk adjustment file
                </p>
              )}
              {uploadFile.value ? (
                <svg
                  width="16"
                  className="ms-auto"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M8.83958 2.40006L3.36624 8.1934C3.15958 8.4134 2.95958 8.84673 2.91958 9.14673L2.67291 11.3067C2.58624 12.0867 3.14624 12.6201 3.91958 12.4867L6.06624 12.1201C6.36624 12.0667 6.78624 11.8467 6.99291 11.6201L12.4662 5.82673C13.4129 4.82673 13.8396 3.68673 12.3662 2.2934C10.8996 0.913397 9.78624 1.40006 8.83958 2.40006Z"
                    stroke="#0F7691"
                    strokeWidth="1.5"
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M7.92578 3.3667C8.21245 5.2067 9.70578 6.61337 11.5591 6.80003"
                    stroke="#0F7691"
                    strokeWidth="1.5"
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M2 14.6666H14"
                    stroke="#0F7691"
                    strokeWidth="1.5"
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              ) : (
                <></>
              )}
            </div>
            <p className={styles["upload-file-desc"] + " mt-1"}>
              Upload CSV file (Max 2 mb)
            </p>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button className={"btn " + styles["btn-cancel"]} onClick={close}>
          CANCEL
        </button>
        {!loading ? <button
          className={
            "btn " +
            styles["btn-submit"] +
            " " +
            (disabled ? styles["disabled"] : "")
          }
          onClick={onSubmit}
        >
          UPLOAD
        </button> : <button
          className={
            "btn " +
            styles["btn-submit"] +
            " " +
            (loading ? styles["disabled"] : "")
          }
        >
          UPLOADING...
        </button> }
      </Modal.Footer>
    </Modal>
  );
}
