import '../../../css/onboarding-details-tab.css';
import { useEffect } from 'react';
import { useState } from 'react';
import { dateFormat } from '../../../helpers/common/common.function';

function DetailsTab({ residentInfo, onboardingInfo }) {
    const [basicDetails, setBasicDetails] = useState(null);

    useEffect(() => {
        // if (residentInfo && residentInfo?.basic_details) setBasicDetails(residentInfo?.basic_details);
        if (onboardingInfo) setBasicDetails(onboardingInfo);
        // console.log("onboardingInfo -- ",onboardingInfo)
    }, [residentInfo, onboardingInfo]);

    return (
        <>
            <div className='mob_details_tab_box'>
                <div className='row'>
                    <div className='col-md-6'>
                        <div className='brd_box_card pd-24-20'>
                            <div className='guest_part'>
                                <div className='d-flex align-items-center'>
                                    <div className='guest_img'><img src='/images/guest.png' className='pr-2' alt='' /></div>
                                    <div>
                                        <h1 className='m-0'>{basicDetails?.resident_name}</h1>
                                    </div>
                                </div>
                            </div>
                            <div className='info_box'>
                                <div className='row'>
                                    <div className='col-3 info_width'>
                                        <div>
                                            <h4>Gender</h4>
                                            <span>{basicDetails?.gender ?? '--'}</span>
                                        </div>
                                    </div>
                                    <div className='col-4 info_width'>
                                        <div>
                                            <h4>Nationality</h4>
                                            <span>{basicDetails?.nationality}</span>
                                        </div>
                                    </div>
                                    <div className='col-5 info_width'>
                                        <div>
                                            <h4>Date of Birth</h4>
                                            <span>{basicDetails?.dob ? dateFormat(basicDetails.dob) : ""}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='list_top_brd'>
                                <div className="d-flex justify-content-between  box_card_data">
                                    <h3>Student vs Working</h3>
                                    <i>:</i>
                                    <span>{basicDetails?.resident_type}</span>
                                </div>
                                <div className="d-flex justify-content-between  box_card_data">
                                    <h3>Property</h3>
                                    <i>:</i>
                                    <span>{basicDetails?.property_details?.property_name ?? '--'}</span>
                                </div>
                                <div className="d-flex justify-content-between  box_card_data">
                                    <h3>CRM Lead ID</h3>
                                    <i>:</i>
                                    <span>{basicDetails?.id ?? '--'}</span>
                                </div>
                                <div className="d-flex justify-content-between  box_card_data">
                                    <h3>LMS Lead ID</h3>
                                    <i>:</i>
                                    <span>{basicDetails?.lms_lead_id ?? '--'}</span>
                                </div>
                                <div className="d-flex justify-content-between  box_card_data">
                                    <h3>City</h3>
                                    <i>:</i>
                                    <span>{basicDetails?.city_name?.city_name} | {basicDetails?.resident_name}</span>
                                </div>
                                <div className="d-flex justify-content-between  box_card_data">
                                    <h3>Cluster</h3>
                                    <i>:</i>
                                    <span>{basicDetails?.property_details?.cluster_details?.cluster_name ?? '--'}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="brd_box_card mt-3">
                    <div className="box_head">
                        <div className="d-flex align-items-center">
                            <h2> <img src="/images/file-shield.svg" className="pr-2" alt="" /> VIRTUAL ACCOUNT</h2>
                        </div>
                    </div>

                    <div className='exit_tab_box'>
                        <div className="box_contain ">
                            <div className="sub_exit_box">
                                <div className="row">
                                    <div className="col-6 sub_exit_data_txt">
                                <h3>Account Holder Name</h3>
                                    <span>
                                    {basicDetails?.resident_name ?? '--'}
                                    </span>
                                    </div>
                                    <div className="col-6 sub_exit_data_txt">
                                    <h3>Account Number</h3>
                                    <span>
                                    {basicDetails?.mobile_number ? `YOSP${basicDetails?.mobile_number}` : "--" }
                                    </span>
                                    </div>
                                    <div className="col-6 sub_exit_data_txt">
                                    <h3>Bank Name</h3>
                                    <span>
                                    ICICI Bank
                                    </span>
                                    </div>
                                    <div className="col-6 sub_exit_data_txt">
                                    <h3>IFSC</h3>
                                    <span>
                                    ICIC0000104
                                    </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="brd_box_card mt-3">
                    <div className="box_head">
                        <div className="d-flex align-items-center">
                            <h2> <img src="/images/file-shield.svg" className="pr-2" alt="" /> POC</h2>
                        </div>
                    </div>

                    <div className='exit_tab_box'>
                        <div className="box_contain ">
                            <div className="sub_exit_box">
                                <div className="row">
                                    <div className="col-6 sub_exit_data_txt">
                                        <h3>RM</h3>
                                        {/* <span>{onboardingInfo && onboardingInfo?.regional_manager_details ? `${onboardingInfo?.regional_manager_details?.employee_first_name} ${onboardingInfo?.regional_manager_details?.employee_last_name}` : '--'}</span> */}
                                        <span>
                                            {onboardingInfo?.regional_manager_details?.employee_first_name ?? '--'}{' '}
                                            {onboardingInfo?.regional_manager_details?.employee_last_name ?? ''}
                                        </span>
                                    </div>
                                    <div className="col-6 sub_exit_data_txt">
                                        <h3>CM</h3>
                                        {/* <span>{onboardingInfo && onboardingInfo?.cluster_manager_details ? `${onboardingInfo?.cluster_manager_details?.employee_first_name} ${onboardingInfo?.cluster_manager_details?.employee_last_name}` : '--'}</span> */}
                                        <span>
                                            {onboardingInfo?.cluster_manager_details?.employee_first_name ?? '--'}{' '}
                                            {onboardingInfo?.cluster_manager_details?.employee_last_name ?? ''}
                                        </span>
                                    </div>
                                    <div className="col-6 sub_exit_data_txt">
                                        <h3>City Head</h3>
                                        {/* <span>{onboardingInfo && onboardingInfo?.city_head_details ? `${onboardingInfo?.city_head_details?.employee_first_name} ${onboardingInfo?.city_head_details?.employee_last_name}` : '--'}</span> */}
                                        <span>
                                            {onboardingInfo?.city_head_details?.employee_first_name ?? '--'}{' '}
                                            {onboardingInfo?.city_head_details?.employee_last_name ?? ''}
                                        </span>
                                    </div>
                                    <div className="col-6 sub_exit_data_txt">
                                        <h3>PM</h3>
                                        {/* <span>{onboardingInfo && onboardingInfo?.property_manager_details ? `${onboardingInfo?.property_manager_details?.employee_first_name} ${onboardingInfo?.property_manager_details?.employee_last_name}` : '--'}</span> */}
                                        <span>
                                            {onboardingInfo?.property_manager_details?.employee_first_name ?? '--'}{' '}
                                            {onboardingInfo?.property_manager_details?.employee_last_name ?? ''}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );

}

export default DetailsTab;
