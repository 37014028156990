export default {
    residentStatus: [
        { key: '', label: 'All' },
        { key: 'created', label: 'Created' },
        { key: 'out_standing', label: 'Out standing' }, // Outstanding
        { key: 'over_due', label: 'Over Due' }, // Overdue
        { key: 'od_late_fee', label: 'Late fee' },
        { key: 'od_wifi_disconnect', label: 'Wifi disconnect' },
        { key: 'od_laundary_disconnect', label: 'Laundary Disconnect' },
        { key: 'od_food_disconnect', label: 'Food disconnect' },
        { key: 'exit_tab', label: 'Exit' },
        { key: 'od_legal', label: 'Legal' },
        { key: 'od_eviction', label: 'Eviction' },
        { key: 'paid', label: 'Paid' },
    ]
};

// export default {
//     residentStatus: [
//         { key: '', label: 'All' },
//         { key: 'paid_tab', label: 'Paid' },
//         { key: 'os_tab', label: 'OS' }, // Outstanding
//         { key: 'od_tab', label: 'OD' }, // Overdue
//         // { key: '', label: 'Late fee interest' },
//         { key: 'wifi_disconnected_tab', label: 'Wifi disconnected' },
//         { key: 'services_stopped_tab', label: 'Service stopped' },
//         { key: 'to_be_evicted_tab', label: 'To be evicted' },
//         { key: 'exit_tab', label: 'Exit' },
//         { key: 'absconded_tab', label: 'Absconded' },
//         { key: 'terminated_tab', label: 'Terminated' },
//     ]
// };