import React, { useEffect, useMemo, useState } from "react";
import { Pagination } from "react-bootstrap";
import withDefaultDashBoardLayout from "../../../components/layouts/withDefaultDashBoardLayout";
import styles from "./revenuePDCPage.module.scss";
import BreadCrumb from "../../../components/BreadCrumb";
import Avatar from "react-avatar";
import { revenuePDCFilters, statusMapper } from "./utils";
import { getPDCEntriesApi } from "../../../helpers/services/revenueservices";
import moment from "moment";
import {
  formatToRupees,
  getPaginationList,
} from "../../../helpers/constants/func";
import StatusBadge from "../../../components/StatusBadge";
import PDCDetailsModal from "./modal/PDCDetailsModal";
import FilterDate from "../../../components/FilterDate";
import { getFiscalYear } from "../../../helpers/common/common.function";

function RevenuePDCPage() {
  const [selected, setSelected] = useState({
    show: false,
    info: null,
  });
  const [filters, setFilters] = useState({
    dateRange: { startDate: "", endDate: "" },
    status: "",
    page: 1,
  });
  const [listInfo, setListInfo] = useState({
    items: [],
    meta: {
      current_page: 1,
      total_data_count: 0,
      total_pages: 0,
    },
  });
  const onSelect = (view = false, value = null, callApi = false) => {
    const result = {
      show: false,
      info: null,
    };
    if (view) {
      result.show = true;
      result.info = value;
    } else {
      if (callApi) {
        getEntries(filters.status, filters.page, filters.dateRange);
      }
    }
    setSelected(result);
  };
  const getEntries = async (status, page, dateRange) => {
    try {
      const { data, meta } = await getPDCEntriesApi(status, page, dateRange);
      setListInfo({
        items: data.map((_i) => {
          let mDT = moment(_i?.received_date);
          _i.received_date =
            _i?.received_date && mDT.isValid() ? mDT.format("DD MMM YYYY") : "";
          mDT = moment(_i?.due_date);
          _i.due_date =
            _i?.due_date && mDT.isValid() ? mDT.format("DD MMM YYYY") : "";
          mDT = moment(_i?.payment_date);
          _i.payment_date =
            _i?.payment_date && mDT.isValid() ? mDT.format("DD MMM YYYY") : "";
          _i.payment_amount = _i.payment_amount
            ? formatToRupees(_i.payment_amount || 0.0)
            : "";
          return _i;
        }),
        meta,
      });
      window.scrollTo(0, 0);
    } catch (e) {
      setListInfo({
        items: [],
        meta: {
          current_page: 1,
          total_data_count: 0,
          total_pages: 0,
        },
      });
    }
  };

  useEffect(() => {
    getEntries(filters.status, filters.page, filters.dateRange);
  }, [filters]);

  const getRandomColor = () => {
    return Math.floor(Math.random() * 16777215).toString(16);
  };

  const onDateChange = (startDate, endDate) => {
    setFilters((prev) => {
      let st = { ...prev };
      st.dateRange = {
        ...st.dateRange,
        startDate: startDate ? moment(startDate).format("YYYY-MM-DD") : "",
        endDate: endDate ? moment(endDate).format("YYYY-MM-DD") : "",
      };
      st.page = 1;
      return st;
    });
  };

  const onHandleFilter = (value) => {
    setFilters((prev) => {
      let st = { ...prev };
      st.status = value;
      st.page = 1;
      return st;
    });
  };

  const onHandlePagination = (value) => {
    setFilters((prev) => {
      let st = { ...prev };
      st.page = value;
      return st;
    });
  };

  const paginateItems = useMemo(() => {
    if (listInfo.meta.total_pages) {
      return getPaginationList(filters.page, listInfo.meta.total_pages);
    }
    return [];
  }, [listInfo, filters.page]);

  return (
    <>
      <div className={styles["revenue-pdc-page"]}>
        <div className={styles["heading-container"]}>
          <div className={styles["heading-left"]}>
            <h1 className={"mb-0 " + styles["heading"]}>
              <span className={"font-zkga fw-800 text-gray-900"}>
                Post-Dated Cheque Approval -{" "}
              </span>
              <span className={"font-manrope fw-700 text-gray-600"}>
                {getFiscalYear()}
              </span>
            </h1>
            <div>
              <BreadCrumb
                seperator="/"
                list={[
                  {
                    label: "Revenue",
                    link: "",
                    active: false,
                  },
                  {
                    label: "Post-dated cheque process",
                    link: "",
                    active: true,
                  },
                ]}
              />
            </div>
          </div>
        </div>

        <div className={styles["content-container"]}>
          <div className={styles["content-filter"]}>
            <div className={styles["content-left"]}>
              {revenuePDCFilters.map((revenueFilter) => {
                return (
                  <button
                    onClick={() => {
                      onHandleFilter(revenueFilter.value);
                    }}
                    key={"revenueFilter_" + revenueFilter.value}
                    className={
                      "btn " +
                      (revenueFilter.value === filters.status
                        ? styles["active"]
                        : "")
                    }
                  >
                    {revenueFilter.label}
                  </button>
                );
              })}
            </div>
            <div className={styles["content-right"]}>
              <FilterDate
                dateRange={filters.dateRange}
                apply={onDateChange}
                placeHolder="Select Due Date Range"
                maxDate={new Date()}
              />
            </div>
          </div>
          <div className={styles["content-table"] + " table-responsive"}>
            <table className="table align-middle">
              <thead>
                <tr>
                  <th>Cheque ID</th>
                  <th>Name</th>
                  <th>Resident ID</th>
                  <th>Lead ID</th>
                  <th>Cheque Number</th>
                  <th>Property ID</th>
                  <th>Collection SPOC</th>
                  <th>Date of Collection</th>
                  <th>Due Date</th>
                  <th>Status</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {listInfo.items.map((item) => {
                  const statusBadge =
                    statusMapper[item.status] ?? statusMapper.collected;
                  return (
                    <tr key={"Cheque_" + item.id}>
                      <td>{item.id}</td>
                      <td>
                        <div className="d-flex align-items-center">
                          {item.lead_details?.resident_name ? (
                            <Avatar
                              name={
                                item.lead_details?.resident_name?.charAt(0) || ""
                              }
                              color={`#${getRandomColor()}`}
                              round={true}
                              size="24"
                              textBackground="transparent"
                              textColor="#ffffff"
                              className="me-1"
                            />
                          ) : (
                            <></>
                          )}
                          <span>{item.lead_details?.resident_name}</span>
                        </div>
                      </td>
                      <td>{item.resident_details?.id}</td>
                      <td>{item.lead_details?.id}</td>
                      <td>{item.cheque_number}</td>
                      <td>{item.property_details?.property_code}</td>
                      <td><>{item.created_by}</></td>
                      <td>{item.received_date}</td>
                      <td>{item.payment_date}</td>
                      <td>
                        <StatusBadge
                          style={statusBadge.style}
                          label={statusBadge.label}
                        />
                      </td>
                      <td className="text-center">
                        <svg
                          width="16"
                          onClick={() => {
                            onSelect(true, item);
                          }}
                          className="cursor-pointer"
                          height="16"
                          viewBox="0 0 16 16"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M6 4L10 8L6 12"
                            stroke="#A2AFB2"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
            {paginateItems.length > 1 ? (
              <div className="d-flex justify-content-end">
                <Pagination>
                  <Pagination.Item
                    disabled={filters.page === 1}
                    onClick={() => {
                      if (filters.page > 1) {
                        onHandlePagination(filters.page - 1);
                      }
                    }}
                  >
                    <svg
                      className="rotate-270deg"
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M4 10.5L8 6.5L12 10.5"
                        stroke="#FF5700"
                        stroke-width="1.25"
                        stroke-linecap="round"
                      />
                    </svg>
                  </Pagination.Item>
                  {paginateItems.map((item) => {
                    return (
                      <Pagination.Item
                        onClick={() => {
                          onHandlePagination(item);
                        }}
                        key={"PaginateItem_" + item}
                        active={item === filters.page}
                      >
                        {item}
                      </Pagination.Item>
                    );
                  })}
                  <Pagination.Item
                    disabled={!(filters.page < listInfo.meta.total_pages)}
                    onClick={() => {
                      if (filters.page < listInfo.meta.total_pages) {
                        onHandlePagination(filters.page + 1);
                      }
                    }}
                  >
                    <svg
                      className="rotate-90deg"
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M4 10.5L8 6.5L12 10.5"
                        stroke="#FF5700"
                        stroke-width="1.25"
                        stroke-linecap="round"
                      />
                    </svg>
                  </Pagination.Item>
                </Pagination>
              </div>
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
      <PDCDetailsModal
        info={selected.info}
        show={selected.show}
        close={(value, callAPi) => onSelect(false, value, callAPi)}
        showButton = {true}
      />
    </>
  );
}

export default withDefaultDashBoardLayout(RevenuePDCPage);
