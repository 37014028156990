import React, { useCallback, useEffect, useState } from 'react'
import styles from './index.module.scss';
import ActionMenu from './ActionMenu';
import { MenuCourse, getInitialDefaultMenu } from '../../interface/menu';
import moment from 'moment';
import { getMenusByDateRange, postCreateMenu, putPublishMenu, putUpdateMenu } from '../../../../helpers/services/foodservices';
import { getConvertedMenu } from '../../utils/conversion';
import Swal from 'sweetalert2'
import { useMemo } from 'react';

function CreateMenuComponent({ dateRange, meals, onAddPoll, onGoBack }) {
    const [openMenu, setOpenMenu] = useState(false);
    const [menuDates, setMenuDates] = useState([]);
    const [draftMenus, setDraftMenus] = useState([])

    const getMenus = async (dateRange, mealsList = []) => {
        try {
            const { data } = await getMenusByDateRange(moment(dateRange.start).format('DD-MM-YYYY'), moment(dateRange.end).format('DD-MM-YYYY'));
            if (data && Array.isArray(data) && data.length) {
                const menus = data.filter((d) => (!d.status));
                if (menus.length) {
                    setDraftMenus(menus.map((item) => {
                        return getConvertedMenu(item, mealsList);
                    }));
                    return true;
                }
            }
        } catch (e) {
            console.error(e);
        }
        return false;
    }
    const init = async (dateRange, mealsList = []) => {
        const dates = [];
        const startDate = moment(dateRange.start).format('YYYY-MM-DD');
        const endDate = moment(dateRange.end).add(1, 'days').format('YYYY-MM-DD');
        let current = startDate;
        while (current !== endDate) {
            dates.push(current);
            current = moment(current).add(1, 'days').format('YYYY-MM-DD')
        }
        setMenuDates(dates);
        const menuExec = await getMenus(dateRange, mealsList)
        if (!menuExec) {
            setDraftMenus([getInitialDefaultMenu(mealsList)])
        }
    }

    // const onAddMenu = () => {
    //     const menus = [...draftMenus];
    //     menus.push(getInitialDefaultMenu(meals));
    //     setDraftMenus(menus)
    // }

    const onActionCourse = useCallback((menuIndex, mealIndex, operation = 'add', courseIndex = -1) => {
        const mealCourses = JSON.parse(JSON.stringify(draftMenus[menuIndex].meals[mealIndex].courses || []));
        if (operation === 'add') {
            mealCourses.push(new MenuCourse());
        } else if (operation === 'delete' && courseIndex > -1) {
            mealCourses.splice(courseIndex, 1);
        }

        setDraftMenus((prev) => {
            let st = [...prev];
            st[menuIndex].meals[mealIndex].courses = mealCourses;
            return st;
        })
    }, [draftMenus, setDraftMenus])

    const onHandleMenu = (menuIndex, e, mealIndex = -1, courseIndex = -1) => {
        const { name, value } = e.target;
        const dateStr = e?.date || "";
        switch (name) {
            case 'name':
                setDraftMenus((prev) => {
                    let st = [...prev];
                    st[menuIndex].name = value;
                    return st;
                });
                break;
            case 'properties':
                setDraftMenus((prev) => {
                    let st = [...prev];
                    st[menuIndex].properties = value;
                    return st;
                });
                break;
            case 'course':
                setDraftMenus((prev) => {
                    let st = [...prev];
                    st[menuIndex].meals[mealIndex].courses[courseIndex].id = value.id;
                    st[menuIndex].meals[mealIndex].courses[courseIndex].name = value.name;
                    st[menuIndex].meals[mealIndex].courses[courseIndex].dateCourse = {};
                    return st;
                });
                break;
            case 'food_items':
                if (dateStr)
                    setDraftMenus((prev) => {
                        let st = [...prev];
                        st[menuIndex].meals[mealIndex].courses[courseIndex].dateCourse[dateStr] = value;
                        return st;
                    });
                break;
            default:
                break;

        }
    }

    const doPublishMenu = async (name) => {
        try {
            let response = null;
            response = await putPublishMenu(name);
            return response ? true : false;
        } catch (e) {
            console.error(e);
        }
        return false;
    }

    const doSaveMenu = async (publish, req, update = false) => {
        try {
            let response = null;
            if (update) {
                response = await putUpdateMenu(req);
            } else {
                response = await postCreateMenu(req);
            }
            if (publish) {
                return await doPublishMenu(req.menu_name, req);
            }
            return response ? true : false;
        } catch (e) {
            console.error(e);
            return false;
        }
    }

    const draftsRequests = useMemo(()=>{
        return draftMenus.reduce((_r, draftMenu) => {
            if(!(draftMenu.name && draftMenu.properties.length) || !_r.add){
                _r.add=false;
                return _r;
            }
            const request = {
                "updateAction": draftMenu.id === 1,
                "menu_name": draftMenu.name,
                "property_ids": draftMenu.properties.map(d => d.id),
                "menu_details": []
            }
            const menuDateItems = {};
            const draftMeals = draftMenu.meals;
            for (let i = 0; i < draftMeals.length; i++) {
                const mealId = draftMeals[i].id;
                const draftCourses = draftMeals[i].courses;
                for (let j = 0; j < draftCourses.length; j++) {
                    const dateCourse = draftCourses[j].dateCourse;
                    for (const key in dateCourse) {
                        const foodItems = dateCourse[key].filter((d) => d.id);
                        if (foodItems.length) {
                            if (menuDateItems[key]) {
                                menuDateItems[key].push(...foodItems.map((foodItem) => ({
                                    "food_item_id": foodItem.id,
                                    "meal_session_id": mealId
                                })));
                            } else {
                                menuDateItems[key] = foodItems.map((foodItem) => ({
                                    "food_item_id": foodItem.id,
                                    "meal_session_id": mealId
                                }));
                            }
                        }
                    }
                }
            }
            if (Object.keys(menuDateItems).length) {
                for (const itemKey in menuDateItems) {
                    request.menu_details.push({
                        "menu_date": itemKey,
                        "menu_items": menuDateItems[itemKey]
                    })
                }
                _r.entries.push(request);
            }
            return _r;
        }, { entries:[], add:true }).entries;
    },[draftMenus])

    const onSaveDraft = async (publish = false) => {

        let _rCounter = 0;
        for (let i = 0; i < draftsRequests.length; i++) {
            const { updateAction, ...rest } = draftsRequests[i];
            const response = await doSaveMenu(publish, rest, updateAction);
            if (response) {
                _rCounter++;
            }
        }
        if (_rCounter) {
            Swal.fire({
                title: "Menu Creation " + ((publish) ? "Publish" : "Draft") + " Submission Success",
                text: "",
                icon: "success"
            });
            init(dateRange, meals);
        }

    }

    useEffect(() => {
        if (dateRange && meals?.length) {
            init(dateRange, meals)
        }
        // eslint-disable-next-line
    }, [dateRange, meals])

    return (<>
        <div className={"d-flex align-items-center " + styles['createmenu-header']}>
            <h4 className={styles["menu-title"] + " me-auto"}>Menu for {dateRange.start} - {dateRange.end}</h4>
            <div className='ms-auto d-flex align-item-center gap-24'>
                {
                    (openMenu) ? (<>
                        <button className={'btn ' + styles['draft']} onClick={() => { setOpenMenu(false); onGoBack(); }}>CANCEL</button>
                        <button className={'btn '+((draftsRequests.length===0)?styles['disabled']:'')+' ' + styles['draft']} onClick={() => { onSaveDraft() }}>SAVE DRAFT</button>
                        <button className={'btn '+((draftsRequests.length===0)?styles['disabled']:'')+' '  + styles['publish']} onClick={() => { onSaveDraft(true) }}>PUBLISH</button>
                    </>) : (<>
                        <button className={'btn ' + styles['next-poll']} onClick={() => onAddPoll(true)}>
                            <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M12.5 3.99609V19.9961M4.5 11.9961H20.5" stroke="#FF5700" strokeWidth="2" strokeLinecap="round" />
                            </svg>
                            <span>ADD NEW POLL</span>
                        </button>
                    </>)
                }
            </div>
        </div>
        <div className={styles['createmenu-container']}>
            {
                (openMenu && draftMenus?.length) ? (<div className='d-flex flex-column gap-24'>
                    {
                        draftMenus.map((draftMenu, index) => {
                            return (<ActionMenu
                                key={"DraftMenu_" + index}
                                onActionCourse={(...args) => onActionCourse(index, ...args)}
                                menuDates={menuDates}
                                info={draftMenu}
                                onChange={(...args) => onHandleMenu(index, ...args)}
                            />)
                        })
                    }

                    {/* <div className='text-center'>
                        <button className={'btn ' + styles['next-poll']} onClick={onAddMenu}>
                            <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M12.5 3.99609V19.9961M4.5 11.9961H20.5" stroke="#FF5700" strokeWidth="2" strokeLinecap="round" />
                            </svg>
                            <span>ADD NEXT MENU</span>
                        </button>
                    </div> */}
                </div>) : (<>
                    <div className={styles['add-view']}>
                        <div className='d-flex flex-column gap-16'>
                            <div>
                                <img className='img-fluid' src="/images/food-menu/create-menu.png" alt="create_menu" />
                            </div>
                            <p className={styles['description']}>You may start prepping the menu for the week</p>
                            <div className={styles['create-menu']}>
                                <button onClick={() => { setOpenMenu(true) }} className={'btn ' + styles['create-menu-cta']}>CREATE MENU</button>
                            </div>
                        </div>
                    </div>
                </>)
            }

        </div>
    </>)
}
export default CreateMenuComponent