import React, { useEffect, useState } from "react";
import { getWebsiteCarousal } from "../../../helpers/services/websiteServices";
import { activateBtn } from "../../../pages/masters/EmployeList";
import ModalCrousel from "../../../pages/website/home-page/modals/ModalCrousel";
import DataTable from "react-data-table-component";

const Carousal = () => {
  const [carousalConfigData, setcarousalConfigData] = useState([]);
  const [carousalMediaData, setcarousalMediaData] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [modalAction, setModalAction] = useState("");
  const [carousalIndex, setcarousalIndex] = useState();
  const [additional_data, setAdditional_data] = useState({});
  const [deleteModal, setDeleteModal] = useState(false);

  const handler = () => {
    setOpenModal(!openModal);
  };
  const fetchcarousalData = async () => {
    try {
      const data = await getWebsiteCarousal();

      if (data.status == "success") {
        setcarousalConfigData(data.data.global_config_data.config_details);
        setcarousalMediaData(data.data.media_storages);
        setAdditional_data({
          id: data.data.global_config_data.id,
          name: data.data.global_config_data.name,
        });
      }
    } catch (error) {
      console.log("Error Fetching data ---> ", error);
    }
  };

  useEffect(() => {
    fetchcarousalData();
  }, [deleteModal , carousalIndex]);

  const columns = [
    {
      name: "Image",
      selector: (row) => row.image,
      width: "20%",
      cell: (row, index) => (
        <>
          <img
            src={row.image}
            alt=""
            style={{
              height: "100px",
              width: "200px",
            }}
            className="py-2 "
          />
        </>
      ),
    },
    {
      name: "Description",
      selector: (row) => row.description,
      width: "25%",
      sortable: true,
    },
    {
      name: "Title",
      selector: (row) => row.title,
      width: "30%",
      sortable: true,
    },
    {
      name: "Order",
      selector: (row) => row.order,
      width: "10%",
      sortable: true,
    },
    {
      name: "Action",
      width: "10%",
      cell: (row, index) => (
        <img
          src="/images/edit.svg"
          alt=""
          className="p-3"
          onClick={() => {
            setcarousalIndex(index);
            setModalAction("update");
            handler();
          }}
        />
      ),
    },
    {
      name: "",
      width: "5%",
      cell: (row, index) => (
        <img
          src="/images/trash-03.png"
          alt=""
          className="p-3"
          onClick={() => {
            setcarousalIndex(index)
            setDeleteModal(true);
          }}
        />
      ),
    },
  ];

  return (
    <div>
      <div className="d-flex justify-content-end">
        <button
          className="basic-button"
          onClick={() => {
            setModalAction("add");
            handler();
          }}
        >
          Add New Carousal
        </button>
      </div>

      <div className="sr_data_table mt-4">
        <DataTable
          columns={columns}
          data={carousalConfigData}
          fixedHeader={true}
          fixedHeaderScrollHeight={"430px"}
        ></DataTable>
      </div>

      {/* 
      <div className="mt-4">
        <div className="bg-light">
          <hr />
          <div className="row">
            <div className="col-3 text-center">
              <b>Image</b>
            </div>
            <div className="col-8 text-center">
              <b>Details</b>
            </div>
            <div className="col-1">
              <b>Action</b>
            </div>
          </div>
          <hr />
        </div>
        {carousalConfigData?.length > 0 &&
          carousalConfigData?.map((data, index) => (
            <div className="p-2" key={index} draggable={true}>
              <div className="row">
                <div className="col-3">
                  <img
                    src={data.image}
                    alt=""
                    className="rounded"
                    style={{
                      height: "150px",
                      width: "350px",
                    }}
                  />
                </div>
                <div className="col-8">
                  <div className="d-flex">
                    <button style={activateBtn}>Title : </button>
                    <div className="mx-3">
                      <b>{data.title}</b>
                    </div>
                  </div>
                  <div className="d-flex mt-3">
                    <button style={activateBtn}>Description : </button>
                    <div className="mx-3">
                      <>{data.description}</>
                    </div>
                  </div>
                  <div className="d-flex mt-3">
                    <button style={activateBtn}>
                      Order : <b>{data.order}</b>
                    </button>
                  </div>
                </div>
                <div className="col-1 d-flex justify-content-center align-items-center">
                  <button
                    className=""
                    style={activateBtn}
                    onClick={() => {
                      setcarousalIndex(index);
                      setModalAction("update");
                      handler();
                    }}
                  >
                    Edit
                  </button>
                </div>
              </div>
              <hr />
            </div>
          ))}
      </div> */}
      <ModalCrousel
        carouselConfigData={carousalConfigData}
        openModal={openModal}
        carousalIndex={carousalIndex}
        actionType={modalAction}
        handler={handler}
        carouselMediaStorage={carousalMediaData}
        additional_data={additional_data}
        deleteModal={deleteModal}
        deleteModalHandler={setDeleteModal}
      />
    </div>
  );
};

export default Carousal;
