import { useFormik } from "formik";
import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import * as Yup from "yup";
import { updateBondDetails } from "../../../../helpers/services/api.services";
import toast from "react-hot-toast";

// Validation Schema
const validationSchema = Yup.object({
  pan_number: Yup.string().required("PAN number is required"),
  gross_income: Yup.number()
    .required("Gross Income is required")
    .min(0, "Income cannot be negative"),
  employment_type: Yup.string().required("Employment type is required"),
  employment_proof_type: Yup.string().required(
    "Employment proof type is required"
  ),
  employment_proof_image: Yup.mixed().required("Proof image is required"),
});

const UpdateBondDetails = ({
  id,
  details,
  showModal,
  setShowModal,
  fetchData,
}) => {
  const [initialValues] = useState({
    pan_number: details?.applicant?.details?.pan_number || "",
    gross_income: details?.applicant?.details?.gross_income || "",
    employment_type: details?.applicant?.details?.employment_type || "",
    employment_proof_type:
      details?.applicant?.details?.employment_proof_type || "",
    employment_proof_image:
      details?.applicant?.details?.employment_proof || null,
    relation: details?.applicant?.relation
      ? details?.applicant?.relation
      : "Self",
  });
  const [loading, setLoading] = useState(false);

  const [imagePreview, setImagePreview] = useState(
    details?.applicant?.details?.employment_proof || ""
  );
  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values) => {
      setLoading(true);
      const formData = new FormData();
      formData.append("pan_number", values.pan_number);
      formData.append("gross_income", values.gross_income);
      formData.append("employment_type", values.employment_type);
      formData.append("employment_proof_type", values.employment_proof_type);
      formData.append("relation", values.relation);
      formData.append("applicant_id", details?.applicant?.details?.id);

      // Conditionally append the employment_proof_image only if it has changed
      if (
        values.employment_proof_image !== initialValues.employment_proof_image
      ) {
        formData.append(
          "employment_proof_image",
          values.employment_proof_image
        );
      }

      try {
        const response = await updateBondDetails(id, formData);

        if (response && response?.data) {
          setShowModal(false);
          toast.success("Bond Details updated");
          fetchData();
        } else {
          toast.error(response?.response?.data?.errors);
        }
      } catch (error) {
        toast.error("An error occurred while updating the bond details.");
      } finally {
        setLoading(false);
      }

      console.log("FormData submitted");
    },
  });

  const handleCloseBondModal = () => {
    setShowModal(false);
    formik.resetForm();
    setImagePreview(details?.applicant?.details?.employment_proof || "");
  };

  const handleImageChange = (event) => {
    const file = event.currentTarget.files[0];
    if (file) {
      formik.setFieldValue("employment_proof_image", file);

      // Preview the selected image
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  return (
    <div>
      <Modal
        show={showModal}
        onHide={handleCloseBondModal}
        centered
        size="lg"
        className="commen_modal"
      >
        <Modal.Header>
          <Modal.Title>Document update for bond issue</Modal.Title>
          <img
            src="/images/cross_modal.svg"
            className="cursor-pointer"
            alt="Close Icon"
            onClick={handleCloseBondModal}
          />
        </Modal.Header>
        <form onSubmit={formik.handleSubmit}>
          <Modal.Body>
            <div className="row">
              {/* PAN Number Field */}
              <div className="col-md-6 col-lg-4 col-sm-6">
                <div className="form_control_box">
                  <label className="form-label" htmlFor="pan_number">
                    PAN Number
                  </label>
                  <input
                    type="text"
                    id="pan_number"
                    name="pan_number"
                    placeholder="Enter PAN Number"
                    className="form-control"
                    value={formik.values.pan_number}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  {formik.touched.pan_number && formik.errors.pan_number ? (
                    <div className="text-danger">
                      {formik.errors.pan_number}
                    </div>
                  ) : null}
                </div>
              </div>

              {/* Gross Income Field */}
              <div className="col-md-6 col-lg-4 col-sm-6">
                <div className="form_control_box">
                  <label className="form-label" htmlFor="gross_income">
                    Gross Income (monthly)
                  </label>
                  <input
                    type="text"
                    id="gross_income"
                    name="gross_income"
                    placeholder="Enter Gross Income"
                    className="form-control"
                    value={formik.values.gross_income}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  {formik.touched.gross_income && formik.errors.gross_income ? (
                    <div className="text-danger">
                      {formik.errors.gross_income}
                    </div>
                  ) : null}
                </div>
              </div>

              {/* Employment Type Dropdown */}
              <div className="col-md-6 col-lg-4 col-sm-6">
                <div className="form_control_box">
                  <label className="form-label" htmlFor="employment_type">
                    Employment Type
                  </label>
                  <select
                    id="employment_type"
                    name="employment_type"
                    className="form-control"
                    value={formik.values.employment_type}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  >
                    <option value="" label="Select employment type" />
                    <option value="Salaried" label="Salaried" />
                    <option
                      value="Salaried - Yet to join"
                      label="Salaried - Yet to join"
                    />
                    <option value="Self Employed" label="Self Employed" />
                  </select>
                  {formik.touched.employment_type &&
                  formik.errors.employment_type ? (
                    <div className="text-danger">
                      {formik.errors.employment_type}
                    </div>
                  ) : null}
                </div>
              </div>

              {/* Employment Proof Type Dropdown */}
              <div className="col-md-6 col-lg-4 col-sm-6">
                <div className="form_control_box">
                  <label className="form-label" htmlFor="employment_proof_type">
                    Employment Proof Type
                  </label>
                  <select
                    id="employment_proof_type"
                    name="employment_proof_type"
                    className="form-control"
                    value={formik.values.employment_proof_type}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  >
                    <option value="" label="Select proof type" />
                    <option value="offer_letter" label="Offer Letter" />
                    <option value="Office Id" label="Office Id" />
                    <option value="Salary Slip" label="Salary Slip" />
                    <option value="Bank Statement" label="Bank Statement" />
                  </select>
                  {formik.touched.employment_proof_type &&
                  formik.errors.employment_proof_type ? (
                    <div className="text-danger">
                      {formik.errors.employment_proof_type}
                    </div>
                  ) : null}
                </div>
              </div>

              {/* Relation Dropdown */}
              {details?.applicant?.relation && (
                <div className="col-md-6 col-lg-4 col-sm-6">
                  <div className="form_control_box">
                    <label className="form-label" htmlFor="relation">
                      Relation
                    </label>
                    <select
                      id="relation"
                      name="relation"
                      className="form-control"
                      value={formik.values.relation}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    >
                      <option value="" label="Select relation" />
                      <option value="Father" label="Father" />
                      <option value="Mother" label="Mother" />
                      <option value="Brother" label="Brother" />
                      <option value="Sister" label="Sister" />
                    </select>
                    {formik.touched.relation && formik.errors.relation ? (
                      <div className="text-danger">
                        {formik.errors.relation}
                      </div>
                    ) : null}
                  </div>
                </div>
              )}
              {/* Employment Proof Image Upload */}
              <div className="col-md-6 col-lg-4 col-sm-6">
                <div className="form_control_box">
                  <label
                    className="form-label"
                    htmlFor="employment_proof_image"
                  >
                    Employment Proof Image
                  </label>
                  {imagePreview && (
                    <div className="mb-2">
                      <img
                        src={imagePreview}
                        alt="Employment Proof"
                        style={{
                          width: "100%",
                          height: "auto",
                          objectFit: "cover",
                        }}
                      />
                    </div>
                  )}
                  <input
                    type="file"
                    id="employment_proof_image"
                    name="employment_proof_image"
                    className="form-control"
                    onChange={handleImageChange}
                    onBlur={formik.handleBlur}
                  />
                  {formik.touched.employment_proof_image &&
                  formik.errors.employment_proof_image ? (
                    <div className="text-danger">
                      {formik.errors.employment_proof_image}
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button
              className="orange_brd_button p-12-16-button mr-3"
              onClick={() => {
                setShowModal(false);
              }}
            >
              CANCEL
            </button>
            <button type="submit" className=" basic-button " disabled={loading}>
              {loading ? "Updating... " : "Update Bond Details"}
            </button>
          </Modal.Footer>
        </form>
      </Modal>
    </div>
  );
};

export default UpdateBondDetails;
