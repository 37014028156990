import { BASE_API_URL } from "../constants";
import apiClient from "./apiClient";

const FOOD_BASE_URL = BASE_API_URL + "/food_erp_dashboard/v1/admin";

export const getApiCourses = async (categoryName = "") => {
    try {
        return await apiClient.get(FOOD_BASE_URL + "/food_categories_lite", { by_name: categoryName })
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                return error;
            });
    } catch (error) {
        console.error('Error fetching data', error);
        throw error;
    }
}

export const getApiCourseItems = async (courseId = '', courseName = '') => {
    try {
        return await apiClient.get(FOOD_BASE_URL + "/food_items_lite", { by_name: courseName, by_food_category: courseId })
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                return error;
            });
    } catch (error) {
        console.error('Error fetching data', error);
        throw error;
    }
}

export const getApiMeals = async () => {
    try {
        return await apiClient.get(FOOD_BASE_URL + "/property_meal_session/property/1")
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                return error;
            });
    } catch (error) {
        console.error('Error fetching data', error);
        throw error;
    }
}

export const postPollMenu = async (request) => {
    try {
        return await apiClient.post(FOOD_BASE_URL + "/create_food_item_preference", request)
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                return error.data;
            });
    } catch (error) {
        console.error('Error fetching data', error);
        throw error;
    }
}

export const putPollMenu = async (request) => {
    try {
        return await apiClient.put(FOOD_BASE_URL + "/update_food_item_preference", request)
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                return error.data;
            });
    } catch (error) {
        console.error('Error fetching data', error);
        throw error;
    }
}

export const getPollMenu = async (startDate, endDate) => {
    try {
        return await apiClient.get(FOOD_BASE_URL + "/food_item_preferences", { start_date: startDate, end_date: endDate })
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                return error;
            });
    } catch (error) {
        console.error('Error fetching data', error);
        throw error;
    }
}

export const getPropertiesByName = async (name = "") => {
    try {
        return await apiClient.get(FOOD_BASE_URL + "/propeties_lite?by_name=" + name)
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                return error;
            });
    } catch (error) {
        console.error('Error fetching data', error);
        throw error;
    }
};

export const putPublishPollMenu = async (request) => {
    try {
        return await apiClient.put(FOOD_BASE_URL + "/activate_food_menu_preference", request)
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                return error;
            });
    } catch (error) {
        console.error('Error fetching data', error);
        throw error;
    }
}

export const postCreateMenu = async (request) => {
    try {
        return await apiClient.post(FOOD_BASE_URL + "/property_food_menu", request)
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                return error;
            });
    } catch (error) {
        console.error('Error fetching data', error);
        throw error;
    }
}

export const putUpdateMenu = async (request) => {
    try {
        return await apiClient.put(FOOD_BASE_URL + "/property_food_menu", request)
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                return error;
            });
    } catch (error) {
        console.error('Error fetching data', error);
        throw error;
    }
}

export const putPublishMenu = async (name,request={}) => {
    try {
        return await apiClient.put(FOOD_BASE_URL + "/publish_menu?menu_name="+name, request)
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                return error;
            });
    } catch (error) {
        console.error('Error fetching data', error);
        throw error;
    }
}

export const getMenusByDateRange = async (start_date = "", end_date = "") => {
    try {
        return await apiClient.get(FOOD_BASE_URL + "/property_food_menu?start_date=" + start_date + "&end_date=" + end_date)
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                return error;
            });
    } catch (error) {
        console.error('Error fetching data', error);
        throw error;
    }
};