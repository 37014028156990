import axios from "axios";
import API_ACTION from "../actions/api.action";
import { BASE_API_URL } from "../constants";
import apiClient from "./apiClient";

const BASE_CRM_DASHBOARD_URL = BASE_API_URL + "/crm_dashboard/v1/admin";

export const fetchClusters = async () => {
    try {
        return await apiClient
            .get(`${BASE_CRM_DASHBOARD_URL}/${API_ACTION.clustersAPI}`)
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                return error;
            });
    } catch (error) {
        console.error("Error fetching user data:", error);
        throw error;
    }
};

export const fetchStates = async () => {
    try {
        return await apiClient
            .get(`${BASE_CRM_DASHBOARD_URL}/${API_ACTION.statesAPI}`)
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                return error;
            });
    } catch (error) {
        console.error("Error fetching user data:", error);
        throw error;
    }
};

export const fetchCities = async () => {
    try {
        return await apiClient
            .get(`${BASE_CRM_DASHBOARD_URL}/${API_ACTION.citiesAPI}`)
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                return error;
            });
    } catch (error) {
        console.error("Error fetching user data:", error);
        throw error;
    }
};

export const fetchEmployees = async () => {
    try {
        return await apiClient
            .get(`${BASE_CRM_DASHBOARD_URL}/${API_ACTION.getEmployeesAPI}`)
            .then((response) => {
                return response.data;
                // return [
                //     {
                //         "id": 1,
                //         "employee_first_name": "John",
                //         "employee_last_name": "Doe",
                //         "email": "john.doe@example.com",
                //         "mobile_number": "1234567890",
                //         "dob": "1990-01-01",
                //         "salary": "5000.0",
                //         "hire_date": "2023-07-18",
                //         "job_title": "Software Engineer",
                //         "department": "Engineering",
                //         "property_id": 1,
                //         "cluster_id": 1,
                //         "employee_details": {},
                //         "role": "Employee",
                //         "zone": "Zone A",
                //         "supervisor_id": null,
                //         "is_head": true,
                //         "is_active": true,
                //         "is_deleted": false,
                //         "created_at": "2023-07-18T21:49:16.806+05:30",
                //         "updated_at": "2023-07-18T21:49:16.806+05:30"
                //     },
                //     {
                //         "id": 2,
                //         "employee_first_name": "Bin",
                //         "employee_last_name": "Tin",
                //         "email": "bin.tin@example.com",
                //         "mobile_number": "9234567899",
                //         "dob": "1990-01-01",
                //         "salary": "5000.0",
                //         "hire_date": "2023-07-18",
                //         "job_title": "Property Manager",
                //         "department": "PM",
                //         "property_id": 1,
                //         "cluster_id": 1,
                //         "employee_details": {},
                //         "role": "Employee",
                //         "zone": "Zone A",
                //         "supervisor_id": 1,
                //         "is_head": true,
                //         "is_active": true,
                //         "is_deleted": false,
                //         "created_at": "2023-07-18T21:49:16.810+05:30",
                //         "updated_at": "2023-07-18T21:49:16.810+05:30"
                //     },
                //     {
                //         "id": 3,
                //         "employee_first_name": "John",
                //         "employee_last_name": "Doe",
                //         "email": "john.doe@example.com",
                //         "mobile_number": "1234567890",
                //         "dob": "1990-01-01",
                //         "salary": "5000.0",
                //         "hire_date": "2023-07-23",
                //         "job_title": "Software Engineer",
                //         "department": "Engineering",
                //         "property_id": 5,
                //         "cluster_id": 5,
                //         "employee_details": {},
                //         "role": "Employee",
                //         "zone": "Zone A",
                //         "supervisor_id": null,
                //         "is_head": true,
                //         "is_active": true,
                //         "is_deleted": false,
                //         "created_at": "2023-07-23T17:29:01.313+05:30",
                //         "updated_at": "2023-07-23T17:29:01.313+05:30"
                //     },
                //     {
                //         "id": 4,
                //         "employee_first_name": "Bin",
                //         "employee_last_name": "Tin",
                //         "email": "bin.tin@example.com",
                //         "mobile_number": "9234567899",
                //         "dob": "1990-01-01",
                //         "salary": "5000.0",
                //         "hire_date": "2023-07-23",
                //         "job_title": "Property Manager",
                //         "department": "PM",
                //         "property_id": 5,
                //         "cluster_id": 5,
                //         "employee_details": {},
                //         "role": "Employee",
                //         "zone": "Zone A",
                //         "supervisor_id": 3,
                //         "is_head": true,
                //         "is_active": true,
                //         "is_deleted": false,
                //         "created_at": "2023-07-23T17:29:01.318+05:30",
                //         "updated_at": "2023-07-23T17:29:01.318+05:30"
                //     }
                // ]
            })
            .catch((error) => {
                return error;
            });
    } catch (error) {
        console.error("Error fetching employee data:", error);
        throw error;
    }
};

export const fetchRoomTypes = async () => {
    try {
        return await apiClient
            .get(`${BASE_CRM_DASHBOARD_URL}/${API_ACTION.roomTypesAPI}`)
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                return error;
            });
    } catch (error) {
        console.error("Error fetching room type data:", error);
        throw error;
    }
};

export const fetchInstitutions = async () => {
    try {
        return await apiClient
            .get(`${BASE_CRM_DASHBOARD_URL}/${API_ACTION.getInstitutionAPI}`)
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                return error;
            });
    } catch (error) {
        console.error("Error fetching institution data:", error);
        throw error;
    }
};

export const fetchBillTypes = async () => {
    try {
        return await apiClient
            .get(`${BASE_CRM_DASHBOARD_URL}/${API_ACTION.getBillTypesAPI}`)
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                return error;
            });
    } catch (error) {
        console.error("Error fetching user data:", error);
        throw error;
    }
};

export const fetchCommonAssets = async () => {
    try {
        return await apiClient
            .get(`${BASE_CRM_DASHBOARD_URL}/${API_ACTION.getCommonAssetsAPI}`)
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                return error;
            });
    } catch (error) {
        console.error("Error fetching user data:", error);
        throw error;
    }
};

export const fetchRoomAssets = async () => {
    try {
        return await apiClient
            .get(`${BASE_CRM_DASHBOARD_URL}/${API_ACTION.getRoomAssestAPI}`)
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                return error;
            });
    } catch (error) {
        console.error("Error fetching user data:", error);
        throw error;
    }
};

export const createLead = async (data) => {
    try {
        return await apiClient
            .post(`${BASE_CRM_DASHBOARD_URL}/${API_ACTION.lead}` , data)
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                return error;
            });
    } catch (error) {
        console.error("Error fetching user data:", error);
        throw error;
    }
};
export const sendLeadOtp = async (data) => {
    try {
        return await apiClient
            .post(`${BASE_CRM_DASHBOARD_URL}/${API_ACTION.lead}/${API_ACTION.sendOtp}` , data)
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                return error;
            });
    } catch (error) {
        console.error("Error fetching user data:", error);
        throw error;
    }
};
export const verifyLeadOtp = async (data) => {
    try {
        return await apiClient
            .post(`${BASE_CRM_DASHBOARD_URL}/${API_ACTION.lead}/${API_ACTION.verifyOtp}` , data)
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                return error;
            });
    } catch (error) {
        console.error("Error fetching user data:", error);
        throw error;
    }
};

export const approveBulkUpload = async (id , actionType) => {
    try {
        return await apiClient
            .post(`${BASE_CRM_DASHBOARD_URL}/${API_ACTION.bulkApproval}/${id}`, {status : actionType})
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                return error;
            });
    } catch (error) {
        console.error("Error fetching user data:", error);
        throw error;
    }
};


export const fetchContractExpire = async (actionType ,date , property_id) => {
    try {
        return await apiClient
            .get(`${BASE_CRM_DASHBOARD_URL}/${actionType}?date=${date}&property_id=${property_id}`)
            .then((response) => {
                
                return response.data;
            })
            .catch((error) => {
                return error;
            });
    } catch (error) {
        console.error("Error fetching user data:", error);
        throw error;
    }
};

export const verifyPropertyCode= async ( property_code) => {
    try {
        return await apiClient
            .post(`${BASE_CRM_DASHBOARD_URL}/verify_property?property_code=${property_code}`)
            .then((response) => {
                
                return response.data;
            })
            .catch((error) => {
                return error;
            });
    } catch (error) {
        console.error("Error fetching user data:", error);
        throw error;
    }
};

export const fetchBlogAuthers = async () => {
    try {
        return await apiClient
            .get(`${BASE_CRM_DASHBOARD_URL}/${API_ACTION.blogAuthers}`)
            .then((response) => {
                
                return response.data;
            })
            .catch((error) => {
                return error;
            });
    } catch (error) {
        console.error("Error fetching user data:", error);
        throw error;
    }
};
export const fetchBlogProperty = async () => {
    try {
        return await apiClient
            .get(`${BASE_CRM_DASHBOARD_URL}/${API_ACTION.blogProperties}`)
            .then((response) => {
                
                return response.data;
            })
            .catch((error) => {
                return error;
            });
    } catch (error) {
        console.error("Error fetching user data:", error);
        throw error;
    }
};
export const fetchBlogsList = async () => {
    try {
        return await apiClient
            .get(`${BASE_CRM_DASHBOARD_URL}/${API_ACTION.allBlogs}`)
            .then((response) => {
                
                return response.data;
            })
            .catch((error) => {
                return error;
            });
    } catch (error) {
        console.error("Error fetching user data:", error);
        throw error;
    }
};

export const fetchBlogsCategories= async () => {
    try {
        return await apiClient
            .get(`${BASE_CRM_DASHBOARD_URL}/${API_ACTION.blogCategories}`)
            .then((response) => {
                
                return response.data;
            })
            .catch((error) => {
                return error;
            });
    } catch (error) {
        console.error("Error fetching user data:", error);
        throw error;
    }
};

export const fetchBlogsCities= async () => {
    try {
        return await apiClient
            .get(`${BASE_CRM_DASHBOARD_URL}/${API_ACTION.blogCites}`)
            .then((response) => {
                
                return response.data;
            })
            .catch((error) => {
                return error;
            });
    } catch (error) {
        console.error("Error fetching user data:", error);
        throw error;
    }
};
export const fetchBlog= async (id) => {
    try {
        return await apiClient
            .get(`${BASE_CRM_DASHBOARD_URL}/${API_ACTION.blogs}/${id}`)
            .then((response) => {
                
                return response.data;
            })
            .catch((error) => {
                return error;
            });
    } catch (error) {
        console.error("Error fetching user data:", error);
        throw error;
    }
};

export const fetchBlogSections= async (blogId) => {
    try {
        return await apiClient
            .get(`${BASE_CRM_DASHBOARD_URL}/${API_ACTION.blogSection}/${blogId}`)
            .then((response) => {
                
                return response.data;
            })
            .catch((error) => {
                return error;
            });
    } catch (error) {
        console.error("Error fetching user data:", error);
        throw error;
    }
};

export const createBlogSection = async (data) => {
    try {
        return await apiClient
            .post(`${BASE_CRM_DASHBOARD_URL}/${API_ACTION.blogSection}` , data)
            .then((response) => {
                
                return response.data;
            })
            .catch((error) => {
                return error;
            });
    } catch (error) {
        console.error("Error fetching user data:", error);
        throw error;
    }
};

export const deleteBlogSection = async (id) => {
    try {
        return await apiClient
            .delete(`${BASE_CRM_DASHBOARD_URL}/${API_ACTION.blogSection}/${id}`)
            .then((response) => {
                
                return response.data;
            })
            .catch((error) => {
                return error;
            });
    } catch (error) {
        console.error("Error fetching user data:", error);
        throw error;
    }
};


export const updateBlogSection = async (id , data) => {
    try {
        return await apiClient
            .patch(`${BASE_CRM_DASHBOARD_URL}/${API_ACTION.blogSection}/${id}` , data)
            .then((response) => {
                
                return response.data;
            })
            .catch((error) => {
                return error;
            });
    } catch (error) {
        console.error("Error fetching user data:", error);
        throw error;
    }
};

export const updateBlog = async (id , data) => {
    try {
        return await apiClient
            .patch(`${BASE_CRM_DASHBOARD_URL}/${API_ACTION.blogs}/${id}` , data)
            .then((response) => {
                
                return response.data;
            })
            .catch((error) => {
                return error;
            });
    } catch (error) {
        console.error("Error fetching user data:", error);
        throw error;
    }
};

export const fetchAllBlogs = async (currentPage,searchObject,sortObject , current_rowPerPage) => {
    let searchString = ''
    let sortString = ''
    if(searchObject){
      Object.keys(searchObject).map((key)=>{
        searchString = searchString + `&${key}=${searchObject[key]}`
      })
    }
    if(sortObject){
      Object.keys(sortObject).map((key)=>{
        sortString = sortString + `&${key}=${sortObject[key]}`
      })
    }
    try {
        return await apiClient
            .get(`${BASE_CRM_DASHBOARD_URL}/${API_ACTION.blogs}?page=${currentPage}${searchString}${sortString}&per_page=${current_rowPerPage ? current_rowPerPage : 10}`)
            .then((response) => {
                
                return response.data;
            })
            .catch((error) => {
                return error;
            });
    } catch (error) {
        console.error("Error fetching user data:", error);
        throw error;
    }
};

export const postBlogs = async (data) => {
    try {
        console.log("data" ,data)
        return await apiClient
            .post(`${BASE_CRM_DASHBOARD_URL}/${API_ACTION.blogs}` , data)
            .then((response) => {
                
                return response.data;
            })
            .catch((error) => {
                return error;
            });
    } catch (error) {
        console.error("Error fetching user data:", error);
        throw error;
    }
};

export const getAccessToken = async (payload) => {
    try {
      return await axios.post(`${BASE_CRM_DASHBOARD_URL}/${API_ACTION.getAccessTokenAPI}`, JSON.stringify(payload),
        {
          headers: { "Content-Type": "application/json" },
        }
      ).then((response) => {
        return response.data;
      }).catch((error) => {
        return error;
      });
    } catch (error) {
      console.error("Error while receiving access token", error);
      throw error;
    }
  };

  export const getMealSession = async (payload) => {
    try {
        return await apiClient
            .get(`${BASE_CRM_DASHBOARD_URL}/${API_ACTION.getMealSessionAPI}`, payload)
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                return error.data;
            });
    } catch (error) {
        console.error("Error fetching user data:", error);
        throw error;
    }
}

export const verifyFoodToken = async (payload) => {
    try {
        return await apiClient
            .post(`${BASE_CRM_DASHBOARD_URL}/${API_ACTION.verifyFoodToken}`, payload)
            .then((response) => {

                return response.data;
            })
            .catch((error) => {
                return error.data;
            });
    } catch (error) {
        console.error("Error fetching user data:", error);
        throw error;
    }
}
