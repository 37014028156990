import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import withDefaultDashBoardLayout from "../../components/layouts/withDefaultDashBoardLayout";
import { getFiscalYear } from "../../helpers/common/common.function";
import styles from "../../pages/revenue/revenueBulkUploadPage/modals/UploadBulkModal/index.module.scss";
import {
  fetchTicketDetails,
  updateTicketStatus,
  addTicketAttachment,
  addNotesTicket,
  fetchTicketsNotes,
} from "../../helpers/services/api.services";
import toast from "react-hot-toast";
import { Modal, Table, Tab, Tabs } from "react-bootstrap";
import moment from "moment";
import TicketHistory from "../../components/tabs/tickets-details/TicketHistory";
import TicketResponsive from "../../components/tabs/tickets-details/TicketResponsive";

function decodeHtmlEntities(str) {
  // Decode HTML entities
  let decodedStr = str
    .replace(/\\u003c/g, "<")
    .replace(/\\u003e/g, ">")
    .replace(/\\u0022/g, '"')
    .replace(/\\u0027/g, "'")
    .replace(/\\u0026/g, "&")
    .replace(/\\u003d/g, "=");

  // Remove specific text if necessary
  const staticText =
    "Agent (Ticket Support) updated on behalf of the requester.";
  const index = decodedStr.indexOf(staticText);

  if (index !== -1) {
    decodedStr = decodedStr.substring(index + staticText.length).trim();
  }

  // Remove HTML tags
  decodedStr = decodedStr.replace(/<\/?[^>]+(>|$)/g, "");

  // Replace <br> tags and trim whitespace
  decodedStr = decodedStr
    .replace(/<br\s*\/?>/gi, "\n")
    .replace(/<p>\s*<\/p>/gi, "\n")
    .trim();

  return decodedStr;
}

function TicketDetails() {
  const [ticketDetail, setTicketDetail] = useState(null);
  const [ticketHistory, setTicketHistory] = useState([]);
  const { ticketId } = useParams();
  const [openReplyModal, setOpenReplyModal] = useState(false);
  const [imageFile, setImageFile] = useState(null);
  const [message, setMessage] = useState("");
  const [loadingData, setLoadingData] = useState(false);
  const [submitLoader, setSubmitLoader] = useState(false);
  const [attachmentsDetails, setAttachmentDetails] = useState([]);
  const [noteType, setNoteType] = useState("Private");
  const [notes, setNotes] = useState([]);

  const loadTicketDetails = async () => {
    setLoadingData(true);
    let response = await fetchTicketDetails(ticketId);
    if (response && response.status === "success") {
      setTicketHistory(response.history.result);
      setTicketDetail(response.data);
      setAttachmentDetails(response.attachments.result ?? []);
    } else {
      setTicketHistory(null);
      setTicketDetail(null);
    }
    setLoadingData(false);
  };

  const loadTicketNotes = async () => {
    let response = await fetchTicketsNotes(ticketDetail?.id);
    if (response && response.status === "success") {
      setNotes(response?.data?.result);
    } else {
      setNotes([]);
    }
  };

  const handleStatusChange = async (e) => {
    const newStatus = e.target.value;
    const formData = new FormData();
    formData.append("status", newStatus);
    formData.append("ticket_id", ticketDetail?.id);

    const updatedResponse = await updateTicketStatus(formData);

    if (updatedResponse && updatedResponse.status === "success") {
      toast.success("Status Updated");
      loadTicketDetails();
    } else {
      toast.error("Error While updating status please try after some time");
    }
  };

  const handleFileChange = (e) => {
    if (e.target.files.length) {
      setImageFile(e.target.files[0]);
    }
  };

  const clearModal = () => {
    setMessage("");
    setImageFile(null);
    setOpenReplyModal(false);
    setSubmitLoader(false);
    loadTicketDetails();
  };

  const handleModalSubmit = async () => {
    setSubmitLoader(true);

    let imageToken = "";

    if (imageFile) {
      const formData = new FormData();
      formData.append("file_path", imageFile);

      const response = await addTicketAttachment(formData);
      if (response.status == "success") {
        imageToken = response.data.token;
      } else {
        toast.error("Image not uploaded");
      }
    }

    const formData = new FormData();
    formData.append("ticket_id", ticketDetail?.id);
    formData.append("description", message);
    formData.append("isPublicNote", noteType == "Public" ? true : false);
    formData.append("attachments", `${imageToken}`);

    const addNotesResponse = await addNotesTicket(formData);
    if (addNotesResponse.status == "success") {
      clearModal();
      toast.success("Reply added successfully");
    } else {
      toast.error("Error while uploading reply");
    }

    setSubmitLoader(false);
  };

  useEffect(() => {
    if (ticketId !== undefined) {
      loadTicketDetails();
    }
  }, []);

  useEffect(() => {
    if (ticketDetail?.id) {
      loadTicketNotes();
    }
  }, [ticketDetail]);

  return (
    <>
      {loadingData ? (
        <div
          className="d-flex justify-content-center align-items-center"
          style={{ height: "100vh" }}
        >
          Loading...
        </div>
      ) : (
        <>
          <div className="main-content-web">
            <div className="rdp-right-side-header">
              <div className="d-flex justify-content-between align-items-center pb-2">
                <div className="d-flex align-items-center">
                  <Link to={"/tickets"}>
                    <img src="/images/Back.svg" alt="Back" />
                  </Link>
                  <div className="tit_point mx-3">{getFiscalYear()}</div>
                  <h2 className="m-0">{ticketDetail?.customFields?.cf_name}</h2>
                </div>
              </div>
              <div className="breadcum">
                <ul>
                  <li>
                    <Link to={"/"}>Tenants</Link> <span>/</span>
                  </li>
                  <li>
                    <Link to={"/tickets"}>Tickets</Link> <span>/</span>
                  </li>
                  <li>{ticketDetail?.id}</li>
                </ul>
              </div>
            </div>
            {ticketDetail ? (
              <>
                <div className="main_tab my-4">
                  <Tabs
                    defaultActiveKey="details"
                    id="ticket-details"
                    className="mb-4"
                  >
                    <Tab eventKey="details" title="Details">
                      <div className="col-md-12">
                        <div className="brd_box_card pd-24-20">
                          <div className="guest_part">
                            <div className="d-flex align-items-center justify-content-between">
                              <div className="d-flex align-items-center">
                                <div className="guest_img">
                                  <img
                                    src="/images/guest.png"
                                    className="pr-2"
                                    alt=""
                                  />
                                </div>
                                <div className="">
                                  <h1>
                                    {ticketDetail?.category?.name ?? "--"}
                                  </h1>
                                  <p style={{ marginTop: "-13px" }}>
                                    <small>
                                      {
                                        ticketDetail?.customFields
                                          ?.cf_sub_category?.name
                                      }
                                    </small>
                                  </p>
                                  <div
                                    className="d-flex align-items-center"
                                    style={{ whiteSpace: "pre-wrap" }}
                                  >
                                    {/* <span>Description :</span> */}
                                    <b>
                                      <div
                                        dangerouslySetInnerHTML={{
                                          __html: decodeHtmlEntities(
                                            ticketDetail?.description ?? "--"
                                          ),
                                        }}
                                      />
                                    </b>
                                  </div>
                                </div>
                              </div>
                              <div className="custom-single-select form_control_box ">
                                <label htmlFor="status-select">
                                  {" "}
                                  <b>Status</b>{" "}
                                </label>
                                <br />
                                {ticketDetail?.status?.description?.toLowerCase() ==
                                "closed" ? (
                                  <>
                                    <input
                                      type="text"
                                      value={ticketDetail?.status?.description}
                                      disabled
                                    />
                                  </>
                                ) : (
                                  <>
                                    <select
                                      id="status-select"
                                      value={
                                        ticketDetail?.status?.description
                                          ?.toLowerCase()
                                          .replace(/\s+/g, "_") ?? "--"
                                      }
                                      onChange={handleStatusChange}
                                      className="cursor-pointer"
                                      style={{ whiteSpace: "pre-wrap" }}
                                    >
                                      <option value="open">Open</option>
                                      <option value="in_progress">
                                        In Progress
                                      </option>
                                      <option value="waiting_for_customer">
                                        Waiting for Customer
                                      </option>
                                      <option value="on_hold">On Hold</option>
                                      <option value="solved">Solved</option>
                                      {/* <option value="closed">Closed</option> */}
                                    </select>
                                  </>
                                )}
                              </div>
                            </div>
                          </div>
                          <div className="info_box d-flex align-items-center justify-content-between">
                            <div>
                              <strong>
                                {moment(ticketDetail?.createdOn).format(
                                  "MMMM D, YYYY [at] h:mm A"
                                )}
                              </strong>
                            </div>
                            <div
                              className="cursor-pointer mx-3 d-flex border px-3 py-1"
                              onClick={() => {
                                setOpenReplyModal(true);
                              }}
                              title="Reply ticket"
                              style={{ borderRadius: "5px" }}
                            >
                              <img
                                src="/images/reply_Icon.png"
                                alt=""
                                style={{ height: "18px", marginTop: "2px" }}
                                className="mr-2"
                              />
                              <div> Reply </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="brd_box_card mt-3 zindex9">
                        <div className="box_head">
                          <div className="d-flex align-items-center">
                            <h2>
                              {" "}
                              <img
                                src="/images/file-shield.svg"
                                className="pr-2"
                                alt=""
                              />{" "}
                              ATTACHMENTS
                            </h2>
                          </div>
                        </div>

                        <div className="exit_tab_box">
                          <div className="box_contain ">
                            <div className="sub_exit_box">
                              {attachmentsDetails?.map(
                                (attachmentData, index) => (
                                  <div
                                    className="d-flex align-items-center justify-content-between mb-4"
                                    key={index}
                                  >
                                    <div>
                                      <a
                                        href={attachmentData?.fileUrl}
                                        target="_blanks"
                                      >
                                        {attachmentData?.name}
                                      </a>
                                    </div>
                                  </div>
                                )
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="brd_box_card mt-3 zindex9">
                        <div className="box_head">
                          <div className="d-flex align-items-center">
                            <h2>
                              {" "}
                              <img
                                src="/images/file-shield.svg"
                                className="pr-2"
                                alt=""
                              />{" "}
                              NOTES
                            </h2>
                          </div>
                        </div>

                        <div className="exit_tab_box">
                          <div className="box_contain ">
                            <div className="sub_exit_box">
                              {notes?.map((notesData, index) => (
                                <div
                                  className="mb-4"
                                  key={index}
                                  style={{ position: "relative" }}
                                >
                                  <div className="text-end mb-2">
                                    <small>
                                      <strong>
                                        {moment(notesData?.updatedOn).format(
                                          "MMMM D, YYYY [at] h:mm A"
                                        )}
                                      </strong>{" "}
                                    </small>
                                  </div>
                                  <div style={{ whiteSpace: "pre-wrap" }}>
                                    {decodeHtmlEntities(notesData?.description)}
                                    <br />
                                    <br />
                                    {notesData?.attachments?.length > 0 && (
                                      <>
                                        {notesData?.attachments?.map(
                                          (attachmentData, index) => (
                                            <div
                                              className="d-flex align-items-center justify-content-between mb-4"
                                              key={index}
                                            >
                                              <div>
                                                <a
                                                  href={attachmentData?.fileUrl}
                                                  target="_blanks"
                                                >
                                                  {attachmentData?.name}
                                                </a>
                                              </div>
                                            </div>
                                          )
                                        )}
                                      </>
                                    )}
                                  </div>
                                  <hr />
                                </div>
                              ))}
                            </div>
                          </div>
                        </div>
                      </div>
                    </Tab>

                    <Tab eventKey="ticket-history" title="History">
                      <TicketHistory ticketHistory={ticketHistory} />
                    </Tab>
                    {/* <Tab></Tab> */}
                  </Tabs>
                </div>
              </>
            ) : (
              <div className=" text-center">No Ticket Found</div>
            )}
          </div>
          <TicketResponsive
            ticketDetail={ticketDetail}
            handleStatusChange={handleStatusChange}
            attachmentsDetails={attachmentsDetails}
            notes={notes}
            submitLoader={submitLoader}
            setMessage={setMessage}
            handleFileChange={handleFileChange}
            handleModalSubmit={handleModalSubmit}
            message={message}
            imageFile={imageFile}
          />
        </>
      )}

      {/* Reply Modal */}
      <Modal
        show={openReplyModal}
        onHide={() => setOpenReplyModal(false)}
        backdrop="static"
        dialogClassName={styles["dialog-container"]}
        keyboard={false}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Reply</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className={styles["body-content"]}>
            <div>
              <input
                type="file"
                accept="image/*"
                onChange={handleFileChange}
                style={{ display: "none" }}
                id="image-file-input"
              />
              <label
                htmlFor="image-file-input"
                className={styles["upload-container"]}
              >
                <div className="" style={{ marginTop: "-3px" }}>
                  <svg
                    width="21"
                    height="20"
                    viewBox="0 0 21 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M12.3304 1.89124V5.33335C12.3304 5.80006 12.3304 6.03342 12.4212 6.21168C12.5011 6.36848 12.6286 6.49596 12.7854 6.57586C12.9637 6.66669 13.197 6.66669 13.6637 6.66669H17.1059M12.3304 1.66663H7.99707C6.59694 1.66663 5.89687 1.66663 5.36209 1.93911C4.89169 2.17879 4.50924 2.56124 4.26955 3.03165C3.99707 3.56643 3.99707 4.26649 3.99707 5.66663V14.3333C3.99707 15.7334 3.99707 16.4335 4.26955 16.9683C4.50924 17.4387 4.89169 17.8211 5.36209 18.0608C5.89687 18.3333 6.59694 18.3333 7.99707 18.3333H13.3304C14.7305 18.3333 15.4306 18.3333 15.9654 18.0608C16.4358 17.8211 16.8182 17.4387 17.0579 16.9683C17.3304 16.4335 17.3304 15.7334 17.3304 14.3333V6.66663L12.3304 1.66663Z"
                      stroke="#0F7691"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M13.1641 12.5L10.6641 10M10.6641 10L8.16406 12.5M10.6641 10L10.6641 15"
                      stroke="#0F7691"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </div>
                {imageFile ? (
                  <p className={"mb-0 " + styles["label-filled"]}>
                    {imageFile.name}
                  </p>
                ) : (
                  <p className={"mb-0 " + styles["label-title"]}>
                    Select Image up to 10mb
                  </p>
                )}
              </label>
            </div>
            <div className="form_control_box">
              {/* <label htmlFor="note">Note Type</label>
              <select
                name="noteType"
                id="note"
                className="w-100 mb-2"
                value={noteType}
                onChange={(e) => setNoteType(e.target.value)}
              >
                <option value="Public">Public</option>
                <option value="Private">Private</option>
              </select> */}
              <textarea
                className="w-100 "
                placeholder="Enter your reply here..."
                value={message}
                onChange={(e) => setMessage(e.target.value)}
              />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            className={"btn " + styles["btn-cancel"]}
            onClick={() => setOpenReplyModal(false)}
          >
            CANCEL
          </button>
          <button
            className={"btn " + styles["btn-submit"]}
            onClick={handleModalSubmit}
            disabled={submitLoader || (!message && !imageFile)}
          >
            {submitLoader ? "SUBMITTING..." : "SUBMIT"}
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default withDefaultDashBoardLayout(TicketDetails);
