import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import DataTable from 'react-data-table-component';
import { fetchStates } from "../../helpers/services/admin.services";
import withDefaultDashBoardLayout from "../../components/layouts/withDefaultDashBoardLayout";
import { addNewState,updateState } from '../../helpers/services/api.services'
import { Modal } from "react-bootstrap";
import { Form } from "react-bootstrap";
import { useFormik } from 'formik';
import * as Yup from 'yup';
import toast from "react-hot-toast";
 
const salesMasterFilters = [
    {
        label:"RM Items",
        value:"rm items",
    },
    {
        label:"Dishes",
        value:'dishes',
    },
    {
        label:"Meals",
        value:'meals',
    },
    {
        label:"Kitchen",
        value:'kitchen',
    },
    {
        label:"Services",
        value:'services',
    },
    {
        label:"Courses",
        value:'courses',
    },
    {
        label:"Recipes",
        value:'recipes',
    }
]


function StateList() {
  const navigate = useNavigate();

  const [activeItem, setActiveItem] = useState({
    label:"Dishes",
    value:'dishes'
});

  // const [activePropertyStatus, setActivePropertyStatus] = useState("All");
  // Data-table
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(100);
  const [operation,setOperation] = useState("Create");
  // const [page, setPage] = useState(1);

  const [showCreatePropertyModal, setShowCreatePropertyModal] = useState(false);
  const handleCloseConfirmNocModal = () => {
    formik.resetForm();
    setShowCreatePropertyModal(false);
  }
  const handleCreatePropertyModal = () => setShowCreatePropertyModal(true);


  useEffect(() => {
    loadData();
  }, []);

  const loadData = async () => {
    setLoading(true);

    let response = await fetchStates();

    if (response && response.data && response.data.length > 0) {
      setData(response.data);
      if (response?.meta && response?.meta?.total_data_count && response?.meta?.total_pages) {
        setTotalRows(response?.meta?.total_data_count);
      }
      setLoading(false);
    } else {
      setData([]);
      setLoading(false);
    }
  };

  // const filteredResidentsData = async (status) => {
  //   setActivePropertyStatus(status);
  // };

  // const handlePageChange = () => {
  //   loadData();
  // };

  const columns = [
    {
      name: 'ID',
      selector: (row) => row.id,
      width: "10%",
      sortable: true,
    },
    {
      name: 'Name',
      width: "25%",
      selector: (row) => row.name,
      sortable: true,
    },
    {
      name: 'Code',
      selector: (row) => row.code,
      sortable: true,
    },
    {
      name: 'GSTIN',
      selector: (row) => row.gstin,
      width: "20%",
      sortable: true,
    },
    {
      name: 'Country',
      selector: (row) => row.country,
      sortable: true,
    },
    {
      name: 'IS_ACTIVE',
      selector: (row) => row.is_active,
      sortable: true,
      cell: row => row.is_active ? "YES" : "NO",
    },
    {
      name: 'IS_DELETED',
    //   width: "10%",
      selector: (row) => row?.is_deleted,
      sortable: true,
      cell: row => row.is_deleted ? "YES" : "NO",
    },
    {
      name: '',
      selector: (row) => row?.id,
      // width: "3%",
      cell: row => (
        <button
          style={{border:"none"}}
          data-bs-toggle="tooltip" data-bs-placement="top" title="Edit"
          onClick={()=>{
            setOperation("Edit")
            const fields = ['id','name', 'code', 'gstin', 'country', 'is_active','is_deleted'];
            fields.forEach(field => formik.setFieldValue(field, row[field], false));
            handleCreatePropertyModal()
          }}
        >
          <img src="/images/edit.svg" alt="Edit Icon"/>
        </button>
      )
    },
  ];

  const initialValues ={
    name: "",
    code: "",
    gstin: "",
    is_active: true,
    is_deleted: false,
    country: ""
  }

  const validationSchema = Yup.object().shape({
    name: Yup.string().matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed").required(),
    code: Yup.string().required(),
    gstin: Yup.string().matches(/^[a-zA-Z0-9]+$/, "GSTIN Must be alphanumeric").min(15, 'GSTIN Must be 15 alphanumeric characters').max(15, 'GSTIN code Must be 15 alphanumeric characters').required(),
    country: Yup.string().matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed").required(),
  });

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values) => {
      try {
        // console.log("Submit values -- ",values)
        if(operation==="Create"){
          let response = await addNewState(values);

          if (response && response?.status === 'success') {
            await toast.success('State has been added successfully.');
            handleCloseConfirmNocModal();
            loadData();
          } else {
            await toast.error('Something went wrong while add a state details.');
          }
        }else{
          const {id,...rest}= values
          let response = await updateState(id, rest);

          if (response && response?.status === 'success') {
            await toast.success('State has been updated successfully.');
            handleCloseConfirmNocModal();
            loadData();
          } else {
            await toast.error('Something went wrong while updating a state details.');
          }
        }
      } catch (error) {
        console.log(error);
      }
    },
  });


//   const handleDetailsPageRedirection = (data) => {
//     navigate(`/property-details/${data?.id}`);
//   }

  return (
    <>
      <div className="property-header">
        <div className="right-side-header">
          <div className="d-flex justify-content-between align-items-center pb-2">
            <div className="d-flex align-items-center">
              <Link to={"/"}>
                <img src="/images/Back.svg" alt="Back" />
              </Link>
              <h2>State</h2>
            </div>
          </div>
          <div className="breadcum">
            <ul>
              <li>
                <a href="#">Masters</a> <span>/</span>
              </li>
              <li>State</li>
            </ul>
          </div>
        </div>
        <div className="create-prop">
          <button className="basic-button" onClick={()=>{
            setOperation('Create');
            handleCreatePropertyModal();
          }}>
            <img
                src="/images/plus-circle.svg"
                className="pr-2"
                alt=""
            />
            Add State
          </button>
        </div>
      </div>

        {/* <div className={"content-container"}>
            <div className={'d-flex align-items-center gap-12'}>
                {
                    salesMasterFilters.map((bulkFilter) => {
                        return (<div onClick={() => { setActiveItem(bulkFilter) }} className={"tab-pane" + ' ' + ((activeItem?.value === bulkFilter.value) ? "active" : '')} key={"bulkFilter_" + bulkFilter.value}>
                            {bulkFilter.label}
                        </div>)
                    })
                }
            </div>
        </div> */}

      {/* <div className="d-flex justify-content-between align-items-center pb-32">
        <div className="status-box">
          {propertyStatus.map((status, index) => (
            <a
              key={index}
              href="#"
              className={status?.label === activePropertyStatus ? "active" : ""}
              onClick={() => filteredResidentsData(status?.label)}
            >
              {status?.label}
                </a>
            ))}
            </div>

            <div className="status-box">
            <a href="#">
                <img src="/images/filter.svg" className="pr-1" alt="" /> Filter
            </a>
            </div>
        </div> */}

      <div className="sr_data_table mt-4">
        <DataTable
          columns={columns}
          data={data}
          progressPending={loading}
        //   pagination
        //   paginationServer
        //   paginationTotalRows={totalRows}
        //   onChangePage={handlePageChange}
          fixedHeader={true}
          fixedHeaderScrollHeight={'430px'}
        //   onRowClicked={handleDetailsPageRedirection}
        ></DataTable>
      </div> 


      <Modal
        show={showCreatePropertyModal}
        onHide={handleCloseConfirmNocModal}
        centered
        size="lg"
        className="confirm_noc_modal"
      >
        <Modal.Body>
        <div className="head_part_date_modal create-prop_modal">
            <div className="d-flex justify-content-between align-items-center mb-4">
              <div>
                <h2 className="title">{operation} State</h2>
              </div>
              <div style={{cursor:"pointer"}}>
                <span onClick={handleCloseConfirmNocModal} className="">
                  <img src="/images/cross_modal.svg" alt="Close Icon" />
                </span>
              </div>
            </div>
          </div>
          <div className="modal_data_part">
        <div className="create-prop_detail">
            <form onSubmit={formik.handleSubmit}>
              <div className="TrailWrap mb-4">
                <div>
                  <Form.Label>Name</Form.Label>
                  <Form.Control
                    placeholder="Enter Name"
                    id="state_name"
                    name="state_name"
                    value={formik.values.name}
                    onChange={(e) => formik.setFieldValue('name', e.target.value)}
                    style={formik.touched.name && formik.errors.name ? {
                      borderColor:'red'
                    }: null}
                  />
                  {
                    formik.touched.name && formik.errors.name ? (
                      <div className="error-label">{formik.errors.name}</div>
                    ) : null
                  }
                </div>
                <div>
                  <Form.Label>Code</Form.Label>
                  <Form.Control
                    placeholder="Enter State Code"
                    id="state_code"
                    name="state_code"
                    value={formik.values.code}
                    onChange={(e) => formik.setFieldValue('code', e.target.value)}
                    style={formik.touched.code && formik.errors.code ? {
                      borderColor:'red'
                    }: null}
                  />
                  {
                    formik.touched.code && formik.errors.code ? (
                      <div className="error-label">{formik.errors.code}</div>
                    ) : null
                  }
                </div>
                <div>
                  <Form.Label>GSTIN (State)</Form.Label>
                  <Form.Control
                    type="text"
                    id="state_gstin_number"
                    name="state_gstin_number"
                    placeholder="XXXXX-XXXXX-XXXXX"
                    value={formik.values.gstin}
                    onChange={(e) => formik.setFieldValue('gstin', e.target.value)}
                    style={formik.touched.gstin && formik.errors.gstin ? {
                      borderColor:'red'
                    }: null}
                  />
                  {
                    formik.touched.gstin && formik.errors.gstin ? (
                      <div className="error-label">{formik.errors.gstin}</div>
                    ) : null
                  }
                </div>
                <div>
                  <Form.Label>Country</Form.Label>
                  <Form.Control
                    placeholder="Enter Country"
                    id="country_name"
                    name="counrty_name"
                    value={formik.values.country}
                    onChange={(e) => formik.setFieldValue('country', e.target.value)}
                    style={formik.touched.country && formik.errors.country ? {
                      borderColor:'red'
                    }: null}
                  />
                  {
                    formik.touched.country && formik.errors.country ? (
                      <div className="error-label">{formik.errors.country}</div>
                    ) : null
                  }
                </div>
              </div>

              <div className="modal-footer">
                <button 
                  type="button" 
                  className="orange_brd_button p-12-16-button mr-3"
                  onClick={()=>handleCloseConfirmNocModal()}
                >CANCEL</button>
                <button type="submit" className="basic-button">{operation === "Create" ? 'SAVE' : 'UPDATE' }</button>
              </div>
            </form>
          </div>
        </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default withDefaultDashBoardLayout(StateList);
