import React, { useEffect, useMemo, useRef, useState } from 'react'
import ReactDatePicker from 'react-datepicker';
import styles from './index.module.scss';
import moment from 'moment';

export default function FilterDate({ dateRange, apply, placeHolder, minDate, maxDate }) {
    const calRef = useRef();
    const [range, setRange] = useState({
        startDate: "",
        endDate: ""
    })
    const onChange = (dates) => {
        console.log(dates)
        const [startDate, endDate] = dates;
        setRange({
            startDate,
            endDate
        })
    };

    const onClear = () => {
        apply("", "")
    }

    const allotDateRange = (rangeInfo) => {

        if (rangeInfo.startDate && rangeInfo.endDate) {
            setRange({
                startDate: moment(rangeInfo.startDate).toDate(),
                endDate: moment(rangeInfo.endDate).toDate(),
            })
        } else {
            setRange({
                startDate: "",
                endDate: ""
            })
        }
    }

    const onApply = () => {
        if (range.startDate && range.endDate) {
            calRef.current.setOpen(false);
            apply(moment(new Date(range.startDate)), moment(new Date(range.endDate)));
        }
    }

    const dateText = useMemo(() => {
        if (range.startDate && range.endDate) {
            return moment(new Date(range.startDate)).format("DD MMM’YY") + " - " + moment(new Date(range.endDate)).format("DD MMM’YY")
        }
        return placeHolder || "Select Date Range"
    }, [placeHolder, range]);


    useEffect(() => {
        allotDateRange(dateRange)
    }, [dateRange]);


    return (<div className={styles['filter-date']}>
        <div className={styles['view-container']} onClick={() => { calRef.current.setOpen(true); }}>
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M17.5 9.58366V7.33366C17.5 5.93353 17.5 5.23346 17.2275 4.69868C16.9878 4.22828 16.6054 3.84583 16.135 3.60614C15.6002 3.33366 14.9001 3.33366 13.5 3.33366H6.5C5.09987 3.33366 4.3998 3.33366 3.86502 3.60614C3.39462 3.84583 3.01217 4.22828 2.77248 4.69868C2.5 5.23346 2.5 5.93353 2.5 7.33366V14.3337C2.5 15.7338 2.5 16.4339 2.77248 16.9686C3.01217 17.439 3.39462 17.8215 3.86502 18.0612C4.3998 18.3337 5.09987 18.3337 6.5 18.3337H10.4167M17.5 8.33366H2.5M13.3333 1.66699V5.00033M6.66667 1.66699V5.00033M15 17.5003V12.5003M12.5 15.0003H17.5" stroke="#FF5700" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
            <p className={styles['date-range']} style={{ minWidth: "150px" }}>
                <span className='mb-0'>{dateText}</span>
                {
                    (dateRange.startDate && dateRange.endDate) ? (<svg className='cursor-pointer' onClick={(e) => { e.stopPropagation(); onClear(); }} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M12 4L4 12" stroke="#617275" stroke-width="1.25" stroke-linecap="round" />
                        <path d="M4 4L12 12" stroke="#617275" stroke-width="1.25" stroke-linecap="round" />
                    </svg>) : (<></>)
                }

            </p>

        </div>
        <ReactDatePicker
            ref={calRef}
            shouldCloseOnSelect={false}
            onChange={onChange}
            selectsRange
            startDate={range.startDate}
            endDate={range.endDate}
            maxDate={maxDate}
            minDate={minDate}
        >
            <div className='d-inline-block' style={{ minWidth: "100%" }}>
                <div className='d-flex gap-10 align-items-center py-2'>
                    <div className='flex-1'>
                    <button
                        className={"btn w-100 "+styles['apply']}
                        onClick={() => {
                            onApply();
                        }}
                    >
                        APPLY
                    </button>
                    </div>
                    <div className='flex-1'>
                    <button
                        className={"btn w-100 "+styles['cancel']}
                        onClick={() => {
                            allotDateRange(dateRange);
                            calRef.current.setOpen(false);
                        }}
                    >
                        CANCEL
                    </button>
                    </div>
                </div>

            </div>
        </ReactDatePicker>
    </div>)
}
