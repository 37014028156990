export class MenuCourse {
    constructor(id = 0, name = "", dateCourse = {}) {
        this.id = id;
        this.name = name;
        this.dateCourse = dateCourse;
    }
}

export class MenuMeal {
    constructor(id = 0, name = "", courses = []) {
        this.id = id;
        this.name = name;
        this.courses = courses;
    }
}


export class Menu {
    constructor(id = 0, name = "", properties = [], meals = []) {
        this.id = id;
        this.name = name;
        this.properties = properties;
        this.meals = meals;
    }
}

export const getInitialDefaultMenu = (mealsList = []) => {


    const mealsInfoList = JSON.parse(JSON.stringify(mealsList)).map((meal) => {
        const course = new MenuCourse();
        return new MenuMeal(meal.id, meal.name, [course]);
    })

    return new Menu(0,"",[],mealsInfoList);
}