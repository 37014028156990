import { useEffect, useState, useContext } from "react";
import { Link, useParams } from "react-router-dom";
import QrReader from "react-qr-scanner";
import { Modal, Spinner } from "react-bootstrap";
import toast from "react-hot-toast";

import withDefaultDashBoardLayout from "../../components/layouts/withDefaultDashBoardLayout";
import { getPropertyDetail } from "../../helpers/services/api.services";
import GlobalStateContext from "../../GlobalStateContext";
import {
  getMealSession,
  verifyFoodToken,
} from "../../helpers/services/admin.services";
import "./food.css";

function FoodVerificationDetails() {
  const { roles } = useContext(GlobalStateContext);
  let [propDetail, setPropDetail] = useState({});
  const [scannerOpen, setScannerOpen] = useState(false);
  const [mealSessions, setMealSessions] = useState([]);
  const { propertyId } = useParams();
  const [facingMode, setFacingMode] = useState("environment");
  const [isLoading, setIsLoading] = useState(false);
  const [selectedMealSession, setSelectedMealSession] = useState(null);
  const [foodToken, setFoodToken] = useState("");

  useEffect(() => {
    loadPropertyDetails();
    loadMealSessions();
  }, [propertyId]);

  const loadMealSessions = async () => {
    let data = await getMealSession({ property_id: propertyId });
    if (data && data.status === "success") {
      setMealSessions(data.meal_sessions);
    }
  };

  const handleManualToken = async () => {
    if (foodToken.trim().length != 8) {
      toast.error("Token Should be of 8 characters");
      return;
    }
    setIsLoading(true);
    let payload = {
      food_token: foodToken.trim(),
      meal_session_id: selectedMealSession.id,
    };
    let response = await verifyFoodToken(payload);
    if (response && response.status === "success") {
      toast.success(response.message);
      setScannerOpen(false);
      setIsLoading(false);
    } else {
      toast.error(response.error);
      setIsLoading(false);
      if (scannerOpen == true) {
        setFoodToken("");
      }
    }
  };

  const handleScan = (data) => {
    if (data) {
      console.log(data);
      setFoodToken(data.text);
    }
  };

  const handleError = (err) => {
    console.error(err);
  };

  const loadPropertyDetails = async () => {
    try {
      let response = await getPropertyDetail(propertyId);
      if (response && response.data) {
        setPropDetail(response.data);
      } else {
        setPropDetail([]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div style={{ overflowX: "hidden" }}>
      <div className="rdp-right-side-header">
        <div className="d-flex justify-content-between align-items-center pb-2">
          <div className="d-flex align-items-center gap-1">
            <Link to={"/food_verification"}>
              <img src="/images/Back.svg" alt="Back" />
            </Link>
            <h2 className="m-0">{propDetail?.property_name}</h2>
          </div>
        </div>
        <div className="breadcum">
          <ul>
            <li>
              <Link to={"/food_verification"}>Property</Link>
              <span>/</span>
            </li>
            <li>{propDetail?.property_name}</li>
          </ul>
        </div>
      </div>

      <div className="md-row d-flex pb-32 status-box">
        {mealSessions.map((meal, index) => (
          <div
            onClick={() => setSelectedMealSession(meal)}
            key={index}
            className=" mt-2 md-col-md-2"
          >
            <a
              className={`cursor-pointor ${
                selectedMealSession?.id == meal?.id ? "active" : ""
              }`}
              style={{ marginRight: "3px", marginLeft: "3px" }}
            >
              {meal.name}{" "}
              <span className="d-none d-md-inline">
                -- {meal?.start}-{meal?.end}
              </span>
            </a>
          </div>
        ))}
      </div>

      {selectedMealSession && selectedMealSession?.id && (
        <div className="mx-1">
          <div className=" form_control_box">
            <div className="row mb-4">
              <div className="d-flex">
                <div className="">
                  <label className="switch">
                    <input
                      type="checkbox"
                      onChange={() => setScannerOpen(!scannerOpen)}
                      checked={scannerOpen}
                    />
                    <span className="slider round"></span>
                  </label>
                </div>
                <div className="mx-2 mt-1">
                  <b>Open QR Scanner</b>
                </div>
              </div>
            </div>
            {/* <div className="d-flex w-100 mb-3">
              <div className="line mx-1 w-100"></div>
              <div><b>OR</b></div>
              <div className="line mx-1 w-100"></div>
            </div> */}
            <div className="form-group">
              <input
                type="text"
                className="form-control w-100"
                placeholder="Enter token"
                aria-label="Enter token"
                value={foodToken}
                onChange={(e) => setFoodToken(e.target.value)}
              />
            </div>
            <div className="d-flex justify-content-left">
              <button
                className="basic-button w-md-25 w-100"
                disabled={foodToken.trim().length != 8 || isLoading}
                type="button"
                onClick={handleManualToken}
              >
                Submit
              </button>
            </div>
          </div>

          <Modal
            show={scannerOpen}
            onHide={() => {
              setScannerOpen(false);
              setFoodToken("");
            }}
            centered
          >
            <Modal.Header closeButton>
              <Modal.Title>QR Scanner</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {!isLoading && (
                <QrReader
                  delay={300}
                  onError={handleError}
                  onScan={handleScan}
                  facingMode={facingMode}
                  constraints={{
                    audio: false,
                    video: { facingMode: facingMode },
                  }}
                  style={{ width: "100%" }}
                />
              )}
              {isLoading && (
                <div
                  className="position-fixed w-100 h-100 d-flex justify-content-center align-items-center"
                  style={{ top: 0, left: 0, background: "rgba(0, 0, 0, 0.5)" }}
                >
                  <Spinner animation="border" role="status">
                    <span className="sr-only">Loading...</span>
                  </Spinner>
                </div>
              )}
              <div className="d-flex flex-row justify-content-center mt-2">
                <div className="d-flex justify-content-center flex-grow-1 mt-2">
                  <button
                    className="btn btn-primary"
                    onClick={() =>
                      setFacingMode((prevState) =>
                        prevState === "user" ? "environment" : "user"
                      )
                    }
                  >
                    Switch Camera
                  </button>
                </div>
                <div className="d-flex justify-content-center flex-grow-1 mt-2">
                  <button
                    className="btn btn-primary"
                    onClick={handleManualToken}
                    disabled={foodToken.trim().length !== 8 || isLoading}
                  >
                    Capture QR
                  </button>
                </div>
              </div>
            </Modal.Body>
          </Modal>
        </div>
      )}
    </div>
  );
}

export default withDefaultDashBoardLayout(FoodVerificationDetails);