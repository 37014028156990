import React, { useCallback, useEffect, useState, useContext } from "react";
import withDefaultDashBoardLayout from "../../../components/layouts/withDefaultDashBoardLayout";
import styles from "./revenueManualPage.module.scss";
import BreadCrumb from "../../../components/BreadCrumb";
import { revenueFilters } from "./utils";
import { Link } from "react-router-dom";
import UploadAdjustmentModal from "./modals/UploadAdjustmentModal";
import DocumentApprovalModal from "./modals/DocumentApprovalModal";
import {
  getManualAdjustmentEntriesApi,
  updateManualAdjustmentEntryApi,
} from "../../../helpers/services/revenueservices";
import moment from "moment";
import GlobalStateContext from "../../../GlobalStateContext";
import { getFiscalYear } from "../../../helpers/common/common.function";

const adjustmentFileTypes = {
  proforma_invoice: "Pro-forma Invoice",
  tax_invoice: "Tax Invoice",
  credit_note: "Credit Note",
};

const RevenuePage = () => {
  const { roles } = useContext(GlobalStateContext);
  const [filters, setFilters] = useState({
    show: "all",
  });

  const [actionBulk, setActionBulk] = useState(false);

  const [actionItem, setActionItem] = useState({
    open: false,
    item: null,
    type: "",
  });

  const [list, setList] = useState([]);

  const getList = async () => {
    let results = [];
    try {
      const response = await getManualAdjustmentEntriesApi();
      if (response.data && response.data.length) {
        results = response.data.map((item) => {
          let fileName = "";
          try {
            fileName = new URL(item.file_url || "").pathname
              .split("/")
              .pop()
              .split(".")[0];
          } catch (e) {
            console.error(e);
          }
          return {
            ...item,
            editStatus: false,
            fileType: adjustmentFileTypes[item.file_type] || "Credit Note",
            fileName,
            fileTotalAmount: parseFloat(item.total_amount).toLocaleString(
              "en-IN",
              {
                maximumFractionDigits: 2,
                style: "currency",
                currency: "INR",
              },
            ),
            fileStatus: item.status === "initiated" ? "pending" : item.status,
            uploadedOn: moment(item.uploaded_on).format("DD MMM YYYY"),
          };
        });
      }
    } catch (e) {
      console.log(e);
    }
    setList(results);
  };

  useEffect(() => {
    getList();
  }, []);

  const onCloseActionBulk = (submitted = true) => {
    setActionBulk(false);
    if (submitted) {
      getList();
    }
  };

  const onHandleFilter = (value) => {
    setList((prev) => {
      return prev.map((item) => {
        return {
          ...item,
          editStatus: false,
        };
      });
    });
    setFilters({
      show: value,
    });
  };

  const onCloseActionItem = () => {
    setActionItem({
      open: false,
      item: null,
      type: "",
    });
  };

  const onHandlePending = useCallback(
    (value, index) => {
      if (value.fileStatus === "pending") {
        const status = !value.editStatus;
        setList((prev) => {
          let st = [...prev];
          st[index].editStatus = status;
          return st;
        });
      }
    },
    [setList],
  );

  const onLockStatusHandle = (value, type = "") => {
    if (value.fileStatus === "pending") {
      setActionItem({
        open: true,
        item: value,
        type,
      });
    }
  };

  const onActionSubmit = async (value) => {
    try {
      const response = await updateManualAdjustmentEntryApi(
        actionItem.item.id,
        { status: value },
      );
      if (response) {
        onCloseActionItem();
        getList();
      }
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <>
      <DocumentApprovalModal
        type={actionItem.type}
        show={actionItem.open}
        close={onCloseActionItem}
        onAction={onActionSubmit}
      />
      <UploadAdjustmentModal show={actionBulk} close={onCloseActionBulk} />
      <div className={styles["revenue-manual-page"]}>
        <div className={styles["heading-container"]}>
          <div className={styles["heading-left"]}>
            <h1 className={"mb-0 " + styles["heading"]}>
              <span className={"font-zkga fw-800 text-gray-900"}>
                Manual Adjustment Entries -{" "}
              </span>
              <span className={"font-manrope fw-700 text-gray-600"}>
                {getFiscalYear()}
              </span>
            </h1>
            <div>
              <BreadCrumb
                seperator="/"
                list={[
                  {
                    label: "Revenue",
                    link: "",
                    active: false,
                  },
                  {
                    label: "Manual adjustment entries",
                    link: "",
                    active: true,
                  },
                ]}
              />
            </div>
          </div>
          <div className={styles["heading-right"]}>
            <button
              className={"btn d-flex items-center gap-8"}
              onClick={() => {
                setActionBulk(true);
              }}
            >
              <span>
                <svg
                  width="20"
                  height="21"
                  viewBox="0 0 20 21"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M10.0003 7.16663V13.8333M6.66699 10.5H13.3337M18.3337 10.5C18.3337 15.1023 14.6027 18.8333 10.0003 18.8333C5.39795 18.8333 1.66699 15.1023 1.66699 10.5C1.66699 5.89759 5.39795 2.16663 10.0003 2.16663C14.6027 2.16663 18.3337 5.89759 18.3337 10.5Z"
                    stroke="#FF5700"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </span>
              <span>Bulk Upload Adjustment entry</span>
            </button>
          </div>
        </div>

        <div className={styles["content-container"]}>
          <div className={styles["content-filter"]}>
            <div className={styles["content-left"]}>
              {revenueFilters.map((revenueFilter) => {
                return (
                  <button
                    onClick={() => {
                      onHandleFilter(revenueFilter.value);
                    }}
                    key={"revenueFilter_" + revenueFilter.value}
                    className={
                      "btn " +
                      (revenueFilter.value === filters.show
                        ? styles["active"]
                        : "")
                    }
                  >
                    {revenueFilter.label}
                  </button>
                );
              })}
            </div>
            <div className={styles["content-right"]}></div>
          </div>
          <div className={styles["content-table"] + " table-responsive"}>
            <table className="table align-middle">
              <thead>
                <tr>
                  <th width="150px">File&nbsp;Type</th>
                  <th>Uploaded&nbsp;File</th>
                  <th width="100px">Uploaded&nbsp;On</th>
                  <th>Status</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {list.map((filteredListItem, index) => {
                  if (
                    !(
                      filters.show === "all" ||
                      (filters.show !== "all" &&
                        filteredListItem.fileStatus === filters.show)
                    )
                  ) {
                    return (
                      <React.Fragment
                        key={"RevenueManualListItem_" + filteredListItem.id}
                      ></React.Fragment>
                    );
                  }
                  return (
                    <tr key={"RevenueManualListItem_" + filteredListItem.id}>
                      <td className={styles["title"]}>
                        {filteredListItem.fileType}
                      </td>
                      <td>
                        <Link
                          className="text-capitalize"
                          to={filteredListItem.file_url}
                          rel="noreferrer"
                          target={"_blank"}
                        >
                          {filteredListItem.fileName}
                        </Link>
                      </td>
                      <td>{filteredListItem.uploadedOn}</td>
                      <td>
                        <button
                          disabled={
                            !(roles?.super_admin || roles?.finance_head || roles?.cfo)
                          }
                          onClick={() => {
                            onHandlePending(filteredListItem, index);
                          }}
                          className={
                            "btn text-capitalize " +
                            styles["status"] +
                            " " +
                            styles[filteredListItem.fileStatus]
                          }
                        >
                          {filteredListItem.fileStatus}
                        </button>
                      </td>
                      <td>
                        {filteredListItem.generated_file_url ? (
                          <></>
                        ) : (
                          <>
                            {filteredListItem.editStatus ? (
                              <div className="d-flex align-items-center gap-8">
                                <button
                                  className={"btn " + styles["btn-approve"]}
                                  onClick={() => {
                                    onLockStatusHandle(
                                      filteredListItem,
                                      "approved",
                                    );
                                  }}
                                >
                                  APPROVE
                                </button>
                                <button
                                  className={"btn " + styles["btn-reject"]}
                                  onClick={() => {
                                    onLockStatusHandle(
                                      filteredListItem,
                                      "rejected",
                                    );
                                  }}
                                >
                                  REJECT
                                </button>
                              </div>
                            ) : (
                              "--"
                            )}
                          </>
                        )}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
};

export default withDefaultDashBoardLayout(RevenuePage);
