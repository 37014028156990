import React from "react";

export default function Operations() {
  return (
    <div className="readOps">
      <div className="brd_box_card">
        <div className="box_head">
          <div className="d-flex align-items-center">
            <h2>
              {" "}
              <img
                src="/images/corner-down-right.svg"
                className="pr-2"
                alt=""
              />{" "}
              POINT OF CONTACT
            </h2>
          </div>
        </div>
        <div className="box_contain">
          <div className="sub_exit_box">
            {/* <h2>point of contact</h2> */}
            <div className="d-flex align-items-center ">
              <div className="sub_exit_data_txt">
                <h3>Sales</h3>
                <span>Venkat Reagan</span>
              </div>
              <div className="sub_exit_data_txt">
                <h3>Admin</h3>
                <span>Vamshi</span>
              </div>
              <div className="sub_exit_data_txt">
                <h3>Property Manager</h3>
                <span>Muhammed ansari</span>
              </div>
              <div className="sub_exit_data_txt">
                <h3>Cluster Manager</h3>
                <span>Rohit Verman</span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row mt-3">
        <div className="col-6">
          <div className="brd_box_card">
            <div className="box_head">
              <div className="d-flex align-items-center">
                <h2>
                  {" "}
                  <img
                    src="/images/corner-down-right.svg"
                    className="pr-2"
                    alt=""
                  />{" "}
                  asset provided in room
                </h2>
              </div>
            </div>
            <div className="box_contain">
              <div className="refer_table commen_table pt-1">
                <div className="page-table-box">
                  <table>
                    <thead>
                      <tr>
                        <th>Asset name</th>
                        <th>Yes/No (Default No)</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>Chair</td>
                        <td>No</td>
                      </tr>
                      <tr>
                        <td>Table</td>
                        <td>No</td>
                      </tr>

                      <tr>
                        <td>Washing machine</td>
                        <td>Yes</td>
                      </tr>
                      <tr>
                        <td>Oven</td>
                        <td>Yes</td>
                      </tr>
                      <tr>
                        <td>Cuoboard</td>
                        <td>Yes</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-6">
        <div className="brd_box_card">
            <div className="box_head">
              <div className="d-flex align-items-center">
                <h2>
                  {" "}
                  <img
                    src="/images/corner-down-right.svg"
                    className="pr-2"
                    alt=""
                  />{" "}
                  asset provided in common area
                </h2>
              </div>
            </div>
            <div className="box_contain">
              <div className="refer_table commen_table pt-1">
                <div className="page-table-box">
                  <table>
                    <thead>
                      <tr>
                        <th>Asset name</th>
                        <th>Yes/No (Default No)</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>Chair</td>
                        <td>No</td>
                      </tr>
                      <tr>
                        <td>Table</td>
                        <td>No</td>
                      </tr>

                      <tr>
                        <td>Washing machine</td>
                        <td>Yes</td>
                      </tr>
                      <tr>
                        <td>Oven</td>
                        <td>Yes</td>
                      </tr>
                      <tr>
                        <td>Cuoboard</td>
                        <td>Yes</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
