import { useState } from "react";
import { Modal } from "react-bootstrap";
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Select from "react-dropdown-select";
import ReactDatePicker from 'react-datepicker';

function GatePassTab({ engagementStatus }) {

    const [activeStatus, setActiveStatus] = useState("All");
    const [showGatepassModal, setShowGatepassModal] = useState(false);

    const handleCloseGatepassModal = () => setShowGatepassModal(false);
    const handleShowGatepassModal = () => setShowGatepassModal(true);

    const filteredEngagementData = async (status) => {
        setActiveStatus(status);

        // Logic for filter data
    };

    // Form Validations
    const validationSchema = Yup.object().shape({
        studentName: Yup.object().required('Please select any student'),
        leavingOn: Yup.date().required('Leaving on date is required'),
        backOn: Yup.date().required('Back on date is required'),
        studentPhoneNo: Yup.object().required('student phone number is required'),
        parentPhoneNo: Yup.object().required('Parent phone number is required'),
        propertyManager: Yup.object().required('Please select any property manager'),
        description: Yup.string().optional(),
    });

    const initialValues = {
        studentName: {},
        leavingOn: '',
        backOn: '',
        studentPhoneNo: {},
        parentPhoneNo: {},
        propertyManager: {},
        description: '',
    };

    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit: (values) => {
            // Handle form submission here
            console.log(values);
        },
    });

    const handleReset = () => {
        formik.resetForm();
        handleCloseGatepassModal();
    };

    return (
        <>
            {/* Status */}
            <div className="d-flex justify-content-between align-items-center pb-32">
                <div className="status-box">
                    {engagementStatus.map((status, index) => (
                        <a
                            key={index}
                            href="#"
                            className={status?.label === activeStatus ? "active" : ""}
                            onClick={() => filteredEngagementData(status?.label)}
                        >
                            {status?.label}
                        </a>
                    ))}
                </div>
                <div className="status-box">
                    <a href="#">
                        <img src="/images/filter.svg" className="pr-1" alt="" /> Filter
                    </a>
                    <button
                        className="orange_brd_button p-12-16-button bg_btn_orange"
                        onClick={handleShowGatepassModal}
                    >
                        <img src="/images/plus_orange.svg" className="pr-1 mt--2" alt="" /> CREATE GATEPASS
                    </button>
                </div>
            </div>

            {/* Table */}
            <div className="getpass_tab">
                <div className="page-table-box">
                    <table>
                        <thead>
                            <tr>
                                <th>Name</th>
                                <th>Start date</th>
                                <th>End date</th>
                                <th>Status</th>
                                <th>Raised by</th>
                                <th>Student Ph</th>
                                <th>PM</th>
                                <th>Parent Ph</th>
                                <th className="w120">Status</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>Water shortage</td>
                                <td>25 Aug 2023</td>
                                <td>25 Aug 2023</td>
                                <td className="w120">
                                    <a className="bg_green_txt">
                                        Approved
                                    </a>
                                </td>
                                <td>Brooklyn Simmons</td>
                                <td>99999 99999</td>
                                <td>Brooklyn Simmons</td>
                                <td>99999 99999</td>
                                <td className="w120">
                                    <a className="bg_warning_txt">
                                        Inactive
                                    </a>
                                </td>
                            </tr>
                            <tr>
                                <td>Water shortage</td>
                                <td>25 Aug 2023</td>
                                <td>25 Aug 2023</td>
                                <td className="w120">
                                    <a className="bg_warning_txt">
                                        Raised
                                    </a>
                                </td>
                                <td>Brooklyn Simmons</td>
                                <td>99999 99999</td>
                                <td>Brooklyn Simmons</td>
                                <td>99999 99999</td>
                                <td className="w120">
                                    <a className="bg_green_txt">
                                        Active
                                    </a>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>

            {/* Modal */}
            <Modal
                show={showGatepassModal}
                onHide={handleCloseGatepassModal}
                centered
                size="lg"
                className="notice_modal commen_modal"
            >
                <Modal.Header>
                    <Modal.Title>Create gate-pass</Modal.Title>
                    <img src="/images/cross_modal.svg" className="cursor-pointor" alt="Close Icon" onClick={handleCloseGatepassModal} />
                </Modal.Header>
                <Modal.Body>
                    <form onSubmit={formik.handleSubmit}>
                        <div className="row">
                            <div className="col-4 custom-single-select">
                                <label className="form-label" htmlFor="studentName">Student Name</label>
                                <Select
                                    id="studentName"
                                    name="studentName"
                                    placeholder='Select student'
                                    options={[
                                        { value: 1, label: 'Brooklyn Simmons' },
                                        { value: 2, label: 'Brook Simmons' },
                                        { value: 3, label: 'Sim B' },
                                    ]}
                                    value={formik.values.studentName}
                                    onChange={(value) => formik.setFieldValue('studentName', value)}
                                    onBlur={formik.handleBlur}
                                />
                                {formik.touched.studentName && formik.errors.studentName ? (
                                    <div className="error-label">{formik.errors.studentName}</div>
                                ) : null}
                            </div>

                            <div className="col-4">
                                <div className="form_control_box">
                                    <div className='modal_input_main'>
                                        <label className="form-label" htmlFor="leavingOn">Leaving On</label>
                                        <ReactDatePicker
                                            placeholderText="DD/MM/YY"
                                            selected={formik.values.leavingOn}
                                            onChange={(date) => formik.setFieldValue('leavingOn', date)}
                                        />
                                        {formik.touched.leavingOn && formik.errors.leavingOn ? (
                                            <div className="error-label">{formik.errors.leavingOn}</div>
                                        ) : null}
                                    </div>
                                </div>
                            </div>

                            <div className="col-4">
                                <div className="form_control_box">
                                    <div className='modal_input_main'>
                                        <label className="form-label" htmlFor="backOn">Back On</label>
                                        <ReactDatePicker
                                            placeholderText="DD/MM/YY"
                                            selected={formik.values.backOn}
                                            onChange={(date) => formik.setFieldValue('backOn', date)}
                                        />
                                        {formik.touched.backOn && formik.errors.backOn ? (
                                            <div className="error-label">{formik.errors.backOn}</div>
                                        ) : null}
                                    </div>
                                </div>
                            </div>

                            <div className="col-4 custom-single-select">
                                <label className="form-label" htmlFor="studentPhoneNo">Student phone number</label>
                                <Select
                                    id="studentPhoneNo"
                                    name="studentPhoneNo"
                                    options={[
                                        { value: 'option1', label: 'Option 1' },
                                        { value: 'option2', label: 'Option 2' },
                                    ]}
                                    value={formik.values.studentPhoneNo}
                                    onChange={(value) => formik.setFieldValue('studentPhoneNo', value)}
                                    onBlur={formik.handleBlur}
                                    placeholder="Seach & select"
                                />
                                {formik.touched.studentPhoneNo && formik.errors.studentPhoneNo ? (
                                    <div className="error-label">{formik.errors.studentPhoneNo}</div>
                                ) : null}
                            </div>

                            <div className="col-4 custom-single-select">
                                <label className="form-label" htmlFor="parentPhoneNo">Parent phone number</label>
                                <Select
                                    id="parentPhoneNo"
                                    name="parentPhoneNo"
                                    options={[
                                        { value: 'crm', label: 'CRM' },
                                        { value: 'function1', label: 'Function 1' },
                                        { value: 'function2', label: 'Function 2' },
                                    ]}
                                    value={formik.values.parentPhoneNo}
                                    onChange={(value) => formik.setFieldValue('parentPhoneNo', value)}
                                    onBlur={formik.handleBlur}
                                    placeholder="Seach & select"
                                />
                                {formik.touched.parentPhoneNo && formik.errors.parentPhoneNo ? (
                                    <div className="error-label">{formik.errors.parentPhoneNo}</div>
                                ) : null}
                            </div>

                            <div className="col-4 custom-single-select">
                                <label className="form-label" htmlFor="propertyManager">Property manager</label>
                                <Select
                                    id="propertyManager"
                                    name="propertyManager"
                                    options={[
                                        { value: 36, label: '36%' },
                                        { value: 50, label: '50%' },
                                        { value: 100, label: '100%' },
                                    ]}
                                    value={formik.values.propertyManager}
                                    onChange={(value) => formik.setFieldValue('propertyManager', value)}
                                    onBlur={formik.handleBlur}
                                    placeholder="Seach & select"
                                />
                                {formik.touched.propertyManager && formik.errors.propertyManager ? (
                                    <div className="error-label">{formik.errors.propertyManager}</div>
                                ) : null}
                            </div>

                            <div className="col-12 mt-4">
                                <div className="form_control_box">
                                    <label className="form-label" htmlFor="description">Description</label>
                                    <textarea
                                        id="description"
                                        name="description"
                                        className="form-control"
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.description}
                                        placeholder="Additional info (Optional)"
                                    />
                                    {formik.touched.description && formik.errors.description ? (
                                        <div className="error-label">{formik.errors.description}</div>
                                    ) : null}
                                </div>
                            </div>

                            <div className="modal_btn_footer">
                                <button type="button" onClick={handleReset} className="orange_brd_button p-12-16-button mr-3">CANCEL</button>
                                <button type="submit" className="basic-button">CONFIRM</button>
                            </div>
                        </div>
                    </form>
                </Modal.Body>
            </Modal>
        </>
    );
}

export default GatePassTab;