import React, { useState, useRef, useEffect } from "react";
import { Form, InputGroup } from "react-bootstrap";
import Select from "react-dropdown-select";
import toast from "react-hot-toast";
import { createPropertyBilling, createPropertyRoomTypeMapping } from "../../../helpers/services/api.services";
import { fetchBillTypes } from "../../../helpers/services/admin.services";
import Papa from "papaparse";

export default function CreateBookingPlan({ roomTypes, savedPropertyId, handleCloseModal }) {
  const [fileName, setFileName] = useState("");
  const fileInputRef = useRef();
  const [billTypes, setBillTypes] = useState([]);
  const [roomTypesData, setRoomTypes] = useState([...roomTypes]);
  const [propertyId, setPropertyId] = useState(null);
  const [roomPlanId, setRoomPlanId] = useState();
  const [showAddMoreButton, setShowAddMoreButton] = useState(false);
  const [bedsError,setBedsError] = useState([]);

  // BOOKING PLANS
  const billDataFormat = {
    billType: {},
    billTypeId: null,
    collectionFrequency: "",
    amount: 0,
    maximumAmount: 0,
  };

  const bookingPlansFormat = {
    room_type_id: null,
    room_type: {},
    property_id: null,
    beds_per_room: 0,
    beds_for_admission: {},
    total_beds_for_admission: 0,
    billings: [],
    is_saved: false,
    is_billing_saved: false,
    room_list_file:'',
    base_64_string:'',
    details:[],
  };

  const [bookingPlans, setBookingPlans] = useState([bookingPlansFormat]);

  useEffect(() => {
    let bookingData = sessionStorage.getItem('bookingPlanData');

    if (bookingData && JSON.parse(bookingData).length > 0) {
      setBookingPlans(JSON.parse(bookingData));
    }

    setPropertyId(savedPropertyId);
  }, [savedPropertyId])

  const handleBrowseClick = () => {
    fileInputRef.current.click();
  };

  function getBase64(file,cb) {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
        cb(reader.result)
    };
    reader.onerror = function (error) {
        console.log('Error: ', error);
    };
}

const fileParser = (file, index) => {
    Papa.parse(file, {
      header: true,
      skipEmptyLines: true,
      complete: function (results) {
        let data_array = results.data.map((r)=>({
          falt_number: r["Flat"],
          room_number: r["Room No."],
          bed: r["Bed No."]
        }))
        let bookingPlans_copy = [...bookingPlans]
        bookingPlans_copy[index].details = data_array
        setBookingPlans(bookingPlans_copy)
      },
    });
  };
  
  const handleFileChange = (e, index) => {
    const file = e.target.files[0];
    if (file) {
      // You can perform your file upload logic here.
      console.log("Uploading file:", file);
      let bookingPlans_copy = [...bookingPlans]
      getBase64(file,(base64_string)=>{
        bookingPlans_copy[index].room_list_file = file
        bookingPlans_copy[index].base_64_string = base64_string
      })
      // setFileName(file?.name);
      console.log("bookingPlans_copy - ",bookingPlans_copy)
      setBookingPlans(bookingPlans_copy)
      fileParser(file,index)
    }
  };

  const loadBillTypes = async () => {
    let response = await fetchBillTypes();

    if (response && response.data && response.data.length > 0) {
      let billType = response.data.map(element => {
        return { value: element?.id, label: element?.bill_name, collectionFrequency: element?.frequency }
      });
      setBillTypes(billType);
    }
  }

  const addBookingPlans = () => {
    setBookingPlans(bookingPlan => [...bookingPlan, bookingPlansFormat]);
  }

  const removeBillRecord = async (index, bIndex) => {
    let updatedBillingPlans = [...bookingPlans];
    updatedBillingPlans[index].billings = updatedBillingPlans[index].billings.filter((_, key) => key !== bIndex);
    setBookingPlans(updatedBillingPlans);
  }

  const addBillRecords = async (index) => {
    await loadBillTypes();
    const updatedBillingPlans = [...bookingPlans];
    updatedBillingPlans[index].billings.push(billDataFormat);
    setBookingPlans(updatedBillingPlans);
  }

  const handleAmountChange = (index, bIndex, value, key) => {
    const updatedBillingPlans = [...bookingPlans];
    updatedBillingPlans[index].billings[bIndex][key] = value;
    setBookingPlans(updatedBillingPlans);
  }

  const onChangeRoomType = (index, roomType) => {
    const updatedBillingPlans = [...bookingPlans];

    if (index >= 0) {
      updatedBillingPlans[index] = {
        ...updatedBillingPlans[index],
        room_type: roomType,
        room_type_id: roomType.value,
        beds_per_room: roomType.bedsPerRoom,
      };
      // let erro_array = [...bedsError]
      // if(updatedBillingPlans[index].total_beds_for_admission <= updatedBillingPlans[index].beds_per_room){
      //   const i = erro_array.indexOf(index);
      //   if (i > -1) {
      //     erro_array.splice(i, 1);
      //   }
      // }else{  
      //   if(!bedsError.includes(index)){
      //     erro_array.push(index)
      //   }
      // }
      // setBedsError(erro_array)
      setBookingPlans(updatedBillingPlans);
    }
  };

  const onChangeBedsForAdmission = (index, totalBeds) => {
    const updatedBillingPlans = [...bookingPlans];

    if (index >= 0) {
      updatedBillingPlans[index] = {
        ...updatedBillingPlans[index],
        beds_for_admission: totalBeds,
        total_beds_for_admission: totalBeds.value,
      };
      setBookingPlans(updatedBillingPlans);
    }
  };

  const handleSavePlanDetails = async (index) => {
    const updatedBillingPlans = [...bookingPlans];
    const planData = updatedBillingPlans[index];

    if (!planData.room_type_id) {
      toast.error('Please select any room type');
    } else if (!planData.total_beds_for_admission) {
      toast.error('Please select any beds for admission');
    } else {
      // const reqBody = {
      //   "room_type_id": planData.room_type_id,
      //   "id": propertyId,
      //   // "id":91,
      //   "no_of_beds": planData.total_beds_for_admission,
      //   "details": [
      //     {
      //       "falt_number": "Flat101",
      //       "room_number": "Room101",
      //       "bed": "Bed1"  
      //     }
      //   ]
      // };

      const reqBody = {
        room_type_id: planData.room_type_id,
        id: propertyId,
        // "id":91,
        no_of_beds: planData.total_beds_for_admission,
        details: planData.details,
        room_list: planData.base_64_string.split(",")[1]
      };

      let response = await createPropertyRoomTypeMapping(reqBody);

      if (response && response?.status === 'success') {
        const roomPlanData = response?.data;
        setRoomPlanId(roomPlanData?.property_room_type_mapping?.id);

        updatedBillingPlans[index].is_saved = true;
        setBookingPlans(updatedBillingPlans);

        toast.success('Booking plan created successfully and please add booking details.');
      } else {
        toast.error('Something went wrong with create a booking plan');
      }
    }
  }

  const handleOnChangeBillType = (value, index, bIndex) => {
    const updatedBillingPlans = [...bookingPlans];
    updatedBillingPlans[index].billings[bIndex].billType = value;
    updatedBillingPlans[index].billings[bIndex].billTypeId = value.value;
    updatedBillingPlans[index].billings[bIndex].collectionFrequency = value.collectionFrequency;

    setBookingPlans(updatedBillingPlans);
  }

  const handleSaveBillingDetails = async (index) => {
    const updatedBillingPlans = [...bookingPlans];
    const billingDetails = updatedBillingPlans[index];

    if (billingDetails?.billings && billingDetails?.billings.length > 0) {

      let reqBody = {
        "room_type_id": billingDetails?.room_type_id,
        "property_id": propertyId,
        // "property_id":91,
        "billings": billingDetails?.billings.map(element => {
          return {
            "billing_id": element?.billTypeId || null,
            "frequency": element?.collectionFrequency || "",
            "amount": parseInt(element?.amount) || 0,
            "discount_type": "percentage",
            "discount_value": parseFloat(element?.maximumAmount) || 0
          }
        })
      };

      let response = await createPropertyBilling(reqBody);

      if (response && response?.status === 'success') {
        updatedBillingPlans[index].is_billing_saved = true;
        setBookingPlans(updatedBillingPlans);
        setShowAddMoreButton(true);

        toast.success('Billing details has been saved successfully.');
      } else {
        toast.error('Something went wrong while save billing details');
      }
    } else {
      toast.error('Please add any billing information.');
    }
  }

  const handleSaveAsDraftBookingPlans = () => {
    let draftData = JSON.stringify(bookingPlans);
    sessionStorage.setItem('bookingPlanData', draftData);
  }

  return (
    <div className="createBooking">
      <h5 className="title mb-4">Plan</h5>

      <div className="room-plan_body">
        {
          (bookingPlans && bookingPlans.length > 0) && bookingPlans.map((item, index) => (
            <>
              {/* Plan Details */}
              <div className="row" key={'Plan-' + index}>
                <div className="col-md-4 custom-single-select">
                  <label className="form-label" htmlFor="room-types">Room Type</label>
                  <Select
                    id="room-types"
                    name="room-types"
                    placeholder="Select Room Type"
                    className="mt-1"
                    options={roomTypesData}
                    value={item?.room_type}
                    onChange={(value) => {
                      onChangeRoomType(index, value[0])
                    }}
                  />
                </div>
                <div className="col-md-4">
                  <Form.Label>Beds per Room</Form.Label>
                  <Form.Control
                    placeholder="--"
                    readOnly
                    value={item?.beds_per_room}
                  />
                </div>
                {/* <div className="col-md-4 custom-single-select">
                  <label className="form-label" htmlFor="room-types">Total beds for admission</label>
                  <Select
                    id="room-types"
                    name="room-types"
                    placeholder="Enter beds for admission"
                    className="mt-1"
                    options={[
                      { value: 1, label: 1 },
                      { value: 2, label: 2 },
                      { value: 3, label: 3 },
                      { value: 4, label: 4 },
                    ]}
                    value={item?.beds_for_admission}
                    onChange={(value) => {

                      console.log(value[0]);
                      let erro_array = [...bedsError]
                      if(value[0].value <= item?.beds_per_room){
                        const i = erro_array.indexOf(index);
                        if (i > -1) {
                          erro_array.splice(i, 1);
                        }
                      }else{  
                        if(!bedsError.includes(index)){
                          erro_array.push(index)
                        }
                      }
                      setBedsError(erro_array)

                      onChangeBedsForAdmission(index, value[0])
                    }}
                    style={bedsError.includes(index) ? {
                      borderColor:'red'
                    }:null}
                  />
                   {
                    bedsError.includes(index) ? 
                      <div className="error-label">Beds for admission cannot be greater than beds per room</div>
                    : null
                  }
                </div> */}
                <div className="col-md-4">
                  <Form.Label>Total beds for admission</Form.Label>
                  <Form.Control
                    id="room-types"
                    name="room-types"
                    type="number"
                    placeholder="Enter beds for admission"
                    value={item?.beds_for_admission}
                    onChange={e => {
                      let value = e.target.value
                      let erro_array = [...bedsError]
                      if(value <= 0){
                        const i = erro_array.indexOf(index);
                        if (i > -1) {
                          erro_array.splice(i, 1);
                        }
                      }else{  
                        if(!bedsError.includes(index)){
                          erro_array.push(index)
                        }
                      }
                      setBedsError(erro_array)
                      onChangeBedsForAdmission(index, value)
                    }}
                    style={bedsError.includes(index) ? {
                      borderColor:'red'
                    }:null}
                    />
                     {
                      bedsError.includes(index) ? 
                        <div className="error-label mt-2">Beds for admission ṃust be greater than 0</div>
                      : null
                    }
                </div>
                <div className="col-md-8 cursor-pointer">
                  <Form.Label>Upload room list</Form.Label>
                  <InputGroup className="mb-3">
                    <Form.Control
                      aria-label="Amount (to the nearest dollar)"
                      // value={fileName}
                      value={item.room_list_file?.name}
                    />
                    <Form.Control
                      id="fileInput"
                      type="file"
                      ref={fileInputRef}
                      // accept=".xlsx,.xls,.csv"
                      accept=".csv"
                      className="custom-file-input"
                      onChange={(e) => handleFileChange(e, index)}
                    />
                    <InputGroup.Text onClick={handleBrowseClick}>
                      UPLOAD
                    </InputGroup.Text>
                  </InputGroup>
                </div>

                {
                  !item?.is_saved && (
                    <div className="col-md-4">
                      <label className="form-label">&nbsp;</label>
                      <br />
                      <button
                        type="button"
                        onClick={() => handleSavePlanDetails(index)}
                        // className={`basic-button`}
                        className={`basic-button ${bedsError.includes(index) ? 'bg_gray_button' : ''}`}
                        disabled={bedsError.includes(index)}
                      >CONFIRM & PROCEED</button>
                    </div>
                  )
                }
              </div>

              {/* Bill Details */}
              {
                item?.is_saved && (
                  <div className="refer_table commen_table" key={'Billing-' + index}>
                    <div className="page-table-box">
                      <table>
                        <thead>
                          <tr>
                            <th width="20%">Bill Type</th>
                            <th width="20%">Collection Frequency</th>
                            <th width="20%" className="text-right">Amount</th>
                            <th width="20%" className="text-right">Maximum Discount</th>
                            {!item?.is_billing_saved && <th width="5%"></th>}
                          </tr>
                        </thead>
                        <tbody>
                          {
                            (item?.billings && item?.billings.length > 0) && item?.billings.map((bill, bIndex) => (
                              <tr key={bIndex}>
                                <td>
                                  <div className="custom-single-select">
                                    <Select
                                      id="billTypes"
                                      name="billTypes"
                                      placeholder="Select Bill Type"
                                      className="mt-1"
                                      options={billTypes}
                                      value={bill.billType}
                                      onChange={(value) => handleOnChangeBillType(value[0], index, bIndex)}
                                    />
                                  </div>
                                </td>
                                <td>
                                  <Form.Control
                                    type="text"
                                    placeholder="Frequency"
                                    readOnly
                                    value={
                                      bill?.collectionFrequency === 'one_time' ? 'One Time' :
                                        bill?.collectionFrequency === 'monthly' ? 'Monthly' :
                                          bill?.collectionFrequency === 'quarterly' ? 'Quarterly' :
                                            bill?.collectionFrequency === 'half_yearly' ? 'Half Yearly' :
                                              bill?.collectionFrequency === 'annually' ? 'Annually' :
                                                bill?.collectionFrequency
                                    }
                                  />
                                </td>
                                <td className="text-right">
                                  <Form.Control
                                    type="number"
                                    placeholder="₹ Enter Amount"
                                    value={bill?.amount}
                                    onChange={(e) => handleAmountChange(index, bIndex, e.target.value, 'amount')}
                                  />
                                </td>
                                <td className="text-right">
                                  <div>
                                    <Form.Control
                                      type="number"
                                      placeholder="₹ Enter Amount"
                                      min={1}
                                      max={100}
                                      value={bill?.maximumAmount}
                                      onChange={(e) => handleAmountChange(index, bIndex, e.target.value, 'maximumAmount')}
                                    />
                                  </div>
                                </td>
                                {!item?.is_billing_saved && <td>
                                  <div className="trash-bg cursor-pointer">
                                    <img src="/images/trash-03.png" onClick={() => removeBillRecord(index, bIndex)} />
                                  </div>
                                </td>}
                              </tr>
                            ))
                          }
                          <tr>
                            <td colSpan={5} className="text-right">
                              <button
                                type="button"
                                disabled={item?.is_billing_saved}
                                className="add_bill_type_btn"
                                onClick={() => addBillRecords(index)}
                              ><img src="/images/Frame.svg" />&nbsp;ADD BILL TYPE</button>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>

                    {!item?.is_billing_saved &&
                      <button
                        type="button"
                        onClick={() => handleSaveBillingDetails(index)}
                        className="basic-button mt-2 float-right"
                      >CONFIRM & PROCEED</button>
                    }
                  </div>
                )
              }

              <hr className="hr-line" />
            </>
          ))
        }
      </div>

      {
        showAddMoreButton &&
        <button
          type="button"
          className="orange_brd_button p-12-16-button mr-3 mt-3"
          onClick={addBookingPlans}
        >
          <img src="/images/plus-circle.png" />
          ADD ROOM PLAN HERE
        </button>
      }

      <div className="modal-footer">
        <button type="button" className="orange_brd_button p-12-16-button" onClick={() => handleCloseModal()}>CANCEL</button>
        <button type="button" className="basic-button" onClick={handleSaveAsDraftBookingPlans}>SAVE AS DRAFT</button>
      </div>
    </div>
  );
}
