import React from 'react'
import { Link } from 'react-router-dom'
import styles from './index.module.scss';

export default function BreadCrumb({ list = [], seperator = "/" }) {
    return (
        <div className={styles['breadcrumb']}>
            {
                list.map((item, index) => {
                    return (<React.Fragment key={"breadcrumb_" + index}>
                        <Link to={item.link || "#"} className={styles['item'] + ' ' + (item.active ? styles['active'] : '')}>
                            {item.label}
                        </Link>
                        {(seperator && list.length-1 !== index) ? <span>{seperator}</span> : ""}
                    </React.Fragment>)
                })
            }
            <div className=''></div>
            <div></div>
        </div>
    )
}
