import React, { useCallback, useEffect, useMemo, useState } from "react";
import styles from "./index.module.scss";
// interface ITabsViewComponentProps {
//   tabs: {
//     label: string,
//     component: React.ReactNode,
//   }[];
//   defaultTabLabel: string;
// }

export default function TabsViewComponent({ tabs, defaultTabLabel, labelPropCallBack }) {
  const [tabName, setTabName] = useState("");

  const onSetTabName = useCallback(
    (label) => {
      if (tabName !== label) setTabName(label);
      if (labelPropCallBack && label) labelPropCallBack(label)
    },
    [tabName, setTabName]
  );

  const tabList = useMemo(() => {
    return tabs.filter(t => t.show);
  }, [tabs])

  useEffect(() => {
    if (defaultTabLabel) {
      onSetTabName(defaultTabLabel);
    } else {
      if (tabList.length) {
        onSetTabName(tabList[0].label);
      }
    }
    // eslint-disable-next-line
  }, [defaultTabLabel, tabList]);

  const Component = useMemo(() => {
    const selectedTab = tabList.find((tab) => {
      return tab.label === tabName;
    });
    if (selectedTab) {
      return selectedTab.component;
    }
    return null;
  }, [tabName, tabList]);


  return (
    <div className={styles["tabs-container"]}>
      <div className={"d-flex align-items-center " + styles["tabs-header"]}>
        {tabList.map((tab) => {
          return (
            <div
              onClick={() => onSetTabName(tab.label)}
              className={
                styles["tabs-header-item"] +
                " font-manrope " +
                (tab.label === tabName ? styles["active"] : "")
              }
              key={"TabLabel_" + tab.label.replace(/ /g, "")}
            >
              <p className="mb-0">{tab.label}</p>
            </div>
          );
        })}
      </div>

      <div className={styles["tabs-content"]}>
        <div>{Component ? Component : <></>}</div>
      </div>
    </div>
  );
}
