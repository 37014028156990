import { useRef, useState } from 'react';
import DatePicker from 'react-datepicker';
import { getYear, getMonth } from 'date-fns';
import moment from 'moment';

function CustomSingleDatepicker({ props }) {
    const [startDate, setStartDate] = useState(new Date());
    // const [isDatepickerOpen, setDatepickerOpen] = useState(true);
    const datePickerRef = useRef(null);

    const handleOnClose = (e) => {
        e.preventDefault();
        if (datePickerRef.current) {
            datePickerRef.current.setOpen(false);
        }
    }

    return (
        <DatePicker
            selected={startDate}
            onChange={(date) => setStartDate(date)}
            withPortal
            isClearable={true}
            shouldCloseOnSelect={false}
            ref={datePickerRef}
            renderCustomHeader={({
                date,
                decreaseMonth,
                increaseMonth,
                prevMonthButtonDisabled,
                nextMonthButtonDisabled,
            }) => (
                <>
                    <div className='row'>
                        <div className='col-12 date_first'>
                            Select Date
                        </div>
                        <div className='col-12 date_second'>
                            {/* <h3>{moment(startDate).format('ddd, MMM DD')}</h3> */}
                            <h3>{moment(startDate).format('DD-MM-YYYY')}</h3>
                        </div>
                    </div>
                    <div className='d-flex justify-content-between align-items-center left_right_box'>
                        <div className='date_first m-0'>
                            {moment(date).format('MMMM YYYY')}
                        </div>

                        <div className='date_icon_prev_next'>
                            {/* Previous Button */}
                            <button onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>
                                <img src='/images/date_prev.svg' />
                            </button>

                            {/* Next Button */}
                            <button onClick={increaseMonth} disabled={nextMonthButtonDisabled}>
                                <img src='/images/date_next.svg' />
                            </button>
                        </div>
                    </div>
                </>
            )}
            maxDate={new Date()}
        >
            <div className='row'>
                <div className='col-12 d-flex specific_button'>
                    <button type='button' className='orange_brd_button p-12-16-button mr-3 bg_color' onClick={handleOnClose}> <img src='/images/arrow-left.svg' /></button>
                    <button type='button' className="basic-button p-16-32-button " onClick={handleOnClose}>CONFIRM</button>
                </div>
            </div>
        </DatePicker>
    );
}

export default CustomSingleDatepicker;