export const revenueDirectPayFilters = [
  {
    label: "All entries",
    value: "",
  },
  {
    label: "Collected",
    value: "collected",
  },
  {
    label: "Deposited",
    value: "deposited",
  },
  {
    label: "Approved",
    value: "approved",
  },
  {
    label: "Rejected",
    value: "rejected",
  },
];
