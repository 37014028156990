import React, { useMemo, useState } from 'react'
import styles from './index.module.scss'
import InputBox from '../../../../../components/formInput/InputBox'
import InputSelect from '../../../../../components/formInput/InputSelect'
import { getApiCourseItems, getApiCourses, getPropertiesByName } from '../../../../../helpers/services/foodservices'
import moment from 'moment'
import { replaceAllSpaces } from '../../../../../helpers/constants/func'

function ActionMenu({ info, onChange, menuDates, onActionCourse }) {

    const [sliceDate, setSliceDate] = useState({
        startIndex: 0,
        endIndex: 3
    })

    const onLoadProperties = async (input) => {
        try {
            const { data } = await getPropertiesByName(input);
            return data.map((item) => {
                item.label = item.property_name;
                item.value = item.id;
                return item;
            })
        } catch (e) {
            console.error(e);
        }
        return []
    }

    const tableList = useMemo(() => {
        const activitesInfo = {
            dates: [],
            meals: []
        }
        if (info?.meals?.length && menuDates?.length) {
            const meals = JSON.parse(JSON.stringify(info?.meals || []));
            meals.forEach((meal) => {
                if (activitesInfo.dates.length === 0 && meal.courses.length) {

                    menuDates.slice(sliceDate.startIndex, sliceDate.endIndex + 1).forEach((key) => {
                        activitesInfo.dates.push({
                            label: moment(key).format('DD MMM'),
                            date: key,
                            day: moment(key).format('ddd'),
                        })
                    })
                }
                activitesInfo.meals.push({
                    ...meal
                })
            })
        }
        return activitesInfo
    }, [sliceDate, menuDates, info?.meals]);

    const onActionDate = (type = "") => {
        if (type === 'prev') {
            setSliceDate((prev) => {
                let st = { ...prev };
                if (st.startIndex > 0) {
                    st.startIndex -= 1;
                    st.endIndex -= 1;
                }
                return st;
            })
        } else {
            setSliceDate((prev) => {
                let st = { ...prev };
                if (st.endIndex < 6) {
                    st.startIndex += 1;
                    st.endIndex += 1;
                }
                return st;
            })
        }
    }

    const onLoadCourses = async (input = "") => {
        try {
            const { data } = await getApiCourses(input);
            return data.map((item) => {
                item.label = item.name;
                item.value = item.id;
                return item;
            })
        } catch (e) {
            console.error(e)
        }
        return []
    }

    const onLoadFoodItems = async (courseId, input) => {
        if (courseId) {
            try {
                const { data } = await getApiCourseItems(courseId, input);
                return data.map((item) => {
                    item.label = item.food_name;
                    item.value = item.id;
                    return item;
                })
            } catch (e) {
                console.error(e)
            }
        }
        return []
    }

    return (
        <div className={styles['action-menu-container']}>
            <div className={styles['action-row']}>
                <div className='row'>
                    <div className='col-3'>
                        <InputBox
                            type="text"
                            label={"Menu Name"}
                            placeHolder={"Enter name"}
                            value={info.name}
                            name="name"
                            disabled={info.id > 0}
                            onChange={(...args) => { if (info.id === 0) { onChange(...args) } }}
                        />
                    </div>
                    <div className='col-5'>
                        <InputSelect
                            isAsync
                            loadOptions={onLoadProperties}
                            label={"Property List"}
                            name="properties"
                            value={info.properties.map((item) => ({ ...item, value: item.id, label: item.property_name, id: item.id, property_name: item.property_name }))}
                            isMulti
                            isSearchable
                            placeholder={"Search here"}
                            onChange={onChange}
                            defaultOptions
                        />
                    </div>
                </div>
            </div>

            <div className={styles['menu-content']}>

                {/* Heading Start */}
                <div className={styles['view-container']}>
                    <div className={'d-flex items-center ' + styles['view-cell-wrapper']}>
                        <div className={styles['cell'] + ' ' + styles['heading'] + ' ' + styles['first-cell']}>Date</div>
                        {
                            (tableList.dates.map((dateInfo, index) => {
                                return (<div key={"MenuHeadingCell_" + index} className={styles['cell'] + ' ' + styles['heading'] + ' text-center ' + styles['equal-w-cell']}>
                                    {
                                        (index === 0) ? (<span onClick={() => { onActionDate('prev') }} className={styles['arrow-left']}>
                                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M10 12.0001L6 8.00012L10 4.00012" stroke="#819093" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
                                            </svg>
                                        </span>) : (<></>)
                                    }
                                    {dateInfo.label}

                                </div>)
                            }))
                        }
                        <div className={styles['cell'] + ' ' + styles['heading'] + ' text-center '} style={{width:"41px"}}>
                            <span onClick={() => { onActionDate('next') }} className={styles['arrow-right']}>
                                <svg width="16" className='rotate-180deg' height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M10 12.0001L6 8.00012L10 4.00012" stroke="#819093" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
                                </svg>
                            </span>
                        </div>
                    </div>
                </div>

                <div className={styles['view-container']}>
                    <div className={'d-flex items-center ' + styles['view-cell-wrapper']}>
                        <div className={styles['cell'] + ' ' + styles['heading'] + ' ' + styles['first-cell']}>Day</div>
                        {
                            (tableList.dates.map((dateInfo, index) => {
                                return (<div key={"MenuHeadingCell_" + index} className={styles['cell'] + ' ' + styles['heading'] + ' text-center ' + styles['equal-w-cell']}>
                                    {dateInfo.day}
                                </div>)
                            }))
                        }
                        <div className={styles['cell'] + ' ' + styles['heading'] + ' text-center '} style={{width:"41px"}}>
                            <span style={{ visibility: "hidden" }}>
                                <svg width="16" className='rotate-180deg' height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M10 12.0001L6 8.00012L10 4.00012" stroke="#819093" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
                                </svg>
                            </span>
                        </div>
                    </div>
                </div>
                {/* Heading End */}

                {
                    (info.meals.map((meal, mealIndex) => {

                        return (<React.Fragment key={"ActionMenu_" + mealIndex}>

                            {/* Meal Type Start */}
                            <div className={styles['view-container'] + ' ' + styles['heading-row-full'] + ' ' + styles[replaceAllSpaces(meal?.name?.toLowerCase() || 'breakfast')]}>
                                <div className={'d-flex items-center justify-content-center'}>
                                    <div className='d-flex align-items-center gap-10'>
                                        <p className='mb-0 text-uppercase'>{meal.name}</p>
                                    </div>
                                </div>
                            </div>
                            {
                                meal.courses.map((course, courseIndex) => {
                                    const courseDate = course.dateCourse || {};
                                    return (<div key={"ActionMenu_" + mealIndex + "_" + courseIndex} className={styles['view-container']}>
                                        <div className={'d-flex items-center ' + styles['view-cell-wrapper']}>
                                            <div className={styles['cell'] + ' ' + styles['cell-content'] + ' ' + styles['first-cell']}>

                                                <InputSelect
                                                    isAsync
                                                    label={""}
                                                    name="course"
                                                    value={(course.id) ? { value: course.id, label: course.name, ...course } : null}
                                                    isSearchable
                                                    loadOptions={onLoadCourses}
                                                    placeholder={"Select course"}
                                                    onChange={(e) => onChange(e, mealIndex, courseIndex)}
                                                    defaultOptions
                                                />

                                            </div>
                                            {
                                                (tableList.dates.map((dateInfo, index) => {
                                                    const dishes = courseDate[dateInfo.date] || []
                                                    return (<div key={"MenuHeadingCell_" + index} className={styles['cell'] + ' ' + styles['cell-content'] + ' text-center ' + styles['equal-w-cell']}>
                                                        <InputSelect
                                                            key={"MenuHeadingCell_" + index+"_"+(course.id ?? 0)}
                                                            isAsync
                                                            label={""}
                                                            name="food_items"
                                                            value={dishes.map((dish) => ({ ...dish, value: dish.id, label: dish.food_name }))}
                                                            isSearchable
                                                            loadOptions={(input) => onLoadFoodItems(course.id, input)}
                                                            placeholder={"Search dishes"}
                                                            onChange={(e) => onChange({ ...e, date: dateInfo.date }, mealIndex, courseIndex)}
                                                            isMulti
                                                            defaultOptions
                                                        />
                                                    </div>)
                                                }))
                                            }
                                            <div className={styles['cell'] + ' ' + styles['cell-content'] + ' d-flex text-center '} style={{width:"41px"}}>
                                                <svg className='cursor-pointer m-auto' onClick={() => { onActionCourse(mealIndex, 'delete', courseIndex) }} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M7.5 3H12.5M2.5 5.5H17.5M15.8333 5.5L15.2489 14.2661C15.1612 15.5813 15.1174 16.2389 14.8333 16.7375C14.5833 17.1765 14.206 17.5294 13.7514 17.7497C13.235 18 12.5759 18 11.2578 18H8.74221C7.42409 18 6.76503 18 6.24861 17.7497C5.79396 17.5294 5.41674 17.1765 5.16665 16.7375C4.88259 16.2389 4.83875 15.5813 4.75107 14.2661L4.16667 5.5M8.33333 9.25V13.4167M11.6667 9.25V13.4167" stroke="#DE1135" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                                </svg>

                                            </div>
                                        </div>
                                    </div>)
                                })
                            }

                            {/* Meal Type End */}

                            <div className={styles['view-container']}>
                                <div className={' ' + styles['view-cell-wrapper']}>
                                    <div className={styles['cell'] + ' d-flex justify-content-center ' + styles['cell-content'] + ' ' + styles['equal-w-cell']}>
                                        <div className={'d-flex align-items-center gap-8 ' + styles['add-course']} onClick={() => {
                                            onActionCourse(mealIndex)
                                        }}>
                                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M8.49935 2.6665V13.3332M3.16602 7.99984H13.8327" stroke="#FF5700" strokeWidth="1.5" strokeLinecap="round" />
                                            </svg>
                                            <span>ADD {meal.name?.toUpperCase()} COURSE</span>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </React.Fragment>)

                    }))
                }

            </div>
        </div >
    )
}

export default ActionMenu