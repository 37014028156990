import { useEffect, useState } from "react";
import "../../../css/onboarding-details-tab.css";
import { resentLink } from "../../../helpers/services/api.services";
import toast from "react-hot-toast";
import styles from "../../../pages/revenue/revenueBulkUploadPage/modals/UploadBulkModal/index.module.scss";
import { Modal } from "react-bootstrap";

function AgreementTab({ residentInfo, onboardingInfo }) {
  const [basicDetails, setBasicDetails] = useState(null);
  const [guardianDetails, setGuardianDetails] = useState(null);
  const [agreementStatus, setAgreementStatus] = useState({
    signed_by_guardian: false,
    signed_by_resident: false,
  });
  const [openErrorBox, setOpenErrorBox] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [resendLoader , setResendLoader] = useState(false)
  const [resendLoaderParent , setResendLoaderParent] = useState(false)

  useEffect(() => {
    // if (residentInfo && residentInfo?.basic_details) setBasicDetails(residentInfo?.basic_details);
    if (onboardingInfo) setBasicDetails(onboardingInfo);
    if (residentInfo && residentInfo?.guardian_details)
      setGuardianDetails(residentInfo?.guardian_details);

    if (onboardingInfo && onboardingInfo?.agreement_details) {
      setAgreementStatus(onboardingInfo?.agreement_details);
    }
  }, [residentInfo, onboardingInfo]);

  const handleResendLink = async (is_parent) => {
    if(is_parent){
      setResendLoaderParent(true)
    }else{
      setResendLoader(true)
    }
    try {
      setErrorMessage(null);
      setOpenErrorBox(false);
      let response = await resentLink(basicDetails?.resident_id, is_parent);

      if (response && response?.data) {
        await toast.success(`Link has been sent successfully.`);
      } else {
        setErrorMessage(response?.response?.data?.errors);
        setOpenErrorBox(true);
        // await toast.error(`${response?.response?.data?.errors}`);
      }
      if(is_parent){
        setResendLoaderParent(false)
      }else{
        setResendLoader(false)
      }
    } catch (error) {
      console.log(error);
    }finally{
      if(is_parent){
        setResendLoaderParent(false)
      }else{
        setResendLoader(false)
      }
    }
  };

  return (
    <>
      <div className="table_main_tit pb-20 d-flex justify-content-between">
        <h2>Current Agreements</h2>
        <a
          href={
            residentInfo?.contract_details?.contract_url
              ? residentInfo?.contract_details?.contract_url
              : ""
          }
          className="download_icn_txt"
          style={{
            zIndex: 40,
            pointerEvents: residentInfo?.contract_details?.contract_url
              ? ""
              : "none",
          }}
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src="/images/download_orange.svg" className="pr-1" alt="" />
          DOWNLOAD
        </a>
      </div>
      <div className="agreement_main exit_tab_box">
        <div className="box_contain">
          <div className="sub_exit_box">
            <div className="d-flex align-items-center">
              <div className="img_round_icon_box">
                <img src="/images/Ellips1.png" alt="" />
              </div>
              <div className="sub_exit_data_txt">
                <h3>Resident Mail ID</h3>
                <span>{basicDetails?.email ?? "--"}</span>
              </div>
              <div className="sub_exit_data_txt">
                {agreementStatus?.signed_by_resident ? (
                  <span className="agreement_badge">Signed by resident</span>
                ) : (
                  <button
                    className="custom_button border-0 bg-white"
                    onClick={() => handleResendLink(false)}
                    disabled={resendLoader || resendLoaderParent}
                  >
                    <img src="/images/join_link.svg" className="pr-1" alt="" />{" "}
                    {!resendLoaderParent && !resendLoader  &&  "RESEND LINK TO STUDENT"}
                    {!resendLoaderParent && resendLoader  &&  "Sending..."}
                     {resendLoaderParent && !resendLoader  &&  "Sending Parent agreement please wait"}
                  </button>
                )}
              </div>
            </div>
          </div>
          {residentInfo?.basic_details?.resident_type !==
          "working_professional" ? (
            <div className="sub_exit_box">
              <div className="d-flex align-items-center ">
                <div className="img_round_icon_box">
                  <img src="/images/Ellips2.png" alt="" />
                </div>
                <div className="sub_exit_data_txt">
                  <h3>Parent Mail ID</h3>
                  <span>{guardianDetails?.email ?? "--"}</span>
                </div>
                <div className="sub_exit_data_txt">
                  {agreementStatus?.signed_by_guardian ? (
                    <span className="agreement_badge">Signed by parent</span>
                  ) : (
                    <button
                      className="custom_button border-0 bg-white"
                      onClick={() => handleResendLink(true)}
                      disabled={resendLoader || resendLoaderParent}
                    >
                      <img
                        src="/images/join_link.svg"
                        className="pr-1"
                        alt=""
                      />{" "}
                      {!resendLoaderParent && !resendLoader  &&  "RESEND LINK TO PARENT"}
                      {resendLoaderParent && !resendLoader  &&  "Sending..."}
                      {!resendLoaderParent && resendLoader  &&  "Sending Resident agreement please wait"}
                    </button>
                  )}
                </div>
              </div>
            </div>
          ) : null}
        </div>
      </div>

      <div className="details_bg">
        <img src="/images/bg_tab_img.png" alt="" />
      </div>

      {openErrorBox && (
        <>
          <Modal
            show={openErrorBox}
            onHide={() => setOpenErrorBox(false)}
            backdrop="static"
            dialogClassName={styles["dialog-container"]}
            keyboard={false}
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >
            {/* <Modal.Header closeButton>
              <Modal.Title id={styles["#modal-title"]}>
                {errorMessage}
              </Modal.Title>
            </Modal.Header> */}
            <div
              style={{
                padding: "7px",
                borderRadius: "8px",
                backgroundColor: "white",
                borderTop: "4px solid red",
                position : "relative"
              }}
            >
              <div className=" d-flex justify-content-between">
                <div style={{
                    margin : "20px"
                }}>
                    <h5><b>Error!</b></h5>
                    {errorMessage}</div>
                <div className="" style={{
                    position : "absolute",
                    right : 10,
                    top : 2
                }}>
                  {/* <img  src="/images/cross_modal.svg" /> */}
                  <button
                    className=" btn-close"
                    onClick={() => {
                      setErrorMessage(null);
                      setOpenErrorBox(false);
                    }}
                    style={{
                      height: "5px",
                      width: "5px",
                    }}
                  ></button>
                </div>
              </div>
            </div>
          </Modal>
        </>
      )}
    </>
  );
}

export default AgreementTab;
