import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import DataTable from 'react-data-table-component';
import { fetchCities,fetchStates } from "../../helpers/services/admin.services";
import withDefaultDashBoardLayout from "../../components/layouts/withDefaultDashBoardLayout";
import Select from "react-dropdown-select";
import { addNewCity,updateCity } from '../../helpers/services/api.services'
import { Modal } from "react-bootstrap";
import { Form } from "react-bootstrap";
import { useFormik } from 'formik';
import * as Yup from 'yup';
import toast from "react-hot-toast";
 
const salesMasterFilters = [
    {
        label:"RM Items",
        value:"rm items",
    },
    {
        label:"Dishes",
        value:'dishes',
    },
    {
        label:"Meals",
        value:'meals',
    },
    {
        label:"Kitchen",
        value:'kitchen',
    },
    {
        label:"Services",
        value:'services',
    },
    {
        label:"Courses",
        value:'courses',
    },
    {
        label:"Recipes",
        value:'recipes',
    }
]


function CityList() {
  const navigate = useNavigate();

  const [activeItem, setActiveItem] = useState({
    label:"Dishes",
    value:'dishes'
});

  // const [activePropertyStatus, setActivePropertyStatus] = useState("All");
  // Data-table
  const [data, setData] = useState([]);
  const [stateData,setStateData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(100);
  const [operation,setOperation] = useState("Create");
  // const [page, setPage] = useState(1);
  const [showCreatePropertyModal, setShowCreatePropertyModal] = useState(false);
  const handleCloseConfirmNocModal = () => {
    formik.resetForm();
    setShowCreatePropertyModal(false);
  }
  const handleCreatePropertyModal = () => setShowCreatePropertyModal(true);


  useEffect(() => {
    loadData();
    loadStatesData()
  }, []);

  const loadStatesData = async () => {
    setLoading(true);

    let response = await fetchStates();

    if (response && response.data && response.data.length > 0) {
      let states = response.data.map(element => {
        return { value: element?.id, label: element?.name }
      });
      setStateData(states);
      setLoading(false);
    } else {
      setStateData([]);
      setLoading(false);
    }
  };

  const loadData = async () => {
    setLoading(true);

    let response = await fetchCities();

    if (response && response.data && response.data.length > 0) {
      setData(response.data);
      if (response?.meta && response?.meta?.total_data_count && response?.meta?.total_pages) {
        setTotalRows(response?.meta?.total_data_count);
      }
      setLoading(false);
    } else {
      setData([]);
      setLoading(false);
    }
  };

  // const filteredResidentsData = async (status) => {
  //   setActivePropertyStatus(status);
  // };

  // const handlePageChange = () => {
  //   loadData();
  // };

  const columns = [
    {
      name: 'ID',
      selector: (row) => row.id,
      width: "10%",
      sortable: true,
    },
    {
      name: 'Name',
      width: "20%",
      selector: (row) => row.city_name,
      sortable: true,
    },
    {
      name: 'Code',
      selector: (row) => row.city_code,
      sortable: true,
    },
    {
      name: 'Description',
      selector: (row) => row.description,
      width: "20%",
      sortable: true,
    },
    {
      name: 'Cluster Count',
      selector: (row) => row.cluster_count,
      sortable: true,
    },
    {
      name: 'IS_ACTIVE',
      selector: (row) => row.is_active,
      sortable: true,
      cell: row => row.is_active ? "YES" : "NO",
    },
    {
      name: 'IS_DELETED',
    //   width: "10%",
      selector: (row) => row?.is_deleted,
      sortable: true,
      cell: row => row.is_deleted ? "YES" : "NO",
    },
    {
        name: 'State Id',
      //   width: "10%",
        selector: (row) => row?.state_id,
        sortable: true,
      },
      {
        name: '',
        selector: (row) => row?.id,
        cell: row => (
          <button
            style={{border:"none"}}
            data-bs-toggle="tooltip" data-bs-placement="top" title="Edit"
            onClick={()=>{
              setOperation("Edit")
              const fields = ['id','city_name', 'description', 'state_id', 'city_code', 'is_active','is_deleted'];
              fields.forEach(field =>{
                if(field === "state_id"){
                  const state = stateData.filter(state=>state.value==row[field])
                  // console.log(state)
                  formik.setFieldValue(field, state.length > 0 ? state[0].value : {}, false)
                }else{
                  formik.setFieldValue(field, row[field], false)
                }
              });
              handleCreatePropertyModal()
            }}
          >
            <img src="/images/edit.svg" alt="Edit Icon"/>
          </button>
        )
      },
  ];

  const initialValues ={
    city_name: "",
    description: "",
    is_active: true,
    meta: {},
    is_deleted: false,
    state_id: "",
    city_code: ""
  }

  const validationSchema = Yup.object().shape({
    city_name: Yup.string().matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed"),
    description: Yup.string(),
    state_id:  Yup.string(),
    city_code: Yup.string(),
  });

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values) => {
      try {
        // console.log("Submit values -- ",values)
        if(operation==="Create"){
          let response = await addNewCity(values);
  
          if (response && response?.status === 'success') {
            await toast.success('City has been added successfully.');
            handleCloseConfirmNocModal();
            loadData();
          } else {
            await toast.error('Something went wrong while add a city details.');
          }
        }else{
          const {id,...rest}= values
          let response = await updateCity(id, rest);

          if (response && response?.status === 'success') {
            await toast.success('City has been updated successfully.');
            handleCloseConfirmNocModal();
            loadData();
          } else {
            await toast.error('Something went wrong while updating a city details.');
          }
        }
      } catch (error) {
        console.log(error);
      }
    },
  });


//   const handleDetailsPageRedirection = (data) => {
//     navigate(`/property-details/${data?.id}`);
//   }

  return (
    <>
      <div className="property-header">
        <div className="right-side-header">
          <div className="d-flex justify-content-between align-items-center pb-2">
            <div className="d-flex align-items-center">
              <Link to={"/"}>
                <img src="/images/Back.svg" alt="Back" />
              </Link>
              <h2>City</h2>
            </div>
          </div>
          <div className="breadcum">
            <ul>
              <li>
                <a href="#">Masters</a> <span>/</span>
              </li>
              <li>City</li>
            </ul>
          </div>
        </div>
        <div className="create-prop">
          <button className="basic-button" onClick={()=>{
            setOperation('Create');
            handleCreatePropertyModal();
          }}>
            <img
                src="/images/plus-circle.svg"
                className="pr-2"
                alt=""
            />
            Add City
          </button>
        </div>
      </div>

        {/* <div className={"content-container"}>
            <div className={'d-flex align-items-center gap-12'}>
                {
                    salesMasterFilters.map((bulkFilter) => {
                        return (<div onClick={() => { setActiveItem(bulkFilter) }} className={"tab-pane" + ' ' + ((activeItem?.value === bulkFilter.value) ? "active" : '')} key={"bulkFilter_" + bulkFilter.value}>
                            {bulkFilter.label}
                        </div>)
                    })
                }
            </div>
        </div> */}

      {/* <div className="d-flex justify-content-between align-items-center pb-32">
        <div className="status-box">
          {propertyStatus.map((status, index) => (
            <a
              key={index}
              href="#"
              className={status?.label === activePropertyStatus ? "active" : ""}
              onClick={() => filteredResidentsData(status?.label)}
            >
              {status?.label}
                </a>
            ))}
            </div>

            <div className="status-box">
            <a href="#">
                <img src="/images/filter.svg" className="pr-1" alt="" /> Filter
            </a>
            </div>
        </div> */}

      <div className="sr_data_table mt-4">
        <DataTable
          columns={columns}
          data={data}
          progressPending={loading}
        //   pagination
        //   paginationServer
        //   paginationTotalRows={totalRows}
        //   onChangePage={handlePageChange}
          fixedHeader={true}
          fixedHeaderScrollHeight={'430px'}
        //   onRowClicked={handleDetailsPageRedirection}
        ></DataTable>
      </div> 

      <Modal
        show={showCreatePropertyModal}
        onHide={handleCloseConfirmNocModal}
        centered
        size="lg"
        className="confirm_noc_modal"
      >
        <Modal.Body>
        <div className="head_part_date_modal create-prop_modal">
            <div className="d-flex justify-content-between align-items-center mb-4">
              <div>
                <h2 className="title">{operation} City</h2>
              </div>
              <div style={{cursor:"pointer"}}>
                <span onClick={handleCloseConfirmNocModal} className="">
                  <img src="/images/cross_modal.svg" alt="Close Icon" />
                </span>
              </div>
            </div>
          </div>
          <div className="modal_data_part">
        <div className="create-prop_detail">
            <form onSubmit={formik.handleSubmit}>
              <div className="TrailWrap mb-4">
                <div>
                  <Form.Label>Name</Form.Label>
                  <Form.Control
                    placeholder="Enter Name"
                    id="city_name"
                    name="city_name"
                    value={formik.values.city_name}
                    onChange={(e) => formik.setFieldValue('city_name', e.target.value)}
                    style={formik.touched.city_name && formik.errors.city_name ? {
                      borderColor:'red'
                    }: null}
                  />
                  {
                    formik.touched.city_name && formik.errors.city_name ? (
                      <div className="error-label">{formik.errors.city_name}</div>
                    ) : null
                  }
                </div>
                <div>
                  <Form.Label>Description</Form.Label>
                  <Form.Control
                    placeholder="Enter Description"
                    id="description"
                    name="description"
                    value={formik.values.description}
                    onChange={(e) => formik.setFieldValue('description', e.target.value)}
                    style={formik.touched.description && formik.errors.description ? {
                      borderColor:'red'
                    }: null}
                  />
                  {
                    formik.touched.description && formik.errors.description ? (
                      <div className="error-label">{formik.errors.description}</div>
                    ) : null
                  }
                </div>
                <div>
                  <Form.Label>Code</Form.Label>
                  <Form.Control
                    placeholder="Enter City Code"
                    id="city_code"
                    name="city_code"
                    value={formik.values.city_code}
                    onChange={(e) => formik.setFieldValue('city_code', e.target.value)}
                    style={formik.touched.city_code && formik.errors.city_code ? {
                      borderColor:'red'
                    }: null}
                    disabled={operation==="Edit"}
                  />
                  {
                    formik.touched.city_code && formik.errors.city_code ? (
                      <div className="error-label">{formik.errors.city_code}</div>
                    ) : null
                  }
                </div>
                {/* <div className="custom-single-select">
                  <label className="form-label" htmlFor="state">State</label>
                  <Select
                    id="state_id"
                    name="state_id"
                    placeholder="Select State"
                    className="mt-1"
                    options={stateData}
                    value={formik.values.state_id}
                    onChange={(value) => formik.setFieldValue('state_id', value[0].value)}
                  />
                  {
                    formik.touched.state_id && formik.errors.state_id ? (
                      <div className="error-label">{formik.errors.state_id}</div>
                    ) : null
                  }
                </div> */}

                <div className="custom-single-select">
                  <Form.Label>State</Form.Label>
                  <Form.Select 
                    placeholder="Select State"
                    id="state_id"
                    isInvalid={formik.touched.state_id && formik.errors.state_id}
                    // onChange={formik.handleChange}
                    onChange={(e) => formik.setFieldValue('state_id', e.target.value ? Number(e.target.value) : '')}
                    onBlur={formik.handleBlur}
                    value={formik.values.state_id}
                    aria-label="Default select example">
                        <option value="">Select State</option>
                        {
                            stateData.map(state=><>
                            <option value={state.value}>{state.label}</option>
                            </>)
                        }
                  </Form.Select>
                  {
                    formik.touched.state_id && formik.errors.state_id ? (
                      <Form.Control.Feedback type="invalid">
                            {formik.errors.state_id}
                    </Form.Control.Feedback>
                    ) : null
                  }
                </div>

              </div>

              <div className="modal-footer">
                <button 
                  type="button" 
                  className="orange_brd_button p-12-16-button mr-3"
                  onClick={()=>handleCloseConfirmNocModal()}
                >CANCEL</button>
                <button type="submit" className="basic-button">{operation === "Create" ? 'SAVE' : 'UPDATE' }</button>
              </div>
            </form>
          </div>
        </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default withDefaultDashBoardLayout(CityList);
