import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
// import { Modal, Tab, Tabs } from "react-bootstrap";
import DataTable from 'react-data-table-component';
import { fetchFoodData,fetchFoodCategoryData } from "../../helpers/services/api.services";
import withDefaultDashBoardLayout from "../../components/layouts/withDefaultDashBoardLayout";

import { addNewFood,updateFood } from '../../helpers/services/api.services'
import { Modal } from "react-bootstrap";
import { Form } from "react-bootstrap";
import { useFormik } from 'formik';
import * as Yup from 'yup';
import toast from "react-hot-toast";
 
const salesMasterFilters = [
    {
        label:"RM Items",
        value:"rm items",
    },
    {
        label:"Dishes",
        value:'dishes',
    },
    {
        label:"Meals",
        value:'meals',
    },
    {
        label:"Kitchen",
        value:'kitchen',
    },
    {
        label:"Services",
        value:'services',
    },
    {
        label:"Courses",
        value:'courses',
    },
    {
        label:"Recipes",
        value:'recipes',
    }
]


function FoodList() {
  const navigate = useNavigate();

  const [activeItem, setActiveItem] = useState({
    label:"Dishes",
    value:'dishes'
});

  // Data-table
  const [properties, setProperties] = useState([]);
  const [foodCategory,setFoodCategory] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(100);
  const [page, setPage] = useState(1);

  const [operation,setOperation] = useState("Create");

  const [showCreatePropertyModal, setShowCreatePropertyModal] = useState(false);
  const handleCloseConfirmNocModal = () => {
    formik.resetForm();
    setShowCreatePropertyModal(false);
  }
  const handleCreatePropertyModal = () => setShowCreatePropertyModal(true);


  useEffect(() => {
    loadData(page);
    loadFoodCategory();
  }, []);

  const loadData = async (currentPage) => {
    setLoading(true);

    let response = await fetchFoodData(currentPage);

    if (response && response.data && response.data.length > 0) {
      setProperties(response.data);
      if (response?.meta && response?.meta?.total_data_count && response?.meta?.total_pages) {
        setTotalRows(response?.meta?.total_data_count);
      }
      setPage(currentPage);
      setLoading(false);
    } else {
      setProperties([]);
      setLoading(false);
    }
  };

  const loadFoodCategory = async () => {
    try {
        let response = await fetchFoodCategoryData();
        if(response.status === "success"){
          let formated_data = response.data.map(category=>({value:category.id,label:category.name}))
          if(formated_data.length>0){
            setFoodCategory(formated_data)
          }
        } 
    } catch (error) {
      setFoodCategory([])
      console.log("Error fetching food category -- ",error)
    }
  };

  const columns = [
    {
      name: 'ID',
      selector: (row) => row.id,
      width: "10%",
      sortable: true,
    },
    {
      name: 'Name',
      width: "30%",
      selector: (row) => row.food_name,
      sortable: true,
    },
    {
      name: 'Food Category',
      width: "25%",
      selector: (row) => row.food_category.name,
      sortable: true,
    },
    {
      name: 'Is Veg',
      selector: (row) => row.is_veg?.toString(),
      sortable: true,
    },
    {
      name: 'IS_ACTIVE',
      selector: (row) => row.is_active,
      sortable: true,
      cell: row => row.is_active ? "YES" : "NO",
    },
    {
      name: 'IS_DELETED',
    //   width: "10%",
      selector: (row) => row?.is_deleted,
      sortable: true,
      cell: row => row.is_deleted ? "YES" : "NO",
    },
    // {
    //   name: 'Created at',
    //   selector: (row) => row?.created_at,
    //   sortable: true,
    // },
    {
      name: '',
      selector: (row) => row?.id,
      // width: "3%",
      cell: row => (
        <button
          style={{border:"none"}}
          data-bs-toggle="tooltip" data-bs-placement="top" title="Edit"
          onClick={()=>{
            setOperation("Edit")
            const fields = ['id','food_name','food_description','minimum_order_qty','maximum_order_qty','base_amount','service_charges','tax_value','is_veg','is_active','is_deleted'];
            fields.forEach(field => formik.setFieldValue(field, row[field], false));
            handleCreatePropertyModal()
            if(row?.food_category?.id){
              formik.setFieldValue("food_category_id", row.food_category.id, false)
            }
          }}
        >
          <img src="/images/edit.svg" alt="Edit Icon"/>
        </button>
      )
    },
  ];

//   const handleDetailsPageRedirection = (data) => {
//     navigate(`/property-details/${data?.id}`);
//   }

const initialValues ={
  food_name: "",
  food_description: "",
  food_category_id: "",
  is_veg: "",
  minimum_order_qty: "",
  maximum_order_qty: "",
  base_amount: "",
  service_charges: "",
  tax_value_type: "percentage",
  tax_value: "",
  is_active: true,
  is_deleted: false,
}

const validationSchema = Yup.object().shape({
  food_name: Yup.string().matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed").required(),
  food_description: Yup.string(),
  minimum_order_qty: Yup.number(),
  maximum_order_qty:Yup.number(),
  base_amount:Yup.number(),
  service_charges:Yup.number(),
  tax_value:Yup.number(),
});

const formik = useFormik({
  initialValues,
  validationSchema,
  onSubmit: async (values) => {
    try {
      // console.log("Submit values -- ",values)
      if(operation==="Create"){
        let response = await addNewFood(values);

        if (response && response?.status === 'success') {
          await toast.success('Food has been added successfully.');
          handleCloseConfirmNocModal();
          loadData();
        } else {
          await toast.error('Something went wrong while add a food details.');
        }
      }else{
        const {id,...rest}= values
        let response = await updateFood(id, rest);

        if (response && response?.status === 'success') {
          await toast.success('Food has been updated successfully.');
          handleCloseConfirmNocModal();
          loadData();
        } else {
          await toast.error('Something went wrong while updating a food details.');
        }
      }
    } catch (error) {
      console.log(error);
    }
  },
});

  return (
    <>
      <div className="property-header">
        <div className="right-side-header">
          <div className="d-flex justify-content-between align-items-center pb-2">
            <div className="d-flex align-items-center">
              <Link to={"/"}>
                <img src="/images/Back.svg" alt="Back" />
              </Link>
              <h2>Food Items</h2>
            </div>
          </div>
          <div className="breadcum">
            <ul>
              <li>
                <a href="#">Masters</a> <span>/</span>
              </li>
              <li>Food Items</li>
            </ul>
          </div>
        </div>
        <div className="create-prop">
          <button className="basic-button" onClick={()=>{
            setOperation('Create');
            handleCreatePropertyModal();
          }}>
            <img
                src="/images/plus-circle.svg"
                className="pr-2"
                alt=""
            />
            Add Food Item
          </button>
        </div>
      </div>

        {/* <div className={"content-container"}>
            <div className={'d-flex align-items-center gap-12'}>
                {
                    salesMasterFilters.map((bulkFilter) => {
                        return (<div onClick={() => { setActiveItem(bulkFilter) }} className={"tab-pane" + ' ' + ((activeItem?.value === bulkFilter.value) ? "active" : '')} key={"bulkFilter_" + bulkFilter.value}>
                            {bulkFilter.label}
                        </div>)
                    })
                }
            </div>
        </div> */}

      {/* <div className="d-flex justify-content-between align-items-center pb-32">
        <div className="status-box">
          {propertyStatus.map((status, index) => (
            <a
              key={index}
              href="#"
              className={status?.label === activePropertyStatus ? "active" : ""}
              onClick={() => filteredResidentsData(status?.label)}
            >
              {status?.label}
                </a>
            ))}
            </div>

            <div className="status-box">
            <a href="#">
                <img src="/images/filter.svg" className="pr-1" alt="" /> Filter
            </a>
            </div>
        </div> */}

      <div className="sr_data_table mt-4">
        <DataTable
          columns={columns}
          data={properties}
          progressPending={loading}
        //   pagination
        //   paginationServer
        //   paginationTotalRows={totalRows}
        //   onChangePage={handlePageChange}
          fixedHeader={true}
          fixedHeaderScrollHeight={'430px'}
        //   onRowClicked={handleDetailsPageRedirection}
        ></DataTable>
      </div> 

      <Modal
        show={showCreatePropertyModal}
        onHide={handleCloseConfirmNocModal}
        centered
        size="lg"
        className="confirm_noc_modal"
      >
        <Modal.Body>
        <div className="head_part_date_modal create-prop_modal">
            <div className="d-flex justify-content-between align-items-center mb-4">
              <div>
                <h2 className="title">{operation} Food</h2>
              </div>
              <div style={{cursor:"pointer"}}>
                <span onClick={handleCloseConfirmNocModal} className="">
                  <img src="/images/cross_modal.svg" alt="Close Icon" />
                </span>
              </div>
            </div>
          </div>
          <div className="modal_data_part">
        <div className="create-prop_detail">
            <form onSubmit={formik.handleSubmit}>
              <div className="TrailWrap mb-4">
                <div>
                  <Form.Label>Name</Form.Label>
                  <Form.Control
                    placeholder="Enter Name"
                    id="food_name"
                    name="food_name"
                    value={formik.values.food_name}
                    onChange={(e) => formik.setFieldValue('food_name', e.target.value)}
                    style={formik.touched.food_name && formik.errors.food_name ? {
                      borderColor:'red'
                    }: null}
                  />
                  {
                    formik.touched.food_name && formik.errors.food_name ? (
                      <div className="error-label">{formik.errors.food_name}</div>
                    ) : null
                  }
                </div>
                {/* <div>
                  <Form.Label>Description</Form.Label>
                  <Form.Control
                    placeholder="Enter State Code"
                    id="food_description"
                    name="food_description"
                    value={formik.values.food_description}
                    onChange={(e) => formik.setFieldValue('food_description', e.target.value)}
                    style={formik.touched.food_description && formik.errors.food_description ? {
                      borderColor:'red'
                    }: null}
                  />
                  {
                    formik.touched.food_description && formik.errors.food_description ? (
                      <div className="error-label">{formik.errors.food_description}</div>
                    ) : null
                  }
                </div> */}
                <div className="custom-single-select">
                    <Form.Label>Food Category</Form.Label>
                    <Form.Select 
                    placeholder="Select Food Category"
                    id="food_category_id"
                    isInvalid={formik.touched.food_category_id && formik.errors.food_category_id}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.food_category_id}
                    aria-label="Default select example">
                        <option value="">Select Food Category</option>
                        {
                            foodCategory.map(category=><>
                            <option value={category.value}>{category.label}</option>
                            </>)
                        }
                    </Form.Select>
                    <Form.Control.Feedback type="invalid">
                            {formik.errors.food_category_id}
                    </Form.Control.Feedback>
                </div>
                <div className="custom-single-select">
                    <Form.Label>Is Veg</Form.Label>
                    <Form.Select 
                    placeholder="Select option"
                    id="is_veg"
                    isInvalid={formik.touched.is_veg && formik.errors.is_veg}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.is_veg}
                    aria-label="Default select example">
                        <option value="">Select option</option>
                        <option value={true}>true</option>
                        <option value={false}>false</option>
                    </Form.Select>
                    <Form.Control.Feedback type="invalid">
                            {formik.errors.is_veg}
                    </Form.Control.Feedback>
                </div>
                <div className="custom-single-select">
                    <Form.Label>Is Active</Form.Label>
                    <Form.Select 
                    placeholder="Select option"
                    id="is_active"
                    isInvalid={formik.touched.is_active && formik.errors.is_active}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.is_active}
                    aria-label="Default select example">
                        <option value="">Select option</option>
                        <option value={true}>true</option>
                        <option value={false}>false</option>
                    </Form.Select>
                    <Form.Control.Feedback type="invalid">
                            {formik.errors.is_active}
                    </Form.Control.Feedback>
                </div>
                {/* <div>
                  <Form.Label>Minimum Order Qty</Form.Label>
                  <Form.Control
                    type="number"
                    id="minimum_order_qty"
                    name="minimum_order_qty"
                    placeholder="Minimum Order Qty"
                    value={formik.values.minimum_order_qty}
                    onChange={(e) => formik.setFieldValue('minimum_order_qty', e.target.value)}
                    style={formik.touched.minimum_order_qty && formik.errors.minimum_order_qty ? {
                      borderColor:'red'
                    }: null}
                  />
                  {
                    formik.touched.minimum_order_qty && formik.errors.minimum_order_qty ? (
                      <div className="error-label">{formik.errors.minimum_order_qty}</div>
                    ) : null
                  }
                </div>
                <div>
                  <Form.Label>Maximum Order Qty</Form.Label>
                  <Form.Control
                    type="number"
                    id="maximum_order_qty"
                    name="maximum_order_qty"
                    placeholder="Maximum Order Qty"
                    value={formik.values.maximum_order_qty}
                    onChange={(e) => formik.setFieldValue('maximum_order_qty', e.target.value)}
                    style={formik.touched.maximum_order_qty && formik.errors.maximum_order_qty ? {
                      borderColor:'red'
                    }: null}
                  />
                  {
                    formik.touched.maximum_order_qty && formik.errors.maximum_order_qty ? (
                      <div className="error-label">{formik.errors.maximum_order_qty}</div>
                    ) : null
                  }
                </div>
                <div>
                  <Form.Label>Base Amount</Form.Label>
                  <Form.Control
                    type="number"
                    id="base_amount"
                    name="base_amount"
                    placeholder="Base Amount"
                    value={formik.values.base_amount}
                    onChange={(e) => formik.setFieldValue('base_amount', e.target.value)}
                    style={formik.touched.base_amount && formik.errors.base_amount ? {
                      borderColor:'red'
                    }: null}
                  />
                  {
                    formik.touched.base_amount && formik.errors.base_amount ? (
                      <div className="error-label">{formik.errors.base_amount}</div>
                    ) : null
                  }
                </div>
                <div>
                  <Form.Label>Service Charges</Form.Label>
                  <Form.Control
                    type="number"
                    id="service_charges"
                    name="service_charges"
                    placeholder="Service Charges"
                    value={formik.values.service_charges}
                    onChange={(e) => formik.setFieldValue('service_charges', e.target.value)}
                    style={formik.touched.service_charges && formik.errors.service_charges ? {
                      borderColor:'red'
                    }: null}
                  />
                  {
                    formik.touched.service_charges && formik.errors.service_charges ? (
                      <div className="error-label">{formik.errors.service_charges}</div>
                    ) : null
                  }
                </div>
                <div>
                  <Form.Label>Tax Value</Form.Label>
                  <Form.Control
                    type="number"
                    id="tax_value"
                    name="tax_value"
                    placeholder="Tax Value"
                    value={formik.values.tax_value}
                    onChange={(e) => formik.setFieldValue('tax_value', e.target.value)}
                    style={formik.touched.tax_value && formik.errors.tax_value ? {
                      borderColor:'red'
                    }: null}
                  />
                  {
                    formik.touched.tax_value && formik.errors.tax_value ? (
                      <div className="error-label">{formik.errors.tax_value}</div>
                    ) : null
                  }
                </div> */}
              </div>

              <div className="modal-footer">
                <button 
                  type="button" 
                  className="orange_brd_button p-12-16-button mr-3"
                  onClick={()=>handleCloseConfirmNocModal()}
                >CANCEL</button>
                <button type="submit" className="basic-button">{operation === "Create" ? 'SAVE' : 'UPDATE' }</button>
              </div>
            </form>
          </div>
        </div>
        </Modal.Body>
      </Modal>

    </>
  );
}

export default withDefaultDashBoardLayout(FoodList);
