import { useState } from "react";
import { Link } from "react-router-dom";
import { Tab, Tabs } from 'react-bootstrap';

import withDefaultDashBoardLayout from "../../components/layouts/withDefaultDashBoardLayout";
import EngagementConstant from "../../helpers/constants/engagement";

// TABS
import NoticeTab from "../../components/tabs/engagement/notice.tab";
import SosTab from "../../components/tabs/engagement/sos.tab";
import GatePassTab from "../../components/tabs/engagement/gate-pass.tab";

function EngagementList() {

    return (
        <>
            {/* Breadcrum */}
            <div className="right-side-header">
                <div className="d-flex justify-content-between align-items-center pb-2">
                    <div className="d-flex align-items-center">
                        <Link to={"/"}>
                            <img src="/images/Back.svg" alt="Back" />
                        </Link>
                        <h2>Engagement</h2>
                    </div>
                </div>
                <div className="breadcum">
                    <ul>
                        <li>
                            <Link to={'/'}>Manage</Link> <span>/</span>
                        </li>
                        <li>Engagement</li>
                    </ul>
                </div>
            </div>

            {/* Engagement Tab View */}
            <div className="main_tab">
                <Tabs
                    defaultActiveKey="notice"
                    id="resident-details-view"
                    className="mb-4"
                >
                    <Tab eventKey="notice" title="Notice">
                        <NoticeTab engagementStatus={EngagementConstant?.engagementStatus} />
                    </Tab>
                    <Tab eventKey="sos" title="SOS">
                        <SosTab engagementStatus={EngagementConstant?.engagementStatus} />
                    </Tab>
                    <Tab eventKey="gate-pass" title="Gate-pass">
                        <GatePassTab engagementStatus={EngagementConstant?.engagementStatus} />
                    </Tab>
                </Tabs>
            </div>
        </>
    );
}

export default withDefaultDashBoardLayout(EngagementList);