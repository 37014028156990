export const sidebarItems = [
    // {
    //     key: 'dashboard',
    //     path: '/dashboard',
    //     label: 'Dashboard',
    //     icon: 'menu1_icn.svg',
    //     subItem: [],
    //     isActive: true,
    //     allowRoutes: []
    // },
    {
        key: 'properties',
        path: '/properties',
        label: 'Properties',
        icon: 'menu2_icn.svg',
        subItem: [],
        isActive: false,
        allowRoutes: ['properties']
    },
    {
        key: 'tenants',
        path: '/',
        label: 'Tenants',
        icon: 'menu2_icn.svg',
        isShowSubMenu: false,
        subItem: [
            {
                key: 'onboarding',
                label: 'Onboarding',
                icon: 'sub_1.svg',
                path: '/onboarding',
                isActive: false,
                allowRoutes: ['/onboarding', '/onboarding-details']
            },
            {
                key: 'residents',
                label: 'Residents',
                icon: 'sub_2.svg',
                path: '/residents',
                isActive: false,
                allowRoutes: ['residents', 'residents-details']
            },
            {
                key: 'tickets',
                path: '/tickets',
                label: 'Tickets',
                icon: 'sub_2.svg',
                subItem: [],
                isActive: false,
                allowRoutes: ['tickets']
            },
        ],
        isActive: false,
        allowRoutes: ['/onboarding', '/residents', '/residents-details', '/onboarding-details' , "/tickets"]
    },
    {
        key: 'revenue',
        path: '/revenue',
        label: 'Revenue',
        icon: 'menu4_icn.svg',
        isShowSubMenu: false,
        subItem: [
            {
                key: 'post-dated-cheque-process',
                label: 'Post-dated cheque process',
                icon: 'empty-wallet-add.svg',
                path: '/revenue/post-dated-cheque-process',
                isActive: false,
                allowRoutes: ['/revenue/post-dated-cheque-process']
            },
            {
                key: 'direct-pay',
                label: 'Direct Pay',
                icon: 'empty-wallet-add.svg',
                path: '/revenue/direct-pay',
                isActive: false,
                allowRoutes: ['/revenue/direct-pay']
            },
            {
                key: 'manual-adjustment-entries',
                label: 'Manual adjustment entries',
                icon: 'task.svg',
                path: '/revenue/manual-adjustment-entries',
                isActive: false,
                allowRoutes: ['/revenue/manual-adjustment-entries']
            },
            {
                key: 'bulk-uploads',
                label: 'Bulk uploads',
                icon: 'document-upload.svg',
                path: '/revenue/bulk-uploads',
                isActive: false,
                allowRoutes: ['/revenue/bulk-uploads']
            },
        ],
        isActive: false,
        allowRoutes: ['/revenue/post-dated-cheque-process', '/revenue/manual-adjustment-entries', '/revenue/bulk-uploads']
    },
    {
        key: 'website',
        path: '/website',
        label: 'Website',
        icon: 'masters.svg',
        isShowSubMenu: false,
        subItem: [
            // {
            //     key: 'home',
            //     label: 'Home Page',
            //     icon: 'sub_1.svg',
            //     path: '/website/home',
            //     isActive: false,
            //     allowRoutes: ['/website/home']
            // },
            {
                key: 'blog',
                label: 'Blog',
                icon: 'sub_1.svg',
                path: '/website/blog',
                isActive: false,
                allowRoutes: ['/website/blog' , '/website/blog/create']
            },
        ],
        isActive: false,
        allowRoutes: ['/website/home',]
    },
     {
        key: 'sales',
        path: '/sales',
        label: 'Sales',
        icon: 'menu6_icn.svg',
        subItem: [],
        isActive: false,
        allowRoutes: ['sales']
    },
    {
        key: 'masters',
        path: '/masters',
        label: 'Masters',
        icon: 'masters.svg',
        isShowSubMenu: false,
        subItem: [
            {
                key: 'state',
                label: 'State',
                icon: 'sub_1.svg',
                path: '/masters/states',
                isActive: false,
                allowRoutes: ['/masters/states']
            },
            {
                key: 'city',
                label: 'City',
                icon: 'sub_2.svg',
                path: '/masters/cities',
                isActive: false,
                allowRoutes: ['/masters/cities']
            },
            {
                key: 'cluster',
                label: 'Cluster',
                icon: 'sub_1.svg',
                path: '/masters/cluster',
                isActive: false,
                allowRoutes: ['/masters/cluster']
            },
            {
                key: 'food',
                label: 'Food Items',
                icon: 'sub_1.svg',
                path: '/masters/food',
                isActive: false,
                allowRoutes: ['/masters/food']
            },
            {
                key: 'employee',
                label: 'Employee',
                icon: 'sub_1.svg',
                path: '/masters/employee',
                isActive: false,
                allowRoutes: ['/masters/employee']
            },
            {
                key: 'room_type',
                label: 'Room Type',
                icon: 'sub_1.svg',
                path: '/masters/room_type',
                isActive: false,
                allowRoutes: ['/masters/room_type']
            },
            // {
            //     key: 'room',
            //     label: 'Room',
            //     icon: 'sub_1.svg',
            //     path: '/masters/room',
            //     isActive: false,
            //     allowRoutes: ['/masters/room']
            // },
            // {
            //     key: 'institution',
            //     label: 'Institution',
            //     icon: 'sub_1.svg',
            //     path: '/masters/institution',
            //     isActive: false,
            //     allowRoutes: ['/masters/institution']
            // },
        ],
        isActive: false,
        allowRoutes: ['/masters/states', '/masters/cities', '/masters/cluster', '/masters/food', '/masters/room_type' , '/masters/employee' , '/masters/room' , '/masters/website']
    },
    {
        key: 'food',
        path: '/food',
        label: 'Food',
        icon: 'menu5_icn.svg',
        subItem: [],
        isActive: false,
        allowRoutes: []
    }
    // {
    //     key: 'manage',
    //     path: '/',
    //     label: 'Manage',
    //     icon: 'menu6_icn.svg',
    //     isShowSubMenu: false,
    //     subItem: [
            // {
            //     key: 'attendance',
            //     label: 'Attendance',
            //     icon: 'sub_1.svg',
            //     path: '/',
            //     isActive: false,
            //     allowRoutes: []
            // },
            // {
            //     key: 'event-deals',
            //     label: 'Event & Deals',
            //     icon: 'sub_1.svg',
            //     path: '/',
            //     isActive: false,
            //     allowRoutes: []
            // },
    //         {
    //             key: 'engagement',
    //             label: 'Engagement',
    //             icon: 'sub_2.svg',
    //             path: '/engagement',
    //             isActive: false,
    //             allowRoutes: ['/engagement']
    //         },
    //     ],
    //     isActive: false,
    //     allowRoutes: ['/engagement']
    // },
    // {
    //     key: 'masters',
    //     path: '/',
    //     label: 'Masters',
    //     icon: 'menu6_icn.svg',
    //     subItem: [],
    //     isActive: false,
    //     allowRoutes: []
    // },
];
