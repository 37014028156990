import { useEffect, useState, useContext } from 'react';
import moment from 'moment';
import { fetchResidentWalletLedger,sendEmailToResident } from '../../../helpers/services/api.services';
import toast from 'react-hot-toast';
import '../../../css/resident-details-tab.css';
import GlobalStateContext from '../../../GlobalStateContext';

function LedgerTab({ residentId }) {

    const {roles} = useContext(GlobalStateContext);
    const [walletLedger, setWalletLedger] = useState([]);
    const [sdLedger, setSdLedger] = useState([]);

    useEffect(() => {
        loadResidentWalletLedger();
    }, [residentId]);

    const loadResidentWalletLedger = async () => {
        let response = await fetchResidentWalletLedger(residentId);

        if (response && response?.status === "success" && (response?.data && response?.data.length > 0)) {
            setWalletLedger(response?.data);
            setSdLedger(response?.sd_data);
        }
    }

    const handleSendToResidentEmail = async (ledger_type) => {
        try{
            if(walletLedger && walletLedger.length > 0){
                let response = await sendEmailToResident(residentId, ledger_type);
                if (response && response.status === "success") {
                    toast.success('Email sent to resident successfully');
                }
            }else{
                toast.error('No ledger to send email to resident');
            }
        }catch(error){
            toast.error('Error while sending email to resident');
        }
    }

    return (
        <>
            <div>
                <div className='table_main_tit d-flex justify-content-between align-items-center pb-20'>
                    <h2> Wallet Ledger</h2>
                    {
                        roles?.super_admin || roles?.admin || roles?.property_manager || roles?.warden || roles?.finance_manager || roles?.finance_head || roles?.cfo ?
                            <button
                                className='orange_brd_button'
                                onClick={() => handleSendToResidentEmail('wallet')}
                            >
                                Send Wallet Ledger to Resident Email
                            </button>
                        : null
                    }
                </div>
                <div className='ledger_table commen_table'>
                    <div className='page-table-box'>
                        <table>
                            <thead>
                                <tr>
                                    <th>Date</th>
                                    <th>Transaction</th>
                                    <th className='text-right'>Withdraws</th>
                                    <th className='text-right'>Deposits</th>
                                    <th className='text-right'>Reference ID</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    (walletLedger && walletLedger.length) ? walletLedger.map((ledger, index) => (
                                        <tr key={index}>
                                            {/* <td>{moment(new Date(ledger?.payment_datetime_string)).format('MMM DD')}</td> */}
                                            <td>{moment(new Date(ledger?.payment_datetime_string)).format('DD-MM-YYYY')}</td>
                                            <td>{ledger?.title}{ledger?.status ? ` - ${ledger.status}` : ''}</td>
                                            <td className='text-right'>{ledger?.wallet_ledger_type === "debit" && ledger?.amount_string}</td>
                                            <td className='text-right'>{ledger?.wallet_ledger_type === "credit" && ledger?.amount_string}</td>
                                            {/* <td className='text-right'>{ledger?.txn_reference_id}</td> */}
                                            <td className='text-right'>{ledger?.payment_slug === "credit_note" ? ledger?.invoice_reference_number : ledger?.txn_reference_id}</td>
                                        </tr>
                                    )) : (
                                        <tr>
                                            <td colSpan={3}>No ledger found</td>
                                        </tr>
                                    )
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <div className='mt-3'>
                <div className='table_main_tit d-flex justify-content-between align-items-center pb-20'>
                    <h2> SD Ledger</h2>
                    {
                        roles?.super_admin || roles?.admin || roles?.property_manager || roles?.warden || roles?.finance_manager || roles?.finance_head || roles?.cfo ?
                            <button
                                className='orange_brd_button'
                                onClick={() => handleSendToResidentEmail('sd')}
                            >
                                Send to SD ledger Resident Email
                            </button>
                        : null
                    }
                </div>
                <div className='ledger_table commen_table'>
                    <div className='page-table-box'>
                        <table>
                            <thead>
                                <tr>
                                    <th>Date</th>
                                    <th>Transaction</th>
                                    <th className='text-right'>Withdraws</th>
                                    <th className='text-right'>Deposits</th>
                                    <th className='text-right'>Reference ID</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    (sdLedger && sdLedger.length) ? sdLedger.map((ledger, index) => (
                                        <tr key={index}>
                                            {/* <td>{moment(new Date(ledger?.payment_datetime_string)).format('MMM DD')}</td> */}
                                            <td>{moment(new Date(ledger?.payment_datetime_string)).format('DD-MM-YYYY')}</td>
                                            <td>{ledger?.title}{ledger?.status ? ` - ${ledger.status}` : ''}</td>
                                            <td className='text-right'>{ledger?.wallet_ledger_type === "debit" && ledger?.amount_string}</td>
                                            <td className='text-right'>{ledger?.wallet_ledger_type === "credit" && ledger?.amount_string}</td>
                                            {/* <td className='text-right'>{ledger?.txn_reference_id}</td> */}
                                            <td className='text-right'>{ledger?.payment_slug === "credit_note" ? ledger?.invoice_reference_number : ledger?.txn_reference_id}</td>
                                        </tr>
                                    )) : (
                                        <tr>
                                            <td colSpan={3}>No ledger found</td>
                                        </tr>
                                    )
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </>
    );
}

export default LedgerTab;
