import React, { useEffect, useState } from 'react'
import styles from './index.module.scss';
import MenuView from '../MenuView';
import moment from 'moment';
import { getMenusByDateRange } from '../../../../helpers/services/foodservices';
import { getConvertedMenu } from '../../utils/conversion';

export default function ManageCurrentMenu({ meals }) {
    const [dateRange, setDateRange] = useState({
        start: "",
        end: ""
    })
    const [menuDates, setMenuDates] = useState([]);
    const [currentMenus, setCurrentMenus] = useState([])

    const getMenus = async (dateRange, mealsList = []) => {
        try {
            const { data } = await getMenusByDateRange(moment(dateRange.start).format('DD-MM-YYYY'), moment(dateRange.end).format('DD-MM-YYYY'));
            if (data && Array.isArray(data) && data.length) {
                const menus = data.filter((d) => (d.status));
                if (menus.length) {
                    setCurrentMenus(menus.map((item) => {
                        return getConvertedMenu(item, mealsList);
                    }));
                    return true;
                }
            }
        } catch (e) {
            console.error(e);
        }
        setCurrentMenus([])
    }

    const init = async () => {
        const startMoment = moment().add(0, 'weeks').startOf('isoWeek');
        const endMoment = moment().add(0, 'weeks').endOf('isoWeek');
        const dates = [];
        const startDate = moment(startMoment).format('YYYY-MM-DD');
        const endDate = moment(endMoment).add(1, 'days').format('YYYY-MM-DD');
        let current = startDate;
        while (current !== endDate) {
            dates.push(current);
            current = moment(current).add(1, 'days').format('YYYY-MM-DD')
        }
        setMenuDates(dates);
        setDateRange({
            start: moment(startMoment).format('DD MMM'),
            end: moment(endMoment).format('DD MMM’YY')
        })
        await getMenus({ start: startMoment, end: endMoment }, meals);
    }

    useEffect(() => {
        init();
        // eslint-disable-next-line
    }, []);

    return (
        <>
            <div className="">
                <h4 className={styles["menu-title"]}>Current Menu ({dateRange.start} - {dateRange.end} )</h4>
            </div>
            {
                currentMenus.map((currentMenuInfo, index) => {
                    return (<MenuView
                        key={"CurrentMenu_" + index}
                        info={currentMenuInfo}
                        menuDates={menuDates}
                    />)
                })
            }

        </>
    )
}
