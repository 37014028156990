import React, { useEffect, useState } from "react";
import { getWebsiteFaqs } from "../../../helpers/services/websiteServices";
import { Accordion } from "react-bootstrap";
import FaQModal from "../../../pages/website/home-page/modals/ModalFaQ";

const Faqs = () => {
  const [faqConfigData, setfaqConfigData] = useState([]);
  const [faqMediaData, setfaqMediaData] = useState([]);
  const [faqIndex, setFaqIndex] = useState();
  const [openModal, setOpenModal] = useState(false);
  const [modalAction, setModalAction] = useState("");

  const handler = () => {
    setOpenModal(!openModal);
  };

  const fetchfaqData = async () => {
    try {
      const data = await getWebsiteFaqs();

      if (data.status == "success") {
        setfaqConfigData(data.data.global_config_data.config_details);
        setfaqMediaData(data.data.media_storages);
      }
    } catch (error) {
      console.log("Error Fetching data ---> ", error);
    }
  };

  useEffect(() => {
    fetchfaqData();
  }, []);

  const deleteFaQs = async (index) => {
    const filterData = faqConfigData.filter((data, ind) => ind !== index);
  };

  return (
    <>
      <div className="d-flex justify-content-end">
        <button
          className="basic-button"
          onClick={() => {
            setModalAction("add");
            handler();
          }}
        >
          Add New Faq
        </button>
      </div>
      {faqConfigData && (
        <>
          {faqConfigData.length > 0 &&
            faqConfigData?.map((data, index) => (
              <Accordion defaultActiveKey="0" key={index} className="mt-3">
                <Accordion.Item eventKey={index}>
                  <Accordion.Header className="row">
                    <div className="col-10">
                      <b>{data.question}</b>
                    </div>
                    <div className="col-1">
                      <img
                        src="/images/edit.svg"
                        alt="Edit Icon"
                        className="bg-white"
                        onClick={() => {
                          setFaqIndex(index);
                          setModalAction("update");
                          handler();
                        }}
                      />
                    </div>
                  </Accordion.Header>

                  <Accordion.Body>{data.answer}</Accordion.Body>
                </Accordion.Item>
              </Accordion>
            ))}
        </>
      )}
      <FaQModal
        openModal={openModal}
        handler={handler}
        faqIndex={faqIndex}
        faqConfigData={faqConfigData}
        actionType={modalAction}
      />
    </>
  );
};

export default Faqs;
