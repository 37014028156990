import React from "react";
import Sidebar from "./sidebar/sidebar";

const Layout = ({ children, ...rest }) => {
  return (
    <div className="wrapper d-flex align-items-stretch">
      <Sidebar />
      <div id="content" className="right-bar">
        {children}
      </div>
    </div>
  );
};

const withDefaultDashBoardLayout = (Component) => {
  return () => (<Layout>
    <Component />
  </Layout>)
}

export default withDefaultDashBoardLayout;