import React from "react";
import styles from "./index.module.scss";
import ReactSelect from "react-select";
import AsyncSelect from 'react-select/async';

export default function InputSelect({
  id,
  name,
  label,
  isSearchable = false,
  options = [],
  value = null,
  onChange,
  isAsync = false,
  ...rest
}) {
  return (
    <div
      className={"d-flex flex-column gap-1 " + styles["input-select-container"]}
    >
      <label className="font-manrope text-gray-600" htmlFor={id || ""}>
        {label}
      </label>
      {
        (isAsync) ? (<AsyncSelect
          {...rest}
          id={id || ""}
          name={name || ""}
          getOptionLabel={(e) => {
            return e && e.label ? e.label : "";
          }}
          getOptionValue={(e) => {
            return e && e.value ? e.value : "";
          }}
          value={value}
          onChange={(changeValue, meta) => {
            onChange({ target: { name, value: changeValue } }, meta)
          }}
          className={
            "text-gray-500 font-manrope " +
            styles["input-select"] +
            " " +
            (rest.className || "")
          }
          classNamePrefix={"inputSelectY_"}
          isSearchable={isSearchable}
        />) : (<ReactSelect
          {...rest}
          id={id || ""}
          name={name || ""}
          getOptionLabel={(e) => {
            return e && e.label ? e.label : "";
          }}
          getOptionValue={(e) => {
            return e && e.value ? e.value : "";
          }}
          options={options}
          value={value}
          onChange={(changeValue, meta) => {
            onChange({ target: { name, value: changeValue } }, meta)
          }}
          className={
            "text-gray-500 font-manrope " +
            styles["input-select"] +
            " " +
            (rest.className || "")
          }
          classNamePrefix={"inputSelectY_"}
          isSearchable={isSearchable}
        />)
      }

    </div>
  );
}
