import React, { useMemo, useState } from 'react'
import styles from './index.module.scss';
import { Collapse } from 'react-bootstrap'
import moment from 'moment';
import { replaceAllSpaces } from '../../../../helpers/constants/func';

export default function MenuView({ info, menuDates }) {
    const [sliceDate, setSliceDate] = useState({
        startIndex: 0,
        endIndex: 3
    })
    const [collapsed, setCollapsed] = useState(false);

    const onActionDate = (type = "") => {
        if (type === 'prev') {
            setSliceDate((prev) => {
                let st = { ...prev };
                if (st.startIndex > 0) {
                    st.startIndex -= 1;
                    st.endIndex -= 1;
                }
                return st;
            })
        } else {
            setSliceDate((prev) => {
                let st = { ...prev };
                if (st.endIndex < 6) {
                    st.startIndex += 1;
                    st.endIndex += 1;
                }
                return st;
            })
        }
    }

    const tableList = useMemo(() => {
        const activitesInfo = {
            dates: [],
            meals: []
        }
        if (info?.meals?.length && menuDates?.length) {
            const meals = JSON.parse(JSON.stringify(info?.meals || []));
            meals.forEach((meal) => {
                if (activitesInfo.dates.length === 0 && meal.courses.length) {

                    menuDates.slice(sliceDate.startIndex, sliceDate.endIndex + 1).forEach((key) => {
                        activitesInfo.dates.push({
                            label: moment(key).format('DD MMM'),
                            date: key,
                            day: moment(key).format('ddd'),
                        })
                    })
                }
                activitesInfo.meals.push({
                    ...meal
                })
            })
        }
        return activitesInfo
    }, [sliceDate, menuDates, info?.meals]);

    return (
        <div className={'mx-1 ' + styles['menu-view']}>
            <div className={styles['menu-header']}>
                <div className='d-flex align-items-center gap-8'>
                    <h3 className={styles['menu-header-title'] + " mb-0"}>{info.name}</h3>
                    <div className={styles['status']}>Live</div>
                </div>
                <div className='d-flex align-items-center gap-10'>
                    {
                        info.properties.map((property, index) => {
                            return (<div key={"CurrentMenu_Property_" + index} className={styles['property-cap']}>
                                <span>{property.property_name}</span>
                            </div>)
                        })
                    }
                    <div>
                        <img width={16} height={16} alt="upward_arrow" src="/images/food-menu/upward arrow.png" className={'img-fluid rounded-circle overflow-hidden cursor-pointer ' + ((!collapsed) ? "rotate-180deg" : "")} onClick={() => setCollapsed(!collapsed)} />
                    </div>
                </div>
            </div>
            <Collapse in={collapsed}>
                <div className={styles['menu-content']}>

                    {/* Heading Start */}
                    <div className={styles['view-container']}>
                        <div className={'d-flex items-center ' + styles['view-cell-wrapper']}>
                            <div className={styles['cell'] + ' ' + styles['heading'] + ' ' + styles['first-cell']}>Date</div>
                            {
                                (tableList.dates.map((dateInfo, index) => {
                                    return (<div key={"MenuHeadingCell_" + index} className={styles['cell'] + ' ' + styles['heading'] + ' text-center ' + styles['equal-w-cell']}>
                                        {
                                            (index === 0) ? (<span onClick={() => { onActionDate('prev') }} className={styles['arrow-left']}>
                                                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M10 12.0001L6 8.00012L10 4.00012" stroke="#819093" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
                                                </svg>
                                            </span>) : (<></>)
                                        }
                                        {dateInfo.label}

                                    </div>)
                                }))
                            }
                            <div className={styles['cell'] + ' ' + styles['heading'] + ' text-center '}>
                                <span onClick={() => { onActionDate('next') }} className={styles['arrow-right']}>
                                    <svg width="16" className='rotate-180deg' height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M10 12.0001L6 8.00012L10 4.00012" stroke="#819093" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
                                    </svg>
                                </span>
                            </div>
                        </div>
                    </div>

                    <div className={styles['view-container']}>
                        <div className={'d-flex items-center ' + styles['view-cell-wrapper']}>
                            <div className={styles['cell'] + ' ' + styles['heading'] + ' ' + styles['first-cell']}>Day</div>
                            {
                                (tableList.dates.map((dateInfo, index) => {
                                    return (<div key={"MenuHeadingCell_" + index} className={styles['cell'] + ' ' + styles['heading'] + ' text-center ' + styles['equal-w-cell']}>
                                        {dateInfo.day}
                                    </div>)
                                }))
                            }
                            <div className={styles['cell'] + ' ' + styles['heading'] + ' text-center '}>
                                <span style={{ visibility: "hidden" }}>
                                    <svg width="16" className='rotate-180deg' height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M10 12.0001L6 8.00012L10 4.00012" stroke="#819093" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
                                    </svg>
                                </span>
                            </div>
                        </div>
                    </div>
                    {/* Heading End */}

                    {
                        (info.meals.map((meal, mealIndex) => {

                            if(meal.courses.length === 0){
                                return (<React.Fragment key={"ActionMenu_" + mealIndex}></React.Fragment>)
                            }

                            return (<React.Fragment key={"ActionMenu_" + mealIndex}>

                                {/* Meal Type Start */}
                                <div className={styles['view-container'] + ' ' + styles['heading-row-full'] + ' ' + styles[replaceAllSpaces(meal?.name?.toLowerCase() || "breakfast")]}>
                                    <div className={'d-flex items-center justify-content-center'}>
                                        <div className='d-flex align-items-center gap-10'>
                                            <p className='mb-0'>{meal.name}</p>
                                            <svg className={'cursor-pointer'} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M9.33958 2.4L3.86624 8.19333C3.65958 8.41333 3.45958 8.84667 3.41958 9.14667L3.17291 11.3067C3.08624 12.0867 3.64624 12.62 4.41958 12.4867L6.56624 12.12C6.86624 12.0667 7.28624 11.8467 7.49291 11.62L12.9662 5.82667C13.9129 4.82667 14.3396 3.68667 12.8662 2.29334C11.3996 0.913336 10.2862 1.4 9.33958 2.4Z" stroke="#292D32" strokeWidth="1.25" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                                <path d="M8.42676 3.36667C8.71342 5.20667 10.2068 6.61333 12.0601 6.8" stroke="#292D32" strokeWidth="1.25" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                                <path d="M2.5 14.6667H14.5" stroke="#292D32" strokeWidth="1.25" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                            </svg>

                                        </div>
                                    </div>
                                </div>
                                {
                                    meal.courses.map((course, courseIndex) => {
                                        const courseDate = course.dateCourse || {};
                                        return (<div key={"ActionMenu_" + mealIndex + "_" + courseIndex} className={styles['view-container']}>
                                            <div className={'d-flex items-center ' + styles['view-cell-wrapper']}>
                                                <div className={styles['cell'] + ' ' + styles['cell-content'] + ' ' + styles['first-cell']}>

                                                    {course.name}

                                                </div>
                                                {
                                                    (tableList.dates.map((dateInfo, index) => {
                                                        const dishes = courseDate[dateInfo.date] || []
                                                        return (<div key={"MenuHeadingCell_" + index} className={styles['cell'] + ' ' + styles['cell-content'] + ' text-center ' + styles['equal-w-cell']}>
                                                            {dishes.map((dish) => dish.food_name)}
                                                        </div>)
                                                    }))
                                                }
                                                <div className={styles['cell'] + ' ' + styles['cell-content'] + ' d-flex text-center '}>
                                                    
                                                </div>
                                            </div>
                                        </div>)
                                    })
                                }

                                {/* Meal Type End */}

                            </React.Fragment>)

                        }))
                    }

                </div>
            </Collapse>
        </div>
    )
}
