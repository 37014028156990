export const getFileName = (url = "") => {
    let fileName = '';
    try {
        fileName = new URL(url || "").pathname.split('/').pop();
    } catch (e) {
        console.error(e);
    }
    return fileName;
}

export const fileToBase64 = file => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = reject;
});

export const replaceAllSpaces = (str = "", to = "") => {
    return str.replace(/ /g, to);
}

export const formatToRupees = (num1) => {
    const num = typeof(num1)=== 'number'?num1:((isNaN(num1)?0.00:parseFloat(num1)))
    return (new Intl.NumberFormat('en-IN', {
        style: 'currency',
        currency: 'INR',
        minimumFractionDigits: 2,
    })).format(num)
}

export const getPaginationList = (currentPage, totalPages, perSlide = 5) => {
    let pageslist = [];
    let i = 0;
    if (totalPages > perSlide) {
        let tmp = 0;
        if (currentPage > parseInt("" + (perSlide / 2)) && !(((currentPage + 1) >= (totalPages - 1)) || ((currentPage + 1) >= (totalPages)))) {
            i = (currentPage - parseInt("" + (perSlide / 2)));
            if (totalPages - i < perSlide) {
                i = totalPages - perSlide;
            }
            for (; i < totalPages; i++) {
                if (tmp === perSlide) {
                    break;
                }
                pageslist.push(i+1);
                tmp++;
            }

        } else {
            if ((currentPage + 1) === (totalPages - 1)) {
                i = (currentPage - (parseInt("" + (perSlide / 2))));
            } else if ((currentPage + 1) > (totalPages - 1)) {
                i = (currentPage - (parseInt("" + (perSlide / 2))) - 1);
            }
            if (totalPages - i < perSlide) {
                i = totalPages - perSlide;
            }
            for (; i < totalPages; i++) {
                if (tmp === perSlide) {
                    break;
                }
                pageslist.push(i+1);
                tmp++;
            }
        }
    } else {
        for (i = 0; i < totalPages; i++) {
            pageslist.push(i+1);
        }
    }
    return pageslist;
}