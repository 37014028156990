import { useEffect, useState, useContext } from 'react';
import moment from 'moment';
import { fetchResidentWalletLedger,sendEmailToResident } from '../../../helpers/services/api.services';
import toast from 'react-hot-toast';
import '../../../css/resident-details-tab.css';
import GlobalStateContext from '../../../GlobalStateContext';

function LedgerTab({ residentId }) {

    const {roles} = useContext(GlobalStateContext);
    const [walletLedger, setWalletLedger] = useState([]);
    const [loading, setLoading] = useState(false);
    const [sendEmailLoader , setSendEmailLoader] = useState(false)

    useEffect(() => {
        setLoading(true);
        loadResidentWalletLedger();
    }, [residentId]);

    const loadResidentWalletLedger = async () => {
        let response = await fetchResidentWalletLedger(residentId);

        if (response && response?.status === "success" && (response?.data && response?.data.length > 0)) {
            setWalletLedger(response?.data);
        }
        setLoading(false);
    }

    const handleSendToResidentEmail = async () => {
        setSendEmailLoader(true)
        try{
            if(walletLedger && walletLedger.length > 0){
                let response = await sendEmailToResident(residentId);
                if (response && response.status === "success") {
                    toast.success('Email sent to resident successfully');
                }
            }else{
                toast.error('No ledger to send email to resident');
            }
            setSendEmailLoader(false)
        }catch(error){
            setSendEmailLoader(false)
            toast.error('Error while sending email to resident');
        }
    }

    return (
        <>
            <div>
                <div className='table_main_tit d-flex justify-content-between align-items-center pb-20'>
                    <h2>Wallet Ledger</h2>
                </div>
                <div className='ledger_table commen_table mob_ledger_table'>
                    <div className='page-table-box'>
                        <table>
                            <thead>
                                <tr>
                                    <th>Date</th>
                                    <th>Transaction</th>
                                    <th>Amount</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    loading ?
                                    <tr>
                                            <td colSpan={3}>Ledger loading...</td>
                                        </tr>:
                                    <>
                                    {
                                    (walletLedger && walletLedger.length) ? walletLedger.map((ledger, index) => (
                                        <tr key={index}>
                                            {/* <td>{moment(new Date(ledger?.payment_datetime_string)).format('MMM DD')}</td> */}
                                            <td>{moment(new Date(ledger?.payment_datetime_string)).format('DD/MMM/YY')}</td>
                                            <td>{ledger?.title}</td>
                                            <td className='text-right'style={{color: ledger?.wallet_ledger_type === 'credit' ? '#57ba35' : '#fc0000'}}>{ledger?.amount_string}</td>
                                        </tr>
                                    )) : (
                                        <tr>
                                            <td colSpan={3}>No ledger found</td>
                                        </tr>
                                    )
                                }
                                    </>
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
                {
                        roles?.super_admin || roles?.admin || roles?.property_manager || roles?.warden || roles?.finance_manager || roles?.finance_head || roles?.cfo ?
                            <div style={{width : "92%" , position : "absolute" , bottom : "120px"}}>
                                <button
                                    className='orange_brd_button'
                                    onClick={handleSendToResidentEmail}
                                    style={{ padding: '5px 10px', fontSize: '14px'}}
                                    disabled={sendEmailLoader}
                                >
                                        {sendEmailLoader ? "Sending..." : "Send to Resident Email"}
                                </button>
                            </div>
                        : null
                }
            </div>
        </>
    );

}

export default LedgerTab;
