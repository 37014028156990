import axios from "axios";
import  secureLocalStorage  from  "react-secure-storage";
import API_ACTION from "../actions/api.action";
import { BASE_API_URL } from "../constants";
import apiClient from "./apiClient";

const BASE_CRM_DASHBOARD_URL = BASE_API_URL + "/crm_dashboard/v1/admin";
const BASE_CRM_DASHBOARD_MASTERS_URL = BASE_API_URL + "/food_erp_dashboard/v1/admin";

export const fetchResidents = async (currentPage,searchObject,sortObject , current_rowPerPage) => {

  let searchString = ''
  let sortString = ''
  if(searchObject){
    Object.keys(searchObject).map((key)=>{
      searchString = searchString + `&${key}=${searchObject[key]}`
    })
  }
  if(sortObject){
    Object.keys(sortObject).map((key)=>{
      sortString = sortString + `&${key}=${sortObject[key]}`
    })
  }

  try {
    return await apiClient
      .get(`${BASE_CRM_DASHBOARD_URL}/${API_ACTION.getResidentsAPI}?page=${currentPage}${searchString}${sortString}&per_page=${current_rowPerPage ? current_rowPerPage : 10}`)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return error;
      });
  } catch (error) {
    console.error("Error fetching user data:", error);
    throw error;
  }
};


export const fetchResidentDetails = async (residentId) => {
  try {
    return await apiClient
      .get(`${BASE_CRM_DASHBOARD_URL}/${residentId}/${API_ACTION.getResidentDetailsAPI}`)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return error;
      });
  } catch (error) {
    console.error("Error::", error);
    throw error;
  }
};

export const fetchResidentOnboardingDetails = async (residentId) => {
  try {
    return await apiClient
      .get(
        `${BASE_CRM_DASHBOARD_URL}/${residentId}/${API_ACTION.getResidentOnboardingDetailsAPI}`
      )
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return error;
      });
  } catch (error) {
    console.error("Error::", error);
    throw error;
  }
};

export const fetchResidentWalletLedger = async (residentId) => {
  try {
    return await apiClient
      .get(
        `${BASE_CRM_DASHBOARD_URL}/${residentId}/${API_ACTION.getResidentWalletLedgerAPI}`
      )
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return error;
      });
  } catch (error) {
    console.error("Error::", error);
    throw error;
  }
};
export const downloadProformaInvoice = async (id) => {
  try {
    return await apiClient
      .get(
        `${BASE_CRM_DASHBOARD_URL}/${id}/proforma_invoice`
      )
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return error;
      });
  } catch (error) {
    console.error("Error::", error);
    throw error;
  }
};

export const fetchResidentExitDetails = async (residentId) => {
  try {
    return await apiClient
      .get(
        `${BASE_CRM_DASHBOARD_URL}/${residentId}/${API_ACTION.getExitDetailsAPI}`
      )
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return error;
      });
  } catch (error) {
    console.error("Error::", error);
    throw error;
  }
};

export const fetchProperties = async (currentPage,searchObject,sortObject , current_rowPerPage) => {

  let searchString = ''
  let sortString = ''
  Object.keys(searchObject).map((key)=>{
    if(key!='city'){
      searchString = searchString + `&${key}=${searchObject[key]}`
    }
  })
  Object.keys(sortObject).map((key)=>{
    sortString = sortString + `&${key}=${sortObject[key]}`
  })

  try {
    let query = (currentPage)?"?page="+currentPage:"";

    return await apiClient
      .get(`${BASE_CRM_DASHBOARD_URL}/${API_ACTION.getPropertiesList}?page=${currentPage}${searchString}${sortString}&per_page=${current_rowPerPage || 10}`)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return error;
      });
  } catch (error) {
    console.error("Error fetching user data:", error);
    throw error;
  }
};

export const getPropertyDetail = async (propertyId) => {
  try {
    return await apiClient
      .get(`${BASE_CRM_DASHBOARD_URL}/${API_ACTION.getPropertyDetail}/${propertyId}`)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return error;
      });
  } catch (error) {
    console.error("Error fetching user data:", error);
    throw error;
  }
};
export const createProperty = async (propertyId) => {
  try {
    return await apiClient
      .get(`${BASE_CRM_DASHBOARD_URL}/${API_ACTION.createProperty}`)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return error;
      });
  } catch (error) {
    console.error("Error fetching user data:", error);
    throw error;
  }
};

export const addNewCreateProperty = async (payload) => {
  try {
    return await axios.post(`${BASE_CRM_DASHBOARD_URL}/${API_ACTION.createProperty}`, JSON.stringify(payload),
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${secureLocalStorage.getItem("accessToken")}`,
        },
      }
    ).then((response) => {
      return response.data;
    }).catch((error) => {
      return error;
    });
  } catch (error) {
    console.error("Error Add New Property fetching user data:", error);
    throw error;
  }
};

export const updateProperty = async (id,payload) => {
  try {
    return await axios.put(`${BASE_CRM_DASHBOARD_URL}/${API_ACTION.createProperty}/${id}`, JSON.stringify(payload),
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${secureLocalStorage.getItem("accessToken")}`,
        },
      }
    ).then((response) => {
      return response.data;
    }).catch((error) => {
      return error;
    });
  } catch (error) {
    console.error("Error updating property data:", error);
    throw error;
  }
};

export const updateExitStatus = async (id,payload) => {
  try {
    return await axios.put(`${BASE_CRM_DASHBOARD_URL}/${id}/${API_ACTION.exitStatusUpdateAPI}`, JSON.stringify(payload),
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${secureLocalStorage.getItem("accessToken")}`,
         },
      }
    ).then((response) => {
      return response.data;
    }).catch((error) => {
      return error;
    });
  } catch (error) {
    console.error("Error updating exit details data:", error);
    throw error;
  }
};

export const resentLink = async (id,payload) => {

  try {
    return await axios.get(`${BASE_CRM_DASHBOARD_URL}/${id}/${API_ACTION.resentLinkAPI}?is_parent=${payload}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${secureLocalStorage.getItem("accessToken")}`,
        },
      }
    ).then((response) => {
      return response.data;
    }).catch((error) => {
      return error;
    });
  } catch (error) {
    console.error("Error Add New Property fetching user data:", error);
    throw error;
  }
};

export const addNewState = async (payload) => {
  try {
    return await axios.post(`${BASE_CRM_DASHBOARD_URL}/${API_ACTION.statesAPI}`, JSON.stringify(payload),
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${secureLocalStorage.getItem("accessToken")}`,
        },
      }
    ).then((response) => {
      return response.data;
    }).catch((error) => {
      return error;
    });
  } catch (error) {
    console.error("Error Add New Property fetching user data:", error);
    throw error;
  }
};

export const updateState = async (id,payload) => {
  try {
    return await axios.put(`${BASE_CRM_DASHBOARD_URL}/${API_ACTION.statesAPI}/${id}`, JSON.stringify(payload),
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${secureLocalStorage.getItem("accessToken")}`,
        },
      }
    ).then((response) => {
      return response.data;
    }).catch((error) => {
      return error;
    });
  } catch (error) {
    console.error("Error Add New Property fetching user data:", error);
    throw error;
  }
};

export const addNewRoomType = async (payload) => {
  try {
    return await axios.post(`${BASE_CRM_DASHBOARD_URL}/${API_ACTION.statesAPI}`, JSON.stringify(payload),
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${secureLocalStorage.getItem("accessToken")}`,
        },
      }
    ).then((response) => {
      return response.data;
    }).catch((error) => {
      return error;
    });
  } catch (error) {
    console.error("Error Add New Room type data:", error);
    throw error;
  }
};

export const updateRoomType = async (id,payload) => {
  try {
    return await axios.put(`${BASE_CRM_DASHBOARD_URL}/${API_ACTION.statesAPI}/${id}`, JSON.stringify(payload),
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${secureLocalStorage.getItem("accessToken")}`,
        },
      }
    ).then((response) => {
      return response.data;
    }).catch((error) => {
      return error;
    });
  } catch (error) {
    console.error("Error Add New Room type data:", error);
    throw error;
  }
};

export const addNewCluster = async (payload) => {
  try {
    return await axios.post(`${BASE_CRM_DASHBOARD_URL}/${API_ACTION.clustersAPI}`, JSON.stringify(payload),
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${secureLocalStorage.getItem("accessToken")}`,
        },
      }
    ).then((response) => {
      return response.data;
    }).catch((error) => {
      return error;
    });
  } catch (error) {
    console.error("Error Add New Room Type data:", error);
    throw error;
  }
};

export const updateCluster = async (id,payload) => {
  try {
    return await axios.put(`${BASE_CRM_DASHBOARD_URL}/${API_ACTION.clustersAPI}/${id}`, JSON.stringify(payload),
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${secureLocalStorage.getItem("accessToken")}`,
        },
      }
    ).then((response) => {
      return response.data;
    }).catch((error) => {
      return error;
    });
  } catch (error) {
    console.error("Error Update cluster data:", error);
    throw error;
  }
};

export const addNewCity = async (payload) => {
  try {
    return await axios.post(`${BASE_CRM_DASHBOARD_URL}/${API_ACTION.citiesAPI}`, JSON.stringify(payload),
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${secureLocalStorage.getItem("accessToken")}`,
        },
      }
    ).then((response) => {
      return response.data;
    }).catch((error) => {
      return error;
    });
  } catch (error) {
    console.error("Error Add New Property fetching user data:", error);
    throw error;
  }
};

export const updateCity = async (id,payload) => {
  try {
    return await axios.put(`${BASE_CRM_DASHBOARD_URL}/${API_ACTION.citiesAPI}/${id}`, JSON.stringify(payload),
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${secureLocalStorage.getItem("accessToken")}`,
        },
      }
    ).then((response) => {
      return response.data;
    }).catch((error) => {
      return error;
    });
  } catch (error) {
    console.error("Error Add New Property fetching user data:", error);
    throw error;
  }
};

export const createPropertyRoomTypeMapping = async (payload) => {
  try {
    return await axios.post(`${BASE_CRM_DASHBOARD_URL}/${API_ACTION.createPropertyRoomTypeMappingAPI}`,
      JSON.stringify(payload),
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${secureLocalStorage.getItem("accessToken")}`,
        },
      }
    ).then((response) => {
      return response.data;
    }).catch((error) => {
      return error;
    });
  } catch (error) {
    console.error("Error Add New Property fetching user data:", error);
    throw error;
  }
};

export const createPropertyBilling = async (payload) => {
  try {
    return await axios.post(`${BASE_CRM_DASHBOARD_URL}/${API_ACTION.createPropertyBillingAPI}`,
      JSON.stringify(payload),
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${secureLocalStorage.getItem("accessToken")}`,
        },
      }
    ).then((response) => {
      return response.data;
    }).catch((error) => {
      return error;
    });
  } catch (error) {
    console.error("Error Add New Property fetching user data:", error);
    throw error;
  }
};

// export const fetchProperties = async () => {
//     try {
//         return await apiClient.get(`${BASE_CRM_DASHBOARD_URL}/${API_ACTION.getPropertiesList}`)
//             .then((response) => {
//                 return response.data;
//             })
//             .catch((error) => {
//                 return error;
//             });
//     } catch (error) {
//         console.error('Error::', error);
//         throw error;
//     }
// };

// ONBOARDING APIs
export const fetchOnboardingList = async (currentPage = 1,searchObject = "",sortObject = "", current_rowPerPage = 10) => {

  let searchString = ''
  let sortString = ''
  Object.keys(searchObject).map((key)=>{
    searchString = searchString + `&${key}=${searchObject[key]}`
  })
  Object.keys(sortObject).map((key)=>{
    sortString = sortString + `&${key}=${sortObject[key]}`
  })

  try {
    return await apiClient
      .get(`${BASE_CRM_DASHBOARD_URL}/${API_ACTION.getOnboardingListAPI}?page=${currentPage}${searchString}${sortString}&per_page=${current_rowPerPage ? current_rowPerPage : 10}`)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return error;
      });
  } catch (error) {
    console.error("Error::", error);
    throw error;
  }
};

export const fetchOnboardingDetails = async (onboardingId) => {
  try {
    return await apiClient
      .get(`${BASE_CRM_DASHBOARD_URL}/${onboardingId}/${API_ACTION.getOnboardingDetailsAPI}`)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return error;
      });
  } catch (error) {
    console.error("Error::", error);
    throw error;
  }
};

export const confirmCheckInStudent = async (onboardingId) => {
  try {
    return await apiClient
      .put(`${BASE_CRM_DASHBOARD_URL}/${onboardingId}/${API_ACTION.confirmCheckinStudentAPI}`)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return error;
      });
  } catch (error) {
    console.error("Error::", error);
    throw error;
  }
};
export const addOwnerAndKyc = async (data) => {
  let body = JSON.stringify({
    owner_name: "Example Owner",
    rent_amount: 1000,
    bw_owners: "Example BW Owners",
    owner_gst: "ABC123",
    rent_increment_type: "percentage",
    rent_increment_type_value: 5,
    lockin_period: 12,
    agreement_duration: 24,
    rent_start_date: "2023-07-15",
    rent_free_period: "2023-08-01",
    is_msme: true,
    meta: {},
  });
  console.log(body, "body", data);
  try {
    return await axios
      .post(
        `${BASE_CRM_DASHBOARD_URL}/${API_ACTION.propertyOwners}`,

        body,
        {
          headers: { "Content-Type": "application/json" },
        }
      )
      .then((response) => {
        console.log(response, "add owner and kyc");
        return response.data;
      })
      .catch((error) => {
        return error;
      });
  } catch (error) {
    console.error("Error add owner and kyc", error);
    throw error;
  }
};

// export const fetchOnboardingDetails = async (onboardingId) => {
//     try {
//         return await apiClient.get(`${BASE_CRM_DASHBOARD_URL}/${onboardingId}/${API_ACTION.getOnboardingDetailsAPI}`)
//             .then((response) => {
//                 return response.data;
//             })
//             .catch((error) => {
//                 return error;
//             });
//     } catch (error) {
//         console.error('Error::', error);
//         throw error;
//     }
// };

// export const confirmCheckInStudent = async (onboardingId) => {
//     try {
//         return await apiClient.put(`${BASE_CRM_DASHBOARD_URL}/${onboardingId}/${API_ACTION.confirmCheckinStudentAPI}`)
//             .then((response) => {
//                 return response.data;
//             })
//             .catch((error) => {
//                 return error;
//             });
//     } catch (error) {
//         console.error('Error::', error);
//         throw error;
//     }
// };

export const enableAssetReverification = async (onboardingId, payload) => {
  try {
    return await apiClient.put(`${BASE_CRM_DASHBOARD_URL}/${onboardingId}/${API_ACTION.enableAssetReverificationAPI}`, payload)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return error;
      });
  } catch (error) {
    console.error('Error::', error);
    throw error;
  }
};

export const fetchFoodData = async (currentPage) => {
  try {
    return await apiClient
      .get(`${BASE_CRM_DASHBOARD_MASTERS_URL}/${API_ACTION.foodDataAPI}`)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return error;
      });
  } catch (error) {
    console.error("Error fetching user data:", error);
    throw error;
  }
};

export const fetchFoodCategoryData = async () => {
  try {
    return await apiClient
      .get(`${BASE_CRM_DASHBOARD_MASTERS_URL}/${API_ACTION.getFoodCategoryAPI}`)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return error;
      });
  } catch (error) {
    console.error("Error fetching user data:", error);
    throw error;
  }
};

export const addNewFood = async (payload) => {
  try {
    return await axios.post(`${BASE_CRM_DASHBOARD_MASTERS_URL}/${API_ACTION.foodDataAPI}`, JSON.stringify(payload),
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${secureLocalStorage.getItem("accessToken")}`,
        },
      }
    ).then((response) => {
      return response.data;
    }).catch((error) => {
      return error;
    });
  } catch (error) {
    console.error("Error Add New food data:", error);
    throw error;
  }
};

export const updateFood = async (id,payload) => {
  try {
    return await axios.put(`${BASE_CRM_DASHBOARD_MASTERS_URL}/${API_ACTION.foodDataAPI}/${id}`, JSON.stringify(payload),
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${secureLocalStorage.getItem("accessToken")}`,
        },
      }
    ).then((response) => {
      return response.data;
    }).catch((error) => {
      return error;
    });
  } catch (error) {
    console.error("Error update food data:", error);
    throw error;
  }
};

export const fetchEmployeesData = async (searchObject,sortObject) => {

  let searchString = ''
  let sortString = ''
  if(searchObject){
    Object.keys(searchObject).map((key , index)=>{
      searchString = searchString + `${index == 0 ? "" : "&"}${key}=${searchObject[key]}`
    })
  }
  if(sortObject){
    Object.keys(sortObject).map((key,index )=>{
      sortString = sortString + `${index == 0 ? "" : "&"}${key}=${sortObject[key]}`
    })
  }
  try {
    return await apiClient
      .get(`${BASE_CRM_DASHBOARD_URL}/${API_ACTION.getEmployeesAPI}?${searchString}${sortString}`)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return error;
      });
  } catch (error) {
    console.error("Error fetching user data:", error);
    throw error;
  }
};
export const addEmployee = async (payload) => {
  try {
    return await apiClient
      .post(`${BASE_CRM_DASHBOARD_URL}/${API_ACTION.getEmployeesAPI}` , payload)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return error;
      });
  } catch (error) {
    console.error("Error fetching user data:", error);
    throw error;
  }
};
export const employee_roles_list = async (id) => {
  try {
    return await apiClient
      .get(`${BASE_CRM_DASHBOARD_URL}/${API_ACTION.getEmployeesAPI}/${id}/employee_roles_list`)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return error;
      });
  } catch (error) {
    console.error("Error fetching user data:", error);
    throw error;
  }
};
export const changeEmployeRole = async (data) => {
  try {
    return await apiClient
      .post(`${BASE_CRM_DASHBOARD_URL}/${API_ACTION.getEmployeesAPI}/add_employee_role`, data)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return error;
      });
  } catch (error) {
    console.error("Error fetching user data:", error);
    throw error;
  }
};

export const listOfRoles = async () => {
  try {
    return await apiClient
      .get(`${BASE_CRM_DASHBOARD_URL}/${API_ACTION.getEmployeesAPI}/list_of_roles`)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return error;
      });
  } catch (error) {
    console.error("Error fetching user data:", error);
    throw error;
  }
};

export const updateEmployeStatus = async (id , status) => {
  try {
    return await apiClient
      .put(`${BASE_CRM_DASHBOARD_URL}/${API_ACTION.getEmployeesAPI}/${id}/?is_active=${status}`)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return error;
      });
  } catch (error) {
    console.error("Error fetching user data:", error);
    throw error;
  }
};

export const employee_property_list = async (id) => {
  try {
    return await apiClient
      .get(`${BASE_CRM_DASHBOARD_URL}/${API_ACTION.getpropertyEmployee}?employee_id=${id}`)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return error;
      });
  } catch (error) {
    console.error("Error fetching user data:", error);
    throw error;
  }
};

export const create_property_employee = async (data) => {
  try {
    return await apiClient
      .post(`${BASE_CRM_DASHBOARD_URL}/${API_ACTION.getpropertyEmployee}` , data)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return error;
      });
  } catch (error) {
    console.error("Error fetching user data:", error);
    throw error;
  }
};

export const update_property_employee = async (data) => {
  try {
    return await apiClient
      .put(`${BASE_CRM_DASHBOARD_URL}/${API_ACTION.getpropertyEmployee}/${data?.id}?is_active=${data.status}`)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return error;
      });
  } catch (error) {
    console.error("Error fetching user data:", error);
    throw error;
  }
};


export const updateEmployedata = async (id , data) => {
  try {
    return await apiClient
      .put(`${BASE_CRM_DASHBOARD_URL}/${API_ACTION.getEmployeesAPI}/${id}`,data)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return error;
      });
  } catch (error) {
    console.error("Error fetching user data:", error);
    throw error;
  }
};

export const createPropertyOwner = async (payload) => {
  try {
    return await axios.post(`${BASE_CRM_DASHBOARD_URL}/${API_ACTION.createPropertyOwner}`, JSON.stringify(payload),
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${secureLocalStorage.getItem("accessToken")}`,
        },
      }
    ).then((response) => {
      return response.data;
    }).catch((error) => {
      return error;
    });
  } catch (error) {
    console.error("Error while adding property owner:", error);
    throw error;
  }
};

export const createPropertyAssetMapping = async (payload) => {
  try {
    return await axios.post(`${BASE_CRM_DASHBOARD_URL}/${API_ACTION.createPropertyAssetMapping}`, JSON.stringify(payload),
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${secureLocalStorage.getItem("accessToken")}`,
        },
      }
    ).then((response) => {
      return response.data;
    }).catch((error) => {
      return error;
    });
  } catch (error) {
    console.error("Error while adding property owner:", error);
    throw error;
  }
};

export const fetchPropertyOwnerDetails = async (property_id) => {
  try {
    return await apiClient
      .get(`${BASE_CRM_DASHBOARD_URL}/${API_ACTION.getPropertyOwnerDetail}?by_property=${property_id}`)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return error;
      });
  } catch (error) {
    console.error("Error::", error);
    throw error;
  }
};

export const updatePropertyOwner = async (id,payload) => {
  try {
    return await axios.put(`${BASE_CRM_DASHBOARD_URL}/${API_ACTION.createPropertyOwner}/${id}`, JSON.stringify(payload),
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${secureLocalStorage.getItem("accessToken")}`,
        },
      }
    ).then((response) => {
      return response.data;
    }).catch((error) => {
      return error;
    });
  } catch (error) {
    console.error("Error while adding property owner:", error);
    throw error;
  }
};

export const getBookingPlanDetails = async (property_id,room_type_id) => {
  try {
    return await apiClient
      .get(`${BASE_CRM_DASHBOARD_URL}/${API_ACTION.getBillingDetails}?property_id=${property_id}&room_type_id=${room_type_id}`)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return error;
      });
  } catch (error) {
    console.error("Error::", error);
    throw error;
  }
};

export const confirmNoc = async (id,payload) => {
  try {
    return await axios.put(`${BASE_CRM_DASHBOARD_URL}/${id}/${API_ACTION.confirmNocAPI}`, JSON.stringify(payload),
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${secureLocalStorage.getItem("accessToken")}`,
        },
      }
    ).then((response) => {
      return response.data;
    }).catch((error) => {
      return error;
    });
  } catch (error) {
    console.error("Error while NOC confirmation:", error);
    throw error;
  }
};

export const sendEmailToResident = async (id,ledger_type = 'wallet') => {
  try {
    return await axios.put(`${BASE_CRM_DASHBOARD_URL}/${id}/${API_ACTION.sendEmailToResidentAPI}`,"",
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${secureLocalStorage.getItem("accessToken")}`,
        },
        params: {
          ledger_type: ledger_type
        }
      }
    ).then((response) => {
      return response.data;
    }).catch((error) => {
      return error;
    });
  } catch (error) {
    console.error("Error sending resident email:", error);
    throw error;
  }
};

export const fetchPropertyBilling = async (currentPage,searchObject,sortObject , current_rowPerPage) => {

  let searchString = ''
  let sortString = ''
  if(searchObject){
    Object.keys(searchObject).map((key)=>{
      searchString = searchString + `&${key}=${searchObject[key]}`
    })
  }
  if(sortObject){
    Object.keys(sortObject).map((key)=>{
      sortString = sortString + `&${key}=${sortObject[key]}`
    })
  }

  try {
    return await apiClient
      .get(`${BASE_CRM_DASHBOARD_URL}/${API_ACTION.propertyBilling}?page=${currentPage || 1}${searchString}${sortString}&per_page=${current_rowPerPage ? current_rowPerPage : 10}`)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return error;
      });
  } catch (error) {
    console.error("Error fetching user data:", error);
    throw error;
  }
};



export const updatePropertyBillings = async (id, data) => {
  try {
    return await axios.put(`${BASE_CRM_DASHBOARD_URL}/${API_ACTION.propertyBilling}/${id}`,data,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${secureLocalStorage.getItem("accessToken")}`,
        },
      }
    ).then((response) => {
      return response.data;
    }).catch((error) => {
      return error;
    });
  } catch (error) {
    console.error("Error sending resident email:", error);
    throw error;
  }
};

export const residentBondStatus = async (id) => {
  try {
    return await axios.get(`${BASE_CRM_DASHBOARD_URL}/${id}/${API_ACTION.residentBondStatus}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${secureLocalStorage.getItem("accessToken")}`,
        },
      }
    ).then((response) => {
      return response.data;
    }).catch((error) => {
      return error;
    });
  } catch (error) {
    console.error("Error while checking bond status", error);
    throw error;
  }
};

export const residentBondEligibilityCheck = async (id) => {
  try {
    return await axios.get(`${BASE_CRM_DASHBOARD_URL}/${id}/${API_ACTION.eligbilityCheck}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${secureLocalStorage.getItem("accessToken")}`,
        },
      }
    ).then((response) => {
      return response.data;
    }).catch((error) => {
      return error;
    });
  } catch (error) {
    console.error("Error checking bond eligibility ", error);
    throw error;
  }
};

export const disableBond = async (id) => {
  try {
    return await axios.post(`${BASE_CRM_DASHBOARD_URL}/${id}/${API_ACTION.disableBond}`, {} ,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${secureLocalStorage.getItem("accessToken")}`,
        },
      }
    ).then((response) => {
      return response.data;
    }).catch((error) => {
      return error;
    });
  } catch (error) {
    console.error("Error disabling bond", error);
    throw error;
  }
};

export const createBondDetails = async (id , data) => {
  try {
    return await axios.post(`${BASE_CRM_DASHBOARD_URL}/${id}/${API_ACTION.createBondApplicationDetails}`, data ,
      {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${secureLocalStorage.getItem("accessToken")}`,
        },
      }
    ).then((response) => {
      return response.data;
    }).catch((error) => {
      return error;
    });
  } catch (error) {
    console.error("Error creating bond details", error);
    throw error;
  }
};

export const updateBondDetails = async (id , data) => {
  try {
    return await axios.patch(`${BASE_CRM_DASHBOARD_URL}/${id}/${API_ACTION.updateBondApplicationDetails}`, data ,
      {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${secureLocalStorage.getItem("accessToken")}`,
        },
      }
    ).then((response) => {
      return response.data;
    }).catch((error) => {
      return error;
    });
  } catch (error) {
    console.error("Error updating bond detail", error);
    throw error;
  }
};

//Tickets
export const fetchTickets = async (currentPage,searchObject,sortObject , current_rowPerPage) => {

  let searchString = ''
  let sortString = ''
  if(searchObject){
    Object.keys(searchObject).map((key)=>{
      searchString = searchString + `&${key}=${searchObject[key]}`
    })
  }
  if(sortObject){
    Object.keys(sortObject).map((key)=>{
      sortString = sortString + `&${key}=${sortObject[key]}`
    })
  }

  try {
    return await apiClient
      .get(`${BASE_CRM_DASHBOARD_URL}/${API_ACTION.getTicketsAPI}?page=${currentPage}${searchString}${sortString}&per_page=${current_rowPerPage ? current_rowPerPage : 10}`)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return error;
      });
  } catch (error) {
    console.error("Error fetching user data:", error);
    throw error;
  }
};

export const fetchTicketDetails = async (ticketId) => {
  try {
    return await apiClient
      .get(
        `${BASE_CRM_DASHBOARD_URL}/${API_ACTION.getTicketsAPI}/${ticketId}`
      )
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return error;
      });
  } catch (error) {
    console.error("Error::", error);
    throw error;
  }
};

export const updateTicketStatus = async (data) => {
  try {
    return await apiClient
      .post(
        `${BASE_CRM_DASHBOARD_URL}/${API_ACTION.getTicketsAPI}/${API_ACTION.updateTicketsStatusAPI}` , data
      )
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return error;
      });
  } catch (error) {
    console.error("Error::", error);
    throw error;
  }
};

export const addTicketAttachment = async (data) => {
  try {
    return await apiClient
      .post(
        `${BASE_CRM_DASHBOARD_URL}/${API_ACTION.getTicketsAPI}/${API_ACTION.addAttachmentAPI}` , data ,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${secureLocalStorage.getItem("accessToken")}`,
          },
        }
      )
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return error;
      });
  } catch (error) {
    console.error("Error::", error);
    throw error;
  }
};


export const addNotesTicket = async (data) => {
  try {
    return await apiClient
      .post(
        `${BASE_CRM_DASHBOARD_URL}/${API_ACTION.getTicketsAPI}/${API_ACTION.addNotesAPI}` , data 
      )
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return error;
      });
  } catch (error) {
    console.error("Error::", error);
    throw error;
  }
};

export const fetchTicketsNotes = async (id) => {
  try {
    return await apiClient
      .get(
        `${BASE_CRM_DASHBOARD_URL}/${API_ACTION.getTicketsAPI}/${API_ACTION.listNotesAPI}/${id}?page=1&per_page=200`
      )
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return error;
      });
  } catch (error) {
    console.error("Error::", error);
    throw error;
  }
};

export const createResidentCheque = async (data) => {
  try {
    return await axios.post(`${BASE_CRM_DASHBOARD_URL}/${API_ACTION.createResidentCheque}`, data ,
      {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${secureLocalStorage.getItem("accessToken")}`,
        },
      }
    ).then((response) => {
      return response.data;
    }).catch((error) => {
      return error;
    });
  } catch (error) {
    console.error("Error creating bond details", error);
    throw error;
  }
};
