import { toast } from 'react-hot-toast';

const CustomToast = ({ message }) => {
    return (
        <div className="copy-custom-toast">
            <span className='message'>{message}</span>
        </div>
    );
};

const toaster = (message) => {
    toast.custom(<CustomToast message={message} />, {
        className: "custom-toast-container",
        autoClose: 5000,
        style: {
            borderRadius: '10px',
            background: '#333',
            color: '#fff',
        },
    });
};

export default toaster;