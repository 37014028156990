import { useState,useEffect,useContext } from "react";
import "../../../css/resident-details-tab.css";
import { getBookingPlanDetails } from '../../../helpers/services/api.services'
import SvgIcon from '@mui/material/SvgIcon';
import { Modal, Tab, Tabs } from "react-bootstrap";
import CreateBookingPlan from "../../tabs/create-property/booking.plan";
import { fetchRoomTypes } from "../../../helpers/services/admin.services";
import GlobalStateContext from "../../../GlobalStateContext";

function AddIcon(props) {
  return (
    <SvgIcon {...props}>
      <g id="plus-circle" clip-path="url(#clip0_1621_20790)">
      <path id="Icon" d="M10.0003 6.66602V13.3327M6.66699 9.99935H13.3337M18.3337 9.99935C18.3337 14.6017 14.6027 18.3327 10.0003 18.3327C5.39795 18.3327 1.66699 14.6017 1.66699 9.99935C1.66699 5.39698 5.39795 1.66602 10.0003 1.66602C14.6027 1.66602 18.3337 5.39698 18.3337 9.99935Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
      </g>
    </SvgIcon>
  );
}

function BookingPLan({ propertyId,room_details,documentDetails }) {

  const {roles} = useContext(GlobalStateContext);
  const [billingData,setBillingData] = useState([])
  const [documentUrl,setDocumentUrl] = useState("")

  const [showCreateBookingPlanModal,setShowCreateBookingPlanModel] = useState(false)
  const [roomTypes, setRoomTypes] = useState([]);

  const handleOpenBookingPlanModal = async () =>{
    await loadRoomTypes();
    setShowCreateBookingPlanModel(true);
  }

  const handleCloseBookingPlanModal = () =>{
    setShowCreateBookingPlanModel(false);
  }

  const loadRoomTypes = async () => {
    let response = await fetchRoomTypes();

    if (response && response.data && response.data.length > 0) {
      let roomTypes = response.data.map(element => {
        return { value: element?.id, label: element?.name, bedsPerRoom: element?.beds_per_room }
      });

      setRoomTypes(roomTypes);
    }
  }

  useEffect(()=>{
    loadBillingData();
  },[room_details,propertyId])

  useEffect(()=>{
    // console.log("documentDetails -- ",documentDetails)
    setDocumentUrl(documentDetails?.filter(data=>data.document_type == "bw_owner")[0]?.document_url)
  },[documentDetails])

  const loadBillingData = async () => {
    try {
      let requestArray=[]
      room_details.map(details=>{
        requestArray.push(getBookingPlanDetails(propertyId,details.room_type_id))
      })
      Promise.all(requestArray).then(res=>setBillingData(res))
    } catch (error) {
      console.log("Error while fetching booking plan details")
    }
  }

  return (
    <>
      <div className="brd_box_card">
        <div className="box_head">
          <div className="d-flex align-items-center">
            <h2>
              {" "}
              <img
                src="/images/corner-down-right.svg"
                className="pr-2"
                alt=""
              />{" "}
              Plan
            </h2>
            {/* <div className="tit_point green_point mx-3">Initiated</div> */}
          </div>
        </div>
        <div className="box_contain">
          <div className="sub_exit_box">

            {
              room_details.map((details,index)=>
                <>
                  <div className={`d-flex align-items-center ${index!==0? 'pt-4' : ''}`}>
                    <div className="sub_exit_data_txt">
                      <h3>Room type</h3>
                      <span>{details?.room_type_name ? details?.room_type_name : ""}</span>
                    </div>
                    <div className="sub_exit_data_txt">
                      <h3>Beds per room</h3>
                      <span>{details?.beds_per_room ? details?.beds_per_room : ""}</span>
                    </div>
                    <div className="sub_exit_data_txt">
                      <h3>Total beds for admission</h3>
                      <span>{details?.total_beds ? details?.total_beds : ""}</span>
                    </div>
                    <div className="sub_exit_data_txt">
                      <h3>Upload room list</h3>
                      {/* <span>Adhar-Card.excel</span> */}
                      {
                        documentUrl ?
                        <span onClick={()=>window.open(documentUrl, "_blank")} style={{cursor:"pointer"}}>{documentUrl.split("/")[documentUrl.split("/").length-1]}</span>
                        :
                        <span>--</span>
                      }
                    </div>
                  </div>
                  {/* table 1 starts */}
                  <div className="refer_table commen_table pt-5">
                    <div className="page-table-box">
                      <table>
                        <thead>
                          <tr>
                            <th>Bill Type</th>
                            <th>Collection Frequency</th>
                            <th>Schedule On</th>
                            <th className="text-right">Amount</th>
                            <th className="text-right">Maximum Amount</th>
                          </tr>
                        </thead>
                        <tbody>
                        {
                          billingData?.[index]?.data.map(details=>
                            <>
                              <tr>
                                <td>{details?.bill_name ? details.bill_name : ""}</td>
                                <td>{details?.frequency ? details.frequency : ""}</td>
                                <td>--</td>
                                <td className="text-right">
                                ₹ {details?.amount ? details.amount : ""}
                                </td>
                                <td className="text-right">₹ {details?.discount_value ? details.discount_value : ""}</td>
                            </tr>
                            </>
                            )
                        }
                        </tbody>
                      </table>
                    </div>
                  </div>
                       
                  {/* {
                    room_details?.length > 0 ? */}
                      
                    {/* : null
                  } */}
                </>
              )
            }

            {/* table 1 ends */}

            {/*  */}
            {/* <div className="d-flex align-items-center pt-4 pb-5">
              <div className="sub_exit_data_txt">
                <h3>Room type</h3>
                <span>Twin sharing premium</span>
              </div>
              <div className="sub_exit_data_txt">
                <h3>Beds per room</h3>
                <span>2</span>
              </div>
              <div className="sub_exit_data_txt">
                <h3>Total beds for admission</h3>
                <span>40</span>
              </div>
              <div className="sub_exit_data_txt">
                <h3>Upload room list</h3>
                <span>Adhar-Card.excel</span>
              </div>
            </div> */}

            {/* table 2 starts */}
            {/* <div className="refer_table commen_table">
              <div className="page-table-box">
                <table>
                  <thead>
                    <tr>
                      <th>Bill Type</th>
                      <th>Collection Frequency</th>
                      <th>Schedule On</th>
                      <th className="text-right">Amount</th>
                      <th className="text-right">Maximum Amount</th>
                    </tr>
                  </thead>
                  <tbody>
                  <tr>
                      <td>SD</td>
                      <td>One time</td>
                      <td>August 23</td>
                      <td className="text-right">
                      ₹ 30000
                      </td>
                      <td className="text-right">₹ 30000</td>
                    </tr>
                    <tr>
                      <td>Rent</td>
                      <td>Quarterly</td>
                      <td>Aug- Sep 23</td>
                      <td className="text-right">₹ 100000</td>
                      <td className="text-right">₹ 100000</td>
                    </tr>

                    <tr>
                      <td>Electricity</td>
                      <td>Monthly</td>
                      <td>August 23</td>
                      <td className="text-right">₹ 280000</td>
                      <td className="text-right">₹ 280000</td>
                    </tr>
                    <tr>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td className="text-right"></td>
                    </tr>
                    <tr>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td className="text-right"></td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div> */}
            {/* table 2 ends */}
          </div>
        </div>
      </div>
      {
        roles?.super_admin && billingData?.length == 0 ? 
          <div style={{
              position:'fixed',
              bottom:25,
              right:25
              }}>
              
                  <button
                      style={{border:"none"}}
                      data-bs-toggle="tooltip" data-bs-placement="top" title={"Add booking plan"}
                      className="edit_button"
                      onClick={handleOpenBookingPlanModal}
                  >
                    <><AddIcon fontSize="small" /> Add</>
              </button>
          </div>
        : null
      }

<Modal
        show={showCreateBookingPlanModal}
        onHide={handleCloseBookingPlanModal}
        centered
        size="lg"
        className="confirm_noc_modal"
      >
        <Modal.Body>
          <div className="head_part_date_modal create-prop_modal">
            <div className="d-flex justify-content-between align-items-center mb-4">
              <div>
                <h2 className="title">Create Booking Plan</h2>
              </div>
              <div style={{cursor:"pointer"}}>
                <span onClick={handleCloseBookingPlanModal} className="">
                  <img src="/images/cross_modal.svg" alt="Close Icon" />
                </span>
              </div>
            </div>
          </div>
          <div className="modal_data_part">
            {/* <div className="main_tab">
              <Tabs
                defaultActiveKey="detailsTab"
                id="resident-details-view"
                className="mb-4"
              >
                <Tab eventKey="bookingPlan" title="Booking Plans"> */}
                  <CreateBookingPlan
                    roomTypes={roomTypes}
                    savedPropertyId={propertyId}
                    handleCloseModal={handleCloseBookingPlanModal}
                  />
                {/* </Tab>
                
              </Tabs>
            </div> */}
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default BookingPLan;
