import React, { useMemo } from "react";
import Modal from "react-bootstrap/Modal";
import styles from "./LedgerStatusModal.module.scss";
import { statusMapper } from "../../../revenuePDCPage/utils";
import StatusBadge from "../../../../../components/StatusBadge";
import { putDirectPayLedgerEntryApi } from "../../../../../helpers/services/revenueservices";

export default function LedgerStatusModal({ info, show, close }) {
  const statusBadge = statusMapper[info?.status] ?? statusMapper.collected;
  const btnInfo = useMemo(() => {
    if (info) {
      if (info.status === "collected" || info.status === "rejected") {
        return {
          success: {
            label: "Deposit",
            value: "deposited",
          },
          fail: {
            label: "",
            value: "",
          },
        };
      } else if (info.status === "deposited") {
        return {
          success: {
            label: "Approve",
            value: "approved",
          },
          fail: {
            label: "Reject",
            value: "rejected",
          },
        };
      }
    }
    return null;
  }, [info]);

  if (!info) {
    return <></>;
  }

  const onSave = async (value) => {
    try {
      await putDirectPayLedgerEntryApi({
        ids: [info.id],
        status: value,
      });
      close(value, true);
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <Modal
      show={show}
      onHide={close}
      backdrop="static"
      dialogClassName={styles["dialog-lsm-container"]}
      keyboard={false}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id={styles["#modal-title"]}>
          <span>{info.payment_transaction_id}</span>{" "}
          <StatusBadge
            style={{ ...statusBadge.style, marginLeft: "1rem" }}
            label={statusBadge.label}
          />
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
          <div className="d-flex flex-wrap">
            <div className={styles["column-5"]}>
              <div className={styles["view-block"]}>
                <label>Property Code</label>
                <p>{info.property_details?.property_code}</p>
              </div>
            </div>
            <div className={styles["column-5"]}>
              <div className={styles["view-block"]}>
                <label>Property Name</label>
                <p>{info.property_details?.property_name}</p>
              </div>
            </div>
            <div className={styles["column-5"]}>
              <div className={styles["view-block"]}>
                <label>Resident ID</label>
                <p>{info.lead_details?.id}</p>
              </div>
            </div>
            <div className={styles["column-5"]}>
              <div className={styles["view-block"]}>
                <label>Resident Name</label>
                <p>{info.lead_details?.resident_name}</p>
              </div>
            </div>
            <div className={styles["column-5"]}>
              <div className={styles["view-block"]}>
                <label>Transaction Date</label>
                <p>{info.transaction_date}</p>
              </div>
            </div>
            <div className={styles["column-5"]}>
              <div className={styles["view-block"]}>
                <label>{info.status === "rejected" ? "Rejected By" : "Approved By"}</label>
                {/* <p>{info?.approved_by_details?.approved_by_name}</p> */}
                {
                  info.status === "rejected" ? 
                    <p>{`${info?.rejected_by_details?.employee_first_name ?? ""} ${info?.rejected_by_details?.employee_last_name ?? ""}`}</p>
                  :
                    <p>{`${info?.approved_by_details?.employee_first_name ?? ""} ${info?.approved_by_details?.employee_last_name ?? ""}`}</p>
                }
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        {btnInfo ? (
          <>
            <button
              className={"btn " + styles["btn-cancel"]}
              style={{ visibility: btnInfo.fail.value ? "visible" : "hidden" }}
              onClick={() => onSave(btnInfo.fail.value)}
            >
              {btnInfo.fail.label}
            </button>
            <button
              className={"btn " + styles["btn-submit"]}
              style={{
                visibility: btnInfo.success.value ? "visible" : "invisible",
              }}
              onClick={() => onSave(btnInfo.success.value)}
            >
              {btnInfo.success.label}
            </button>
          </>
        ) : (
          <></>
        )}
      </Modal.Footer>
    </Modal>
  );
}
