import React, { useState,useEffect } from "react";
import Select from "react-select";
import Multiselect from 'multiselect-react-dropdown';
import { updateProperty,createPropertyAssetMapping } from "../../../helpers/services/api.services";
import { useFormik } from 'formik';
import * as Yup from 'yup';

export default function CreateOperations({
  savedPropertyId,
  emplyees,
  commonAssets,
  roomAssets,
  handleCloseModal,
  refreshData,
}) {
  const [selectValue,setSelectValue] = useState("")

  const [roomAssetsSelected, setRoomAssestsSelected] = useState([])
  const [commonAssetsSelected, setCommonAssestsSelected] = useState([])
  const [salesManagerList,setSalesManagerList] = useState([])
  const [adminList,setAdminList] = useState([])
  const [propertyManagerList,setPropertyManagerList] = useState([])
  const [cluseterManagerList,setCluseterManagerList] = useState([])

  useEffect(()=>{
    
    let sales_manager_list = emplyees.filter(e=>e.job_title==="sales_manager").map(e=>({value:e.id,label:`${e.employee_first_name} ${e.employee_last_name}`}))
    let admin_list = emplyees.filter(e=>e.job_title==="admin").map(e=>({value:e.id,label:`${e.employee_first_name} ${e.employee_last_name}`}))
    let property_manager_list = emplyees.filter(e=>e.job_title==="property_manager").map(e=>({value:e.id,label:`${e.employee_first_name} ${e.employee_last_name}`}))
    let cluster_manager_list = emplyees.filter(e=>e.job_title==="care_taker").map(e=>({value:e.id,label:`${e.employee_first_name} ${e.employee_last_name}`}))

    setSalesManagerList(sales_manager_list)
    setAdminList(admin_list)
    setPropertyManagerList(property_manager_list)
    setCluseterManagerList(cluster_manager_list)

  },[emplyees])

  const initialValues = {
    sales_manager:'',
    admin: '',
    property_manager: '',
    cluster_manager:'',
  }

  const validationSchema = Yup.object().shape({
    sales_manager: Yup.string(),
    admin: Yup.string(),
    property_manager: Yup.string(),
    cluster_manager: Yup.string()
});

const formik = useFormik({
  initialValues,
  validationSchema:null,
  onSubmit: async (values) => {
      // console.log("values -- ",values)
      // console.log("admin -- ",values.admin? true: false)
      // console.log("property_manager -- ",values.property_manager?true:false)
      // console.log("sales_manager -- ",values.sales_manager?true:false)
      // console.log("cluster_manager -- ",values.cluster_manager?true:false)
      try {
        let contactDetailsPayload={
          sales_poc_id: values.sales_manager ? values.sales_manager.value :'',
          regional_manager_id:'',
          cluster_manager_id: values.cluster_manager ? values.cluster_manager.value : '',
          city_head_id: values.admin ? values.admin.value : false,
          property_manager_id: values.property_manager ? values.property_manager.value : '',
        }
        await updateProperty(205, contactDetailsPayload)
        let propertyAssetMappingPayload={
          property_asset_mappings:[]
        }
        if(roomAssetsSelected.length > 0){
          roomAssetsSelected.map(asset=>propertyAssetMappingPayload.property_asset_mappings.push({
            property_id: 205, 
            property_asset_id: asset
          }))
        }
        if(commonAssetsSelected.length > 0){
          commonAssetsSelected.map(asset=>propertyAssetMappingPayload.property_asset_mappings.push({
            property_id: 205, 
            property_asset_id: asset
          }))
        }
        if(propertyAssetMappingPayload.property_asset_mappings.length > 0){
          let response = await createPropertyAssetMapping(propertyAssetMappingPayload)
        }
        // console.log("propertyAssetMappingPayload -- ",propertyAssetMappingPayload)
      } catch (error) {
        console.log("Error while mapping property assets/point of contact -- ",error)
      }
  },
});

  return (
    <div className="createOps">
      <h3 className="title">POINT OF CONTACT</h3>
    <form noValidate onSubmit={formik.handleSubmit}>
      <div className="row">
        <div className="col-4">
          <div className="form_control_box">
            <label className="form-label" htmlFor="sales_manager">
              Sales
            </label>

            {/* <Multiselect
                options={yes.options} // Options to display in the dropdown
                // selectedValues={this.state.selectedValue} // Preselected value to persist in dropdown
                onSelect={(e)=>console.log("select event -- ",e)} // Function will trigger on select event
                onRemove={(e)=>console.log("Remove event -- ",e)} // Function will trigger on remove event
                displayValue="name" // Property name to display in the dropdown options
                /> */}

             <Select
              id="sales_manager"
              name="sales_manager"
              // isMulti={true}
              // value={[
              //   { value: "rohit-nanjan", label: "Rohit Nanjan" }
              // ]}
              isSearchable={true}
              placeholder="Select Sales"
              value={formik.values.sales_manager}
              options={salesManagerList}
              onChange={values=>formik.setFieldValue("sales_manager",values)}
            /> 
          </div>
        </div>
        <div className="col-4">
          <div className="form_control_box">
            <label className="form-label" htmlFor="admin">
            Admin
            </label>
            <Select
              id="admin"
              name="admin"
              placeholder="Select admin"
              // isMulti={true}
              value={formik.values.admin}
              options={adminList}
              onChange={values=>formik.setFieldValue("admin",values)}
            />
          </div>
        </div>
        <div className="col-4">
          <div className="form_control_box">
            <label className="form-label" htmlFor="property_manager">
            Property Manager
            </label>
            <Select
              id="property_manager"
              name="property_manager"
              placeholder="Select Property Manager"
              // isMulti={true}
              value={formik.values.property_manager}
              options={propertyManagerList}
              onChange={values=>formik.setFieldValue("property_manager",values)}
            />
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-4">
          <div className="form_control_box">
            <label className="form-label" htmlFor="cluster_manager">
            Cluster Manager
            </label>
            <Select
              id="cluster_manager"
              name="cluster_manager"
              placeholder="Select Cluster Manager"
              // isMulti={true}
              value={formik.values.cluster_manager}
              options={cluseterManagerList}
              onChange={values=>formik.setFieldValue("cluster_manager",values)}
            />
          </div>
        </div>
        </div>

        <div className="row mt-4">
              <h3 className="title">ASSETS PROVIDED IN ROOM</h3>
              <div className="chips-wrapper mt-3">
                  {
                  roomAssets.map((asset,index)=>
                  <div 
                    id={asset.id}
                      className={`chip-orange ${roomAssetsSelected.includes(asset.id) ? 'chip-active' : ''}`}
                      onClick={()=>{
                        let array = [...roomAssetsSelected]
                        index = array.indexOf(asset.id)
                        if (index > -1) { 
                          array.splice(index, 1);
                        }else{
                          array.push(asset.id)
                        }
                        setRoomAssestsSelected(array)
                      }}
                    >
                    <img src="/images/exclam.png" />
                    <span>{asset.asset_slug}</span>
                  </div>
                  )
                }
                  {/* <div 
                    // className={"chip-orange"}
                    className={state1}
                    onClick={()=>state1==="chip-orange" ? setState1("chip-orange chip-active") : setState1("chip-orange")}
                  >
                      <img src="/images/exclam.png" />
                      <span>Chair</span>
                  </div>
                  <div 
                    // className={"chip-orange"}
                    className={state2}
                    onClick={()=>state2==="chip-orange" ? setState2("chip-orange chip-active") : setState2("chip-orange")}
                  >
                      <img src="/images/exclam.png" />
                      <span>Table</span>
                  </div>
                  <div 
                    // className={"chip-orange"}
                    className={state5}
                    onClick={()=>state5==="chip-orange" ? setState5("chip-orange chip-active") : setState5("chip-orange")}
                  >
                      <img src="/images/exclam.png" />
                      <span>Washing machine</span>
                  </div>
                  <div 
                    // className={"chip-orange"}
                    className={state3}
                    onClick={()=>state3==="chip-orange" ? setState3("chip-orange chip-active") : setState3("chip-orange")}
                  >
                      <img src="/images/exclam.png" />
                      <span>Oven</span>
                  </div>
                  <div 
                    // className={"chip-orange"}
                    className={state4}
                    onClick={()=>state4==="chip-orange" ? setState4("chip-orange chip-active") : setState4("chip-orange")}
                  >
                      <img src="/images/exclam.png" />
                      <span>Cupboard</span>
                  </div> */}

              </div>
        </div>
        <div className="row mt-4">
              <h3 className="title">ASSETS PROVIDED IN COMMON ROOM</h3>
              <div className="chips-wrapper mt-3">
                {
                  commonAssets.map((asset,index)=>
                  <div 
                    id={asset.id}
                      className={`chip-orange ${commonAssetsSelected.includes(asset.id) ? 'chip-active' : ''}`}
                      onClick={()=>{
                        let array = [...commonAssetsSelected]
                        index = array.indexOf(asset.id)
                        if (index > -1) { 
                          array.splice(index, 1);
                        }else{
                          array.push(asset.id)
                        }
                        setCommonAssestsSelected(array)
                      }}
                    >
                    <img src="/images/exclam.png" />
                    <span>{asset.asset_name}</span>
                  </div>
                  )
                }
                  {/* <div 
                    // className={"chip-orange"}
                    className={state6}
                    onClick={()=>state6==="chip-orange" ? setState6("chip-orange chip-active") : setState6("chip-orange")}
                  >
                      <img src="/images/exclam.png" />
                      <span>Chair</span>
                  </div>
                  <div 
                    // className={"chip-orange"}
                    className={state7}
                    onClick={()=>state7==="chip-orange" ? setState7("chip-orange chip-active") : setState7("chip-orange")}
                  >
                      <img src="/images/exclam.png" />
                      <span>Table</span>
                  </div>
                  <div 
                    // className={"chip-orange"}
                    className={state8}
                    onClick={()=>state8==="chip-orange" ? setState8("chip-orange chip-active") : setState8("chip-orange")}
                  >
                      <img src="/images/exclam.png" />
                      <span>Washing machine</span>
                  </div>
                  <div 
                    // className={"chip-orange"}
                    className={state9}
                    onClick={()=>state9==="chip-orange" ? setState9("chip-orange chip-active") : setState9("chip-orange")}
                  >
                      <img src="/images/exclam.png" />
                      <span>Oven</span>
                  </div>
                  <div 
                    // className={"chip-orange"}
                    className={state10}
                    onClick={()=>state10==="chip-orange" ? setState10("chip-orange chip-active") : setState10("chip-orange")}
                  >
                      <img src="/images/exclam.png" />
                      <span>Cupboard</span>
                  </div> */}
              </div>
        </div>

        <div className="modal-footer">
            <button 
              className="orange_brd_button p-12-16-button mr-3"
              onClick={()=>handleCloseModal()}
              >
                CANCEL
            </button>
            <button 
              type="submit"
              className="basic-button">SAVE</button>
        </div>
      </form>
    </div>
  );
}
